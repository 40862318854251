import { Timestamp, writeBatch, doc } from 'firebase/firestore';

export const moreLater = ({ db, currentUser, setDataOfUser }) => {
	try {
		const batch = writeBatch(db);
		const thisUserRef = doc(db, 'users', currentUser.uid);
		const profileEditedObj = {
			profileEdited: {
				status: false,
				sleepTime: Timestamp.now().toDate().getTime() + 24 * 60 * 60 * 1000,
			},
		};
		batch.update(thisUserRef, profileEditedObj);
		setDataOfUser((prev) => ({
			...prev,
			...profileEditedObj,
		}));
		batch.commit();
	} catch (error) {
		console.warn(error.message);
	}
};
