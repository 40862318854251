import styled from 'styled-components';
import { Text } from './../../../styles/textGeneral';

export const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(29, 30, 34, 0.8);
	backdrop-filter: blur(5px);
	z-index: 5000;
`;

export const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
`;

export const ModalContainer = styled.div`
	min-height: 100px;
	background: ${(props) => props.theme.color.neutral900};
	position: fixed;
	border-radius: 8px;
	//box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 8px;
	padding: 20px;
	overflow: hidden;
	z-index: 5000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: fit-content;
	margin: auto;
	color: ${(props) => props.theme.color.neutral100};

	${Text} {
		color: ${(props) => props.theme.color.neutral100};
	}

	h1 {
		font-family: ${(props) => props.theme.font.brandFont};
		font-weight: ${(props) => props.theme.font.weight.semiBold};
		font-size: 1.325em;
		text-align: center;
		padding: 0 10%;
		margin: 10px 0 30px 0;
		line-height: 1.35em;
	}

	.closeModal {
		position: absolute;
		top: 10px;
		right: 15px;
		fill: ${(props) => props.theme.color.neutral400};
		width: 20px;
		cursor: pointer;
		transition: 0.2s all;

		&:hover {
			fill: ${(props) => props.theme.color.neutral900};
		}
	}

	.buttonsContent {
		display: flex;
		margin: 30px 0 0 0;
		gap: 15px;
	}

	@media (min-width: 574px) {
		width: fit-content;
		overflow: auto;
	}

	@media (min-width: 768px) {
		padding: 30px 60px 30px 60px;

		h1 {
			padding: 0;
			margin: 10px 0 30px 0;
		}

		.closeModal {
			top: 15px;
			right: 20px;
		}

		.buttonsContent {
			gap: 20px;
		}
	}
`;

export const ModalContainerBoard = styled.div`
	min-height: 100px;
	background: ${(props) => props.theme.color.neutral0};
	position: fixed;
	border-radius: 14px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 8px;
	overflow: auto;
	z-index: 5000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 50px;
	width: fit-content;
	height: fit-content;
	margin: auto;

	img {
		width: 90vw;
		cursor: pointer;
	}

	.closeModal {
		position: absolute;
		top: 10px;
		right: 15px;
		fill: ${(props) => props.theme.color.neutral400};
		width: 20px;
		cursor: pointer;
		transition: 0.2s all;

		&:hover {
			fill: ${(props) => props.theme.color.neutral900};
		}
	}

	.rightArrow,
	.leftArrow {
		position: absolute;
		top: 50%;
		fill: ${(props) => props.theme.color.neutral0};
		transition: all 0.2s;
		width: 30px;
	}

	.rightArrow {
		left: 10px;
	}

	.leftArrow {
		right: 10px;
	}

	.rightArrow:hover,
	.leftArrow:hover {
		fill: ${(props) => props.theme.color.primary200};
		cursor: pointer;
	}

	@media (min-width: 768px) {
		img {
			height: 35vh;
		}
	}

	@media (min-width: 1024px) {
		img {
			height: 70vh;
		}
	}

	@media (min-width: 1200px) {
		img {
			height: auto;
			width: 850px;
		}
	}
`;

export const ModalBoard = styled.div`
	.boardDescription {
		position: fixed;
		bottom: 20px;
		left: 20px;
		right: 20px;
		z-index: 5000;
		color: ${(props) => props.theme.color.neutral0};

		h1 {
			font-size: 1.25em;
			margin: 0 0 10px 0;
		}
	}

	@media (min-width: 768px) {
		.boardDescription {
			bottom: 40px;
			left: 18%;
			right: 18%;
		}
	}

	@media (min-width: 768px) {
		.boardDescription {
			bottom: 40px;
			left: 15%;
			right: 15%;
		}
	}

	@media (min-width: 768px) {
		.boardDescription {
			bottom: 20px;
		}
	}
`;
