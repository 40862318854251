import styled, { css } from 'styled-components';

export const ContainerCards = styled.div`
	display: flex;
	margin: 40px 0 50px 0;
	gap: 20px;
	width: auto;
	justify-content: center;
	flex-wrap: wrap;
`;

export const PriceText = styled.h2`
	font-size: 1.375em;
	font-weight: ${(props) => props.theme.font.weight.bold};
	font-family: ${(props) => props.theme.font.brandFont};
`;

export const CardsTemporaryOffers = styled.div`
	width: 345px;
	height: 644px;
	border-radius: 8px;
	padding: 35px 30px 20px 25px;

	${(props) =>
		props.basic &&
		css`
			background: ${(props) => props.theme.color.neutral350};
		`}
	${(props) =>
		props.premium &&
		css`
			background: ${(props) => props.theme.color.neutral600};
		`}

	.svg-container {
		display: flex;
		justify-content: center;
	}

	.text-container {
		display: inherit;
		width: 290px;
		height: 64px;

		div {
			display: flex;
			flex-wrap: wrap;
			align-items: end;
			flex-direction: column;
			justify-content: flex-end;
		}
	}

	.botton-container {
		justify-content: center;
		margin: 20px 0 10px 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;

		p {
			font-family: ${(props) => props.theme.font.brandFont};
			margin-bottom: 10px;
			text-decoration-line: line-through;
		}
	}

	.list-container {
		display: grid;
		margin: 20px 0 10px 0;
		font-size: 15px;

		div {
			display: grid;
			grid-template-columns: 30px minmax(280px, auto);
			margin: 10px 0 10px 0;
		}
	}
`;

export const CardsLastingOffers = styled.div`
	width: 300px;
	height: 505px;
	border-radius: 8px;
	padding: 25px;
	background: ${(props) => props.theme.color.neutral350};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	label {
		font-size: 16px;
		font-family: ${(props) => props.theme.font.brandFont};
	}

	h1 {
		font-size: 32px;
	}

	p {
		font-size: 13px;
		line-height: 18px;
		width: 260px;
	}

	button {
		margin-top: auto;
	}
`;
