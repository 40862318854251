import styled from 'styled-components';

export const ContactsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
`;

export const FormContainer = styled.form`
	display: flex;
	width: 100%;
	flex-direction: column;

	@media (min-width: 480px) {
		margin: 0 auto;
		width: 400px;
	}
`;

export const ContentEvent = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	> button {
		width: 90%;
	}

	@media (min-width: 480px) {
		> button {
			width: 380px;
		}
	}
	img {
		width: 200px;
		margin-bottom: 20px;
	}
`;
