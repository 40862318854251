import styled from 'styled-components';
import { HeaderLandingContainer, HeaderLandingItems } from './../../landingComponents/styles/sHeaderLanding';

export const HeaderInHomeContainer = styled(HeaderLandingContainer)`
	position: fixed;
	background: ${(props) => props.theme.color.neutral900};
	box-shadow: ${(props) => props.theme.shadow.shadow1};

	.menu {
		fill: ${(props) => props.theme.color.neutral0};
	}

	@media (min-width: 1200px) {
		display: none;
	}
`;

export const HeaderInHomeItems = styled(HeaderLandingItems)`
	height: 50px;

	.logo {
		height: 25px;
		width: auto;
		fill: ${(props) => props.theme.color.neutral0};
	}

	.close {
		width: 20px;
		fill: ${(props) => props.theme.color.neutral0};
	}
`;
