import React from 'react';
import { MainContactAdviser } from '../components/contactAdviser/MainContactAdviser';
import { Helmet } from 'react-helmet-async';

const ContactAdviser = () => {
	return (
		<>
			<Helmet>
				<title>Contactar asesor | Ebbiner</title>
				<meta name="description" content="Ebbiner - Educación virtual" />
				<meta property="og:title" content="Contactar asesor | Ebbiner" />
				<meta property="og:description" content="Ebbiner - Educación virtual" />
				<meta property="twitter:title" content="Contactar asesor | Ebbiner" />
				<meta property="twitter:description" content="Ebbiner - Educación virtual" />
			</Helmet>
			<MainContactAdviser />
		</>
	);
};

export default ContactAdviser;
