import React from 'react';
import { OtherBookContainer } from './styles/sOtherBook';
import { Link } from 'react-router-dom';

const Otherbook = ({ name }) => {
	return (
		<li>
			<Link to="/">
				<OtherBookContainer>
					<p>{name}: </p>
					<a src="/">Ver o descargar</a>
				</OtherBookContainer>
			</Link>
		</li>
	);
};

export default Otherbook;
