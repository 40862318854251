import { useContext } from 'react';
import { AppContext } from '../../App';

const Alternative = ({ uqid, item, question, markedKey, index }) => {
	const { setQuiz } = useContext(AppContext);
	return (
		<input
			type="radio"
			id={uqid.concat(item)}
			name={question}
			defaultChecked={markedKey === item}
			onClick={() => {
				setQuiz({ key: item, selectAlternative: index, uqidSelected: uqid });
			}}
		/>
	);
};

export default Alternative;
