import React, { useEffect, useState, useContext, memo, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { WrapperHome } from '../../styles/generalStyles';

// import { SelectContainer } from '../general/internalComponents/otherElements';
import { CourseCardsUnlist, Preview1CardsUnlist, Preview2CardsUnlist, ProgressSection } from './styles/sMainHome';
import CourseCard from '../general/cards/CourseCard';
// import promoStart from "./../../images/promo-start.webp";
// import promoStartMobile from "./../../images/promo-start-mobile.webp";
// import CardLive from './../general/cards/CardLive';
// import { PromoBanner } from "./../../styles/imageGeneral";
// import { Link } from "react-router-dom";
// import { getLives } from "../../api/getLives";
// import { useDispatch } from 'react-redux';
// import { fetchLive } from '../../actions/index';
import EditProfileModal from './../modal/cards/EditProfileModal';

//import Contexts
import { AppContext } from '../../App';

// Import Firebase utils
import { Timestamp } from 'firebase/firestore';

//import ALGORITHMS
// import ClassWeek from './ClassWeek';
import MainModal from './../modal/MainModal';
// import { ReactComponent as FavoriteSVG } from './../../icons/favorite.svg';
import { ReactComponent as ChartSVG } from './../../icons/chart.svg';
import { ReactComponent as FavoriteSVG } from './../../icons/favorite.svg';
import { ReactComponent as ClockSVG } from './../../icons/clock-five.svg';

import { probabilityCalculator } from '../profile/algorithms/probabilityCalculator';
import { Preview1 } from '../../packages/shared/components/cards/previews/Preview1';
import { Preview2 } from '../../packages/shared/components/cards/previews/Preview2';
import { Profile } from '../../packages/shared/components/items/Profile';
import { useStatesContext } from '../layouts/LayoutContextOfStates';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Section2 } from '../../packages/shared/components/title/Section2';
import { Section1 } from '../../packages/shared/components/title/Section1';
import { ShareWithFriends } from '../profile/ShareWithFriends';
import { SharedSocialMedia } from './SharedSocialMedia';
import { useQuery } from '@tanstack/react-query';
import { getUsersOfPlatform } from './algorithms/getUsersOfPlatform';
import { useFirestore } from 'reactfire';
import { getClassContent } from '../inClasses/algorithms/getClassContent';

const MainHome = () => {
	const [modalState, setModalState] = useState(false);
	const [modalShareState, setModalShareState] = useState(false);
	const [usersPlaform, setUsersPlaform] = useState([]);
	// const [recentPathLocal, setRecentPathLocal] = useState(null);
	const db = useFirestore();
	const [, setWidth] = useState(window.innerWidth);
	const setWindowWidth = () => setWidth(window.innerWidth);
	const {
		dataPrevOfClasses,
		listOfCoursesPerTopics,
		currentUser,
		dataOfClasses,
		weekSelected,
		setWeekSelected,
		dataOfUser,
		setDataOfUser,
	} = useContext(AppContext);
	const { stats, localRecentCoursePath } = useStatesContext();

	const [t] = useTranslation('main');
	const navigate = useNavigate();
	const timestampOfServer = Timestamp.now().toDate().getTime();

	const { isPending, data } = useQuery({
		queryKey: ['getUsers'],
		queryFn: () => getUsersOfPlatform(db),
	});

	useEffect(() => {
		if (data) {
			setUsersPlaform(data);
		}
	}, [isPending]);
	// const dispatch = useDispatch();
	// const lives = useSelector((state) => state.listLives);

	useEffect(() => {
		return () => {
			window.removeEventListener('resize', setWindowWidth);
		};
	});

	useEffect(() => {
		!currentUser && navigate('/');
		window.addEventListener('resize', setWindowWidth);
		dataOfClasses && !weekSelected && setWeekSelected(Object.keys(dataOfClasses.listOfMorningClasses).sort()[0]);
	}, [dataOfClasses, currentUser, navigate, weekSelected]);

	useEffect(() => {
		if (dataOfUser) {
			(!dataOfUser.profileEdited ||
				!dataOfUser.profileEdited?.sleepTime ||
				dataOfUser.profileEdited?.sleepTime <= timestampOfServer) &&
				!dataOfUser.profileEdited?.status &&
				setModalState(true);
		}
	}, [dataOfUser, timestampOfServer]);

	// useEffect(() => {
	// 	dispatch(fetchLive());
	// }, [dispatch]);

	// console.log(stats);
	/*TODO: Uncomment if resolve the errors*/
	const profileDetailsList = [
		{
			image: <FavoriteSVG />,
			title: 'Probabilidad de ingreso',
			description: stats?.averageStatistics ? probabilityCalculator(stats.averageStatistics) : <span>0%</span>,
			color: '#FDFF86',
		},
		{
			image: <ChartSVG />,
			color: '#FFC086',
			description: stats?.averageStatistics ? `${stats.averageStatistics?.totalScoreAverage.toFixed(3)} pts` : <span>--</span>,
			title: 'Promedio de simulacros',
			link: '/statistic/simulacrums/score',
			classN: 'iconBlack',
		},
		{
			image: <ClockSVG />,
			color: '#45FCA4',
			description: stats?.averageStatistics ? stats.averageStatistics?.examsSolved : <span>--</span>,
			title: 'Horas de aprendizaje',
			link: '/statistic/simulacrums/score',
			classN: 'iconBlack',
		},
		// {
		// 	image: <ChartSVG />,
		// 	color: 'green',
		// 	// description: stats?.averageStatistics ? stats.averageStatistics?.examsSolved : <span>--</span>,
		// 	title: 'Simulacros resueltos',
		// 	link: '/statistic/simulacrums/score',
		// 	classN: 'iconBlack',
		// },
	];

	// const listClassWeeks =
	// 	dataOfClasses &&
	// 	Object.keys(dataOfClasses?.listOfMorningClasses)
	// 		.map((week) => parseInt(week?.slice(4)))
	// 		.sort((a, b) => a - b);

	const selectedCourses = useMemo(() => {
		return Object.values(listOfCoursesPerTopics)
			.flat()
			.sort(() => Math.random() - 0.5)
			.slice(0, 4);
	}, [listOfCoursesPerTopics]);

	const recentCourse = useMemo(() => {
		const pathCourse = localRecentCoursePath?.split('/');
		if (pathCourse?.length > 1) {
			const courseName = pathCourse[2];
			const course = Object.values(listOfCoursesPerTopics)
				.flat()
				.find((course) => course.route === courseName);
			return [course];
		}
		return [];
	}, [dataOfUser, localRecentCoursePath]);

	return (
		<main>
			<WrapperHome>
				<div>
					<Section1 iconShow={false}>
						Hola {dataOfUser?.name?.firstName !== '' ? `${dataOfUser?.name?.firstName}` : dataOfUser?.username}✌🏼
					</Section1>
				</div>
				<ProgressSection>
					<Section2 iconShow={false} title={'Tu progreso'}></Section2>
					<CourseCardsUnlist>
						{profileDetailsList.map((card) => (
							<CourseCard key={card.title} {...card} />
						))}
					</CourseCardsUnlist>
				</ProgressSection>
				<ProgressSection>
					{dataOfUser.recentPath && <Section2 iconShow={false} title={'Continua donde lo dejaste'}></Section2>}
					<Preview1CardsUnlist>
						{/* <Preview1 isInLive={true}></Preview1> */}
						{dataOfUser.recentPath &&
							recentCourse?.map((course, index) => {
								const week = localRecentCoursePath?.split('/')[4];
								const dayClasses = dataPrevOfClasses.listOfMorningClasses[week];
								const recentClass = getClassContent(dayClasses, localRecentCoursePath?.split('/')[2]);
								const descriptionCourse = recentClass?.flat()[0]?.titleOfClass;
								return (
									<Link key={index} to={dataOfUser?.recentPath}>
										<Preview1
											urlImage={course?.coverPage}
											descriptionTopic={descriptionCourse}
											courseName={t(`courses.${course?.course}`)}
										/>
									</Link>
								);
							})}
					</Preview1CardsUnlist>
				</ProgressSection>
				<ProgressSection>
					<Section2 iconShow={true} title={'Cursos'} linkTitle={'Ver más'} pathLink={'/courses'} />
					<Preview2CardsUnlist>
						{selectedCourses.map((course, index) => (
							<Link key={index} to={`/courses/${course.route}`}>
								<Preview2 courseName={t(`courses.${course.course}`)} urlImage={course.imageClass} totalClass="12" />
							</Link>
						))}
					</Preview2CardsUnlist>
				</ProgressSection>
				<ProgressSection>
					<Section2 title={'Ellos tambien estudian contigo'}></Section2>
					<Preview1CardsUnlist>
						{usersPlaform.map((user, index) => (
							<Profile key={index} name={user.username} urlImage={user.urlPhoto} />
						))}
						<ShareWithFriends setModalState={setModalShareState} text={'Invitar amigos'} />
					</Preview1CardsUnlist>
				</ProgressSection>
				{/*	<div>
					<Title5>{t('live')}</Title5>
					<CourseCardsUnlist>
						{lives.length > 0 ? (
							lives.map((live) => <CardLive key={live.id} {...live.attributes} />)
						) : (
							<Text>Clases en vivo muy pronto</Text>
						)}
						 {lives.length > 0 && subscriptionTime.timeRemaining > 0 ? (
              lives.map((live) => (
                <CardLive key={live.id} {...live.attributes} />
              ))
            ) : lives.length == 0 && subscriptionTime.timeRemaining > 0 ? (
              <div>
                <Text>¡Muy Pronto!</Text>
              </div>
            ) : (
              <div>
                <Text>Contenido Premium</Text>
              </div>
            )}
					</CourseCardsUnlist>
				</div>*/}
				{/*<div>*/}
				{/*	<Title5>{t('learningContinue')}</Title5>*/}
				{/*	<Text>Muy Pronto</Text>*/}
				{/*</div>*/}

				{/* {weekSelected && dataOfClasses && (
					<>
						<SelectContainer>
							<select value={weekSelected} name="selectClassWeek" onChange={(e) => setWeekSelected(e.target.value)}>
								{listClassWeeks.map((option) => (
									<option value={`week${option}`} key={`week${option}`}>{`${t('week')} ${option}`}</option>
								))}
							</select>
							<i></i>
						</SelectContainer>
						<WeekSelectContainer>
							<ClassWeek weekSelected={weekSelected} data={dataOfClasses} />
						</WeekSelectContainer>
					</>
				)} */}

				{/* <div>
					<Title5>Cursos</Title5>
					<CourseCardsUnlist>
						<button>
							<CourseCard
								image="https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/Icons%2Fcourses%2FHP%20ICON.svg?alt=media&token=53f22629-839d-4b77-8422-e2e6de9c44bb"
								widthSvg="46px"
								title="Historia del Perú"
								description="12 semanas"
								color="#ffab"
							/>
						</button>
					</CourseCardsUnlist>
				</div> */}
			</WrapperHome>
			{modalState && (
				<MainModal
					modalState={modalState}
					setModalState={setModalState}
					currentUser={currentUser}
					setDataOfUser={setDataOfUser}
					title="Antes de seguir">
					<EditProfileModal currentUser={currentUser} setDataOfUser={setDataOfUser} setModalState={setModalState} />
				</MainModal>
			)}
			{modalShareState && (
				<MainModal type={'classic'} modalState={modalShareState} setModalState={setModalShareState} title="Compartir por:">
					<SharedSocialMedia message={'Encontre esta plataforma muy hermosa'} urlForShared={'www.ebbiner.com'} />
				</MainModal>
			)}
		</main>
	);
};

export default memo(MainHome);
