import React, { useLayoutEffect } from 'react';
import { Text } from '../styles/textGeneral';

export const Terms = () => {
	useLayoutEffect(() => {
		document.documentElement.scrollTop = 0;
	}, []);
	return (
		<>
			<Text style={{ padding: '60px 20px' }}>
				Términos y condiciones de uso del sitio Web Condiciones de uso El usuario del sitio Web (www.ebbiner.com.pe) se compromete
				a leer detenidamente los términos y condiciones generales, antes de utilizar los portales y servicios Web que ofrece la
				institución educativa. Ello implica que usted acepta expresamente los términos y condiciones generales. En caso de no
				aceptarlos, se le solicita que no haga uso, ni acceda, ni manipule la información de los servicios ofrecidos por el sitio
				Web, ya que usted (usuario) está haciendo un uso inadecuado de éste. El propósito del sitio Web es la promoción y
				divulgación de las actividades académicas, información de los cursos y servicios Web. Está dirigido para estudiantes
				preuniversitarios. Para sugerencias y/o comentarios sobre los términos y condiciones de uso de nuestro sitio Web pueden
				notificarnos en Urb. Mariscal Cáceres Mz L Lote 5 frente al CEPRE UNSCH, Huamanga, Ayacucho-Perú. Derechos de Propiedad
				Intelectual Todos los derechos de propiedad intelectual de la página Web son de propiedad de EBBINER. Se prohíbe el uso de
				cualquier derecho de propiedad intelectual sin contar con el consentimiento previo, expreso y por escrito de aquella.
				Derechos de Autor Este sitio se encuentra protegido por la normativa vigente sobre derechos de autor. Todos los derechos
				involucrados, como por ejemplo su contenido y su diseño visual, son de titularidad de EBBINER, por lo que se encuentra
				estrictamente prohibido su empleo, modificación, reproducción, distribución, transmisión o comercialización de los
				derechos involucrados sin el permiso previo, expreso y por escrito de EBBINER, salvo los casos de derechos de cita, empleo
				para finalidades educativas y los demás usos honrados reconocidos. Signos distintivos y patentes Todos los signos
				distintivos (marcas de productos y de servicio, lemas comerciales y nombres comerciales) y patentes registradas de acuerdo
				a la legislación nacional y supranacional, son propiedad de EBBINER, a menos que se indique lo contrario. Se prohíbe el
				uso de cualquier elemento de propiedad intelectual de EBBINER sin el consentimiento por escrito previo pertinente.
				Aceptación de términos Los sitios Web y referencias están disponibles para que el usuario bajo su responsabilidad los
				utilice adecuadamente sin aprovecharse de alguna falla que ocurra y saque provecho de la misma. Si encuentra alguna falla
				en nuestro sitio Web reportarla a consultas@EBBINER.com Los cambios y actualizaciones en los términos y condiciones de uso
				serán comunicados en nuestro sitio Web oportunamente para que todos los usuarios se informen de los términos y condiciones
				de uso vigente. Está bajo la responsabilidad del usuario enterarse de los nuevos cambios y restringirse a cumplir como
				normativa del sitio Web. La información de los eventos institucionales será de carácter público para todos los usuarios en
				el sitio Web, mientras los vínculos que soliciten el registro del usuario estará restringido para todo usuario que no esté
				autenticado, este último será responsable del uso adecuado o no del manejo de la información. En caso incumpla, el
				administrador de la web desactivará su cuenta de usuario y recibirá una amonestación. Queda prohibido el uso del nombre,
				logotipos, marcas, diseños o cualquier signo distintivo de autoría de EBBINER como enlaces de hipertexto o hipervínculos a
				páginas no controladas por la empresa, sin previa y expresa autorización de EBBINER. Se prohíbe igualmente incluir en
				sitios y portales no controladas por EBBINER enlaces o páginas interiores, evitando así no visitar la página principal. Se
				prohíbe igualmente el despliegue, exhibición o reproducción del Sitio o de cualquiera de sus subdirectorios o páginas
				secundarias, en sitios o portales no controlados por EBBINER, solo si el área de Desarrollo Web tiene pertinente
				promocionar la empresa en las redes sociales y otras tecnologías Web 2.0, mientras tanto queda prohibido que personas
				ajenas usen el nombre de EBBINER para registrarse en alguna tecnología Web 2.0. El usuario se compromete a usar de forma
				diligente y lícita los contenidos informativos y boletines de EBBINER solo con fines informativos y sin reproducir, copiar
				o distribuir información que no sea pública de otros enlaces que no incluya las referencias al portal de EBBINER.
				Obligaciones de los usuarios registrados • El acceso a los servicios a través del Código de Usuario y Contraseña
				entregados es de su plena y exclusiva responsabilidad, así como por el uso de los mismos. • El uso diligente del Código de
				Usuario y Contraseña entregados, mantener y preservar la confidencialidad de los mismos. • Notificar inmediatamente al
				área de desarrollo de cualquier uso no autorizado del Código de Usuario y Contraseña o cualquier otra infracción a la
				seguridad de la misma no siendo el área de sistemas en ningún caso responsable de las acciones realizados por la cuenta
				del usuario. • Toda transacción realizada con su código de Usuario y contraseña será vigilada para que no intente hacer de
				un mal uso los servicios que se les brinda. En caso contrario, no lo cumpla será acreedor de una sanción. • No usar el
				Código de Usuario y Contraseña para ningún propósito ilegal. Obligaciones de los usuarios en general • El acceso de los
				servicios Web son carácter público en zonas públicas de la Web donde la información entregada es de la referida empresa.
				El usuario se obliga a utilizar los servicios y contenidos que le proporciona el portal conforme a la legislación vigente
				y a los principios de buena fe y usos generalmente aceptados y a no contravenir con su actuación a través de la Web. • El
				usuario se compromete a suministrar información verdadera y exacta acerca de él mismo en los formularios de registro del
				portal de la referida empresa. Modificaciones a los Términos y Condiciones EBBINER se reserva el derecho de modificar el
				contenido y alcance de los presentes Términos y Condiciones en cualquier momento y según lo considere necesario. El
				Usuario estará obligado a sujetarse a dichas modificaciones, una vez que éstas entren en vigor. Delimitación de
				responsabilidad EBBINER realiza los mayores esfuerzos para brindar la más actualizada y exacta información en este sitio.
				Sin perjuicio de lo anterior, en algunas oportunidades puede incurrir en errores o en omisiones. En tales hipótesis
				EBBINER no será responsable del daño, perjuicio directo, indirecto, incidental, consecuente o especial que se derive del
				uso de la información contenida en este sitio o de las páginas Web aquí recomendados. Toda la información que es publicada
				en esta página Web, la cual incluye, pero no se limita, a los gráficos, textos y referencias a otras páginas, se
				proporciona "como es" y está sujeta a posibles modificaciones sin aviso previo. La información a la que se hace referencia
				es proporcionada sin garantía de cualquier clase, expresa o implícita, incluyendo, pero no limitándose a una garantía de
				negocios y similares o a encontrarse libre de todo virus de computación. EBBINER no garantiza, en ningún caso, la
				suficiencia, exactitud e integridad de la información contenida en este sitio o en las páginas Web recomendadas por lo que
				no asumirá ninguna clase de responsabilidad por los errores o las omisiones en las que pudiera incurrir. Los visitantes
				son enteramente responsables de verificar y evaluar la exactitud, integridad y la utilidad de cualquier información que se
				encuentre disponible desde esta página Web. EBBINER no garantiza un servicio libre e interrumpido de esta página, pero sí
				declara su voluntad de efectuar los esfuerzos que, dentro de lo razonables, permitan alcanzar su disponibilidad por el
				mayor tiempo posible.
			</Text>
		</>
	);
};
