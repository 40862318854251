const styles = {
	background: 'linear-gradient(153.62deg, #609AE7 0%, #DC6BE7 100.51%)',
	'-webkit-background-clip': 'text',
	'-webkit-text-fill-color': 'transparent',
	'background-clip': 'text',
	'text-fill-color': 'transparent',
};

export const previewsList = [
	{
		title: (
			<p>
				Clases por <span style={styles}>sesiones temáticas</span>
			</p>
		),
		description: 'Cada clase contiene una parte teórica y práctica.',
		image: require('./../../images/preview1-1.png'),
		backgroundColor: '#031021',
		reverse: true,
		height: 'auto',
	},
	{
		title: (
			<p>
				Acceso a <span style={styles}>clases grabadas</span> de todo el ciclo académico
			</p>
		),
		description: 'Actualizadas de acuerdo al prospecto de cada universidad del Perú.',
		image: require('./../../images/preview1-0.png'),
		backgroundColor: '#031021',
		height: 'auto',
	},
];
