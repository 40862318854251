import { writeBatch, doc, arrayUnion } from 'firebase/firestore';

export const profileInformationUpdate = async (db, dataOfUser, user, navigate, setDataOfUser) => {
	const batch = writeBatch(db);
	const userDocRef = doc(db, 'users', dataOfUser.uid);

	//referencia a la coleccion usersLogs
	const userLogDocRef = doc(db, 'usersLogs', dataOfUser.uid);
	batch.set(userDocRef, user, { merge: true });
	batch.set(
		userLogDocRef,
		{
			listOfDataBackup: arrayUnion(dataOfUser),
		},
		{ merge: true },
	);
	//corroborar si se han modificado los campos de nombre y apellido
	if (
		dataOfUser.name.firstName !== user.name.firstName ||
		dataOfUser.name.lastName !== user.name.lastName ||
		dataOfUser.idCard !== user.idCard ||
		dataOfUser.gender !== user.gender ||
		dataOfUser.phoneNumber !== user.phoneNumber ||
		dataOfUser.university !== user.university ||
		dataOfUser.school !== user.school ||
		dataOfUser.nameAttorney.firstName !== user.nameAttorney.firstName ||
		dataOfUser.nameAttorney.lastName !== user.nameAttorney.lastName ||
		dataOfUser.phoneNumberAttorney !== user.phoneNumberAttorney
	) {
		return await batch
			.commit()
			.then(() => {
				console.info('Se ha actualizado el perfil');
				console.info('Se ha guardado los datos anteriores');
				setDataOfUser((prev) => ({ ...prev, ...user }));
				navigate('/profile');
			})
			.catch((error) => console.warn('Error: ' + error));
	} else {
		return navigate('/profile');
	}
};
