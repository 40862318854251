import React from 'react';
import { CommentListContainer } from './styles/sCommentList';
import { Comment } from './Comment';
import { Body4 } from '../../../../styles/textGeneral';

export const CommentList = ({ commentsArreglo }) => {
	commentsArreglo = [];
	return (
		<CommentListContainer>
			{commentsArreglo?.length > 0 ? (
				<>
					{commentsArreglo.map((commentsArreglo) => (
						<Comment
							userImageLink={commentsArreglo.userImageLink}
							userName={commentsArreglo.userName}
							userComent={commentsArreglo.userComent}
							numberCommentReactions={commentsArreglo.numberCommentReactions}
						/>
					))}
				</>
			) : (
				<div className="weDontComments">
					<Body4>Aun no tenemos comentarios para esta clase</Body4>
				</div>
			)}
		</CommentListContainer>
	);
};
