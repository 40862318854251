import styled from 'styled-components';

export const FooterLandingContainer = styled.div`
	background: ${(props) => props.theme.color.neutral900};
	color: ${(props) => props.theme.color.primary200};
	padding: 30px 0 120px 0;
	width: 100%;

	svg {
		fill: ${(props) => props.theme.color.primary200};
		width: -webkit-fill-available;
	}

	a {
		color: ${(props) => props.theme.color.primary200};
	}

	.unlistedListItemsFooter {
		margin: 0 0 40px 0;

		.itemFooterLanding {
			margin: 0 0 20px 0;

			&:last-child {
				margin: 0;
			}
		}
	}

	@media (min-width: 768px) {
		padding: 50px 0 90px 0;

		.logoFooterLanding {
			width: -webkit-fill-available;
			height: 90px;
			margin: 0 0 70px 0;
		}
	}

	@media (min-width: 1200px) {
		ul {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		ul li {
			max-width: 300px;
		}

		.unlistedListItemsFooter {
			margin: 0 0 90px 0;

			.itemFooterLanding {
				margin: 0;
			}
		}
	}
`;

export const AuthorsFooterLanding = styled.div`
	background: ${(props) => props.theme.color.neutral900};
	padding: 15px 0;
	color: ${(props) => props.theme.color.primary200};

	p {
		text-align: center;
		font-size: 0.75em;
	}
`;

export const LinkContainer = styled.div`
	margin: 0 0 40px 0;

	.linkColumn {
		margin: 0 0 20px 0;

		a {
			margin: 0 0 15px 0;

			&:last-child {
				margin: 0;
			}

			&:hover {
				color: ${(props) => props.theme.color.neutral0};
			}
		}
	}

	@media (min-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-items: center;
	}

	@media (min-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr;

		.linkColumn {
			a {
				margin: 0 0 18px 0;

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	@media (min-width: 1200px) {
		width: 740px;
		margin: 0 auto 50px auto;
	}
`;

export const LinkColumn = styled.div`
	display: flex;
	flex-direction: column;

	.sectionTitle {
		font-weight: ${(props) => props.theme.font.weight.bold};
	}
`;
export const SocialMediaContainer = styled.ul`
	display: flex;
	justify-content: center;
	gap: 25px;

	svg {
		fill: ${(props) => props.theme.color.primary200};

		&:hover {
			fill: ${(props) => props.theme.color.neutral0};
		}
	}

	@media (min-width: 1200px) {
		width: 200px;
		margin: 0 auto;
	}
`;

export const SocialMediaColumn = styled.li`
	a {
		display: block;
	}

	svg {
		width: 30px;
	}
`;
