import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './../../../styles/buttonGeneral';
import { EditProfileModalContent } from './styles/sEditProfileModal';
//Import firebase utils
import { useFirestore } from 'reactfire';

//Import ALGORITHMS
import { moreLater } from './algorithms/moreLater';

export default function EditProfileModal({ setModalState, currentUser, setDataOfUser }) {
	const db = useFirestore();
	return (
		<EditProfileModalContent>
			<p>Es importante que completes tu perfil para tener un mejor experiencia en Ebbiner.</p>
			<div className="buttons">
				<Link to="/profile/edit">
					<Button
						primary
						className="buttonEdit"
						text={'Editar perfil'}
						onClick={() => {
							setModalState(false);
						}}
					/>
				</Link>
				<Link to="/">
					<Button
						secondary
						type="button"
						text={'Más tarde'}
						onClick={() => {
							setModalState(false);
							moreLater({ db, currentUser, setDataOfUser });
						}}
					/>
				</Link>
			</div>
		</EditProfileModalContent>
	);
}
