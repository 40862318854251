const listOfUnschExamCourses = [
	'verbalReasoning',
	'mathematicalReasoning',
	'algebra',
	'arithmetic',
	'trigonometry',
	'geometry',
	'physics',
	'chemistry',
	'literature',
	'language',
	'civic',
	'universalHistory',
	'historyOfPeru',
	'biology',
	'humanAnatomy',
	'geography',
	'economy',
	'topicality',
];

export const questionsOrganizer = (questions) => {
	//Ordenamos examen por curso segun la lista de listOfUnschExamCourses
	return questions
		.sort((a, b) => {
			const indexA = listOfUnschExamCourses.indexOf(a.course);
			const indexB = listOfUnschExamCourses.indexOf(b.course);
			return indexA - indexB;
		})
		.reduce((previousValue, currentValue) => {
			const index = listOfUnschExamCourses.indexOf(currentValue.course);
			//Si no existe el index en el array, lo creamos
			if (index === -1) {
				return previousValue;
			}
			//Si existe el index en el array, lo agregamos al array
			if (!previousValue[index]) {
				previousValue[index] = [];
			}
			//Agregamos el currentValue al array
			previousValue[index].push(currentValue);
			return previousValue;
		}, []);
};
