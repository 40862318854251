export const probabilityCalculator = (stats) => {
	if (stats) {
		//Calculamos la probabilidad de ingreso
		const probability =
			parseFloat(
				(
					(parseFloat(stats?.totalScoreAverage) +
						parseFloat(
							typeof parseFloat(stats?.lastExamScore) === 'number'
								? parseFloat(stats?.lastExamScore)
								: parseFloat(stats?.totalScoreAverage),
						)) /
					20
				).toFixed(1),
			) - 8; //Constante por competencia existente no contabilizada aqui
		if (probability < 0 || typeof parseFloat(stats?.lastExamScore) !== 'number') {
			return 0 + '%';
		}

		return probability?.toFixed(1) + '%';
	}
	return '0%';
};
