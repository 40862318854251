import axios from 'axios';

const strapiURL = process.env.REACT_APP_STRAPI_LIVES_URL;

const axiosInstance = axios.create({
	baseURL: strapiURL,
	headers: {
		'Content-Type': 'application/json',
		Authorization: 'Bearer ' + process.env.REACT_APP_STRAPI_LIVES_TOKEN,
	},
});

export default axiosInstance;
