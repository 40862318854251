import { ContentModal, ModalPay } from './styles/sModalKat';
import YapeSmall from '../../images/yape-small.webp';
import { Text } from '../../styles/textGeneral';
import Qr from '../../images/qr.png';

const ModalYape = () => {
	return (
		<ModalPay>
			<h3>
				Pago de <strong>s/&nbsp;32.90</strong>&nbsp;por yape
			</h3>
			<ContentModal style={{ height: '50vh', overflowY: 'auto', padding: '10px' }}>
				<img className="yape" src={YapeSmall} alt="Imágen del yape" />
				<Text>Número: 935&nbsp;502&nbsp;067</Text>
				<img className="qr" src={Qr} alt="Imágen del yape del código qr" />
				<Text>KAT PREMIUM</Text>
				<Text center>Finalizado el pago, un asistente de ventas se comunicará con usted</Text>
			</ContentModal>
			<Text center style={{ color: '#7D8187' }}>
				Pagos seguros con Ebbiner Security
			</Text>
		</ModalPay>
	);
};

export default ModalYape;
