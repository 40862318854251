import { useState } from 'react';
import { SecondaryTitle, Text, TextSubtext4 } from '../../styles/textGeneral';
import { PaymentContainer, ContentPay, PaymentsList } from './styles/sModalKat';
// import Tarjeta from '../../images/pago-tarjeta.webp';
import Yape from '../../images/yape.webp';
import Deposito from '../../images/deposito-bancario.webp';
import MainModal from '../modal/MainModal';
import ModalYape from './ModalYape';
import ModalDeposit from './ModalDeposit';

const ModalKatPayment = () => {
	const [modalState, setModalState] = useState(false);
	const [modalStateD, setModalStateD] = useState(false);

	return (
		<PaymentContainer>
			<SecondaryTitle white center>
				Elige método de pago
			</SecondaryTitle>
			<PaymentsList>
				{/*TODO: Uncomment when need card pay*/}
				{/*<ContentPay radiusLeftTop>*/}
				{/*	<TextSubtext4>Tarjeta</TextSubtext4>*/}
				{/*	<img src={Tarjeta} alt="Pago por tarjeta" />*/}
				{/*	<TextSubtext4 style={{ color: '#EF424C' }}>Obtén 3 días gratuitos</TextSubtext4>*/}
				{/*</ContentPay>*/}
				<ContentPay onClick={() => setModalState(!modalState)}>
					<TextSubtext4>Yape</TextSubtext4>
					<img src={Yape} alt="Pago por yape" />
				</ContentPay>
				<ContentPay radiusRigthBottom onClick={() => setModalStateD(!modalStateD)}>
					<TextSubtext4>Depósito bancario</TextSubtext4>
					<img src={Deposito} alt="Pago por depósito bancario" />
				</ContentPay>
			</PaymentsList>
			<Text center style={{ color: '#7D8187' }}>
				Pagos seguros con Ebbiner Security
			</Text>

			{modalState && (
				<MainModal
					modalState={modalState}
					setModalState={setModalState}
					type={'classic'}
					overlayStyles={{ background: 'rgba(6, 14, 24, 0.8)' }}>
					<ModalYape />
				</MainModal>
			)}
			{modalStateD && (
				<MainModal
					modalState={modalStateD}
					setModalState={setModalStateD}
					type={'classic'}
					overlayStyles={{ background: 'rgba(6, 14, 24, 0.8)' }}>
					<ModalDeposit />
				</MainModal>
			)}
		</PaymentContainer>
	);
};

export default ModalKatPayment;
