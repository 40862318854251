import React from 'react';
import { SelfCommentsContainer, CommentStyled, CommentMessage, SelfCommentsContainerDesktop } from './styles/sSelfComments';
import PropTypes from 'prop-types';
import { formatDate } from './algorithm/formatDate';
import IconUserGauler from '../../icons/user-gauler.svg';

const SelfComments = ({ message, username, createdAt, photoURL }) => {
	return (
		<>
			<SelfCommentsContainer>
				<img src={photoURL || IconUserGauler} alt="Foto de usuario" />
				<CommentStyled>
					<CommentMessage>
						<h6>{username}</h6>
						<p>{message}</p>
					</CommentMessage>
					<span>{createdAt?.seconds && formatDate(new Date(createdAt.seconds * 1000))}</span>
				</CommentStyled>
			</SelfCommentsContainer>
			<SelfCommentsContainerDesktop>
				<div className="containerMessageInfo">
					<figure>
						<img src={photoURL || IconUserGauler} alt="Foto de usuario" />
					</figure>
					<div className="contentInfo">
						<h6>{username}</h6>
						<span>{createdAt?.seconds && formatDate(new Date(createdAt.seconds * 1000))}</span>
					</div>
				</div>
				<p>{message}</p>
			</SelfCommentsContainerDesktop>
		</>
	);
};

SelfComments.propTypes = {
	message: PropTypes.string,
	username: PropTypes.string,
	createdAt: PropTypes.shape({
		seconds: PropTypes.number,
	}),
	displayName: PropTypes.string,
	photoURL: PropTypes.string,
};

export default SelfComments;
