import styled from 'styled-components';

export const EditProfileModalContent = styled.div`
	max-width: 300px;
	font-family: ${(props) => props.theme.font.textFont};
	text-align: center;
	line-height: 1.5em;

	button,
	a {
		width: 100%;
		height: 44px;
	}

	.buttons {
		margin: 30px 0 0 0;
	}

	.buttonEdit {
		margin: 0 0 20px 0;
	}

	@media (min-width: 768px) {
		max-width: 400px;

		.buttons {
			display: flex;
			gap: 20px;
		}
	}
`;
