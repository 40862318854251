import React, { useContext, useEffect } from 'react';
import { WrapperHome } from './../../styles/generalStyles';
import { Link, useSearchParams } from 'react-router-dom';
import { Body3 } from './../../styles/textGeneral';
import { useTranslation } from 'react-i18next';
// import CourseCard from '../general/cards/CourseCard';
import { ContainerSectionCourses, CourseCardsUnlistRow, SectionCourses } from '../home/styles/sMainHome';
import { AppContext } from '../../App';
import MainSpinner from '../spinner/MainSpinner';

//Import constants
import { titleOfTopics } from '../../constants/titleOfTopics';
import { BoxCard } from '../../styles/boxGeneral';
import { Preview2 } from '../../packages/shared/components/cards/previews/Preview2';
import { Section1 } from '../../packages/shared/components/title/Section1';
import { useNavbarStore } from '../../store/navbarStore';

const Mainclasses = () => {
	const { navbarDeployed } = useNavbarStore();
	const { dataOfClasses, setWeekSelected, listOfCoursesPerTopics } = useContext(AppContext);
	const [t] = useTranslation('main');
	let [searchParams] = useSearchParams();

	useEffect(() => {
		searchParams.get('week')
			? setWeekSelected(searchParams.get('week'))
			: dataOfClasses && setWeekSelected(Object.keys(dataOfClasses.listOfMorningClasses).sort()[0]);
	}, [dataOfClasses]);

	if (!dataOfClasses) {
		return <MainSpinner title={'Obteniendo lista de clases disponibles...'} />;
	}

	// TODO: Uncomment if needed this
	// const listClassWeeks = Object.keys(dataOfClasses.listOfMorningClasses)
	// 	.map((week) => parseInt(week.slice(4)))
	// 	.sort((a, b) => a - b);

	if (listOfCoursesPerTopics) {
		Object.values(listOfCoursesPerTopics).map((topic) =>
			topic.sort((a, b) => t(`courses.${a.course}`).localeCompare(t(`courses.${b.course}`))),
		);
	}

	return (
		<main>
			<WrapperHome>
				<div>
					<Section1>{t('recordedClassAndBoards')}</Section1>
					{/* <Text centerD>{t('recordedClassAndBoardsDescription')}</Text> */}
				</div>
				{/* <div>
					<Coursesbar />
					<ClassContent
            {...props}
            listClassWeeks={listClassWeeks}
            weekSelected={weekSelected}
            setWeekSelected={setWeekSelected}
            dataOfClasses={dataOfClasses}
          />
				</div> */}
				<div>
					{listOfCoursesPerTopics &&
						Object.keys(listOfCoursesPerTopics)
							.sort()
							.map((key, index) => {
								return (
									<ContainerSectionCourses key={index}>
										<SectionCourses>
											<Body3>{titleOfTopics[key]}</Body3>
											<BoxCard key={index}>
												<CourseCardsUnlistRow navbarDeployed={navbarDeployed}>
													{listOfCoursesPerTopics[key].map((course) => {
														return (
															<Link to={`/courses/${course.route}`} key={course.route}>
																{/* <CourseCard
																	image={course.icon}
																	widthSvg="38px"
																	title={t(`courses.${course.course}`)}
																	description={course.description}
																	color={course.background}
																/> */}
																<Preview2
																	key={course.route}
																	urlImage={course.imageClass}
																	totalClass="12"
																	courseName={t(`courses.${course.course}`)}
																/>
															</Link>
														);
													})}
												</CourseCardsUnlistRow>
											</BoxCard>
										</SectionCourses>
									</ContainerSectionCourses>
								);
							})}
				</div>
			</WrapperHome>
		</main>
	);
};

export default Mainclasses;
