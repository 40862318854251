import { useContext } from 'react';
import { WrapperHomeHamburger } from '../../../styles/generalStyles';
import { useTranslation } from 'react-i18next';
import ItemNavbar from './ItemNavbar';
import { ContainerLogo, ContainerButton, InLandingNavbarModalContainer } from './styles/sItemNavbarInLanding';
import { ReactComponent as VeryUserSVG } from './../../../icons/navbarLanding/very-user.svg';
import { ReactComponent as MoneySimbolSVG } from './../../../icons/navbarLanding/money-symbol.svg';
import { ReactComponent as HelpSVG } from './../../../icons/navbarLanding/help.svg';

import { ReactComponent as CloseMenuSVG } from './../../../icons/close.svg';
import Logo from '../Logo';
import { Button } from '../../../styles/buttonGeneral';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../App';

const listOfItems = [
	{
		nameItem: 'aboutUs',
		icon: <VeryUserSVG />,
		path: '/about-us',
	},
	{
		nameItem: 'prices',
		icon: <MoneySimbolSVG />,
		path: '/promotions',
	},
	// {
	// 	nameItem: 'methodology',
	// 	icon: <img />,
	// 	path: '/methodology',
	// },
	// {
	// 	nameItem: 'blog',
	// 	icon: <img />,
	// 	path: '/blog',
	// },
	{
		nameItem: 'help',
		icon: <HelpSVG />,
		path: '/help-center',
	},
];

export const InLandingNavbarModal = ({ setHamburger, hamburger }) => {
	const [t] = useTranslation('main');
	const { dataOfUser } = useContext(AppContext);

	return (
		<InLandingNavbarModalContainer>
			<ContainerLogo>
				<Logo classContainerLogoName="logo" onClick={() => setHamburger(!hamburger)} />
				<CloseMenuSVG className="close" onClick={() => setHamburger(!hamburger)} />
			</ContainerLogo>
			<WrapperHomeHamburger>
				{listOfItems.map((item, index) => (
					<ItemNavbar
						key={index}
						icon={item.icon}
						path={item.path}
						nameItem={t(item.nameItem)}
						setHamburger={setHamburger}
						hamburger={hamburger}
					/>
				))}
			</WrapperHomeHamburger>
			<ContainerButton>
				{!dataOfUser && (
					<Link to="/login">
						{/* TODO: Corregir el color del botón */}
						<Button secondary text={'Iniciar sesión'} />
					</Link>
				)}
			</ContainerButton>
		</InLandingNavbarModalContainer>
	);
};
