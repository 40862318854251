import styled, { css } from 'styled-components';

export const ProfileEditContainer = styled.div`
	margin: 45px 0 0 0;
	font-family: ${(props) => props.theme.font.textFont};

	.userProfileDescription {
		display: flex;
		flex-direction: column;
		align-items: center;

		svg,
		img {
			border-radius: 50%;
			margin: 0 0 15px 0;
			width: 70px;
			height: 70px;
		}

		h2 {
			font-size: 1.063em;
			font-weight: ${(props) => props.theme.font.weight.semiBold};
			margin: 0 0 10px 0;
			text-align: center;
		}

		span {
			margin: 0 0 20px 0;
		}
	}

	@media (min-width: 1200px) {
		.userProfileDescription {
			display: flex;
			flex-direction: row;
			border-bottom: 1px solid ${(props) => props.theme.color.neutral500};
			padding: 0 0 50px 0;

			svg,
			img {
				margin: 0 40px 0 0;
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}

			h2 {
				text-align: left;
			}
		}
	}
`;

export const ProfileEditInformation = styled.div`
	button {
		margin: 30px 0 0 0;
	}

	.profileEditButtonContainer {
		display: flex;
		justify-content: center;
	}

	@media (min-width: 1200px) {
		button {
			margin: 50px 0 0 0;
			padding: 0 20px;
		}

		.profileEditButtonContainer {
			display: block;
		}
	}
`;
export const ErrorText = styled.p`
	font-size: 0.85em;
	line-height: 1.5em;
	font-weight: ${(props) => props.theme.font.weight.medium};
	color: ${(props) => props.theme.color.error400};
	cursor: default;

	${(props) =>
		props.sizeH3 &&
		css`
			font-size: 1.125em;
			line-height: 1.25em;
		`}

	${(props) =>
		props.paragraphLanding &&
		css`
			margin: 15px 0 0 0;
		`}
`;
