import React from 'react';
import { DescriptionPreview1Container, PicturePreview1Container, Preview1Container } from './styles/sPreview1';
import { Body8, Details3 } from '../../../../../styles/textGeneral';
import { TagLive } from './tags/TagLive';
import { TagQuantity } from './tags/TagQuantity';

export const Preview1 = ({ urlImage, isInLive, courseName, descriptionTopic }) => {
	const quantityDetails = {
		text: 'Clase',
		total: 10,
		partial: 5,
	};
	return (
		<Preview1Container>
			<PicturePreview1Container>
				{isInLive && <TagLive text={'En vivo'} />}
				<TagQuantity {...quantityDetails} />
				<img src={urlImage ?? 'https://picsum.photos/200/300'} alt="Preview1" />
			</PicturePreview1Container>
			<DescriptionPreview1Container>
				<Details3>{courseName ?? 'Actualizando'}</Details3>
				<Body8>{descriptionTopic ?? 'Obteniendo la descripciòn del curso'}</Body8>
			</DescriptionPreview1Container>
		</Preview1Container>
	);
};
