import React from 'react';
import { Button } from '../../styles/buttonGeneral';
import { Wrapper } from '../../styles/generalStyles';
import { Text, TextBody1, TextBody3, TextSubtext1, Title1A, Title2, Title3, Title5, Title6 } from '../../styles/textGeneral';
import { ReactComponent as CheckUlSVG } from './../../../src/icons/check-ul.svg';
import { ReactComponent as PlanBasicTemporarySVG } from './../../../src/icons/offers/planBasicImage.svg';
import { ReactComponent as PlanPremiumTemporarySVG } from './../../../src/icons/offers/planPremiumImage.svg';
import { ReactComponent as PlanBasicLastingSVG } from './../../../src/icons/offers/planBasicLastingImage.svg';
import { ReactComponent as PlanPremiumLastingSVG } from './../../../src/icons/offers/planPremiumLastingImage.svg';
import { ReactComponent as PlanClasicLastingSVG } from './../../../src/icons/offers/randomPlanLasting.svg';

import { CardsLastingOffers, CardsTemporaryOffers, ContainerCards, PriceText } from './styles/sOffersMain';
import { useNavigate } from 'react-router-dom';
import { MainContainer } from '../helpCenter/styles/sMainHelpCenter';

export const OffersMain = () => {
	const navigate = useNavigate();

	const basicPlanBenefits = [
		'Acceso a más de 800 horas clases',
		'Acceso a clase grabadas de todos los cursos',
		'Exámenes tipo admision ilimitados',
		'Resolución inmediata de tus simulacros',
		'Estadisticas reales de tu progreso',
		'Acceso desde cualquier dispositivo',
	];

	const premiumPlanBenefits = [
		'Asesoria académica personalizada',
		'Plan de estudios de acuerdo a tu universidad de postulación',
		'Acceso a seminarios de los cursos',
		'Resolución inmediata de tus simulacros',
		'Acceso a cursos de superación personal (programa Ebbiner para la vida)',
		'3 consultas con un profesor de cualquier curso',
	];
	return (
		<main>
			<Wrapper>
				<MainContainer>
					<div className="center">
						<Title2>Promociones</Title2>
						<Title6>Accede y gana promociones para ti y tus amig@s</Title6>
					</div>
					<div className="title-container">
						<Title1A>
							En estas fiestas navideñas <span style={{ color: 'blue' }}>10% </span>de descuento
						</Title1A>
						<Title3>Del 1 al 31 de Diciembre</Title3>
					</div>
					<ContainerCards>
						<CardsTemporaryOffers basic>
							<div className="text-container">
								<TextBody1>Plan basico</TextBody1>
								<div>
									<PriceText>s/ 79</PriceText>
									<Text>al mes</Text>
								</div>
							</div>
							<div className="svg-container">
								<PlanBasicTemporarySVG />
							</div>
							<div className="botton-container">
								<TextBody3>Antes s/ 87</TextBody3>
								<Button primary text="Comprar ahora" onClick={() => navigate('/promotions')} />
							</div>
							<div className="list-container">
								<TextBody3>Beneficios básicos:</TextBody3>
								{basicPlanBenefits.map((item, index) => (
									<div key={index}>
										<CheckUlSVG />
										<label>{item}</label>
									</div>
								))}
							</div>
						</CardsTemporaryOffers>
						<CardsTemporaryOffers premium>
							<div className="text-container">
								<TextBody1>Plan Premium por 3 meses</TextBody1>
								<div>
									<PriceText>s/ 127</PriceText>
									<Text>al mes</Text>
								</div>
							</div>
							<div className="svg-container">
								<PlanPremiumTemporarySVG />
							</div>
							<div className="botton-container ">
								<TextBody3>Antes s/ 140</TextBody3>
								<Button primary text="Comprar ahora" onClick={() => navigate('/promotions')} />
							</div>
							<div className="list-container">
								<TextBody3>Beneficios básicos:</TextBody3>
								{premiumPlanBenefits.map((item, index) => (
									<div key={index}>
										<CheckUlSVG />
										<label>{item}</label>
									</div>
								))}
							</div>
						</CardsTemporaryOffers>
					</ContainerCards>
					<div className="title-container">
						<Title5>Válida todo el año</Title5>
						<label>Promociones especiales activas durante todo el año</label>
					</div>
					<ContainerCards>
						<CardsLastingOffers>
							<label>Plan basico</label>
							<Title1A>Gana un 5x4</Title1A>
							<PlanBasicLastingSVG />
							<TextSubtext1>Solo paga por 4 personas y gana 5 cuentas.</TextSubtext1>
							<Button primary text="Comprar ahora" onClick={() => navigate('/promotions')} />
						</CardsLastingOffers>
						<CardsLastingOffers>
							<label>Cualquier Plan</label>
							<Title1A>Obtén un 10% dto.</Title1A>
							<PlanClasicLastingSVG />
							<TextSubtext1>Cada vez que un amigo compre una suscripción por tu invitación obtendrás un descuento.</TextSubtext1>
							<Button primary text="Comprar ahora" onClick={() => navigate('/promotions')} />
						</CardsLastingOffers>
						<CardsLastingOffers>
							<label>Plan básico</label>
							<Title1A>Continúa aprendiendo a un menor costo</Title1A>
							<PlanPremiumLastingSVG />
							<TextSubtext1>Te damos un descuento especial por continuar aprendiendo con nosotros.</TextSubtext1>
							<Button primary text="Comprar ahora" onClick={() => navigate('/promotions')} />
						</CardsLastingOffers>
					</ContainerCards>
				</MainContainer>
			</Wrapper>
		</main>
	);
};
