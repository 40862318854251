import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useFirestore } from 'reactfire';
import { Button } from './../../../styles/buttonGeneral';
import { Text } from './../../../styles/textGeneral';

//Import Contexts
import { AppContext } from '../../../App';

//Import ALGORITHMS
import { finishExam } from './algorithms/finishExam';

export default function ConfirmationSendExamModal({ setModalState, setLoading }) {
	const db = useFirestore();
	const navigate = useNavigate();
	const { setSimulacrum, simulacrum, currentUser, setSolvedExams } = useContext(AppContext);
	return (
		<div>
			<Text center>Si lo envías ya no habrá vuelta atrás</Text>
			<div className="buttonsContent">
				<Button
					secondary
					text={'Cancelar'}
					onClick={() => {
						setModalState(false);
					}}
				/>
				<Button
					primary
					text={'Si, enviar'}
					onClick={(e) => {
						e.preventDefault();
						finishExam(db, currentUser.uid, simulacrum, navigate, setSimulacrum, setLoading, setSolvedExams);
					}}
				/>
			</div>
		</div>
	);
}
