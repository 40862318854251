import React, { useContext } from 'react';
import { useFirestore } from 'reactfire';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper, RegisterAndLoginWrapper } from './../../styles/generalStyles';
import { Button } from './../../styles/buttonGeneral';
import { Text } from './../../styles/textGeneral';
import { ImageDesktopRegisterAndLogin, TransparentLR } from '../../styles/boxGeneral';
import { ReactComponent as GoogleSVG } from './../../icons/google-fill.svg';
import { MainRegisterContainer, DesktopRegisterContainer } from './styles/sMainRegister';
import registerImage from './../../images/register-background.jpg';

//Import ALGORITHMS
import { SignInWithGoogle } from './algorithms/SignInWithGoogle';

//Import Contexts
import { AppContext } from '../../App';
import Logo from './../general/Logo';

const MainRegister = () => {
	const db = useFirestore();
	const { setCurrentUser, setLoading } = useContext(AppContext);
	const navigate = useNavigate();
	const [t] = useTranslation('main');

	return (
		<main>
			<DesktopRegisterContainer>
				<ImageDesktopRegisterAndLogin>
					<TransparentLR />
					<img src={registerImage} alt="Matricúlate en Ebbiner" />
					<p>Con Ebbiner cualquier universidad te espera</p>
				</ImageDesktopRegisterAndLogin>
				<Wrapper>
					<RegisterAndLoginWrapper>
						<MainRegisterContainer>
							<div className="mainRegisterAndLogin">
								<Logo height="50px" />
								<Text>{t('initialRegisterMessage')}</Text>
								<Button
									type="button"
									text={t('signUpWithGoogle')}
									primary
									icon={<GoogleSVG />}
									onClick={() => SignInWithGoogle(db, navigate, setCurrentUser, setLoading)}
								/>
							</div>
							<div className="footerRegisterAndLogin">
								<Text className="registerAndLoginFooter">{t('areYouEnrolled')}</Text>
								<Link to="/login">
									<Button primary text={t('signIn')} />
								</Link>
							</div>
						</MainRegisterContainer>
					</RegisterAndLoginWrapper>
				</Wrapper>
			</DesktopRegisterContainer>
		</main>
	);
};

export default MainRegister;
