import styled, { css } from 'styled-components';

export const PrincipalContainer = styled.main`
	min-width: 320px;
	background: #031021;
`;

export const MainContainer = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	align-items: center;
	margin-top: 100px;
	margin-bottom: 64px;
	row-gap: 40px;
	z-index: 1000;

	@media (min-width: 574px) {
		margin-top: 160px;
	}

	@media (min-width: 1024px) {
		margin-top: 7%;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
		margin-bottom: 0;
	}

	@media (min-width: 1920px) {
		margin-top: 15%;
	}
`;

export const InfoContent = styled.div`
	order: 1;
	text-align: center;
	margin-top: initial;

	.regularPrice {
		color: ${(props) => props.theme.color.neutral400};
		text-decoration: line-through;
		font-family: ${(props) => props.theme.font.brandFont};
	}

	h3 :is(strong) {
		color: ${(props) => props.theme.color.error400};
		font-size: 1.6875em;
	}

	@media (min-width: 480px) {
		margin-top: 40px;
		button {
			width: 347px;
		}
	}

	@media (min-width: 768px) {
		margin-top: initial;
	}

	@media (min-width: 1024px) {
		text-align: initial;
		order: initial;
		h3 :is(strong) {
			font-size: 1.6875em;
		}
	}
`;

export const SectionContent = styled.section`
	height: 500px;
	width: 100%;

	@media (min-width: 574px) {
		height: initial;
		width: initial;
	}
`;

export const ListItem = styled.li`
	display: flex;
	gap: 10px;

	svg {
		min-width: max-content;
	}
`;

export const Items = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: fit-content;
	${(props) =>
		props?.mb30 &&
		css`
			margin-bottom: 30px;
		`}
`;

export const TextBackground = styled.span`
	background: linear-gradient(153.62deg, #609ae7 0%, #dc6be7 100.51%);
	background-clip: 'text';
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
`;

export const ContentImage = styled.div`
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;

	figure :is(img) {
		display: none;
	}
	.flex-content-kat {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 22px;
	}

	@media (min-width: 574px) {
		flex-direction: row;
		column-gap: 30px;
		margin-top: initial;

		figure :is(img) {
			display: inline-block;
			width: 190px;
			height: auto;
			margin: 0 15px 0 0;
			object-fit: cover;
			z-index: 100;
		}
	}

	@media (min-width: 1200px) {
		figure :is(img) {
			width: 233px;
		}
	}
`;

export const ContentTextBrand = styled.div`
	display: block;
	order: -1;
	@media (min-width: 1024px) {
		order: initial;
	}
`;

export const TextBrand = styled.p`
	display: block;
	font-family: 'Roboto', monospace;
	align-items: center;
	letter-spacing: 0.15em;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 28px;

	&:first-letter {
		color: #297ded;
	}
`;
