import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import MainRecordedCourse from './../components/inClasses/recordedCourse/MainRecordedCourse';
import { useParams } from 'react-router-dom';

//Import contexts
import { AppContext } from '../App';

const RecordedCourse = () => {
	const { listOfCoursesPerTopics } = useContext(AppContext);
	let params = useParams();
	const [course, setCourse] = useState(null);

	useEffect(() => {
		setCourse(
			Object.values(listOfCoursesPerTopics).reduce((acc, value) => {
				let result = value.filter((course) => course.route === params.course);
				if (result.length > 0) {
					acc = result;
				}
				return acc;
			}, []),
		);
	}, []);

	return (
		<>
			<Helmet>
				<title>Cursos | Ebbiner</title>
				<link rel="canonical" href="https://ebbiner.com" />
				<meta name="description" content="" />
				<meta property="og:title" content="Cursos | Ebbiner" />
				<meta property="og:description" content="Tu futuro universitario te espera." />
				<meta property="twitter:title" content="Ebbiner" />
				<meta property="twitter:description" content="Tu futuro universitario te espera." />
				<meta name="twitter:card" content="summary" />
			</Helmet>
			{course && <MainRecordedCourse course={course} />}
		</>
	);
};

export default RecordedCourse;
