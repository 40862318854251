import { useContext, useEffect } from 'react';
import { AppContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HomeSVG } from './../../icons/home-1.svg';
import { ReactComponent as ClassesSVG } from './../../icons/carrusel-video.svg';
import { ReactComponent as ExamsSVG } from './../../icons/exams.svg';
import { ReactComponent as UserSVG } from './../../icons/user.svg';

const NavBarItemsModifier = () => {
	const { dataOfUser } = useContext(AppContext);
	const navigate = useNavigate();
	useEffect(() => {
		if (!dataOfUser) {
			navigate('/', { replace: true });
		}
	}, [dataOfUser]);
	return (
		<>
			{dataOfUser &&
				(dataOfUser.name?.firstName !== '' ? `${dataOfUser.name?.firstName.split(' ')[0]}` : dataOfUser.username.split(' ')[0])}
		</>
	);
};

const ProfileModifier = () => {
	const { dataOfUser, currentUser } = useContext(AppContext);

	return (
		<>
			{dataOfUser?.photoURL ? (
				<img src={dataOfUser.photoURL} alt="Foto de perfil"></img>
			) : currentUser?.photoURL ? (
				<img src={currentUser.photoURL.replace('s96-c, s120-c')} alt="Foto de perfil"></img>
			) : (
				<UserSVG />
			)}
		</>
	);
};

export const listItemsNavbar = [
	{
		nameItem: 'Inicio',
		icon: <HomeSVG />,
		link: '/home',
	},
	{
		nameItem: 'Clases',
		icon: <ClassesSVG />,
		link: '/courses',
	},
	{
		nameItem: 'Examen',
		icon: <ExamsSVG />,
		link: '/exams',
	},
	{
		nameItem: <NavBarItemsModifier />,
		icon: <ProfileModifier />,
		link: '/profile',
	},
];
