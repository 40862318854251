import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SocialMediaContain } from '../components/general/cOthers/SocialNetworkContain';

export default function SocialNetworks() {
	return (
		<>
			<Helmet>
				<title>Comunidad Ebbiner</title>
				<meta name="description" content="Comunidad Ebbiner" />
				<meta property="og:title" content="Grupos de Ebbiner | Ebbiner" />
				<meta property="og:description" content="Links de acceso a grupos de Ebbiner" />
				<meta property="twitter:title" content="Comunidad Ebbiner | Ebbiner" />
				<meta property="twitter:description" content="Links de acceso a grupos de Ebbiner" />
			</Helmet>
			<SocialMediaContain />
		</>
	);
}
