import { SET_LIVE, SET_ONE_LIVE } from '../actions/type';

const initialState = {
	listLives: [],
	oneLive: [],
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LIVE:
			return { ...state, listLives: action.payload };
		case SET_ONE_LIVE:
			return { ...state, oneLive: action.payload };
		default:
			return { ...state };
	}
};

export default reducer;
