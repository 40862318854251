import styled, { css } from 'styled-components';

//TODO: Update this file with the new styles
////////// Old styles //////////
export const ButtonBox = styled.div`
	margin: 0 0 30px 0;
`;

export const TitlePrincipalBox = styled.div`
	margin: 22% 0 8% 0;

	@media (min-width: 375px) and (orientation: portrait) {
		margin: 48% 0 10% 0;
	}

	@media (min-width: 568px) and (orientation: landscape) {
		margin: 2vh 0 6vh 0;
	}

	@media (min-width: 768px) {
		margin: 58% 0 10% 0;
	}

	@media (min-width: 812px) and (orientation: landscape) {
		margin: 10vh 0 5vh 0;
	}

	@media (min-width: 1024px) {
		margin: 23% 0 7% 0;
	}

	@media (min-width: 1200px) {
		margin: 12% 0 3% 0;
	}
`;

export const ImageDesktopRegisterAndLogin = styled.div`
	display: none;

	@media (min-width: 1200px) {
		display: block;

		img {
			width: 50vw;
			height: 100vh;
		}

		p {
			position: absolute;
			bottom: 30px;
			left: 30px;
			font-family: ${(props) => props.theme.font.textFont};
			width: 24%;
		}
	}
`;

export const TransparentLR = styled.div`
	width: 50vw;
	height: 100vh;
	position: absolute;
	background: linear-gradient(153.62deg, #ed299633 0%, #011010b8 100.51%);
	font-weight: ${(props) => props.theme.font.weight.semiBold};
`;

export const BoxCard = styled.div`
	margin: 0 0 10px 0;
`;

export const WrapperDuplex = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
`;

export const TagsUnlist = styled.ul`
	display: flex;

	li {
		margin: 0 10px 0 0;

		&:last-child {
			margin: 0;
		}
	}
`;

////////// New styles //////////
import { Body2Mixin, Details1Mixin, Details5Mixin, Headline5Mixin } from './textGeneral';

const BlockFlex1Mixin = css`
	display: flex;
	flex-direction: column;
`;

const GroupingOfComponentsMixin = css`
	display: grid;
	column-gap: ${({ theme }) => theme.media.mediaXS.gutter};
	row-gap: ${({ theme }) => theme.space.spacing3X5};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		grid-template-columns: 1fr;
		column-gap: ${({ theme }) => theme.media.mediaMD.gutter};
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		column-gap: ${({ theme }) => theme.media.mediaLG.gutter};
		row-gap: ${({ theme }) => theme.space.spacing5X};
	}
`;

const BlockElementsMixin = css`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: ${({ theme }) => theme.space.spacing3X5};
	column-gap: ${({ theme }) => theme.space.spacing4X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing4X};
		grid-template-columns: 1fr 1fr;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing5X};
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	${({ columns }) =>
		columns === '1' &&
		css`
			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				grid-template-columns: 1fr;
			}

			@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
				grid-template-columns: 1fr;
			}
		`}

	${({ columns }) =>
		columns === '2' &&
		css`
			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				grid-template-columns: 1fr 1fr;
			}

			@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
				grid-template-columns: 1fr 1fr;
			}
		`}

  ${({ columns }) =>
		columns === '3' &&
		css`
			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				grid-template-columns: 1fr 1fr;
			}

			@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
				grid-template-columns: 2fr 1fr 1fr;
			}
		`}

    ${({ columns }) =>
		columns === '121' &&
		css`
			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				grid-template-columns: 1fr 1fr;
			}

			@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
				grid-template-columns: 1fr;
			}
		`}

    ${({ mission }) =>
		mission === 'tablelg' &&
		css`
			@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
				display: flex;
				flex-direction: column;
				width: 100%;
				border-radius: ${({ theme }) => theme.border.border12};
				overflow: hidden;
			}
		`}
`;

const ButtonAbsoluteLGMixin = css`
	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		position: fixed;
		bottom: ${({ theme }) => theme.space.spacing4X};
		right: ${({ theme }) => theme.space.spacing6X};
	}
`;

export const PageContainer = styled.main`
	${BlockFlex1Mixin}
	width: 100%;

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing6X};
	}
`;

export const PageSimpleContainer = styled.div`
	width: 100%;
`;

export const InputContainer = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing3X};

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing3X5};
	}
`;

export const InputSectionContainer = styled.div`
	display: grid;
	grid-gap: ${({ theme }) => theme.space.spacing3X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: ${({ theme }) => theme.space.spacing6X};
	}
`;

export const SectionWrapper = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing6X};

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing7X};

		${({ spacebuttonslg }) =>
			spacebuttonslg &&
			css`
				padding: 0 0 60px 0;
			`}
	}

	${({ spacebuttons }) =>
		spacebuttons &&
		css`
			padding: 0 0 40px 0;

			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				padding: 0 0 44px 0;
			}
		`}
`;

export const BlockWrapper = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing8X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacin9X};
	}

	${({ mission }) =>
		mission === 'textblog' &&
		css`
			gap: ${({ theme }) => theme.space.spacing10X};

			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				gap: ${({ theme }) => theme.space.spacing12X};
			}
		`}
`;

export const BlockInputsWrapper = styled.div`
	${BlockElementsMixin}
`;

export const BlockElementsWrapper = styled.div`
	${BlockElementsMixin}
`;

export const ElementsXSandMD = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing4X};

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		opacity: 0;
		display: none;
	}
`;

export const SectionContainer = styled.section`
	${BlockFlex1Mixin}
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.space.spacing4X};

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing6X};
	}
`;

export const SubSectionContainer = styled.section`
	${BlockFlex1Mixin}
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.space.spacing3X5};

	h4 {
		${Details5Mixin}
		color: ${({ theme }) => theme.color.primary200};
		text-transform: uppercase;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing4X};

		h4 {
			${Details1Mixin}
		}
	}
`;

export const ButtonContainer = styled.div`
	display: none;

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		display: flex;
		gap: ${({ theme }) => theme.space.spacing3X};
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing5X};
	}
	.item-button {
		flex: 1;
	}
	.item-button-small {
		flex: 0.1;
	}
`;

export const ButtonAbsolute = styled.div`
	position: fixed;
	bottom: calc(16px + 56.8px);
	right: ${({ theme }) => theme.space.spacing4X};
	z-index: 600;

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		bottom: calc(24px + 56.8px);
		right: ${({ theme }) => theme.space.spacing6X};
	}

	${ButtonAbsoluteLGMixin}
`;

export const ButtonAbsoluteLG = styled.div`
	z-index: 600;
	${ButtonAbsoluteLGMixin}
`;

export const GroupingOfComponents1 = styled.div`
	${GroupingOfComponentsMixin};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		grid-template-columns: 1fr;
	}
`;

export const GroupingOfComponents3 = styled.div`
	${GroupingOfComponentsMixin};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		grid-template-columns: 1fr 1fr;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

export const CheckboxsSubSectionContainer = styled.div`
	${BlockFlex1Mixin}
	align-items: flex-start;
	row-gap: ${({ theme }) => theme.space.spacing2X};

	h4 {
		${Details5Mixin}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		h4 {
			${Details1Mixin}
		}
	}

	${({ flexdirection }) =>
		flexdirection === 'row' &&
		css`
			flex-direction: row;
			gap: ${({ theme }) => theme.space.spacing6X};
		`}
`;

export const CkeckboxsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.space.spacing6X};
`;

export const CardWrapper = styled.div`
	display: flex;
	border-radius: ${({ theme }) => theme.border.border12};
	background-color: ${({ theme }) => theme.color.neutral5};
	padding: ${({ theme }) => theme.space.spacing3X};
	transition: all 0.2s;

	&:hover {
		background-color: ${({ theme }) => theme.color.primary305};
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		padding: ${({ theme }) => theme.space.spacing4X};
	}

	${({ dist }) =>
		dist === 'space-between' &&
		css`
			justify-content: space-between;
		`}
`;

export const SectionBlog = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing6X};

	h3 {
		color: ${({ theme }) => theme.color.primary100};
	}

	h4 {
		color: ${({ theme }) => theme.color.primary200};
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		padding: 0 250px 0 0;
	}
`;

export const SubSectionBlog = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing3X};
`;

export const SubSectionParagraphBlog = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing2X};

	p {
		font-size: 0.867em;
		line-height: 160%;
		color: ${({ theme }) => theme.color.neutral200};
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		p {
			font-size: 0.933em;
		}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		p {
			font-size: 1em;
		}
	}

	${({ mission }) =>
		mission === 'list' &&
		css`
			ol {
				${BlockFlex1Mixin}
				gap: ${({ theme }) => theme.space.spacing2X};
				list-style: lower-latin;
				padding: 0 0 0 20px;
			}
		`}
`;

/* -------- Not Found -------- */

export const Block404Container = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing7X};
	align-items: center;

	img {
		width: 90%;
		height: 90%;
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing11X};

		img {
			width: 75%;
			height: 75%;
		}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		flex-direction: row;
		padding: 0 15%;

		img {
			width: 40%;
			height: 40%;
		}
	}
`;

export const SubBlock404Container = styled.div`
	${BlockFlex1Mixin}
	align-items: center;
	gap: ${({ theme }) => theme.space.spacing7X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing11X};
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		text-align: left;
	}
`;

export const TextBlock404Container = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing5X};

	h2 {
		${Body2Mixin}
		text-align: center;
	}

	p {
		text-align: center;
		line-height: 160%;
		color: ${({ theme }) => theme.color.neutral200};
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing7X};
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		h2 {
			${Headline5Mixin}
			text-align: left;
		}

		p {
			text-align: left;
		}
	}
`;

export const ParagraphBlock404 = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing2X};
`;

export const InputBlock404Container = styled.div`
	${BlockFlex1Mixin}
	gap: ${({ theme }) => theme.space.spacing3X5};
	width: 100%;

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		flex-direction: row;
		gap: ${({ theme }) => theme.space.spacing4X};
	}
`;
