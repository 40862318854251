import React, { useContext } from 'react';
import { AppContext } from '../../../../App';
// import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { TextSubtext2 } from '../../../../styles/textGeneral';
import { ExpandHeaderUnlist, ProfileContainer } from './styles/sNavExpandHeader';
import { ReactComponent as ProfileSVG } from './../../../../icons/user.svg';

export default function NavExpandHeader() {
	const { dataOfUser, currentUser } = useContext(AppContext);
	return (
		<>
			<nav className="onlyDesktop">
				<ExpandHeaderUnlist>
					<li>
						<TextSubtext2>
							<NavLink to="/">Inicio</NavLink>
						</TextSubtext2>
					</li>
					<li>
						<TextSubtext2>
							<NavLink to="/courses">Clases</NavLink>
						</TextSubtext2>
					</li>
					<li>
						<TextSubtext2>
							<NavLink to="/exams">Exámenes</NavLink>
						</TextSubtext2>
					</li>
				</ExpandHeaderUnlist>
			</nav>
			<ProfileContainer className="onlyDesktop">
				<NavLink to="/profile">
					<div className="userCercle">
						{dataOfUser?.photoURL ? (
							<img src={dataOfUser.photoURL} alt="Foto de perfil"></img>
						) : currentUser?.photoURL ? (
							<img src={currentUser.photoURL.replace('s96-c, s20-c')} alt="Foto de perfil"></img>
						) : (
							<ProfileSVG />
						)}
					</div>
					<div className="userName">
						<TextSubtext2>
							{dataOfUser.name?.firstName !== ''
								? `${dataOfUser.name?.firstName.split(' ')[0]}`
								: dataOfUser.username.split(' ')[0]}
						</TextSubtext2>
					</div>
				</NavLink>
			</ProfileContainer>
		</>
	);
}
