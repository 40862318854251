import React from 'react';
import { NavLinkStyled } from '../styles/sNavbarInHome';

const Itemnavbarinhome = ({ icon, link, nameItem }) => {
	return (
		<li>
			<NavLinkStyled to={link} end>
				{icon}
				<span>{nameItem}</span>
			</NavLinkStyled>
		</li>
	);
};

export default Itemnavbarinhome;
