import styled from 'styled-components';

export const CourseCategoryFormContainer = styled.li`
	margin: 0 0 10px 0;

	details {
		&[open] > summary {
			border: 1px solid ${(props) => props.theme.color.primary600};
			color: ${(props) => props.theme.color.primary300};
			background: ${(props) => props.theme.color.info410};
		}
	}

	summary {
		background: ${(props) => props.theme.color.neutral10};
		border: 1px solid transparent;
		padding: 15px;
		box-shadow: ${(props) => props.theme.shadow.shadow1};
		font-family: ${(props) => props.theme.font.brandFont};
		font-weight: ${(props) => props.theme.font.weight.medium};
		cursor: pointer;
		transition: all 0.2s;
		color: ${(props) => props.theme.color.neutral0};

		&:hover {
			background: ${(props) => props.theme.color.info410};
		}
	}

	.courseQuestionCompleted {
		border: 1px solid ${(props) => props.theme.color.success800};
		position: relative;

		svg {
			width: 20px;
			fill: ${(props) => props.theme.color.success800};
			position: absolute;
			right: 15px;
		}

		span {
			color: ${(props) => props.theme.color.error400};
			width: 20px;
			text-align: end;
		}
	}

	.courseQuestionIcompleted {
		position: relative;

		span {
			color: ${(props) => props.theme.color.error400};
			width: 20px;
			position: absolute;
			right: 20px;
		}
	}

	.titleCourseDesktop {
		display: none;
	}

	@media (min-width: 1200px) {
		.questionInputContainer {
			position: absolute;
			left: 380px;
			width: calc(1200px - 380px);
			top: 0;
			padding: 0 0 50px 0;
		}

		.titleCourseDesktop {
			display: block;
			margin: 0 0 20px 0;
			color: ${(props) => props.theme.color.info400};
			font-weight: ${(props) => props.theme.font.weight.semiBold};
			font-family: ${(props) => props.theme.font.textFont};
			font-size: 1.125em;

			span {
				color: ${(props) => props.theme.color.neutral400};
				font-weight: ${(props) => props.theme.font.weight.medium};
				font-size: 0.973em;
				margin: 0 0 0 10px;
			}
		}
	}
`;

export const QuestionInputResultContainer = styled.div`
	display: flex;
	flex-direction: column;
	svg {
		align-self: flex-end;
		cursor: pointer;
		margin-bottom: 10px;
	}
`;

export const QuestionInputContainer = styled.div`
	display: flex;
	flex-direction: column;

	svg {
		align-self: flex-end;
		cursor: pointer;
		margin: 8px 0 5px 0;
	}

	br {
		border-color: ${(props) => props.theme.color.neutral400} !important;
	}
`;
