import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainFree from './../components/promotions/MainFree';

export default function Promotions() {
	return (
		<>
			<Helmet>
				<title>Ebbiner Premium gratis por 14 días - Ebbiner</title>
				<link rel="canonical" href="https://ebbiner.com" />
				<meta name="description" content="Ebbiner Premium gratis por 14 días" />
				<meta property="og:title" content="Ebbiner Premium gratis por 14 días" />
				<meta property="og:description" content="Completa tu preparación a la universidad." />
				<meta property="twitter:title" content="Ebbiner Premium gratis por 14 días" />
				<meta property="twitter:description" content="Completa tu preparación a la universidad." />
				<meta name="twitter:card" content="summary" />
			</Helmet>
			<MainFree />
		</>
	);
}
