import React from 'react';
// import { ReactComponent as Ebbiner } from './../../images/ebbiner.svg';
// import { ReactComponent as EbbinerText } from './../../images/ebbiner-text.svg';
import { MainSpinnerContainer } from './styles/sMainSpinner';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Mainspinner = ({ title }) => {
	const [t] = useTranslation('main');

	return (
		<MainSpinnerContainer>
			<Helmet>
				<title>{t('brand.spinner.title')}</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<div className="spinnerDescription">
				<div className="logoContainer">
					{/* <Ebbiner className="ebbiner" /> */}
					{/* <EbbinerText className="ebbinerText" /> */}
					<img
						src="https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/logos%2Flogo_academiaDecide.png?alt=media&token=c4ddc934-55bd-4ebf-a7fd-d8dd9b2edd24"
						alt="logo academia decide"
					/>
				</div>
				<p>{title}</p>
			</div>
		</MainSpinnerContainer>
	);
};

export default Mainspinner;
