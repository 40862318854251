import React from 'react';
import MainHome from './../components/home/MainHome';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const [t] = useTranslation('main');
	return (
		<>
			<Helmet>
				<title>{t('brand.home.title')}</title>
				<meta name="description" content={t('brand.home.description')} />
				<meta property="og:title" content={t('brand.home.title')} />
				<meta property="og:description" content={t('brand.home.description')} />
				<meta property="twitter:title" content={t('brand.home.title')} />
				<meta property="twitter:description" content={t('brand.home.description')} />
			</Helmet>
			<MainHome />
		</>
	);
};

export default Home;
