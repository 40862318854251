import styled from 'styled-components';

export const WelcomeContainer = styled.div`
	background: linear-gradient(180deg, #eb882d 0%, #f09815 100%);
	color: ${(props) => props.theme.color.neutral0};
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 0 15px;

	h1 {
		text-align: center;
	}

	p {
		font-family: ${(props) => props.theme.font.textFont};
		margin: 30px 0 20px 0;
		text-align: center;
	}

	button {
		&:hover {
			color: ${(props) => props.theme.color.primary900};
		}
	}

	.video {
		background: #c4c4c4;
		width: 100%;
		height: 320px;
		margin: 0 0 10% 0;
	}

	@media (min-width: 768px) {
		padding: 10%;
	}

	@media (min-width: 1200px) {
		button {
			width: 300px;
		}
	}
`;
