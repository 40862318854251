import React from 'react';
import {
	FacebookShareButton,
	FacebookMessengerShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
	TelegramShareButton,
	EmailShareButton,
} from 'react-share';
import { ShareWithFriendsContainer } from './styles/sSharedSocialMedia';
import {
	EmailIcon,
	FacebookIcon,
	FacebookMessengerIcon,
	// HatenaIcon,
	// InstapaperIcon,
	// LineIcon,
	LinkedinIcon,
	// LivejournalIcon,
	// MailruIcon,
	// OKIcon,
	// PinterestIcon,
	// PocketIcon,
	// RedditIcon,
	TelegramIcon,
	// TumblrIcon,
	TwitterIcon,
	// ViberIcon,
	// VKIcon,
	// WeiboIcon,
	WhatsappIcon,
	// WorkplaceIcon,
} from 'react-share';

export const SharedSocialMedia = ({ urlForShared, message }) => {
	return (
		<ShareWithFriendsContainer>
			<FacebookMessengerShareButton url={urlForShared}>
				<FacebookMessengerIcon size={32} round={true} />
				<p>Messenger</p>
			</FacebookMessengerShareButton>
			<FacebookShareButton url={urlForShared} quote={message}>
				<FacebookIcon size={32} round={true} />
				<p>Facebook</p>
			</FacebookShareButton>
			<TwitterShareButton url={urlForShared} title={message}>
				<TwitterIcon size={32} round={true} />
				<p>Twitter</p>
			</TwitterShareButton>
			<WhatsappShareButton url={urlForShared}>
				<WhatsappIcon size={32} round={true} />
				<p>WhatsApp</p>
			</WhatsappShareButton>
			<LinkedinShareButton url={urlForShared}>
				<LinkedinIcon size={32} round={true} />
				<p>LinkedIn</p>
			</LinkedinShareButton>
			<TelegramShareButton url={urlForShared}>
				<TelegramIcon size={32} round={true} />
				<p>Telegram</p>
			</TelegramShareButton>
			<EmailShareButton url={urlForShared}>
				<EmailIcon size={32} round={true} />
				<p> Email</p>
			</EmailShareButton>
		</ShareWithFriendsContainer>
	);
};
