import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../App';
import { Timestamp } from 'firebase/firestore';
import Mainspinner from '../../spinner/MainSpinner';

//Import ALGORITHMS
import { finishExam } from './algorithms/finishExam';

//Import firebas utils
import { useFirestore } from 'reactfire';

export const RemainingTimer = () => {
	const { simulacrum, setSimulacrum, currentUser, setSolvedExams } = useContext(AppContext);
	const [remainingTimerFormatted, setRemainingTimerFormatted] = useState('');
	const [loading, setLoading] = useState({ status: false, title: null });
	const db = useFirestore();
	const navigate = useNavigate();
	useEffect(() => {
		//creamos un timer que cada segundo se actualizaciones
		const timer = setInterval(() => {
			//Si el examen ya ha finalizado
			if (simulacrum.metadata.endAt.toDate() < Timestamp.now().toDate()) {
				clearInterval(timer);
				finishExam(db, currentUser.uid, simulacrum, navigate, setSimulacrum, setLoading, setSolvedExams);
				return;
			}
			//Si el examen aun no ha finalizado
			const remainingTime = simulacrum.metadata.endAt.toDate() - Timestamp.now().toDate(); //Tiempo restante
			const minutes = Math.floor(remainingTime / 1000 / 60);
			const seconds = Math.floor(remainingTime / 1000) % 60;
			setRemainingTimerFormatted(`${minutes}:${seconds < 10 ? '0' : ''}${seconds} min. restantes`);
		}, 1000);
		return () => clearInterval(timer);
	}, []);

	if (loading.status) return <Mainspinner title={'El examen ha concluido...'} />;

	//retornamos cada actualizacion del timer
	return <>{remainingTimerFormatted}</>;
};
