import { WeekClass } from './WeekClass';
import { CourseContentContainer, Footer, Header, WeeklyClasses } from './style/sCourseContent';

export const CourseContent = ({ clasesInformacion, headerActive, footerActive, menu }) => {
	return (
		<CourseContentContainer menu={menu}>
			{headerActive && (
				<Header>
					<p>Contenido del curso</p>
				</Header>
			)}
			<WeeklyClasses>
				{clasesInformacion?.map((classCourse, index) => (
					<WeekClass
						key={index}
						title={classCourse.titleOfClass}
						description={`Semana ${classCourse.week}`}
						pathRedirect={`/courses/${classCourse.route}/classes/week${classCourse.week}`}
					/>
				))}
			</WeeklyClasses>
			{footerActive && <Footer />}
		</CourseContentContainer>
	);
};
