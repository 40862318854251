import styled, { css } from 'styled-components';

export const StatisticCardContainer = styled.div`
	background: ${(props) => props.theme.color.neutral10};
	padding: ${(props) => props.padding || '40px 36px 20px 36px'};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	border-radius: 14px;
	position: relative;
	top: 35px;
	margin: 0 0 55px 0;

	svg {
		width: 30px;
	}

	span {
		font-family: ${(props) => props.theme.font.brandFont};
		font-size: 2.5em;
		font-weight: ${(props) => props.theme.font.weight.bold};
		display: block;
		text-align: center;
		margin: 25px 0;
	}

	p {
		font-size: 0.875em;
		font-weight: ${(props) => props.theme.font.weight.medium};
		margin: 20px 25px 0 25px;
		line-height: 1.25em;
		text-align: center;
	}

	button {
		display: ${(props) => (props.button ? 'block' : 'none')};
		p {
			margin: 0;
		}
	}

	.iconContainer {
		width: 70px;
		height: 70px;
		border-radius: 50px;
		position: absolute;
		top: -35px;
		left: calc(50% - 35px);
		display: flex;
		justify-content: center;
	}

	${(props) =>
		props.color === 'green' &&
		css`
			svg {
				fill: ${(props) => props.theme.color.success800};
			}

			button {
				background: ${(props) => props.theme.color.success300};

				&:hover {
					background: ${(props) => props.theme.color.success500};
				}
			}

			.iconContainer {
				background: ${(props) => props.theme.color.success200};
			}
		`}

	${(props) =>
		props.color === 'bluesky' &&
		css`
			svg {
				fill: #083b3f;
			}

			button {
				background: #86f8ff;
				color: #083b3f;

				&:hover {
					background: #34dae4;
				}
			}

			.iconContainer {
				background: #86f8ff;
			}
		`}

  ${(props) =>
		props.color === 'yellow' &&
		css`
			svg {
				width: 30px;
				fill: #3d3e08;
			}

			button {
				background: #fdff86;
				color: #3d3e08;

				&:hover {
					background: #e3e734;
				}
			}

			.iconContainer {
				background: #fdff86;
			}
		`}
`;
