import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavLinkLandingStyled = styled(NavLink)`
	display: flex;
	flex-direction: column;
	z-index: 100;
	font-size: 1.5em;
	width: 100%;
	border-radius: 2px;

	.line {
		width: 100%;
		margin-top: 5px;
		border-bottom: 1px solid ${(props) => props.theme.color.neutral200};
	}

	.container-icon-text {
		padding: 0 3px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 20px;
		font-size: 0.9em;

		svg {
			color: ${(props) => props.theme.color.neutral0} !important;
			fill: ${(props) => props.theme.color.neutral0} !important;
		}

		img {
			width: 24px;
			height: 24px;
			border-radius: 50px;
		}

		span {
			width: 100%;
			font-size: 0.785em;
			font-weight: ${(props) => props.theme.font.weight.regular};
			font-family: ${(props) => props.theme.font.brandFont};
			margin: 2px 0 0 0;
		}
	}

	&.active {
		//TODO: Agregar el color a theme
		background-color: rgba(244, 243, 250, 0.1);
		span {
			color: ${(props) => props.theme.color.neutral0};
		}
	}
`;

export const InLandingNavbarModalContainer = styled.div`
	background: #161e24;
	z-index: 1000;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 100vh;

	button {
		margin: 20px 0 0 0;
	}
`;

export const ContainerLogo = styled.div`
	display: flex;
	width: 100%;
	height: auto;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	padding: 7px 25px;
	margin: 0 0 20px 0;
`;

export const ContainerButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		display: flex;
		justify-content: center;
		width: 100%;
		button {
			width: 50% !important;
		}
	}
`;
