import React, { useEffect, useContext, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../App';
import { Wrapper } from './../../styles/generalStyles';
import Coverpage from './../general/landingComponents/CoverPage';
import Preview from './../general/landingComponents/Preview';
import { TertiaryTitle, TextSubtext1 } from './../../styles/textGeneral';
import Contacts from './../general/cOthers/Contacts';
import { Button } from './../../styles/buttonGeneral';
import PriceCard from './../general/cards/PriceCard';
import { CourseCardsUnlist } from './../home/styles/sMainHome';

//Import constants
import { culqiConstants } from '../../constants/culqiConstants';

//Import firebase utils
import { useFirestore } from 'reactfire';

//Import ALGORITHMS
import { sendToken } from './algorithms/sendToken';

const pricesList = [
	{
		background: require('./../../images/bg-basic.png'),
		type: 'Plan básico',
		price: '79',
		uniquePrice: '79',
		features: [
			<p>
				Acceso a más de <span>800 horas</span> de clases
			</p>,
			<p>
				Acceso a <span>clases grabadas</span> de todos los cursos
			</p>,
			<p>
				<span>Exámenes tipo admisión</span> ilimitados
			</p>,
			<p>
				<span>Resolución inmediata</span> de tus simulacros
			</p>,
			<p>
				<span>Estadísticas reales</span> de tu progreso
			</p>,
			<p>
				Acceso desde <span>cualquier dispositivo</span>
			</p>,
		],
		button: 'Probar por 7 días',
		discount: false,
		intervalCount: 1,
		trialDays: 7,
		nameOfPlan: 'package-gauler-basic-001',
	},
	{
		background: require('./../../images/bg-month.png'),
		type: 'Plan Premium mensual',
		price: '147',
		uniquePrice: '147',
		features: [
			<p>
				Acceso a más de <span>800 horas</span> de clases
			</p>,
			<p>
				Acceso a <span>clases grabadas</span> de todos los cursos
			</p>,
			<p>
				<span>Exámenes tipo admisión</span> ilimitados
			</p>,
			<p>
				<span>Resolución inmediata</span> de tus simulacros
			</p>,
			<p>
				<span>Estadísticas reales</span> de tu progreso
			</p>,
			<p>
				Acceso desde <span>cualquier dispositivo</span>
			</p>,
			<p>
				<span>Asesoria académica</span> personalizada
			</p>,
			<p>
				<span>Plan de estudios</span> de acuerdo a tu universidad de postulación
			</p>,
			<p>
				<span>Acceso a seminarios</span> de los cursos
			</p>,
			<p>
				Acceso a cursos de <span>superación personal</span> (programa Ebbiner para la vida)
			</p>,
			<p>
				<span>3 consultas</span> con un profesor de cualquier curso
			</p>,
		],
		button: 'Comprar por s/ 147',
		discount: false,
		intervalCount: 1,
		trialDays: null,
		nameOfPlan: 'package-gauler-oneMonth-001',
	},
	{
		background: require('./../../images/bg-three-months.png'),
		type: 'Plan Premium por 3 meses',
		price: '127',
		uniquePrice: '381',
		features: [
			<p>
				Acceso a más de <span>800 horas</span> de clases
			</p>,
			<p>
				Acceso a <span>clases grabadas</span> de todos los cursos
			</p>,
			<p>
				<span>Exámenes tipo admisión</span> ilimitados
			</p>,
			<p>
				<span>Resolución inmediata</span> de tus simulacros
			</p>,
			<p>
				<span>Estadísticas reales</span> de tu progreso
			</p>,
			<p>
				Acceso desde <span>cualquier dispositivo</span>
			</p>,
			<p>
				<span>Asesoria académica</span> personalizada
			</p>,
			<p>
				<span>Plan de estudios</span> de acuerdo a tu universidad de postulación
			</p>,
			<p>
				<span>Acceso a seminarios</span> de los cursos
			</p>,
			<p>
				Acceso a cursos de <span>superación personal</span> (programa Ebbiner para la vida)
			</p>,
			<p>
				<span>6 consultas</span> con un profesor de cualquier curso
			</p>,
			<p>
				<span>Acceso a futuros eventos</span> académicos presenciales (solo Ayacucho)
			</p>,
		],
		button: 'Comprar por s/ 381',
		discount: true,
		intervalCount: 3,
		trialDays: null,
		nameOfPlan: 'package-gauler-threeMonth-001',
	},
];

export default function MainPromotions() {
	const db = useFirestore();
	const navigate = useNavigate();
	const { setLoading, dataOfUser, setSubscriptionTime } = useContext(AppContext);

	const onCulqiEvent = async (messageEvent) => {
		const { origin, data } = messageEvent;
		if (origin !== culqiConstants.baseUrl) return;
		if (typeof data === 'object') {
			const { object } = data;
			if (!object) return;
			if (object === 'token') {
				window.Culqi.close();
				await sendToken({
					db,
					data,
					setLoading,
					setSubscriptionTime,
					dataOfUser,
					planData: window.Culqi.getSettings,
					navigate,
				});
			} else if (object === 'error') {
				alert('Es un error nuestro, por favor reintentar');
				// await sendError(db, data, setLoading);
			}
		}
	};

	useEffect(() => {
		window.addEventListener('message', onCulqiEvent, false);
		return () => {
			window.removeEventListener('message', onCulqiEvent, false);
		};
	}, []);
	useLayoutEffect(() => {
		document.documentElement.scrollTop = 0;
	}, []);

	return (
		<main>
			<Coverpage background={require('./../../images/background-promotion-1.webp')} work="promotion" />
			<Wrapper>
				<div>
					<TertiaryTitle margin="100px 0 20px 0" center white>
						Aprende cada día con Ebbiner
					</TertiaryTitle>
					<TextSubtext1 center>Adquiere uno de nuestros paquetes Premium</TextSubtext1>
					<CourseCardsUnlist margin="0 0 80px 0">
						{pricesList.map((card, index) => (
							<PriceCard key={card.type + index} {...card} dataOfUser={dataOfUser} />
						))}
					</CourseCardsUnlist>
				</div>
			</Wrapper>
			<Preview
				title="Planes mayores a 3 meses"
				image={require('./../../images/phone-whatsapp.webp')}
				background={require('./../../images/b-whatsapp.webp')}
				imageWidth="otherPhone"
				reverse={true}
				height="auto">
				<ul>
					<li>
						<TextSubtext1 black>Contáctate con nosotros WhatsApp.</TextSubtext1>
					</li>
					<li>
						<TextSubtext1 black>Envíanos tus datos personales y mencionanos cuantos meses quieres.</TextSubtext1>
					</li>
					<li>
						<TextSubtext1 black>Y después realiza el pago por Yape, Plin o transferencia bancaria.</TextSubtext1>
					</li>
				</ul>
				<a
					href="https://api.whatsapp.com/send?phone=+51974761393&text=%C2%A1Hola!%20quiero%20comprar%EBBINER%20premium.%F0%9F%A7%A1"
					target="_blank"
					rel="noopener noreferrer">
					<Button primary whatsApp text="Contactar a Ebbiner" />
				</a>
			</Preview>
			<Contacts />
		</main>
	);
}
