import React from 'react';
import { WrapperHome } from './../../styles/generalStyles';
import { Title4, Title5, Text } from './../../styles/textGeneral';
import { useTranslation } from 'react-i18next';
import CardBookOfAcademy from './CardBookOfAcademy';
import { BooksOfAcademyContainer } from './styles/sMainMaterials';
import OtherBook from './OtherBook';

const Mainmaterials = () => {
	const [t] = useTranslation('main');

	const listBooksOfAcademy = [{ name: 'Tomo I' }, { name: 'Tomo II' }];

	const listOtherBooks = [{ name: 'Libro 1' }, { name: 'Libro 2' }];

	return (
		<main>
			<WrapperHome>
				<div>
					<Title4>{t('materials')}</Title4>
					<Text>{t('materialsDescription')}</Text>
				</div>
				<div>
					<Title5>{t('booksOfAcademy')}</Title5>
					<BooksOfAcademyContainer>
						{listBooksOfAcademy.map((book) => (
							<CardBookOfAcademy key={book.name} {...book} />
						))}
					</BooksOfAcademyContainer>
				</div>
				<div>
					<Title5>{t('booksOther')}</Title5>
					<BooksOfAcademyContainer>
						{listOtherBooks.map((book) => (
							<OtherBook key={book.name} {...book} />
						))}
					</BooksOfAcademyContainer>
				</div>
			</WrapperHome>
		</main>
	);
};

export default Mainmaterials;
