import React, { useContext, useEffect } from 'react';
import { useReducedState } from '../../../hooks/useReducedState';
import { QuestionInputContainer, TextQuestionStyled } from './styles/sQuestionInput';
import { AppContext } from '../../../App';

import { handleChangeAnswer } from './algorithms/handleChangeAnswer';
import { Latex } from '../../general/latex/Latex';
import OldLatex from 'react-latex-next';

//firebase utils
import { useFirestore } from 'reactfire';

export default function QuestionInput({ question, keys, UrlOfImage, uqid, course, markedKey, isKatex }) {
	const { setSimulacrum, simulacrum, currentUser } = useContext(AppContext);
	const db = useFirestore();
	const [checkedCounter, setCheckedCounter] = useReducedState({
		checked: markedKey ? 1 : 0,
		id: markedKey ? uqid.concat(markedKey).concat(question) : null,
		item: markedKey,
	});

	useEffect(() => {
		if (checkedCounter.id && checkedCounter.item && simulacrum.timers && simulacrum.timers.questionStartTime) {
			changeCounter(checkedCounter);
		}
	}, [checkedCounter]);

	const changeCounter = async (checkedCounter) => {
		const elem = document.getElementById(checkedCounter.id);
		if (checkedCounter.checked !== 0 && checkedCounter.checked % 2 === 0) {
			elem.checked = false;
			//Eliminamos la respuesta
			await handleChangeAnswer(db, currentUser, null, uqid, simulacrum, setSimulacrum, course);
		} else {
			elem.checked = true;
			//Enviamos respuesta marcada
			await handleChangeAnswer(db, currentUser, checkedCounter.item, uqid, simulacrum, setSimulacrum, course);
		}
	};

	return (
		<QuestionInputContainer>
			<TextQuestionStyled>{isKatex ? <Latex>{question}</Latex> : <OldLatex>{question}</OldLatex>}</TextQuestionStyled>
			{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
			{keys.map((item, index) => {
				let keyLetter = null;
				switch (index) {
					case 0:
						keyLetter = 'a';
						break;
					case 1:
						keyLetter = 'b';
						break;
					case 2:
						keyLetter = 'c';
						break;
					case 3:
						keyLetter = 'd';
						break;
					case 4:
						keyLetter = 'e';
						break;
					default:
						keyLetter = null;
				}
				if (keyLetter !== null) {
					return (
						<div className="questionContainer" key={uqid.concat(item.concat(index))}>
							<input
								type="radio"
								id={uqid.concat(item)}
								name={question}
								defaultChecked={markedKey === item ? true : false}
								onClick={(e) => {
									//check/uncheck radio button
									if (e.target.id === checkedCounter.id) {
										setCheckedCounter({
											checked: checkedCounter.checked + 1,
											id: e.target.id,
											item: item,
										});
									} else {
										setCheckedCounter({
											checked: 1,
											id: e.target.id,
											item: item,
										});
									}
								}}
							/>
							<label htmlFor={uqid.concat(item)}>
								<span className="keyLetterQuestion">{keyLetter}</span>
								<div className="question">
									{item.startsWith('https') ? (
										<img src={item} alt={item} />
									) : (
										<p>{isKatex ? <Latex>{item}</Latex> : <OldLatex>{item}</OldLatex>}</p>
									)}
								</div>
							</label>
						</div>
					);
				}
				return false;
			})}
		</QuestionInputContainer>
	);
}
