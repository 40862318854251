import styled from 'styled-components';

export const ComentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.spacing4X};
	gap: ${({ theme }) => theme.space.spacing2X};
	background-color: ${({ theme }) => theme.color.neutral980};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing3X};
	}
`;

export const UserInformation = styled.section`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.space.spacing2X};
	align-items: center;

	img {
		width: 28px;
		height: 28px;
		border-radius: 50%;
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing3X};
	}
`;

export const Reactions = styled.section`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.space.spacing1X};
	align-items: center;

	svg {
		width: 12px;
		height: 12px;
	}
`;
