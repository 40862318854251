import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainSimulacrumScores from './../../components/statistics/MainSimulacrumScores';

export default function SimulacrumScores() {
	return (
		<>
			<Helmet>
				<title>Ebbiner | Puntajes de simulacros</title>
				<meta name="description" content="Mis puntajes de mis simulacros" />
				<meta property="og:title" content="Puntajes de simulacros" />
				<meta property="og:description" content="Mis puntajes de mis simulacros" />
				<meta property="twitter:title" content="Puntajes de simulacros" />
				<meta property="twitter:description" content="Mis probabilidades de ingreso" />
			</Helmet>
			<MainSimulacrumScores />
		</>
	);
}
