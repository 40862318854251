import { doc, getDoc } from 'firebase/firestore';

export const GetQuestionAnswer = async (db, uqid, setAnswer, setSuccess, setError) => {
	try {
		const questionRef = doc(db, 'questions', uqid);
		const solutionRef = doc(db, 'solutions', uqid);
		const questionSnap = await getDoc(questionRef);
		const solutionSnap = await getDoc(solutionRef);
		setAnswer({ ...questionSnap.data(), ...solutionSnap.data() });
		setError(false);
		setSuccess(true);
	} catch (err) {
		setSuccess(false);
		setError(true);
		console.error(err);
	}
};
