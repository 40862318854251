import { doc, getDoc } from 'firebase/firestore';

export const recoverSolutionPerQuestion = async ({ db, uqid }) => {
	const solutionRef = await getDoc(doc(db, 'solutions', uqid));
	if (solutionRef.exists()) {
		return solutionRef.data();
	} else {
		return { error: 404, message: 'No se encontró la solución' };
	}
};
