import { doc, writeBatch, Timestamp } from 'firebase/firestore';

export const finishExam = async (db, uid, simulacrum, navigate, setSimulacrum, setLoading, setSolvedExams) => {
	//Enviar timestamp de finalizacion del examen (endTime)
	setLoading({ status: true, title: null });
	//Si el timestamp now es mayor que endAt, utilizar entAt como tiempo de finalizacion
	const serverTimestamp = Timestamp.now().toDate();
	const endAt =
		serverTimestamp.getTime() > simulacrum.metadata.endAt.toDate().getTime()
			? simulacrum.metadata.endAt.toDate()
			: serverTimestamp;
	const endTime = Timestamp.fromDate(endAt);
	const batch = writeBatch(db);
	const examRef = doc(db, 'admissionExams', uid, 'exams', simulacrum.metadata.examId);
	batch.set(examRef, { endTime: endTime }, { merge: true });
	await batch
		.commit()
		.then(() => {
			console.info('Se ha finalizado el examen');
			navigate('/exams');
			setSimulacrum({
				questions: null,
				metadata: null,
				timers: null,
				totalMarkedAnswers: [],
			});
			//Limpia la lista de examenes resueltos y asigna el examen actual
			setSolvedExams({});
			setLoading({ status: false, title: null });
		})
		.catch((err) => {
			console.warn(err?.message);
			setLoading({ status: false, title: null });
		});
};
