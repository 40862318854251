import React, { useRef } from 'react';
import ExpandHeader from '../general/internalComponents/ExpandHeader/ExpandHeader';
import NavbarInHome from '../general/internalComponents/navbarInHome/NavbarInHome';
import { useScroll } from './../../hooks/useScroll';

export default function ExpandLayout({ children, title }) {
	const pageRef = useRef();
	const { scroll } = useScroll(pageRef);

	return (
		<>
			<ExpandHeader title={title} scroll={scroll} />
			<div ref={pageRef}>{children}</div>
			<NavbarInHome />
		</>
	);
}
