import styled from 'styled-components';

export const SelfCommentsContainerDesktop = styled.li`
	display: none;

	@media (min-width: 1024px) {
		display: flex;
		flex-direction: column;
		margin: 0 0 20px;
		padding: 0;

		.containerMessageInfo {
			display: flex;
			flex-direction: row;
			margin: 0 0 10px 0;

			img {
				width: 40px;
				height: 40px;
				margin: 0 15px 0 0;
				border-radius: 50%;
				object-fit: cover;
				z-index: 100;
			}
		}

		.contentInfo {
			display: inline;

			h6 {
				font-size: 0.8125em;
				margin: 0 0 -2px 0;
				font-family: ${(props) => props.theme.font.brandFont};
				font-weight: ${(props) => props.theme.font.weight.bold};
				text-align: start;
				font-style: normal;
				color: ${(props) => props.theme.color.neutral0};

				@media (min-width: 1200px) {
					font-size: 0.95em;
				}
			}

			span {
				font-size: 0.625em;
				font-weight: ${(props) => props.theme.font.weight.regular};
				font-family: ${(props) => props.theme.font.textFont};
				font-style: normal;
				color: ${(props) => props.theme.color.neutral200};
				align-self: flex-start;
			}
		}

		p {
			line-height: 1.3125em;
			text-align: start;
			word-break: break-word;
			font-size: 0.8125em;
			font-family: ${(props) => props.theme.font.textFont};
			font-weight: ${(props) => props.theme.font.weight.regular};
			color: ${(props) => props.theme.color.info500};
		}
	}
`;

export const SelfCommentsContainer = styled.li`
	display: flex;
	margin: 0 0 15px;

	img {
		width: 40px;
		height: 40px;
		margin: 0 15px 0 0;
		border-radius: 50%;
		object-fit: cover;
		z-index: 100;
	}

	h6 {
		margin: 0 0 3px 0;
		font-size: 1em;
		font-weight: ${(props) => props.theme.font.weight.medium};
		font-family: ${(props) => props.theme.font.textFont};
		text-align: start;
		font-style: normal;
		color: ${(props) => props.theme.color.neutral900};

		@media (min-width: 1200px) {
			font-size: 0.95em;
		}
	}

	p {
		line-height: 1.67em;
		text-align: start;
		word-break: break-word;
		font-size: 0.875em;
		font-family: ${(props) => props.theme.font.textFont};
		font-weight: ${(props) => props.theme.font.weight.light};
		color: ${(props) => props.theme.color.neutral900};
	}

	span {
		font-size: 0.75em;
		font-weight: ${(props) => props.theme.font.weight.medium};
		font-family: ${(props) => props.theme.font.textFont};
		font-style: normal;
		color: ${(props) => props.theme.color.neutral200};
		align-self: flex-end;
	}

	@media (min-width: 768px) {
		margin: 0 0 14px 0;
	}

	@media (min-width: 1024px) {
		display: none;
		font-weight: ${(props) => props.theme.font.weight.light};
	}
`;

export const CommentStyled = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CommentMessage = styled.div`
	margin: 0 0 6px;
	padding: 10px 12px;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	border-radius: 0px 4px 4px 4px;
`;
