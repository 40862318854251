import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './../../../styles/buttonGeneral';
import { TextSubtext4 } from './../../../styles/textGeneral';
import { Promotion, PromotionsContainer } from './styles/sPromotionCompact';

export default function PromotionCompact() {
	return (
		<PromotionsContainer>
			<div className="content">
				<Promotion background="gradient">
					<h3>Plan Básico</h3>
					<div>
						<p className="price">s/ 79</p>
						<TextSubtext4>por mes</TextSubtext4>
					</div>
				</Promotion>
				<Promotion background="black">
					<h3>Plan Premium mensual</h3>
					<div>
						<p className="price">s/ 147</p>
						<TextSubtext4>por mes</TextSubtext4>
					</div>
				</Promotion>
				<Promotion background="gradient">
					<h3>Plan Premium por 3 meses</h3>
					<div>
						<p className="price">s/ 107</p>
						<TextSubtext4>por mes</TextSubtext4>
					</div>
				</Promotion>
			</div>
			<Link to="/promotions">
				<Button secondary type="Ver promociones" />
			</Link>
		</PromotionsContainer>
	);
}
