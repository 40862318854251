import styled from 'styled-components';

export const ContainerAdvantage = styled.div`
	justify-items: center;
	margin: 0 auto;
	margin-top: 70px;
	margin-bottom: 0px;

	@media (min-width: 768px) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
`;

export const Advantage = styled.li`
	width: 200px;
	height: 160px;
	margin-bottom: 10px;
	text-align: center;
	padding: 10px;
	margin-top: 20px;
	list-style: none;

	p {
		margin-top: 20px;
	}

	path {
		fill: ${(props) => props.theme.color.neutral200};
	}

	@media (min-width: 200px) {
		width: 100%;
	}

	@media (min-width: 1028px) {
		margin: 30px;
		margin-bottom: 6px;
	}
`;

export const ContainerTeam = styled.div`
	height: auto;
	justify-items: center;
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 0;

	p {
		font-size: 0.813em;
		margin-bottom: 6px;
		color: ${(props) => props.theme.color.neutral600};
		font-weight: 700;
	}
	&:last-child {
		margin: 0 0 80px 0;
	}
	@media (min-width: 375px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	@media (min-width: 792px) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
	.sectionBot {
		margin-bottom: 100px;
	}
`;

export const BoxTeam = styled.li`
	width: 100%;
	height: 190px;
	margin-bottom: 10px;
	text-align: center;
	padding: 10px;
	margin-top: 10px;
	list-style: none;
`;

export const BoxOne = styled.li`
	width: 115px;
	height: 150px;
	margin-bottom: 10px;
	text-align: center;
	padding: 5px;
	margin-top: 0px;
	display: table-cell;

	p {
		color: ${(props) => props.theme.color.neutral400};
		font-size: 0.9em;
		margin-top: 14px;
		font-weight: 500;
	}
`;

export const BoxTwo = styled.li`
	height: 170px;
	text-align: center;
	padding: 5px;
	margin-top: 0px;
	display: table-cell;
	padding: 9px;

	@media (min-width: 200px) {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
`;

export const ImageBox = styled.section`
	width: 80px;
	height: 80px;
	background: url(${(props) => props.image});
	background-size: cover;
	margin: auto;
	border-radius: 50px;
	display: inline-block;
`;

export const MainContainer = styled.section`
	position: relative;
	z-index: 100;
`;

export const TextSubtextCard = styled.p`
	font-size: 0.938em;
	line-height: 1.5em;
	font-family: 'Nunito';
	font-weight: ${(props) => props.theme.font.weight.medium};

	@media (min-width: 768px) {
		font-size: 0.978em;
	}
	@media (min-width: 1024px) {
		font-size: 1em;
	}
`;

export const TextSubtext1 = styled.p`
	font-size: 0.853em;
	line-height: 1.5em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
	font-weight: 500;

	@media (min-width: 768px) {
		font-size: 0.9em;
	}
	@media (min-width: 1024px) {
		font-size: 0.938em;
	}
`;

export const TextSubtext2 = styled.p`
	font-size: 0.853em;
	line-height: 1.3em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
	@media (min-width: 768px) {
		font-size: 0.9em;
	}
	@media (min-width: 1024px) {
		font-size: 0.938em;
	}
`;
