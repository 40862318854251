import styled, { css } from 'styled-components';

export const StatisticGeneralDataContainer = styled.div`
	display: grid;
	margin: 0 0 20px 0;

	.generalDataContainer {
		display: flex;
		background: ${(props) => props.theme.color.neutral10};
		border-radius: 14px;
		padding: 10px;
		margin: 0 0 20px 0;
		align-items: center;

		p {
			font-weight: ${(props) => props.theme.font.weight.semiBold};
			color: ${(props) => props.theme.color.neutral300};
			margin: 0 0 4px 0;
			font-family: ${(props) => props.theme.font.textFont};
		}

		span {
			font-size: 1.38em;
			font-weight: ${(props) => props.theme.font.weight.bold};
		}
	}

	.iconContainer {
		width: 70px;
		height: 70px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		margin: 0 15px 0 0;

		svg {
			width: 30px;
		}
	}

	.detailsData {
		flex: 1 0 0;
	}

	.detailsStatisticContainer {
		border-radius: 14px;
		padding: 10px;
		background: ${(props) => props.theme.color.warning200};
		color: ${(props) => props.theme.color.warning900};
		font-family: ${(props) => props.theme.font.textFont};
		font-size: 0.938em;

		svg {
			fill: ${(props) => props.theme.color.warning900};
			margin: 0 0 10px 0;
		}

		p {
			line-height: 1.25em;
		}
	}

	${(props) =>
		props.color === 'skyblue' &&
		css`
			.iconContainer {
				background: #86f8ff;
			}

			svg {
				fill: #083b3f;
			}
		`}

	@media(min-width:768px) {
		.iconContainer {
			width: 130px;
			height: 130px;
			margin: 0 40px 0 0;

			svg {
				width: 55px;
			}
		}

		.generalDataContainer {
			padding: 20px;

			p {
				font-size: 1.25em;
			}

			span {
				font-size: 2.5em;
			}
		}

		.detailsStatisticContainer {
			padding: 20px;
			font-size: 1em;

			svg {
				margin: 0 0 20px 0;
				width: 40px;
			}
		}
	}

	@media (min-width: 1200px) {
		grid-template-columns: 64% 1fr;
		margin: 0;
		gap: 20px;

		.iconContainer {
			width: 130px;
			height: 130px;
			margin: 0 40px 0 0;

			svg {
				width: 55px;
			}
		}

		.generalDataContainer {
			padding: 20px 40px;
			margin: 0;

			p {
				font-size: 1.25em;
			}

			span {
				font-size: 2.5em;
			}
		}

		.detailsStatisticContainer {
			padding: 20px;
			font-size: 0.938em;
		}
	}
`;
