import React from 'react';
import Preview from '../general/landingComponents/Preview';
import ContactCard from '../general/cards/ContactCard';
import { ReactComponent as WhatsappSVG } from './../../../src/icons/icons-whatsapp2.svg';
import { ReactComponent as EnviarSVG } from './../../../src/icons/icons-enviar.svg';
import { ContactsContainer } from './styles/sContactsComponents';

const contactList = [
	{
		image: require('./../../../src/images/staff/academiaDecide/TíoDecide.webp'),
		name: 'Tío Decide',
		number: '+51 959 187 087',
		link: 'https://api.whatsapp.com/send?phone=+51959187087&text=%C2%A1Hola!%20quiero%20más%20información%20sobre%20la%20Academia%20Decide.',
		type: 'whatsapp',
		icon: <WhatsappSVG />,
	},
	{
		image: require('./../../../src/images/staff/academiaDecide/Lizeth.webp'),
		name: 'Lizeth',
		number: '+51 942 128 000',
		link: 'https://api.whatsapp.com/send?phone=+51942128000&text=%C2%A1Hola!%20quiero%20más%20información%20sobre%20la%20Academia%20Decide.%F0%9F%A7%A1',
		type: 'whatsapp',
		icon: <WhatsappSVG />,
	},
	{
		image: require('./../../../src/images/staff/academiaDecide/Royer.webp'),
		name: 'Royer',
		number: '+51 997 330 540',
		link: 'https://api.whatsapp.com/send?phone=+51997330540&text=%C2%A1Hola!%20quiero%20más%20información%20sobre%20la%20Academia%20Decide.%F0%9F%A7%A1',
		type: 'whatsapp',
		icon: <WhatsappSVG />,
	},
	{
		image: require('./../../../src/images/facebook.webp'),
		name: 'Facebook Messenger',
		number: 'Abrir',
		link: 'https://www.facebook.com/TeamDecide',
		type: 'facebook',
		icon: <EnviarSVG />,
	},
	// {
	// 	image: require('./../../../src/images/google.webp'),
	// 	name: 'Gmail (correo)',
	// 	number: 'Abrir',
	// 	link: 'https://www.youtube.com/',
	// 	type: 'email',
	// 	icon: <EnviarSVG />,
	// },
];

export default function ContactsComponents() {
	return (
		<Preview margin="25px" padding="30px 0 81px 0" height="auto">
			<ContactsContainer>
				{contactList.map((contact) => (
					<ContactCard key={contact.name} {...contact} />
				))}
			</ContactsContainer>
		</Preview>
	);
}
