'use client';
import { ReactComponent as ArrowRightSVG } from '../../../../icons/arrow-right.svg';
import { Section2Container } from './styles/sSection2';
import { Link } from 'react-router-dom';

export const Section2 = ({ title, pathLink, linkTitle, iconShow }) => {
	return (
		<Section2Container>
			<h3>{title}</h3>
			<Link className="link" to={pathLink}>
				{linkTitle && <span>{linkTitle}</span>}
				{iconShow && <ArrowRightSVG />}
			</Link>
		</Section2Container>
	);
};
