import React from 'react';
import MainExams from './../components/exams/MainExams';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Exams = () => {
	const [t] = useTranslation('main');
	return (
		<>
			<Helmet>
				<title>{t('brand.exams.title')}</title>
				<meta name="description" content={t('brand.exams.description')} />
				<meta property="og:title" content={t('brand.exams.title')} />
				<meta property="og:description" content={t('brand.exams.description')} />
				<meta property="twitter:title" content={t('brand.exams.title')} />
				<meta property="twitter:description" content={t('brand.exams.description')} />
			</Helmet>
			<MainExams />
		</>
	);
};

export default Exams;
