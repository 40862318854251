import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { WrapperChat } from '../components/inClasses/styles/sMainClassVideo';
import { ClassVideoLiveContainer } from '../components/inClasses/classVideo/styles/sClassVideoLiveContainer';
import { MainClassVideoContainer } from '../components/inClasses/classVideo/styles/sMainClassVideo';
import { MainVideoContainer } from '../components/inClasses/classVideo/styles/sMainVideoContainer';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOneLive } from '../actions/index';
import MainSpinner from '../components/spinner/MainSpinner';
import ReactPlayer from 'react-player';
import ChatRoom from '../components/chat/ChatRoom';

const MainClassLive = () => {
	const [t] = useTranslation('main');
	const params = useParams();
	const dispatch = useDispatch();
	const live = useSelector((state) => state.oneLive);

	useEffect(() => {
		dispatch(fetchOneLive(params));
	}, []);

	if (live.length === 0) return <MainSpinner />;

	const { titleOfClass, course, urlOfVideo } = live[0].attributes;
	return (
		<MainVideoContainer>
			<MainClassVideoContainer>
				<div className="mainClassVideoBloqued"></div>
				<ClassVideoLiveContainer>
					<Helmet>
						<title>{titleOfClass} - Ebbiner</title>
						<meta name="description" content={`Clase del curso de ${t(`courses.${course}`)}. Tema: ${titleOfClass}.`} />
						<meta property="og:title" content={`${titleOfClass} - Ebbiner`} />
						<meta property="og:description" content={`Clase del curso de ${t(`courses.${course}`)}. Tema: ${titleOfClass}.`} />
						<meta property="twitter:title" content={`${titleOfClass} - Ebbiner`} />
						<meta
							property="twitter:description"
							content={`Clase del curso de ${t(`courses.${course}`)}. Tema: ${titleOfClass}.`}
						/>
					</Helmet>
					<ReactPlayer
						url={urlOfVideo}
						muted={false}
						playing={false}
						controls={true}
						className="react-player"
						fallback={null}
						style={null}
					/>
				</ClassVideoLiveContainer>
				{/* <WrapperHome>
          <h2>{t(`courses.${course}`)}</h2>
        </WrapperHome> */}
			</MainClassVideoContainer>
			<WrapperChat>
				<ChatRoom params={params} />
			</WrapperChat>
		</MainVideoContainer>
	);
};

export default MainClassLive;
