import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainPromotions from './../components/promotions/MainPromotions';

export default function Promotions() {
	return (
		<>
			<Helmet>
				<title>Promociones | Ebbiner</title>
				<link rel="canonical" href="https://ebbiner.com" />
				<meta name="description" content="" />
				<meta property="og:title" content="" />
				<meta property="og:description" content="Tu futuro universitario te espera." />
				<meta property="twitter:title" content="Ebbiner" />
				<meta property="twitter:description" content="Tu futuro universitario te espera." />
				<meta name="twitter:card" content="summary" />
			</Helmet>
			<MainPromotions />
		</>
	);
}
