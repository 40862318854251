import React from 'react';
import SelfComments from './SelfComments';

const Message = ({ message }) => {
	return (
		<>
			<SelfComments {...message} />
		</>
	);
};

export default Message;
