import { call, put, takeEvery } from 'redux-saga/effects';
import { getLivesInfo } from '../api/getLives';
import { FETCH_LIVE } from '../actions/type';
import { setLive } from '../actions/index';

function* fetchLives() {
	try {
		const livesData = yield call(getLivesInfo);
		yield put(setLive(livesData));
	} catch (error) {
		console.warn(error);
	}
}

function* liveSaga() {
	yield takeEvery(FETCH_LIVE, fetchLives);
}

export default liveSaga;
