import React, { useContext, useEffect, useState } from 'react';
// import { WrapperOthers, WrapperCompleteM } from './../../../styles/generalStyles';
// import CoverPageRC from './CoverPageRC';
// import RecordedClasses from './RecordedClasses';
// import RecordedClassCard from './RecordedClassCard';

//Import contexts
import { AppContext } from '../../../App';

//Import algorithms
import { classesOrganizer } from './algorithms/classesOrganizer';
import { Section1 } from '../../../packages/shared/components/title/Section1';
import { CourseHeader } from '../../../packages/shared/components/items/CourseHeader';

// import { ReactComponent as ArrowLeftSVG } from '../../../icons/ArrowLeft.svg';
import { ReactComponent as ArrowLineRightSVG } from '../../../icons/ArrowLineRight.svg';
import { Preview3 } from '../../../packages/shared/components/cards/previews/Preview3';
import { TeacherInformation } from '../../../packages/shared/components/items/TeacherInformation';
import { CourseContent } from '../../../packages/shared/components/items/CourseContent';
import {
	CourseInformationContainer,
	WrapperCourse,
	WrapperFullCourse,
} from '../../../packages/shared/components/items/style/sCourseContent';
import { useTranslation } from 'react-i18next';
import { WrapperOthers } from '../../../styles/generalStyles';
// import { useStatesContext } from '../../layouts/LayoutContextOfStates';

const MainRecordedCourse = ({ course }) => {
	const [t] = useTranslation('main');

	const {
		dataOfClasses,
		//  dataPrevOfClasses
	} = useContext(AppContext);
	// const { localRecentCoursePath } = useStatesContext();
	const [latestClasses, setLatestClasses] = useState(null);
	// const [oldClasses, setOldClasses] = useState(null);
	const [
		viewerClass,
		//  setViewerClass
	] = useState({
		top: false,
		bot: false,
	});

	useEffect(() => {
		setLatestClasses(
			classesOrganizer({
				classes: dataOfClasses,
				course: course,
				latestClasses: true,
			}),
		);
		// setOldClasses(
		// 	classesOrganizer({
		// 		classes: dataPrevOfClasses,
		// 		course: course,
		// 		latestClasses: false,
		// 	}),
		// );
	}, [viewerClass]);
	// console.log('course', course);

	return (
		<main>
			<WrapperOthers>
				<div>
					{/* <WrapperCompleteM> */}
					<WrapperFullCourse>
						<Section1>Clases grabadas 💻</Section1>
						<WrapperCourse>
							<CourseInformationContainer>
								{course.map((course) => (
									<>
										<CourseHeader
											urlImageCourse={course.icon}
											titleCourse={t(`courses.${course.course}`)}
											totalQuantityClass={`${latestClasses?.length} clases `}
											totalTime={'10 hrs 30 min'}
										/>
										<Preview3
											// pathRedirect={localRecentCoursePath}
											urlBackground={course.imageClass}
											iconButton={<ArrowLineRightSVG />}
										/>
										<TeacherInformation
											nameTeacher={course.professor}
											urlImage={course.imageProfessor}
											descriptionTeacher={'Cargando la descripcion del docente de este curso ...'}
										/>
									</>
								))}
							</CourseInformationContainer>
							<CourseContent headerActive={true} clasesInformacion={latestClasses} />
						</WrapperCourse>
					</WrapperFullCourse>
					{/* </WrapperCompleteM> */}
				</div>
				{/* 
			<div>
				<WrapperCompleteM>
					{course.map((course) => {
						// console.log('course', course);

						return <CoverPageRC key={course.course} {...course} />;
					})}
				</WrapperCompleteM>
				<WrapperOthers>
					{latestClasses && (
						<RecordedClasses
							title="Clases grabadas actuales"
							type="top"
							otherType="bot"
							setViewerClass={setViewerClass}
							status={viewerClass.top}>
							<ul>
								{latestClasses.map((card, index) => {
									if (viewerClass.top) {
										return <RecordedClassCard key={card.week} {...card} />;
									} else {
										if (window.innerWidth < 1300) {
											if (index < 2) {
												return <RecordedClassCard key={card.week} {...card} />;
											}
										} else {
											if (index < 3) {
												return <RecordedClassCard key={card.week} {...card} />;
											}
										}
									}
								})}
							</ul>
						</RecordedClasses>
					)}
					{oldClasses && (
						<RecordedClasses
							title="Clases grabadas anteriores"
							type="bot"
							otherType="top"
							setViewerClass={setViewerClass}
							status={viewerClass.bot}>
							<ul>
								{oldClasses.map((card, index) => {
									if (viewerClass.bot) {
										return <RecordedClassCard key={card.week} {...card} />;
									} else {
										if (window.innerWidth < 1300) {
											if (index < 2) {
												return <RecordedClassCard key={card.week} {...card} />;
											}
										} else {
											if (index < 3) {
												return <RecordedClassCard key={card.week} {...card} />;
											}
										}
									}
								})}
							</ul>
						</RecordedClasses>
					)}
				</WrapperOthers>
			</div> */}
			</WrapperOthers>
		</main>
	);
};

export default MainRecordedCourse;
