import React, { useEffect } from 'react';
import { useReducedState } from '../../../hooks/useReducedState';
import { QuestionInputContainer, TextQuestionStyled } from '../form/styles/sQuestionInput';
import CourseCategoryForm from '../form/CourseCategoryForm';

//firebase utils

export default function ResultsPerCourse({ setModalStateReport, modalStateReport, setDataQuestionReport, state }) {
	const [detailsOpened, setDetailsOpened] = useReducedState({
		actual: null,
		previous: null,
	});

	useEffect(() => {
		if (detailsOpened.previous) {
			const openedElement = document.getElementById(detailsOpened.previous);
			openedElement.removeAttribute('open');
		}
	}, [detailsOpened]);

	return (
		<>
			<QuestionInputContainer inputResults>
				<TextQuestionStyled>
					<ul id="listOfQuestionsContainter" className="courseCategoryFormContainer">
						{state?.categorizedQuestions.map((questions) => (
							<CourseCategoryForm
								key={questions[0].course}
								setDetailsOpened={setDetailsOpened}
								detailsOpened={detailsOpened}
								questions={questions}
								isReview={true}
								setModalStateReport={setModalStateReport}
								modalStateReport={modalStateReport}
								setDataQuestionReport={setDataQuestionReport}
							/>
						))}
					</ul>
				</TextQuestionStyled>
			</QuestionInputContainer>
		</>
	);
}
