import React from 'react';
import HeaderLanding from './../general/landingComponents/HeaderLanding';
import FooterLanding from './../general/landingComponents/FooterLanding';

const LandingLayout = ({ children }) => {
	return (
		<>
			<HeaderLanding />
			{children}
			<FooterLanding />
		</>
	);
};

export default LandingLayout;
