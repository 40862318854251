import React from 'react';
import { QuestionResultsContainer } from './styles/sQuestionResults';

export default function QuestionResults({ state }) {
	return (
		<QuestionResultsContainer>
			<li>
				<div className="circle correct" />
				<p>Correctas: {state?.metadata?.statistics?.totalCorrectAnswers}</p>
			</li>
			<li>
				<div className="circle incorrect" />
				<p>Incorrectas: {state?.metadata?.statistics?.totalMarkedAnswers - state?.metadata?.statistics?.totalCorrectAnswers}</p>
			</li>
			<li>
				<div className="circle unanswered" />
				<p>No respondidas: {state?.numberOfQuestions - state?.metadata?.statistics?.totalMarkedAnswers}</p>
			</li>
		</QuestionResultsContainer>
	);
}
