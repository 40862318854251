import React from 'react';
import { Button } from './../../styles/buttonGeneral';
import { WelcomeContainer } from './styles/sMainWelcome';

const MainWelcome = () => {
	return (
		<WelcomeContainer>
			<h1>Bienvenido futuro(a) cachimbo(a)</h1>
			<p>Antes de iniciar mira el video tutorial del funcionamiento de la plataforma.</p>
			<div className="video"></div>
			<Button text="INICIAR" />
		</WelcomeContainer>
	);
};

export default MainWelcome;
