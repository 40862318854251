import { useContext, useEffect, useState } from 'react';
import { AnswerComponents } from '../components/general/answer/AnswerComponents';
import { Wrapper } from '../styles/generalStyles';
import { Title4, Text } from '../styles/textGeneral';
import { ChatKat, ContentAnswers, SectionPromotions, TitleContainer } from './styles/sMainAnswer';
import { useParams } from 'react-router-dom';
import { GetQuestionAnswer } from './algorithms/getQuestionAnswer';
import { useFirestore } from 'reactfire';
import { useSearchParams } from 'react-router-dom';
import { saveAnswerLog } from './algorithms/saveAnswerLog';
import { AppContext } from '../App';
import perfilKat from './../images/staff/perfil-de-Kat.webp';
import PromotionCompact from './../components/general/promotions/PromotionCompact';
import LocalSpinner from './../components/spinner/LocalSpinner';

export default function MainAnswer() {
	const db = useFirestore();
	let params = useParams();
	const { dataOfUser } = useContext(AppContext);
	const [searchParams] = useSearchParams();
	const [answer, setAnswer] = useState(null);
	const [isSuccess, setSuccess] = useState(false);
	const [isError, setError] = useState(false);
	let uqid = params?.uqid;
	const query = Object.fromEntries([...searchParams]);
	const whatsapp = 'https://api.whatsapp.com/send?phone=+51974761393&text=Pregunta';
	useEffect(() => {
		if (uqid && query?.nu) {
			GetQuestionAnswer(db, uqid, setAnswer, setSuccess, setError);
			saveAnswerLog(db, query?.nu, dataOfUser, uqid);
		}
	}, [params?.uqid, searchParams]);

	return (
		<main>
			<Wrapper>
				<ContentAnswers>
					<TitleContainer>
						{isError || (!isSuccess && <LocalSpinner title="Resolviendo pregunta" />)}
						{isSuccess && <Title4>Pregunta</Title4>}
					</TitleContainer>
					{isSuccess && answer && (
						<AnswerComponents
							{...answer}
							trueKey={answer?.keys[0]?.key}
							secondKey={answer?.keys[1]?.key}
							thirdKey={answer?.keys[2]?.key}
						/>
					)}
				</ContentAnswers>
				<ChatKat>
					<p className="title">Kat te ayuda en tu ingreso a la universidad</p>
					<div className="chatContainer">
						<img className="profile" src={perfilKat} alt="Foto de perfil de Kat" />
						<div>
							<div className="chat chat-first">
								<p>¿Quieres más preguntas tipo admisión para resolver?</p>
							</div>
							<div className="chat">
								<p>
									👉
									<a href={whatsapp} target="_blank" rel="noopener noreferrer">
										Presiona aquí
									</a>
								</p>
							</div>
						</div>
					</div>
				</ChatKat>
			</Wrapper>
			<SectionPromotions>
				<Wrapper>
					<Title4 center>Ofertas especiales de suscripción</Title4>
					<Text center>Como suscriptor, tienes acceso a descuentos y ofertas exclusivas.</Text>
					<PromotionCompact />
				</Wrapper>
			</SectionPromotions>
		</main>
	);
}
