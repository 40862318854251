import styled from 'styled-components';

export const LogoExpandContainer = styled.div`
	display: flex;
	align-items: center;

	svg {
		fill: ${(props) => props.theme.color.neutral0};
		margin: -5px 0 0 0;
		height: 25px;
		width: auto;
	}

	p {
		color: ${(props) => props.theme.color.neutral500};
		font-family: ${(props) => props.theme.font.brandFont};
		font-weight: ${(props) => props.theme.font.weight.semiBold};
	}

	.separator {
		width: 1px;
		height: 30px;
		background: ${(props) => props.theme.color.neutral500};
		margin: 0 15px;
	}
`;
