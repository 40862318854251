import { colors, spacing, media, borders, shadows, blurs, fonts } from './fundations';

const theme = () => {
	return {
		/* Colors */
		color: {
			primary0: colors.primary.p0,
			primary100: colors.primary.p100,
			primary200: colors.primary.p200,
			primary300: colors.primary.p300,
			primary304: colors.primary.p304,
			primary305: colors.primary.p305,
			primary310: colors.primary.p310,
			primary335: colors.primary.p335,
			primary350: colors.primary.p350,
			primary400: colors.primary.p400,
			primary500: colors.primary.p500,
			primary600: colors.primary.p600,
			primary605: colors.primary.p605,
			primary610: colors.primary.p610,
			primary700: colors.primary.p700,
			primary800: colors.primary.p800,
			primary900: colors.primary.p900,
			secondary0: colors.secondary.sec0,
			secondary5: colors.secondary.sec5,
			secondary100: colors.secondary.sec100,
			secondary200: colors.secondary.sec200,
			secondary300: colors.secondary.sec300,
			secondary400: colors.secondary.sec400,
			secondary500: colors.secondary.sec500,
			secondary600: colors.secondary.sec600,
			secondary700: colors.secondary.sec700,
			secondary800: colors.secondary.sec800,
			secondary900: colors.secondary.sec900,
			error0: colors.error.e0,
			error100: colors.error.e100,
			error200: colors.error.e200,
			error300: colors.error.e300,
			error400: colors.error.e400,
			error405: colors.error.e405,
			error410: colors.error.e410,
			error435: colors.error.e435,
			error500: colors.error.e500,
			error600: colors.error.e600,
			error700: colors.error.e700,
			error800: colors.error.e800,
			error900: colors.error.e900,
			info0: colors.info.i0,
			info100: colors.info.i100,
			info200: colors.info.i200,
			info300: colors.info.i300,
			info400: colors.info.i400,
			info405: colors.info.i405,
			info410: colors.info.i410,
			info500: colors.info.i500,
			info600: colors.info.i600,
			info700: colors.info.i700,
			info800: colors.info.i800,
			info900: colors.info.i900,
			success0: colors.success.suc0,
			success100: colors.success.suc100,
			success200: colors.success.suc200,
			success300: colors.success.suc300,
			success400: colors.success.suc400,
			success405: colors.success.suc405,
			success410: colors.success.suc410,
			success500: colors.success.suc500,
			success600: colors.success.suc600,
			success700: colors.success.suc700,
			success800: colors.success.suc800,
			success900: colors.success.suc900,
			warning0: colors.warning.w0,
			warning5: colors.warning.w5,
			warning100: colors.warning.w100,
			warning200: colors.warning.w200,
			warning300: colors.warning.w300,
			warning400: colors.warning.w400,
			warning405: colors.warning.w405,
			warning410: colors.warning.w410,
			warning500: colors.warning.w500,
			warning600: colors.warning.w600,
			warning700: colors.warning.w700,
			warning800: colors.warning.w800,
			warning900: colors.warning.w900,
			neutral0: colors.neutral.n0,
			neutral2: colors.neutral.n2,
			neutral5: colors.neutral.n5,
			neutral10: colors.neutral.n10,
			neutral35: colors.neutral.n35,
			neutral100: colors.neutral.n100,
			neutral105: colors.neutral.n105,
			neutral200: colors.neutral.n200,
			neutral300: colors.neutral.n300,
			neutral400: colors.neutral.n400,
			neutral405: colors.neutral.n405,
			neutral500: colors.neutral.n500,
			neutral600: colors.neutral.n600,
			neutral605: colors.neutral.n605,
			neutral700: colors.neutral.n700,
			neutral735: colors.neutral.n735,
			neutral800: colors.neutral.n800,
			neutral835: colors.neutral.n835,
			neutral900: colors.neutral.n900,
			neutral910: colors.neutral.n910,
			neutral980: colors.neutral.n980,
			neutral1000: colors.neutral.n1000,
		},
		/* Spacing */
		space: spacing,
		/* Borders */
		border: borders,
		/* Shadows */
		shadow: shadows,
		/* Blurs */
		blur: blurs,
		/* Media */
		media: {
			mediaXS: media.xs,
			mediaMD: media.md,
			mediaLG: media.lg,
			mediaXL: media.xl,
		},
		/* Fonts */
		font: fonts,
	};
};

export default theme;
