import React from 'react';
import { Button, ModalContent, ModalOverlay } from './styles/sCustomPreviewModal';
import { ReactComponent as CloseSVG } from '../../../../../icons/close.svg';
import { ReactComponent as ArrowLineRightSVG } from '../../../../../icons/ArrowLineRight.svg';
import { ReactComponent as ArrowLineLeftSVG } from '../../../../../icons/ArrowLineLeft.svg';

const CustomPreviewModal = ({ urlImage, courseName, onClose, onNext, onPrev, selectedIndex, totalElements }) => {
	const handleOverlayClick = (e) => {
		if (e.target === e.currentTarget) {
			onClose();
		}
	};
	return (
		<ModalOverlay onClick={handleOverlayClick}>
			<ModalContent>
				<Button onClick={onClose}>
					<CloseSVG />
				</Button>
				<img src={urlImage} alt={courseName} />
				{totalElements == 1 ? (
					<></>
				) : selectedIndex < 1 ? (
					<Button onClick={onNext} className="nextButton">
						<ArrowLineRightSVG />
					</Button>
				) : selectedIndex === totalElements - 1 ? (
					<Button onClick={onPrev} className="backButton">
						<ArrowLineLeftSVG />
					</Button>
				) : (
					<>
						<Button onClick={onPrev} className="backButton">
							<ArrowLineLeftSVG />
						</Button>
						<Button onClick={onNext} className="nextButton">
							<ArrowLineRightSVG />
						</Button>
					</>
				)}
			</ModalContent>
		</ModalOverlay>
	);
};

export default CustomPreviewModal;
