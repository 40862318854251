import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { ReactComponent as StatisticSimulacrums } from './../../images/statistic-simulacrums.svg';
import { ReactComponent as StatisticAdmission } from './../../images/statistic-admission.svg';
import { Title5 } from './../../styles/textGeneral';
import { WrapperOthers } from './../../styles/generalStyles';
import { UserProfileContainer } from './styles/sMainProfile';
import { ReactComponent as DegreeHatSVG } from './../../icons/degree-hat.svg';
// import { ReactComponent as StatisticClasses } from './../../images/statistic-classes.svg';

// import { ReactComponent as FavoriteSVG } from './../../icons/favorite.svg';
import CourseCard from '../general/cards/CourseCard';
import { CourseCardsUnlist } from './../home/styles/sMainHome';
import { BoxCard } from '../../styles/boxGeneral';
import MainSpinner from '../spinner/MainSpinner';
import StatisticsCard from './../general/cards/StatisticsCard';
import ProfileGeneralData from './ProfileGeneralData';

// import { probabilityCalculator } from './algorithms/probabilityCalculator';

import { useStatesContext } from '../layouts/LayoutContextOfStates';

const statisticsDetailsList = [
	{
		image: <StatisticAdmission />,
		title: 'Ingreso',
		link: '/statistic/admission',
	},
	{
		image: <StatisticSimulacrums />,
		title: 'Simulacros',
		link: '/statistic/simulacrums',
	},
	// {
	// 	image: <StatisticClasses />,
	// 	title: 'Clases',
	// 	link: '/profile/statistic/classes',
	// },
];

const Mainprofile = () => {
	const { dataOfUser } = useContext(AppContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState({ status: false, title: null });
	const { stats } = useStatesContext();

	useEffect(() => {
		setLoading({ status: true, title: null });
		if (!dataOfUser) {
			navigate('/');
		}
		setLoading({ status: false, title: null });
	}, [dataOfUser]);

	if (loading.status) return <MainSpinner title={'Actualizando tus datos'} />;

	if (loading.status && !stats) return <MainSpinner title={'Estamos cargando tus datos'} />;

	const profileDetailsList = [
		{
			image: <DegreeHatSVG />,
			title: 'Postulante a',
			description: !dataOfUser?.university
				? `${dataOfUser?.universityAcronym} - ${dataOfUser?.school ?? 'UNIVERSIDAD'}`
				: 'Universidad',
			color: 'brand',
		},
		// {
		// 	image: <FavoriteSVG />,
		// 	title: 'Probabilidad de ingreso',
		// 	description: stats?.averageStatistics ? probabilityCalculator(stats.averageStatistics) : <span>0</span>,
		// 	color: 'yellow',
		// },
	];
	return (
		<main>
			<UserProfileContainer>
				<ProfileGeneralData stats={stats} />
				<WrapperOthers>
					<BoxCard>
						<CourseCardsUnlist margin="40px 0 0 0">
							{profileDetailsList.map((card) => (
								<CourseCard key={card.title} {...card} />
							))}
						</CourseCardsUnlist>
					</BoxCard>
					{stats && (
						<div className="userProfileExtension">
							<Title5>Tus estadísticas</Title5>
							<BoxCard>
								<CourseCardsUnlist>
									{statisticsDetailsList.map((card) => (
										<StatisticsCard key={card.title} {...card} />
									))}
								</CourseCardsUnlist>
							</BoxCard>
						</div>
					)}
				</WrapperOthers>
			</UserProfileContainer>
		</main>
	);
};

export default Mainprofile;
