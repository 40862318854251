import React from 'react';
import { TextBody3 } from '../../../styles/textGeneral';
import { CourseCardContainer } from './styles/sCourseCard';
import { useNavigate } from 'react-router-dom';

export default function CourseCard({ image, title, description, widthSvg, color, classN, link }) {
	const navigate = useNavigate();
	return (
		<li onClick={() => link && navigate(link)}>
			<CourseCardContainer widthSvg={widthSvg} color={color} classN={classN}>
				<div className="imageContainer">{typeof image == 'string' ? <img src={image} alt={title} /> : <>{image}</>}</div>
				<div className="titleContainerCard">
					<TextBody3>{title}</TextBody3>
					{description && (
						<div>
							<span>{description}</span>
						</div>
					)}
				</div>
			</CourseCardContainer>
		</li>
	);
}
