'use client';
import styled, { css } from 'styled-components';

export const Preview2Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: stretch;
	border-radius: ${({ theme }) => theme.border.border12};
	min-width: 230px;
	max-width: 400px;
	padding-bottom: ${({ theme }) => theme.space.spacing2X};
	cursor: pointer;
	transition: transform 0.1s ease-out;

	${(props) =>
		props.isBoard &&
		css`
			max-width: 284px;
		`}

	&:hover {
		transform: scale(1.01);
	}
`;

export const PicturePreview2Container = styled.picture`
	display: flex;
	width: 100%;
	img {
		width: 100%;
		height: 140px;
		border-radius: ${({ theme }) => theme.border.border12} ${({ theme }) => theme.border.border12} 0 0;
	}
`;

export const DescriptionPreview2Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.spacing4X};
	gap: ${({ theme }) => theme.space.spacing1X};
	background-color: ${({ theme }) => theme.color.neutral5};
	border-radius: 0 0 ${({ theme }) => theme.border.border12} ${({ theme }) => theme.border.border12};
	font-size: 14px;
	height: auto;
	width: 100%;
	color: ${({ theme }) => theme.color.neutral0};

	& > *:nth-child(2) {
		font-size: 12px;
		color: ${({ theme }) => theme.color.neutral400};
	}
`;
