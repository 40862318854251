import { Helmet } from 'react-helmet-async';
import MainQuiz from '../components/quiz/MainQuiz';
import HeaderLanding from '../components/general/landingComponents/HeaderLanding';
import React from 'react';

const Quiz = () => {
	return (
		<>
			<HeaderLanding />
			<Helmet>
				<title>Quiz | Ebbiner</title>
				<meta name="description" content="Ebbiner - Educación virtual" />
				<meta property="og:title" content="Quiz | Ebbiner" />
				<meta property="og:description" content="Ebbiner - Educación virtual" />
				<meta property="twitter:title" content="Quiz | Ebbiner" />
				<meta property="twitter:description" content="Ebbiner - Educación virtual" />
			</Helmet>
			<MainQuiz />
		</>
	);
};

export default Quiz;
