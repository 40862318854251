import styled from 'styled-components';

export const WeekSelectContainer = styled.div`
	margin: 0;
`;
export const ProgressSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing6X};
	height: auto;

	p {
		font-size: 1.188em;
		font-weight: 500;
	}
`;
export const ContainerSectionCourses = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SectionCourses = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing6X};
	margin-bottom: ${({ theme }) => theme.space.spacing7X};
`;

export const SubTitleSpaceBetweenContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const CourseCardsUnlist = styled.ul`
	display: grid;
	gap: 20px;
	margin: ${(props) => props.margin};

	@media (min-width: 600px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
`;

export const CourseCardsUnlistRow = styled.ul`
	display: flex;
	gap: ${({ theme }) => theme.space.spacing5X};
	width: auto;
	overflow-y: scroll;

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		display: grid;
		/* grid-template-columns: 1fr 1fr 1fr 1fr; */
		grid-template-columns: ${(props) => (props.navbarDeployed ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr')};
		overflow-y: hidden;
		overflow-x: hidden;
	}
	@media (width >=1300px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
`;

export const SocialMediaCardsUnlist = styled.ul`
	display: grid;
	gap: 20px;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (min-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
`;

export const Preview1CardsUnlist = styled.div`
	display: flex;
	gap: 20px;
	margin: ${(props) => props.margin};
	overflow-y: hidden;
	padding-bottom: 20px;
	height: auto;
`;

export const Preview2CardsUnlist = styled.div`
	display: grid;
	grid-gap: ${({ theme }) => theme.space.spacing5X};
	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		grid-template-columns: 1fr 1fr;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
`;
