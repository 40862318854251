import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { CourseCardsUnlist } from './../home/styles/sMainHome';
import { BoxCard } from '../../styles/boxGeneral';
import { WrapperOthers } from './../../styles/generalStyles';
import { Title5, Title6 } from './../../styles/textGeneral';
import ProfileGeneralData from './../profile/ProfileGeneralData';
import { UserProfileContainer } from './../profile/styles/sMainProfile';
import StatisticCard from './../general/cards/StatisticCard';
import { ReactComponent as ChartSVG } from './../../icons/chart.svg';

import { useStatesContext } from '../layouts/LayoutContextOfStates';

export default function MainStatisticSimulacrums() {
	const { dataOfUser } = useContext(AppContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState({ status: false, title: null });
	const { stats } = useStatesContext();

	useEffect(() => {
		setLoading({ status: true, title: null, loading });
		if (!dataOfUser) {
			navigate('/');
		}
		setLoading({ status: false, title: null });
	}, [dataOfUser, stats]);

	const statisticsCardList = [
		{
			icon: <ChartSVG />,
			color: 'green',
			number: stats?.averageStatistics ? stats?.averageStatistics?.totalScoreAverage.toFixed(3) : 0,
			name: 'Promedio de exámenes simulacro',
			button: 'Ver notas',
			link: '/statistic/simulacrums/score',
		},
		{
			icon: <ChartSVG />,
			color: 'bluesky',
			number: stats?.averageStatistics ? stats?.averageStatistics?.examsSolved : 0,
			name: 'Simulacros tipo admisión resueltos',
		},
	];

	return (
		<main>
			<UserProfileContainer>
				<ProfileGeneralData />
				<WrapperOthers>
					<Title5>Estadísticas de ingreso</Title5>
					<Title6>Posibilidades de ingreso</Title6>
					<BoxCard>
						<CourseCardsUnlist>
							{statisticsCardList?.map((card) => {
								if (card.number !== 0) {
									return <StatisticCard key={card.name} {...card} />;
								}
							})}
						</CourseCardsUnlist>
					</BoxCard>
				</WrapperOthers>
			</UserProfileContainer>
		</main>
	);
}
