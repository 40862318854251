import styled from 'styled-components';
import { Body6Mixin } from '../../../../styles/textGeneral';

export const ClassVideoMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: ${({ theme }) => theme.border.border12};
	background-color: ${({ theme }) => theme.color.neutral1000};
	padding-bottom: ${({ theme }) => theme.space.spacing4X};
	min-height: 400px;
`;

export const MenuContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0 ${({ theme }) => theme.space.spacing4X};
	padding-top: ${({ theme }) => theme.space.spacing3X};
	gap: ${({ theme }) => theme.space.spacing2X};

	.buttonContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}

	button {
		display: flex;
		justify-content: center;
		width: 100%;
		background-color: ${({ theme }) => theme.color.neutral1000};
		color: ${({ theme }) => theme.color.neutral600};
		padding-bottom: ${({ theme }) => theme.space.spacing2X};
	}
	button p {
		${Body6Mixin}
	}

	.active {
		color: ${({ theme }) => theme.color.primary200};
	}
`;
export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const LineButtonActive = styled.div`
	width: 100%;
	height: 4px;
	border-radius: ${({ theme }) => theme.border.border12};
	background-color: transparent;

	&.active {
		background-color: ${({ theme }) => theme.color.primary200};
	}
`;

export const MainClassContent = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	.ClassDescriptionContainer {
		width: 100%;
		/* width: 50%; */
	}
	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		display: grid;
		grid-template-columns: 1fr minmax(359px, 10%);
	}
	@media (width >= ${({ theme }) => theme.media.mediaXL.width}) {
		flex-direction: row;
	}
`;
