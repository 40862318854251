import styled from 'styled-components';

export const CardBookOfAcademyContainer = styled.div`
	background: ${(props) => props.theme.color.neutral10};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	padding: 16px 0;

	p {
		text-transform: uppercase;
		text-align: center;
		font-family: ${(props) => props.theme.font.brandFont};
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
`;
