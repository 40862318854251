import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { ReactComponent as UserGauler } from './../../icons/user-gauler.svg';
import { WrapperCompleteM } from './../../styles/generalStyles';
import { Link } from 'react-router-dom';
import { Button } from './../../styles/buttonGeneral';
import MainSpinner from '../spinner/MainSpinner';
import { ProfileGeneralDataContainer } from './styles/sProfileGeneralData';

export default function ProfileGeneralData({ stats }) {
	const { dataOfUser, subscriptionTime, currentUser } = useContext(AppContext);
	const navigate = useNavigate();

	const [loading, setLoading] = useState({ status: false, title: null });
	const [userName, setUserName] = useState(null);

	useEffect(() => {
		setLoading({ status: true, title: null });
		if (!dataOfUser) {
			navigate('/');
		}
		const username = dataOfUser?.username || null;
		if (username && dataOfUser.username.split(' ').length >= 4) {
			const cleanned = username.split(' ');
			cleanned.shift();
			setUserName(cleanned.toString().replaceAll(',', ' '));
		} else {
			setUserName(username);
		}

		setLoading({ status: false, title: null });
	}, [dataOfUser, stats]);

	if (loading.status) return <MainSpinner title={'Actualizando tus datos'} />;

	return (
		<WrapperCompleteM>
			<ProfileGeneralDataContainer>
				<div className="userDescriptionContent">
					{dataOfUser?.photoURL ? (
						<img src={dataOfUser.photoURL} alt="Foto de perfil"></img>
					) : currentUser?.photoURL ? (
						<img src={currentUser.photoURL.replace('s96-c, s120-c')} alt="Foto de perfil"></img>
					) : (
						<UserGauler />
					)}
					<div>
						<h2>
							{dataOfUser?.name?.firstName !== '' ? `${dataOfUser?.name?.firstName} ${dataOfUser?.name?.lastName}` : userName}
						</h2>
						<h5>
							{subscriptionTime.timeRemaining === 1
								? `Te queda ${subscriptionTime?.timeRemaining} día en tu suscripción de Ebbiner.`
								: `Te quedan ${subscriptionTime?.timeRemaining} días en tu suscripción de Ebbiner.`}
						</h5>

						<br />
						{/* {!dataOfUser?.subscriptionData?.may2022Per15DaysPromo && (
              <Button primary small onClick={() => add15DaysPromo(db, dataOfUser, setLoading, subscriptionTime)}>
                Quiero mis 14 días gratis
              </Button>
            )} */}
					</div>
					<Link to="/profile/edit">
						{/* TODO: Arreglar botton  */}
						<Button primary text="Editar mi perfil" />
					</Link>
				</div>
			</ProfileGeneralDataContainer>
		</WrapperCompleteM>
	);
}
