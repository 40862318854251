import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { Title4, Title6 } from './../../styles/textGeneral';
import { WrapperHome } from './../../styles/generalStyles';
import StatisticGeneralData from './StatisticGeneralData';
import SimulacrumRecord from './../general/cardsModal/SimulacrumRecord';
import { useStatesContext } from '../layouts/LayoutContextOfStates';

export default function MainSimulacrumScores() {
	const { dataOfUser } = useContext(AppContext);
	const { stats } = useStatesContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (!dataOfUser) {
			navigate('/');
		}
	}, [dataOfUser]);

	return (
		<main>
			<WrapperHome>
				<div>
					<Title4>Notas de exámenes simulacro</Title4>
				</div>
				<StatisticGeneralData type="simulacrumExamnsRecord" />
				<div>
					<Title6>Tus simulacros</Title6>
					<SimulacrumRecord type="simulacrum" stats={stats ? stats : null} />
				</div>
			</WrapperHome>
		</main>
	);
}
