import styled, { css } from 'styled-components';
import { Body5, Button1Mixin } from './textGeneral';
import { typeButtonsPayment } from './constants/constans';

const ButtonMixin = css`
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	will-change: transform;
	width: 100%;
	border-radius: ${(props) => props.theme.border.border12};
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

const buttonActive = css`
	transition: 0.2s;

	svg {
		transition: 0.2s;
	}

	&:active {
		transform: scale(0.98);
	}
`;

const primaryStyle = css`
	${buttonActive};
	background: ${(props) => props.theme.color.primary300};
	color: ${(props) => props.theme.color.neutral900};

	svg {
		fill: ${(props) => props.theme.color.neutral900};
		width: 16px;
		height: 16px;
	}

	&:hover {
		background: ${(props) => props.theme.color.primary600};
	}

	&:disabled {
		background: ${(props) => props.theme.color.primary900};
		color: ${(props) => props.theme.color.neutral800};

		svg {
			fill: ${(props) => props.theme.color.neutral800};
		}

		&:hover {
			color: ${(props) => props.theme.color.neutral900};
			svg {
				fill: ${(props) => props.theme.color.neutral900};
			}
		}
	}
`;

export const Button = styled(({ icon, text, ...rest }) => (
	<button {...rest}>
		{text && <p>{text}</p>}
		{icon}
	</button>
))`
	${ButtonMixin}
	gap: ${({ theme }) => theme.space.spacing2X5};
	height: 39px;
	padding: 12px 32px;
	${primaryStyle};

	p {
		${Button1Mixin}
	}

	${(props) =>
		props.primary &&
		css`
			${primaryStyle}
		`}

	${({ primary }) =>
		primary === 'invert' &&
		css`
			${buttonActive};
			background: ${(props) => props.theme.color.primary305};
			color: ${(props) => props.theme.color.primary300};

			svg {
				fill: ${(props) => props.theme.color.primary300};
			}

			&:hover {
				background: ${(props) => props.theme.color.primary310};
			}

			&:disabled {
				background: ${(props) => props.theme.color.neutral605};
				color: ${(props) => props.theme.color.primary600};

				svg {
					fill: ${(props) => props.theme.color.primary600};
				}

				&:hover {
					background: ${(props) => props.theme.color.primary610};
				}
			}
		`};

	${(props) =>
		props.secondary &&
		css`
			${buttonActive};
			background: transparent;
			color: ${(props) => props.theme.color.neutral0};
			border: 1px solid ${(props) => props.theme.color.neutral0};

			svg {
				fill: ${(props) => props.theme.color.neutral0};
			}

			&:hover {
				background: ${(props) => props.theme.color.primary305};
				color: ${(props) => props.theme.color.primary300};
				border: 1px solid ${(props) => props.theme.color.primary300};

				svg {
					fill: ${(props) => props.theme.color.primary300};
				}
			}

			&:disabled {
				background: transparent;
				color: ${(props) => props.theme.color.neutral700};
				border: 1px solid ${(props) => props.theme.color.neutral600};

				svg {
					fill: ${(props) => props.theme.color.neutral700};
				}

				&:hover {
					background: ${(props) => props.theme.color.neutral5};
				}
			}
		`}

	${({ tertiary }) =>
		tertiary &&
		css`
			${buttonActive};
			background: ${(props) => props.theme.color.primary0};
			color: ${(props) => props.theme.color.neutral900};

			svg {
				fill: ${(props) => props.theme.color.neutral900};
			}

			&:hover {
				background: ${(props) => props.theme.color.primary100};
			}

			&:disabled {
				background: ${(props) => props.theme.color.neutral100};
				color: ${(props) => props.theme.color.neutral900};
				svg {
					fill: ${(props) => props.theme.color.neutral900};
				}
				&:hover {
					background: ${(props) => props.theme.color.neutra200};
				}
			}
		`};

	${({ select }) =>
		select &&
		css`
			${buttonActive};
			background: ${(props) => props.theme.color.neutral500};
			color: ${(props) => props.theme.color.neutral0};
			padding: 12px;
			height: 30px;

			svg {
				fill: ${(props) => props.theme.color.neutral900};
			}

			&:hover {
				background: ${(props) => props.theme.color.neutral600};
			}

			&:active,
			&:focus {
				background: ${(props) => props.theme.color.info500};
			}

			&:disabled {
				background: ${(props) => props.theme.color.neutral300};
				svg {
					fill: ${(props) => props.theme.color.neutral900};
				}
				&:hover {
					background: ${(props) => props.theme.color.neutra400};
				}
			}

			@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
				height: 30px;
			}

			@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
				height: 30px;
			}
		`};

	${(props) =>
		props.typebutton === typeButtonsPayment.FULLPAYMENT &&
		css`
			${buttonActive};
			background: ${({ theme }) => theme.color.success400};
			color: ${({ theme }) => theme.color.neutral900};
			max-width: 220px;

			svg {
				fill: ${({ theme }) => theme.color.neutral900};
			}

			&:hover {
				background: ${({ theme }) => theme.color.success700};
			}

			&:disabled {
				background: ${({ theme }) => theme.color.success405};
				color: ${({ theme }) => theme.color.neutral700};

				svg {
					fill: ${({ theme }) => theme.color.neutral700};
				}

				&:hover {
					color: ${({ theme }) => theme.color.neutral700};
					svg {
						fill: ${({ theme }) => theme.color.neutral700};
					}
				}
			}
		`}

	${(props) =>
		props.typebutton === typeButtonsPayment.PARTIALPAYMENT &&
		css`
			${buttonActive};
			background: ${({ theme }) => theme.color.warning800};
			color: ${({ theme }) => theme.color.neutral800};
			max-width: 220px;

			svg {
				fill: ${({ theme }) => theme.color.neutral800};
			}

			&:hover {
				background: ${({ theme }) => theme.color.warning900};
			}

			&:disabled {
				background: ${({ theme }) => theme.color.warning5};
				color: ${({ theme }) => theme.color.neutral900};

				svg {
					fill: ${({ theme }) => theme.color.neutral900};
				}

				&:hover {
					color: ${({ theme }) => theme.color.neutral900};
					svg {
						fill: ${({ theme }) => theme.color.neutral900};
					}
				}
			}
		`}

  ${(props) =>
		props.typebutton === typeButtonsPayment.NOTPAYMENT &&
		css`
			${buttonActive};
			background: ${({ theme }) => theme.color.error400};
			color: ${({ theme }) => theme.color.neutral900};
			max-width: 220px;

			svg {
				fill: ${({ theme }) => theme.color.neutral900};
			}

			&:hover {
				background: ${({ theme }) => theme.color.error800};
			}

			&:disabled {
				background: ${({ theme }) => theme.color.error435};
				color: ${({ theme }) => theme.color.neutral700};

				svg {
					fill: ${({ theme }) => theme.color.neutral700};
				}

				&:hover {
					color: ${({ theme }) => theme.color.neutral700};

					svg {
						fill: ${({ theme }) => theme.color.neutral700};
					}
				}
			}
		`};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		height: 40px;
		padding: 10px 20px;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		height: 44px;
		padding: 12px 32px;
	}
`;

export const Button2 = styled(({ icon, text, ...rest }) => (
	<button {...rest}>
		{text && <p>{text}</p>}
		{icon}
	</button>
))`
	${ButtonMixin}
	gap: ${({ theme }) => theme.space.spacing4X};
	height: 47px;
	padding: 13px 32px;
	background-color: ${({ theme }) => theme.color.neutral405};
	color: ${({ theme }) => theme.color.neutral400};
	border: 1px solid ${(props) => props.theme.color.neutral400};
	transition: all 0.2s;

	p {
		${Body5}
	}

	&:hover {
		background-color: ${({ theme }) => theme.color.primary305};
		color: ${({ theme }) => theme.color.primary300};
		border: 1px solid ${(props) => props.theme.color.primary300};
	}

	&:hover > svg {
		fill: ${({ theme }) => theme.color.primary300};
	}

	svg {
		width: 20px;
		height: 20px;
		fill: ${({ theme }) => theme.color.neutral400};
	}
`;
