import React from 'react';
import { Link } from 'react-router-dom';
import { StatisticsCardContainer } from './styles/sStatisticsCard';
import { TextBody3 } from './../../../styles/textGeneral';

export default function StatisticsCard({ image, title, link }) {
	return (
		<li>
			<Link to={link}>
				<StatisticsCardContainer>
					<div className="imageContent">{image}</div>
					<TextBody3>{title}</TextBody3>
				</StatisticsCardContainer>
			</Link>
		</li>
	);
}
