import React from 'react';
import { Preview3Container, Title, TitleClass } from './styles/sPreview3';
import { Button } from '../../../../../styles/buttonGeneral';

export const Preview3 = ({ title, urlBackground, iconButton = null, textButton, pathRedirect }) => {
	return (
		<Preview3Container
			backgroundImage={
				urlBackground ??
				'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d'
			}>
			<section>
				{title && (
					<>
						<Title>Título de clase</Title>
						<TitleClass>{title}</TitleClass>
					</>
				)}
			</section>
			{pathRedirect && <Button primary text={textButton ?? 'Continuar curso'} icon={iconButton} />}
		</Preview3Container>
	);
};
