import React from 'react';
import MainClasses from './../components/inClasses/MainClasses';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Classes = (props) => {
	const [t] = useTranslation('main');
	return (
		<>
			<Helmet>
				<title>{t('brand.classes.title')}</title>
				<meta name="description" content={t('brand.classes.description')} />
				<meta property="og:title" content={t('brand.classes.title')} />
				<meta property="og:description" content={t('brand.classes.description')} />
				<meta property="twitter:title" content={t('brand.classes.title')} />
				<meta property="twitter:description" content={t('brand.classes.description')} />
			</Helmet>
			<MainClasses {...props} />
		</>
	);
};

export default Classes;
