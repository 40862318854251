import styled from 'styled-components';

export const ContactCardContainer = styled.div`
	background: ${(props) => props.theme.color.neutral10};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	padding: 11px;
	border-radius: 50px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: 0.2s all;

	img {
		border-radius: 50px;
		width: 80px;
		height: 80px;
		margin: 0 20px 0 0;
	}

	.details {
		display: flex;
		align-items: center;
	}

	.details div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	p,
	span {
		font-weight: ${(props) => props.theme.font.weight.semiBold};
		font-family: ${(props) => props.theme.font.textFont};
	}

	p {
		font-size: 1.25em;
		text-align: left;
		margin: 0 0 7px 0;
		color: ${(props) => props.theme.color.neutral0};
	}

	span {
		font-size: 1.063em;
		color: ${(props) => props.theme.color.primary900};
	}

	svg {
		fill: ${(props) => props.theme.color.neutral0};
		width: 40px;
		height: 40px;
		margin: 0 10px 0 0;
	}

	&:hover {
		background: ${(props) => props.theme.color.neutral350};
	}

	@media (min-width: 1200px) {
		width: 400px;
		svg {
			margin: 0 10px 0 31px;
		}
	}
`;
