import React, { useLayoutEffect } from 'react';
import { Wrapper } from '../../styles/generalStyles';
import { Title1A } from '../../styles/textGeneral';
import ContactsComponents from './ContactsComponents';
import { MainContainer, TextContacts } from './styles/sMainHelpContacts';

export default function MainHelpContacts() {
	useLayoutEffect(() => {
		document.documentElement.scrollTop = 0;
	}, []);
	return (
		<main>
			<Wrapper>
				<MainContainer>
					<Title1A>Contacta con un coordinador</Title1A>
					<TextContacts>Resuelve tus dudas con una persona encargada a través del WhatsApp, llamada o Messenger.</TextContacts>
					<ContactsComponents />
				</MainContainer>
			</Wrapper>
		</main>
	);
}
