import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, arrayUnion, writeBatch, query, where, collection, getDocs, deleteField } from 'firebase/firestore';
import { recoverUserForEmail } from '../../../algorithms/recoverUserForEmail';

export const SignInWithGoogle = (db, navigate, setCurrentUser, setLoading) => {
	try {
		const provider = new GoogleAuthProvider();
		const auth = getAuth();
		auth.languageCode = 'es';
		signInWithPopup(auth, provider)
			.then(async (result) => {
				setLoading({ status: true, title: null });
				const user = await result.user;
				const dataUserRegistered = await recoverUserForEmail({ db, emailForSearch: user.email });

				const data = {
					displayName: user.displayName,
					email: user.email,
					createdAt: user.metadata.createdAt,
					lastLoginAt: user.metadata.lastLoginAt,
					photoURL: user.photoURL.replace('s96-c', 's400-c'),
					endTimestamp: dataUserRegistered ? dataUserRegistered?.subscriptionData?.endTimestamp : null,
				};
				await sendDataUserFromGoogle({
					data,
					uid: user.uid,
					db,
					setCurrentUser,
					auth,
				});
				navigate('/home', { replace: true });
				return setLoading({ status: false, title: null });
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				console.warn(errorCode, errorMessage);
				setLoading({ status: false, title: null });
			});
	} catch (err) {
		console.error(err.message);
		setLoading({ status: false, title: null });
	}
};

const sendDataUserFromGoogle = async ({ data, uid, db, setCurrentUser, auth }) => {
	try {
		const { displayName, email, createdAt, lastLoginAt, photoURL, endTimestamp } = data;
		console.log('endTimestamp', endTimestamp);
		const batch = await writeBatch(db);
		const thisUserRef = doc(db, 'users', uid);

		//referencia a la coleccion usersLogs
		const thisUserLogRef = doc(db, 'usersLogs', uid);

		const recoverQuery = await query(collection(db, 'users'), where('uid', '==', uid));

		const querySnapshot = await getDocs(recoverQuery);
		if (querySnapshot.empty) {
			batch.set(
				thisUserRef,
				{
					username: displayName,
					name: {
						firstName: '',
						lastName: '',
					},
					email,
					uid,
					photoURL,
					universityAcronym: 'UNSCH',
					unversityName: 'Universidad Nacional de San Cristóbal de Huamanga',
					subscriptionData: {
						//Le damos 15 dias a partir del momento del registro RECORDAR QUE ES TEMPORAL
						endTimestamp,
					},
					profileEdited: {
						status: false,
						sleepTime: null,
					},
				},
				{ merge: true },
			);

			//creando el documento de usersLogs
			batch.set(
				thisUserLogRef,
				{
					uid,
					username: displayName,
					metadata: {
						createdAt: parseInt(createdAt),
						listOfLogins: arrayUnion(parseInt(lastLoginAt)),
					},
				},
				{ merge: true },
			);
		} else {
			const userDoc = querySnapshot.docs[0];
			const userDocData = userDoc.data();
			if (userDocData.metadata) {
				batch.update(thisUserRef, {
					metadata: deleteField(),
				});
			}
			//creando el documento de usersLogs
			batch.set(
				thisUserLogRef,
				{
					metadata: {
						createdAt: parseInt(createdAt),
						listOfLogins: arrayUnion(parseInt(lastLoginAt)),
					},
				},
				{ merge: true },
			);
		}

		await batch
			.commit()
			.then(() => {
				setCurrentUser(auth.currentUser);
			})
			.catch((err) => {
				console.error(err.message);
			});
	} catch (err) {
		console.error(err.message);
	}
};
