import React, { useState } from 'react';
import { InputLabelResult, TextQuestionStyled, QuestionInputContainer } from '../exams/form/styles/sQuestionInput';
import MainModal from './../modal/MainModal';
import ResolutionQuizQuestion from './ResolutionQuizQuestion';
import { Latex } from '../general/latex/Latex';
import OldLatex from 'react-latex-next';

export default function QuestionInputResultQuiz({
	stateQuestion,
	question,
	keys,
	UrlOfImage,
	trueKey,
	markedKey,
	isKatex,
	uqid,
}) {
	const [modalState, setModalState] = useState(false);
	const keysList = 'abcde';

	return (
		<>
			<QuestionInputContainer>
				<TextQuestionStyled>
					{isKatex ? <Latex>{question}</Latex> : <OldLatex>{question}</OldLatex>}
					{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
				</TextQuestionStyled>
				<div className="questionContainer">
					<InputLabelResult stateQuestion={stateQuestion}>
						<span className="keyLetterQuestion">{keysList[keys.indexOf(trueKey)]}</span>
						<div className="question">
							<p>
								{isKatex ? (
									<Latex>{markedKey ? markedKey : trueKey}</Latex>
								) : (
									<OldLatex>{markedKey ? markedKey : trueKey}</OldLatex>
								)}
							</p>
							<button
								onClick={(e) => {
									e.preventDefault();
									setModalState(!modalState);
								}}>
								Ver solución
							</button>
						</div>
					</InputLabelResult>
				</div>
			</QuestionInputContainer>
			<MainModal modalState={modalState} setModalState={setModalState} title="Resolución de la pregunta" type="classic">
				<ResolutionQuizQuestion
					isKatex={isKatex}
					UrlOfImage={UrlOfImage}
					question={question}
					stateQuestion={stateQuestion}
					markedKey={markedKey}
					trueKey={trueKey}
					uqid={uqid}
				/>
			</MainModal>
		</>
	);
}
