import React from 'react';
import ItemNavbarInHome from './ItemNavbarInHome';
import { ContentsContainer, NavbarStyled } from './../styles/sNavbarInHome';
import { listItemsNavbar } from './../../listButtonsMenu';

const Navbarinhome = () => {
	return (
		<NavbarStyled>
			<ContentsContainer>
				{listItemsNavbar.map((item) => (
					<ItemNavbarInHome key={item.nameItem} {...item} />
				))}
			</ContentsContainer>
		</NavbarStyled>
	);
};

export default Navbarinhome;
