import { doc, getDoc } from 'firebase/firestore';
import { listOfCourses } from '../constants/courses';
import shuffle from '../algorithms/suffleArray';

export const recoverQuestionsPerWeek = async ({ db, week, course, setError }) => {
	try {
		const questionsRefsArray = await getDoc(doc(db, 'indices', 'questionsPerWeek', listOfCourses[course], week));
		if (questionsRefsArray.exists()) {
			let listOfQuestionsRef = [];
			if (questionsRefsArray.data().questions.length > 5) {
				let listOfDifferentNumbers = [];
				while (listOfQuestionsRef.length < 5) {
					let randomNumber = Math.floor(Math.random() * questionsRefsArray.data().questions.length);
					if (!listOfDifferentNumbers.includes(randomNumber)) {
						listOfDifferentNumbers.push(randomNumber);
						listOfQuestionsRef.push(questionsRefsArray.data().questions[randomNumber]);
					}
				}
			} else {
				listOfQuestionsRef = questionsRefsArray.data().questions;
			}

			let questionsSnapShot = await Promise.all(listOfQuestionsRef.map((ref) => getDoc(ref)));
			let questions = questionsSnapShot.map((doc) => doc.data());
			let copy_questions = JSON.parse(JSON.stringify(questions));
			let newQuestions = copy_questions.map((item) => {
				let keys = shuffle(item.keys);
				return {
					...item,
					keys,
				};
			});
			return { questions, newQuestions };
		} else {
			return { error: 404, message: '¡Oh no! Parece que el cuestionario que estás buscando no está disponible en este momento.' };
		}
	} catch (e) {
		setError(true);
		console.error(e);
	}
};
