import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Navbar = () => {
	const [t] = useTranslation('main');
	return (
		<ul>
			<Link to={'/about-us'}>
				<li>{t('aboutUs')}</li>
			</Link>
			{/* <Link to={'/promotions'}>
				<li>{t('prices')}</li>
			</Link> */}
			{/* <Link to={''}>
				<li>{t('methodology')}</li>
			</Link>
			<Link to={''}>
				<li>{t('blog')}</li>
			</Link> */}
			<Link to={'/help-center'}>
				<li>{t('help')}</li>
			</Link>
		</ul>
	);
};

export default Navbar;
