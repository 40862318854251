import styled from 'styled-components';

export const ProfileGeneralDataContainer = styled.div`
	background: linear-gradient(180deg, rgba(202, 245, 169, 0.1) 0%, rgb(93 172 219 / 10%) 100%);
	padding: 70px 0 20px 0;
	margin: 50px 0 0 0;

	svg,
	img {
		border-radius: 50%;
		margin: 0 0 15px 0;
		width: 70px;
		height: 70px;
	}

	h2,
	span {
		text-align: center;
		display: block;
	}

	h2 {
		font-weight: ${(props) => props.theme.font.weight.bold};
		font-family: ${(props) => props.theme.font.brandFont};
		font-size: 1.525em;
		margin: 0 0 10px 0;
	}

	span {
		line-height: 1.35em;
	}

	a {
		margin: 20px 0 0 0;
	}

	.userDescriptionContent {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	h5 {
		font-weight: ${(props) => props.theme.font.weight.regular};
		margin: 20px 0 0 0;
	}

	@media (min-width: 768px) {
		padding: 70px 0 40px 0;

		h2 {
			font-size: 2.125em;
		}

		span {
			line-height: 1.45em;
		}

		a {
			margin: 30px 0 0 0;
		}
	}

	@media (min-width: 1200px) {
		border-radius: 14px;
		padding: 50px 0;

		svg,
		img {
			margin: 0 40px 0 0;
			width: 120px;
			height: 120px;
			border-radius: 50%;
		}

		h2,
		span {
			text-align: start;
		}

		a {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
		}

		h5 {
			margin: 15px 0 0 0;
		}

		.userDescriptionContent {
			display: flex;
			position: relative;
			flex-direction: row;
			align-items: center;
			margin: 0 50px;
		}
	}
`;
