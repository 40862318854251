import React from 'react';
import Preview from './../landingComponents/Preview';
import ContactCard from './../cards/ContactCard';
import { ReactComponent as CallSVG } from './../../../icons/headset-1.svg';
import { ContactsContainer } from './styles/sContacts';

const contactList = [
	{
		image: require('./../../../images/staff/Arnold.webp'),
		name: 'Arnold',
		number: '+51 974 761 393',
		icon: <CallSVG />,
		link: 'https://api.whatsapp.com/send?phone=51974761393&text=%C2%A1Hola!%20quiero%20más%20información%20sobre%20EBBINER%20premium.%F0%9F%A7%A1',
	},
	{
		image: require('./../../../images/staff/Rafael.webp'),
		name: 'Rafael',
		number: '+51 917 669 029',
		icon: <CallSVG />,
		link: 'https://api.whatsapp.com/send?phone=+51917669029&text=%C2%A1Hola!%20quiero%20más%20información%20sobre%20EBBINER%20premium.%F0%9F%A7%A1',
	},
];

export default function Contacts() {
	return (
		<Preview
			title="Números de atención telefónica"
			description="Para consultas y promociones especiales"
			allText="center"
			displayD="block"
			height="auto"
			them="black">
			<ContactsContainer>
				{contactList.map((contact) => (
					<ContactCard key={contact.name} {...contact} />
				))}
			</ContactsContainer>
		</Preview>
	);
}
