import { doc, writeBatch, Timestamp } from 'firebase/firestore';

export const handleChangeAnswer = async (db, currentUser, item, uqid, simulacrum, setSimulacrum, course) => {
	let count = 0;
	try {
		await sendAnswer(db, currentUser, item, uqid, simulacrum, setSimulacrum, course);
	} catch (err) {
		console.error(err);
		//Reintento en caso de error
		if (count < 5) {
			await sendAnswer(db, currentUser, item, uqid, simulacrum, setSimulacrum, course);
		}
		count++;
	}
};

const sendAnswer = async (db, currentUser, item, uqid, simulacrum, setSimulacrum, course) => {
	const { uid } = currentUser;
	const { metadata, timers } = simulacrum;
	const batch = writeBatch(db);
	const questionRef = await doc(db, 'admissionExams', uid, 'exams', metadata.examId, 'questions', uqid);
	batch.set(
		questionRef,
		{
			markedKey: item,
			responseTime: item ? (Timestamp.now().toDate() - timers.questionStartTime) / 1000 : null,
		},
		{ merge: true },
	);

	await batch.commit().then(() => {
		const totalMarkedAnswers = simulacrum.totalMarkedAnswers;
		const numberOfChecksPerCourse = simulacrum.numberOfChecksPerCourse;
		if (item) {
			//Agregamos el uqid de totalMarkedAnswers
			const index = totalMarkedAnswers.indexOf(uqid);
			if (index === -1) {
				totalMarkedAnswers.push(uqid);
				numberOfChecksPerCourse[course] = numberOfChecksPerCourse[course] ? numberOfChecksPerCourse[course] + 1 : 1;
				setSimulacrum({
					totalMarkedAnswers,
					numberOfChecksPerCourse,
				});
			}
			console.info('Se ha guardado la respuesta');
		} else {
			//Eliminamos el uqid de totalMarkedAnswers
			const index = totalMarkedAnswers.indexOf(uqid);
			if (index > -1) {
				totalMarkedAnswers.splice(index, 1);
				numberOfChecksPerCourse[course] = numberOfChecksPerCourse[course] - 1;
				setSimulacrum({
					totalMarkedAnswers,
					numberOfChecksPerCourse,
				});
			}
			console.info('Se ha eliminado la respuesta');
		}
		setSimulacrum({
			timers: {
				questionStartTime: Timestamp.now().toDate(),
			},
		});
	});
};
