import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from './../../icons/logo-ebbiner-comp.svg';
import { LogoContainer } from './styles/sLogo';

const Logo = ({ color, height }) => {
	return (
		<Link to="/" aria-label="Ebbiner">
			<LogoContainer color={color} height={height}>
				<LogoSVG />
			</LogoContainer>
		</Link>
	);
};

export default Logo;
