import styled from 'styled-components';

export const StatisticsCardContainer = styled.button`
	background: ${(props) => props.theme.color.neutral10};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	width: 100%;
	border-radius: 16px;
	transition: all 0.2s;
	color: ${(props) => props.theme.color.neutral0};
	overflow: hidden;

	svg {
		width: 100%;
		transition: all 0.2s;
	}

	p {
		margin: 15px 0;
	}

	.imageContent {
		overflow: hidden;
		height: 130px;
	}

	&:hover {
		background: ${(props) => props.theme.color.neutral350};
	}

	&:hover {
		svg {
			transform: scale(1.1);
		}
	}
`;
