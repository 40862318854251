import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainWelcome from './../components/welcome/MainWelcome';

const Welcome = () => {
	return (
		<>
			<Helmet>
				<title>Bienvenido | Ebbiner</title>
				<meta name="description" content="Ebbiner - Educación virtual" />
				<meta property="og:title" content="Bienvenido | Ebbiner" />
				<meta property="og:description" content="Ebbiner - Educación virtual" />
				<meta property="twitter:title" content="Bienvenido | Ebbiner" />
				<meta property="twitter:description" content="Ebbiner - Educación virtual" />
			</Helmet>
			<MainWelcome />
		</>
	);
};

export default Welcome;
