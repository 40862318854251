import { arrayUnion, doc, writeBatch } from 'firebase/firestore';

export const sendReportQuestion = async ({ db, data, dataQuestionReport, uid, username }) => {
	try {
		const batch = writeBatch(db);
		const { commentOfReport } = data;
		const dataReport = {
			comment: commentOfReport,
			uid,
			username,
			date: new Date(),
		};
		const reportRef = doc(db, 'reports', dataQuestionReport?.uidQuestion);
		batch.set(
			reportRef,
			{
				subTopicId: dataQuestionReport?.subTopicId,
				uqid: dataQuestionReport?.uidQuestion,
				reportsOfQuestion: arrayUnion(dataReport),
			},
			{ merge: true },
		);
		await batch.commit();
		console.info('Sent report info successfully');
	} catch (error) {
		console.warn(err?.message);
	}
};
