import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { App } from './App';
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { createStore, compose, applyMiddleware } from 'redux';
import reducer from './reducer';
import liveSaga from './sagas/liveSaga';
import liveOneSaga from './sagas/liveOneSaga';
import { I18nextProvider } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { SkeletonTheme } from 'react-loading-skeleton';
import MainSpinner from './components/spinner/MainSpinner';
import i18next from 'i18next';
import '/node_modules/swiper/swiper-bundle.min.css';
import '/node_modules/swiper/swiper.min.css';
import '/node_modules/swiper/modules/pagination/pagination.min.css';
import '/node_modules/swiper/modules/navigation/navigation.min.css';
import 'katex/dist/katex.min.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//Import languages
import main_es from './lang/main-es.json';

//Firebase Configs
import { firebaseConfig } from './firebase/Config';
const queryClient = new QueryClient();
const composeAlt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const composedEnhancers = composeAlt(applyMiddleware(sagaMiddleware));
const store = createStore(reducer, composedEnhancers);
const container = document.getElementById('root');
const root = createRoot(container);
function* rootSaga() {
	yield spawn(liveOneSaga);
	yield spawn(liveSaga);
}

sagaMiddleware.run(rootSaga);

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'es',
	resources: {
		es: {
			main: main_es,
		},
	},
});

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<SkeletonTheme baseColor="#F4F3FA19" highlightColor="#6b7680" duration={1}>
				<FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
					<Provider store={store}>
						<I18nextProvider i18n={i18next}>
							<BrowserRouter>
								<SuspenseWithPerf
									traceId={'firebase-user-wait'}
									fallback={<MainSpinner title="Cargando tu espacio de aprendizaje..." />}>
									<HelmetProvider>
										<App />
									</HelmetProvider>
								</SuspenseWithPerf>
							</BrowserRouter>
						</I18nextProvider>
					</Provider>
				</FirebaseAppProvider>
			</SkeletonTheme>
		</QueryClientProvider>
	</React.StrictMode>,
);

reportWebVitals();
