import React from 'react';
import { ContactCardContainer } from './styles/sContactCard';
import { Link } from 'react-router-dom';

export default function ContactCard({ image, name, number, icon, link, type }) {
	const mailTo = (e) => {
		e.preventDefault();
		window.location.href = 'mailto:zbeizaga@gmail.com?Subject= Quiero%20más%20información%20de%20Servicio%20Puntual';
	};

	return (
		<>
			{type === 'email' ? (
				<Link className="center-items" to="/" target="_blank" rel="noopener noreferrer" aria-label="Ebbiner" onClick={mailTo}>
					<ContactCardContainer className="contactItem">
						<div className="details">
							<img src={image} />
							<div>
								<p>{name}</p>
								<span>{number}</span>
							</div>
						</div>
						{icon}
					</ContactCardContainer>
				</Link>
			) : (
				<a className="center-items" href={link} target="_blank" rel="noopener noreferrer" aria-label="Ebbiner">
					<ContactCardContainer className="contactItem">
						<div className="details">
							<img src={image} />
							<div>
								<p>{name}</p>
								<span>{number}</span>
							</div>
						</div>
						{icon}
					</ContactCardContainer>
				</a>
			)}
		</>
	);
}
