import { ContainerBar, Progress } from './styles/sProgressBar';

const ProgressBar = ({ heightSize = 8, completed = 0, bgColor, frColor, borderRadius = 0, margin }) => {
	return (
		<ContainerBar heightSize={heightSize} bgColor={bgColor} borderRadius={borderRadius} margin={margin}>
			<Progress completed={completed} frColor={frColor}>
				<span></span>
			</Progress>
		</ContainerBar>
	);
};

export default ProgressBar;
