import { writeBatch, doc, serverTimestamp, increment } from 'firebase/firestore';

export const saveAnswerLog = async (db, phone, dataOfUser = false, uqid) => {
	const batch = writeBatch(db);
	if (phone && phone.startsWith('51') && uqid) {
		const logDocRef = doc(db, 'whatsAppLogs', phone);
		const answerInWhatsAppLogsRef = doc(db, 'whatsAppLogs', phone, 'answers', uqid);

		if (dataOfUser?.uid) {
			batch.set(
				logDocRef,
				{
					userUid: dataOfUser?.uid,
				},
				{ merge: true },
			);
		}
		batch.set(
			logDocRef,
			{
				hasInternet: true,
			},
			{ merge: true },
		);

		batch.set(
			answerInWhatsAppLogsRef,
			{
				lastAccess: serverTimestamp(),
				countAccessAnswer: increment(1),
				uqid,
			},
			{ merge: true },
		);
	}
	batch
		.commit()
		.then(() => {
			console.info('Success');
		})
		.catch((err) => {
			console.error(err);
		});
};
