import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainStatisticSimulacrums from './../../components/statistics/MainStatisticSimulacrums';

export default function Simulacrums() {
	return (
		<>
			<Helmet>
				<title>Ebbiner | Mis simulacros</title>
				<meta name="description" content="Estadísticas de mis simulacros" />
				<meta property="og:title" content="Mis simulacros" />
				<meta property="og:description" content="Estadísticas de mis simulacros" />
				<meta property="twitter:title" content="Mis simulacros" />
				<meta property="twitter:description" content="Estadísticas de mis simulacros" />
			</Helmet>
			<MainStatisticSimulacrums />
		</>
	);
}
