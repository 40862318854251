import { onSnapshot, collection, query, orderBy } from 'firebase/firestore';

export const readMessages = async (db, live, route, setDataMessages) => {
	try {
		const messageRef = query(collection(db, 'chatRoom', `${live}-${route}`, 'messages'), orderBy('createdAt', 'asc'));
		const unsubscribe = await onSnapshot(messageRef, async (snapshot) => {
			const allData = snapshot.docs.map((doc) => {
				const entry = doc.data();
				return {
					id: doc.id,
					userUid: entry.userUid,
					message: entry.message,
					createdAt: entry.createdAt,
					username:
						entry.name?.firstName && entry.name?.lastName ? entry.name?.firstName + ' ' + entry.name?.lastName : entry.username,
					photoURL: entry.photoURL,
				};
			});
			setDataMessages(allData);
		});
		return unsubscribe;
	} catch (err) {
		console.warn(err);
	}
};
