export const classesOrganizer = ({ classes, course, latestClasses = true }) => {
	return Object.values(
		Object.entries(classes.listOfMorningClasses)
			.sort((a, b) => parseInt(a[0].slice(4)) - parseInt(b[0].slice(4)))
			.reduce((acc, classes) => {
				const listOfClasses = Object.values(classes[1]).reduce((acc, prev) => {
					const result = Object.values(prev).filter((c) => c.course === course[0].course);
					if (result.length > 0) {
						acc = result;
					}
					return acc;
				}, []);
				if (listOfClasses[0]) {
					acc[classes[0]] = {
						...listOfClasses[0],
						week: parseInt(classes[0].slice(4)),
						imageClass: course[0].imageClass,
						latestClasses,
					};
				}
				return acc;
			}, {}),
	);
};
