import { useContext } from 'react';
import { AppContext } from '../../../../App';
import { sendRecentPahOfUser } from '../../../../algorithms/sendRecentPahOfUser';
import { Body4 } from '../../../../styles/textGeneral';
import { Point, WeekClassContainer, Informationn } from './style/sWeekClass';
import { useFirestore } from 'reactfire';
import { useStatesContext } from '../../../../components/layouts/LayoutContextOfStates';

export const WeekClass = ({ title, description, pathRedirect }) => {
	const { dataOfUser } = useContext(AppContext);
	const { setLocalRecentCoursePath } = useStatesContext();

	const db = useFirestore();
	return (
		<WeekClassContainer
			isActive={window.location.pathname === pathRedirect}
			to={pathRedirect}
			onClick={async () => {
				await sendRecentPahOfUser({ db, recentPath: pathRedirect, userId: dataOfUser.uid });
				setLocalRecentCoursePath(pathRedirect);
			}}>
			<Point />
			<section>
				<Body4>{title}</Body4>
				<Informationn>{description ?? 'Cargando descripción ...'}</Informationn>
			</section>
		</WeekClassContainer>
	);
};
