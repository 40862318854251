import styled from 'styled-components';

export const ClassVideoLiveContainer = styled.div`
	position: relative;
	top: 50px;

	& iframe,
	& object,
	& embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.react-player {
		height: 0 !important;
		width: 100% !important;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
	}
`;
