import styled from 'styled-components';

export const MainContainer = styled.div`
	margin-top: 100px;

	.title-container {
		margin-left: 60px;
		margin-top: 50px;
	}

	.addTop-container {
		margin-top: 100px;
	}

	@media (max-width: 768px) {
		margin-top: 90px;
		.title-container {
			margin-left: 15px;
		}
	}
`;

export const HelloTitle = styled.h1`
	font-size: 2.125em;
	margin-bottom: 9px;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.bold};
	cursor: default;
	@media (max-width: 820px) {
		margin-top: 65px;
	}
	@media (max-width: 912px) {
		margin-top: 65px;
	}
	@media (max-width: 1024px) {
		margin-top: 65px;
	}
`;

export const SubTitle = styled.label`
	font-size: 1em;
	font-family: ${(props) => props.theme.font.brandFont};
	cursor: default;
`;

export const CardBox = styled.div`
	margin: 0 0 60px 0;
	padding: 0 60px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	justify-items: center;
	gap: 20px;

	svg {
		width: 40px;
		height: 40px;
		fill: ${(props) => props.theme.color.neutral0};
		display: flex;
	}

	@media (max-width: 768px) {
		padding: 15px;
	}
`;

export const CardAssistance = styled.div`
	display: grid;
	cursor: pointer;
	width: 100%;
	height: 185px;
	border-radius: 8px;
	padding: 25px 25px 19px 25px;
	background: ${(props) => props.theme.color.neutral2};
`;
export const TitleCard = styled.h5`
	font-family: 'Inter', sans-serif;
	font-size: 1.125em;
	font-style: normal;
	margin-top: 25px;
	font-weight: 500;
	line-height: 22px;
`;

export const TextCard = styled.p`
	margin-top: 12px;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 0.938em;
	line-height: 21px;
`;
