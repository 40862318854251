import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavbarStyled = styled.nav`
	position: fixed;
	z-index: 2000;
	bottom: 0;
	width: 100%;
	background: ${(props) => props.theme.color.neutral900};
	filter: drop-shadow(0px -2px 2px rgba(13, 27, 30, 0.25));

	@media (min-width: 1200px) {
		display: none;
	}
`;

export const ContentsContainer = styled.ul`
	height: 50px;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin: 0 15px;

	@media (min-width: 768px) {
		margin: 0 18%;
	}
`;

//Items

export const NavLinkStyled = styled(NavLink)`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${(props) => props.theme.color.neutral0};

	svg {
		fill: ${(props) => props.theme.color.neutral0};
	}

	img {
		width: 24px;
		height: 24px;
		border-radius: 50px;
	}

	span {
		font-size: 0.785em;
		font-weight: ${(props) => props.theme.font.weight.regular};
		font-family: ${(props) => props.theme.font.brandFont};
		margin: 2px 0 0 0;
	}
`;
