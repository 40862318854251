import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
	const [t] = useTranslation('main');
	return (
		<>
			<Helmet>
				<title>{t('brand.not-found.title')}</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<h1>Error 404</h1>
		</>
	);
};

export default NotFound;
