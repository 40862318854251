import { collection, doc, setDoc, serverTimestamp } from 'firebase/firestore';

export const SendContactAdviser = async (db, formInfo, setSuccess, setError) => {
	try {
		const newContactRef = doc(collection(db, 'contactAdviser'));
		const data = {
			...formInfo,
			createdAt: serverTimestamp(),
		};
		await setDoc(newContactRef, data);
		setSuccess(true);
		setError(false);
		console.info('Sent contact info successfully');
	} catch (err) {
		setError(true);
		setSuccess(false);
		console.warn(err?.message);
	}
};
