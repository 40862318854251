import { useEffect, useState } from 'react';
import {
	TextQuestionStyled,
	InputLabelResult,
	InputQuestionModalStyled,
	QuestionInputContainer,
	ModalResultContent,
} from './../exams/form/styles/sQuestionInput';
import { Latex } from '../general/latex/Latex';
import OldLatex from 'react-latex-next';
import { Text, TextSubtext4 } from '../../styles/textGeneral';
import { recoverSolutionPerQuestion } from '../../algorithms/recoverSolutionPerQuestion';
import { useFirestore } from 'reactfire';
import { Button } from '../../styles/buttonGeneral';
import { BoxComments } from '../general/answer/styles/sAnswerComponents';

export default function ResolutionQuizQuestion({ question, UrlOfImage, trueKey, isKatex, uqid, stateQuestion, markedKey }) {
	const db = useFirestore();
	const [solution, setSolution] = useState();

	useEffect(() => {
		recoverSolutionPerQuestion({ db, uqid }).then((res) => setSolution(res));
	}, []);

	if (!solution) return null;

	return (
		<InputQuestionModalStyled>
			<ModalResultContent>
				<section>
					<QuestionInputContainer inputResults noBorder>
						<InputQuestionModalStyled>
							<TextQuestionStyled>
								{isKatex ? <Latex>{question}</Latex> : <OldLatex>{question}</OldLatex>}
								{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
							</TextQuestionStyled>
						</InputQuestionModalStyled>
						{stateQuestion !== 'correct' && (
							<div className="questionContainer">
								<InputLabelResult stateQuestion={stateQuestion}>
									<div className={`question ${stateQuestion}`}>
										<p>{isKatex ? <Latex>{markedKey}</Latex> : <OldLatex>{markedKey}</OldLatex>}</p>
										<button onClick={(e) => e.preventDefault()}>Incorrecta</button>
									</div>
								</InputLabelResult>
							</div>
						)}

						<div className="questionContainer">
							<InputLabelResult stateQuestion={'correct'}>
								<div className={`question correct`}>
									<p>{isKatex ? <Latex>{trueKey}</Latex> : <OldLatex>{trueKey}</OldLatex>}</p>
									<button onClick={(e) => e.preventDefault()}>correcta</button>
								</div>
							</InputLabelResult>
						</div>

						<Text margin={'0 0 10px'}>Resolución:</Text>
						<TextQuestionStyled>
							<p>{isKatex ? <Latex>{solution?.justification}</Latex> : <OldLatex>{solution?.justification}</OldLatex>}</p>
							{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
						</TextQuestionStyled>
					</QuestionInputContainer>
				</section>
				<section>
					<Button className="button-report" tertiary text="Reportar pregunta" />
					<BoxComments>
						<TextSubtext4>¿Quieres realizar un comentario?</TextSubtext4>
						<p className="descriptionComments">
							Pronto habilitaremos los comentarios para que interactúes con los demás estudiantes de Ebbiner
						</p>
					</BoxComments>
				</section>
			</ModalResultContent>
		</InputQuestionModalStyled>
	);
}
