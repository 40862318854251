// @ts-nocheck
import { NavbarInHomeMenuContainer, UnlistNavbarInHomeMenu } from './styles/sNavbarInHomeMenu';
import Logo from './../../Logo';
import { ReactComponent as VeryUser } from './../../../../icons/very-user.svg';
// import { ReactComponent as OffersSVG } from './../../../../icons/star-1.svg';
import { ReactComponent as DoorCloseSVG } from './../../../../icons/door-close.svg';
import { ReactComponent as LogoMinSVG } from './../../../../icons/navbarHome/Ebbiner.svg';
import { ReactComponent as HelpSVG } from './../../../../icons/help.svg';
import { ReactComponent as CarretDoubleSVG } from './../../../../icons/navbarHome/caret-double-left.svg';
import ItemNavbarInHomeMenu from './ItemNavbarInHomeMenu';
import { listItemsNavbar } from './../../listButtonsMenu';
import { useLocation } from 'react-router';
import { useNavbarStore } from '../../../../store/navbarStore';
// import LogoDecide from './../../../../images/academiaDecide.png';

const lastListItemsNavbar = [
	{
		nameItem: 'Ayuda y soporte',
		icon: <HelpSVG />,
		link: '/support-help',
	},
	// {
	// 	nameItem: 'Promociones',
	// 	icon: <OffersSVG />,
	// 	link: '/offers',
	// },
	{
		nameItem: 'Comunidades',
		icon: <VeryUser />,
		link: '/social-networks',
	},
	{
		nameItem: 'Cerrar sesión',
		icon: <DoorCloseSVG />,
		link: '/',
	},
];

const Navbarinhomemenu = () => {
	const { navbarDeployed, setNavbarDeployed } = useNavbarStore();
	const location = useLocation();

	return (
		<NavbarInHomeMenuContainer navbarWith={!navbarDeployed ? '108px' : '270px'}>
			<div className="logoNavbarInHomeMenuContainer">
				{navbarDeployed ? (
					<>
						<Logo color={(props) => props.theme.color.neutral0} height="32px" />
						<div className="logoAcademia-Container">
							<img
								src={
									'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/logos%2FAcademiaDecide2.png?alt=media&token=355d1d5f-bbb8-40d2-98d1-0868e2fa83b0'
								}
								alt="Academia Decide Logo"
							/>
						</div>
					</>
				) : (
					<LogoMinSVG className="logoMin" />
				)}
				<CarretDoubleSVG
					className={navbarDeployed ? 'carretDoubleLeft' : 'carretDoubleRigth'}
					onClick={() => setNavbarDeployed(!navbarDeployed)}
				/>
			</div>
			<UnlistNavbarInHomeMenu>
				{listItemsNavbar.map((item) => (
					<ItemNavbarInHomeMenu key={item.nameItem} pathname={location.pathname} {...item} />
				))}
				{lastListItemsNavbar.map((item) => (
					<ItemNavbarInHomeMenu key={item.nameItem} pathname={location.pathname} {...item} />
				))}
			</UnlistNavbarInHomeMenu>
		</NavbarInHomeMenuContainer>
	);
};

export default Navbarinhomemenu;
