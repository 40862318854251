import {
	InputLabelResult,
	InputQuestionModalStyled,
	QuestionInputContainer,
	TextQuestionStyled,
} from './../../exams/form/styles/sQuestionInput';
/* import { Button } from './../../../styles/buttonGeneral'; */
import OldLatex from 'react-latex-next';
import { AnswerComponetsContainer, BoxComments } from './styles/sAnswerComponents';
import { Latex } from '../latex/Latex';
import { TextSubtext4, Title4 } from './../../../styles/textGeneral';

export const AnswerComponents = ({
	isKatex = true,
	// markedKey = 'Respuesta incorrecta',
	latexQuestion = 'Esto es el enunciado de la pregunta',
	justification = 'Resolución de la pregunta',
	trueKey = 'Respuesta correcta ',
	secondKey = 'Respuesta incorrecta',
	thirdKey = 'Respuesta incorrecta',
	UrlOfImage = null,
	// stateQuestion = 'incorrect',
}) => {
	return (
		<>
			<AnswerComponetsContainer>
				<section>
					<QuestionInputContainer inputResults noBorder>
						<InputQuestionModalStyled>
							<TextQuestionStyled>
								{isKatex ? <Latex>{latexQuestion}</Latex> : <OldLatex>{latexQuestion}</OldLatex>}
								{UrlOfImage && <img className="questionImage" src={UrlOfImage?.urlImage} alt={latexQuestion} />}
							</TextQuestionStyled>
						</InputQuestionModalStyled>
						{/*TODO: Uncomment this when add query params attribute alternative */}
						{/*<div className="questionContainer">*/}
						{/*	<InputLabelResult stateQuestion={stateQuestion}>*/}
						{/*		<div className={`question ${stateQuestion}`}>*/}
						{/*			<p>{isKatex ? <Latex>{markedKey}</Latex> : <OldLatex>{markedKey}</OldLatex>}</p>*/}
						{/*			<button onClick={(e) => e.preventDefault()}>Incorrecta</button>*/}
						{/*		</div>*/}
						{/*	</InputLabelResult>*/}
						{/*</div>*/}
						<div className="questionContainer">
							<InputLabelResult stateQuestion={'correct'}>
								<div className={`question correct`}>
									<p>{isKatex ? <Latex>{trueKey}</Latex> : <OldLatex>{trueKey}</OldLatex>}</p>
									<button onClick={(e) => e.preventDefault()}>Correcta</button>
								</div>
							</InputLabelResult>
						</div>
						<div className="questionContainer">
							<InputLabelResult stateQuestion={'incorrect'}>
								<div className={`question correct`}>
									<p>{isKatex ? <Latex>{secondKey}</Latex> : <OldLatex>{trueKey}</OldLatex>}</p>
									<button onClick={(e) => e.preventDefault()}>Incorrecta</button>
								</div>
							</InputLabelResult>
						</div>
						<div className="questionContainer">
							<InputLabelResult stateQuestion={'incorrect'}>
								<div className={`question correct`}>
									<p>{isKatex ? <Latex>{thirdKey}</Latex> : <OldLatex>{trueKey}</OldLatex>}</p>
									<button onClick={(e) => e.preventDefault()}>Incorrecta</button>
								</div>
							</InputLabelResult>
						</div>
						{justification && (
							<Title4 left className="resolucionSubtitle">
								Resolución
							</Title4>
						)}
						{isKatex ? <Latex>{justification}</Latex> : <OldLatex>{justification}</OldLatex>}
					</QuestionInputContainer>
					{/*<div>*/}
					{/*	<Button primary large>*/}
					{/*		Repasar teoría*/}
					{/*	</Button>*/}
					{/*</div>*/}
				</section>
				<section>
					{/* <Button className="button-report" tertiary small>
						Reportar pregunta
					</Button> */}
					<BoxComments>
						<TextSubtext4>¿Quieres realizar un comentario?</TextSubtext4>
						<p className="descriptionComments">
							Pronto habilitaremos los comentarios para que interactúes con los demás estudiantes de Ebbiner
						</p>
					</BoxComments>
				</section>
			</AnswerComponetsContainer>
		</>
	);
};
