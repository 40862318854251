import React, { useContext } from 'react';
import MainClassVideo from './../components/inClasses/classVideo/MainClassVideo';
import { useParams } from 'react-router-dom';
import { AppContext } from '../App';
import MainSpinner from '../components/spinner/MainSpinner';
import { getClassContent } from '../components/inClasses/algorithms/getClassContent';

const Classvideo = () => {
	const { dataOfClasses } = useContext(AppContext);
	const { week, course } = useParams();
	if (!dataOfClasses) {
		return <MainSpinner title={'Obteniendo lista de clases disponibles...'} />;
	}
	const dayClasses = dataOfClasses.listOfMorningClasses[week];
	const listClassesMorning = getClassContent(dayClasses, course);

	return (
		<>
			<MainClassVideo listClassesMorning={listClassesMorning} />
		</>
	);
};

export default Classvideo;
