import styled from 'styled-components';

export const TagQuantityContainer = styled.aside`
	padding: ${({ theme }) => theme.space.spacing1X} ${({ theme }) => theme.space.spacing2X};
	font-size: ${({ theme }) => theme.space.spacing3X};
	font-weight: ${({ theme }) => theme.font.weight.regular};
	background-color: ${({ theme }) => theme.color.neutral835};
	color: ${({ theme }) => theme.color.neutral0};
	border-radius: ${({ theme }) => theme.border.border12};
`;
