import styled, { css } from 'styled-components';
import { Text } from './../../../../styles/textGeneral';

export const QuestionInputContainer = styled.li`
	margin: 0;
	padding: 30px 0;
	font-family: ${(props) => props.theme.font.textFont};
	border-bottom: 1px solid ${(props) => props.noBorder || props.theme.color.neutral400};

	&:last-child {
		border-bottom: none;
	}

	input {
		appearance: none;
		visibility: hidden;
		position: absolute;
		right: 0;
	}

	input[type='radio'] + label > .keyLetterQuestion,
	input[type='radio'] + label > .question {
		transition: all 0.2s;
	}

	input[type='radio'] + label {
		transition: all 0.2s;

		&:hover > .keyLetterQuestion,
		&:hover > .question {
			border: 1px solid ${(props) => props.theme.color.primary200};
		}
	}

	input[type='radio']:checked + label > .keyLetterQuestion {
		background: ${(props) => props.theme.color.primary200};
		border: 1px solid ${(props) => props.theme.color.primary200};
		color: ${(props) => props.theme.color.neutral0};
	}

	input[type='radio']:checked + label > .question {
		border: 1px solid ${(props) => props.theme.color.primary200};
		background: ${(props) => props.theme.color.primary200 + '1a'};
	}

	.questionImage {
		width: 100%;
	}

	.questionContainer,
	label {
		display: flex;
		margin: 5px 0;
		align-items: flex-start;
	}

	.keyLetterQuestion,
	.question {
		border: 1px solid ${(props) => props.theme.color.primary200 + '33'};
		color: ${(props) => props.theme.color.neutral100};
		font-weight: ${(props) => props.theme.font.weight.light};
		line-height: 1.55em;
	}

	label {
		cursor: pointer;
		width: 100vw;
	}

	.keyLetterQuestion {
		width: 44px;
		height: 44px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 44px;
		background: ${(props) => props.theme.color.primary300 + '1a'};
		margin: 0 10px 0 0;
	}

	.question {
		padding: 12px;
		border-radius: 14px;
		width: 100%;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
	}

	img {
		margin: 10px 0;
		max-width: 400px;
	}

	.resolucionSubtitle {
		color: ${(props) => props.theme.color.primary300};
	}

	${(props) =>
		props.inputResults &&
		css`
			input[type='radio'] + label {
				&:hover > .keyLetterQuestion,
				&:hover > .question {
					border: 1px solid transparent;
				}
			}
		`}

	@media(min-width:768px) {
		.questionImage {
			width: auto;
		}
	}

	@media (min-width: 1200px) {
		margin: 0 0 35px 0;
		padding: 0 0 35px 0;

		.question {
			padding: 12px 15px;
		}
	}
`;

export const TextQuestionStyled = styled(Text)`
	font-weight: ${(props) => props.theme.font.weight.light};
	padding: 0 0 10px 0;
	line-height: 1.55em;
`;

export const InputLabelResult = styled.label`
	cursor: default !important;

	p {
		color: white;
	}

	button {
		font-size: 0.813em;
		font-weight: ${(props) => props.theme.font.weight.bold};
		display: block;
		margin: 10px 0 0 0;
		transition: all 0.2s;
		line-height: inherit;
		background: transparent;
		font-family: ${(props) => props.theme.font.textFont};
	}

	${(props) =>
		props.stateQuestion === 'correct' &&
		css`
			button {
				color: ${(props) => props.theme.color.success800};

				&:hover {
					color: ${(props) => props.theme.color.succsess600};
				}
			}

			.keyLetterQuestion {
				color: ${(props) => props.theme.color.neutral0};
				background: ${(props) => props.theme.color.success800};
				border: 1px solid ${(props) => props.theme.color.success800};
			}

			.question {
				background: ${(props) => props.theme.color.success100 + '1a'};
				border: 1px solid transparent;
			}
		`}

	${(props) =>
		props.stateQuestion === 'incorrect' &&
		css`
			button {
				color: ${(props) => props.theme.color.error400};

				&:hover {
					color: ${(props) => props.theme.color.error900};
				}
			}

			.keyLetterQuestion {
				color: ${(props) => props.theme.color.neutral0};
				background: ${(props) => props.theme.color.error400};
				border: 1px solid ${(props) => props.theme.color.error400};
			}

			.question {
				background: ${(props) => props.theme.color.error400 + '1a'};
				border: 1px solid transparent;
			}
		`}

  ${(props) =>
		props.stateQuestion === 'unaswered' &&
		css`
			button {
				color: ${(props) => props.theme.color.warning800};

				&:hover {
					color: ${(props) => props.theme.color.warning900};
				}
			}

			.keyLetterQuestion {
				color: ${(props) => props.theme.color.neutral0};
				background: ${(props) => props.theme.color.warning800};
				border: 1px solid ${(props) => props.theme.color.warning800};
			}

			.question {
				background: ${(props) => props.theme.color.warning800 + '1a'};
				border: 1px solid transparent;
			}
		`};

	@media (min-width: 1024px) {
		button {
			margin: 0 0 0 20px;
		}

		.question {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			align-items: center;
		}
	}
`;

export const InputQuestionModalStyled = styled.div`
	label {
		width: 100%;
		margin: 0;
	}

	.questionContainer {
		margin: 0 0 10px 0;
	}

	.question {
		margin: 0;
	}

	button {
		cursor: default;
	}

	.correct,
	.incorrect {
		color: ${(props) => props.theme.color.neutral900};
		font-weight: ${(props) => props.theme.font.weight.regular};
	}

	.correct {
		//background: ${(props) => props.theme.color.success100};
	}

	.incorrent {
		background: ${(props) => props.theme.color.error100};
	}
`;

export const ModalResultContent = styled.div`
	display: grid;
	grid-template-columns: 100%;
	height: 70vh;
	overflow-y: auto;
	padding: 10px;

	.button-report {
		height: 40px !important;

		&:hover {
			color: #9ea6ad;
		}
	}

	@media (min-width: 1200px) {
		grid-template-columns: 68% 1fr;
		gap: 20px;
	}
`;
