import styled, { css } from 'styled-components';

export const PaymentContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	h3 :is(strong) {
		color: ${(props) => props.theme.color.error400};
		font-size: 1.1875em;
	}
`;

export const PaymentsList = styled.section`
	display: grid;
	grid-template-columns: 1fr;
	margin: 19px auto;

	@media (min-width: 574px) {
		height: initial;
		margin: 49px auto;
		grid-template-columns: repeat(2, 1fr);
	}
`;
export const ContentPay = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 190px;
	padding: 35px;
	border: 1px solid #1a2228;
	background: #121a21;
	gap: 20px;
	cursor: pointer;

	${(props) =>
		props.radiusLeftTop &&
		css`
			border-radius: 14px 14px 0px 0px;
			@media (min-width: 574px) {
				border-radius: 14px 0px 0px 14px;
			}
		`}

	${(props) =>
		props.radiusRigthBottom &&
		css`
			border-radius: 0px 0px 14px 14px;
			@media (min-width: 574px) {
				border-radius: 0px 14px 14px 0px;
			}
		`}

	img {
		height: 80px;
		width: 100%;
		object-fit: scale-down;
	}

	@media (min-width: 574px) {
		height: 338px;
		img {
			height: 200px;
			width: 100%;
			object-fit: scale-down;
		}
	}
`;

export const ModalPay = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	row-gap: 49px;
`;

export const ContentModal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 30px;

	.yape {
		height: 80px;
		width: 100%;
		object-fit: scale-down;
	}
	.qr {
		height: 150px;
		width: 100%;
		object-fit: scale-down;
	}

	.bcp {
		height: 50px;
		width: 100%;
		object-fit: scale-down;
	}
	.interbank {
		height: 50px;
		width: 100%;
		object-fit: scale-down;
	}

	.mb20 {
		margin-bottom: 20px;
	}
`;
