import styled from 'styled-components';

export const ExpandHeaderContainer = styled.div`
	background: ${(props) => props.theme.color.neutral900};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 2000;
	backdrop-filter: blur(1000px);
	transition: 0.2s all;

	@media (min-width: 1200px) {
		background: ${(props) => (props.scroll < -50 ? '#303D53' : props.theme.color.neutral10)};
	}
`;

export const ExpandHeaderContent = styled.div`
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.onlyDesktop {
		display: none;
	}

	.menu {
		fill: ${(props) => props.theme.color.neutral0};
	}

	.close {
		fill: ${(props) => props.theme.color.neutral0};
		width: 20px;
	}

	@media (min-width: 1200px) {
		.onlyMobile {
			display: none;
		}

		.onlyDesktop {
			display: block;
		}
	}
`;
