import React, { useContext, useState } from 'react';
import { AppContext } from '../../../App';
import { Link } from 'react-router-dom';
import { Wrapper } from './../../../styles/generalStyles';
import Logo from './../Logo';
import { InLandingNavbarModal } from './InLandingNavbarModal';
import { ReactComponent as MenuSVG } from './../../../icons/menu.svg';
import { HeaderLandingContainer, HeaderLandingItems } from './styles/sHeaderLanding';
import { Button } from '../../../styles/buttonGeneral';
import Navbar from './Navbar';

const Headerlanding = ({ backgroundColor, hiddeNavBar = false }) => {
	const { dataOfUser } = useContext(AppContext);
	const [hamburger, setHamburger] = useState(false);

	return (
		<header>
			<HeaderLandingContainer backgroundColor={backgroundColor}>
				<Wrapper>
					<HeaderLandingItems>
						<Logo className="logo" />
						{!hamburger ? !hiddeNavBar && <Navbar /> : <InLandingNavbarModal hamburger={hamburger} setHamburger={setHamburger} />}
						<div className="container-btn">
							<Link to="/login">
								{/* TODO: Corregir el color del botón */}
								<Button secondary text={dataOfUser ? 'Regresar a inicio' : 'Iniciar sesión'} />
							</Link>
							<div className="container-hamburger">
								{!hamburger && <MenuSVG className="menu" onClick={() => setHamburger(!hamburger)} />}
							</div>
						</div>
					</HeaderLandingItems>
				</Wrapper>
			</HeaderLandingContainer>
		</header>
	);
};

export default Headerlanding;
