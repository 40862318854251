import styled from 'styled-components';

export const ContainerBar = styled.div`
	height: ${(props) => props?.heightSize}px;
	width: 100%;
	border-radius: ${(props) => props.borderRadius}px;
	background-color: ${(props) => props.bgColor || props.theme.color.info400};
	margin: ${(props) => props.margin};
`;

export const Progress = styled.div`
	height: 100%;
	width: ${(props) => Math.min(100, Math.max(props?.completed, 0))}%;
	background-color: ${(props) => props.frColor || props.theme.color.primary900};
	border-radius: inherit;
	text-align: right;
	transition: width 0.3s ease-in;

	span {
		padding: 5px;
	}
`;
