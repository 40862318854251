import { Body2Mixin, Headline5Mixin } from '../../../../../styles/textGeneral';

import styled from 'styled-components';

export const Section1Container = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.space.spacing4X};

	a {
		width: 20px;
		height: 20px;
	}

	svg {
		width: 20px;
		height: 20px;
		fill: ${(props) => props.theme.color.neutral0};
		margin: 0 ${({ theme }) => theme.space.spacing4X} 0 0;
	}

	h2 {
		${Body2Mixin}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		svg {
			width: 24px;
			height: 24px;
		}

		h2 {
			${Headline5Mixin}
		}
	}
`;
