import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, Timestamp, getDoc } from 'firebase/firestore';

export const createSimulacrum = (uid, db, setLoading, setSimulacrum) => {
	setLoading({ status: true, title: null });
	const functions = getFunctions();
	// Hacemos llamado a la function creadora de examen
	const createExam = httpsCallable(functions, 'createExam');
	createExam({ uid: uid })
		.then((result) => {
			if (result.data) {
				//Elminamos todos los values que sean null y nos quedamos solo con el que tenga las preguntas
				const exam = Object.values(result.data).filter((value) => value !== null)[0];
				//creamos una referencia al examen
				const examRef = doc(db, 'admissionExams', uid, 'exams', exam.examId);
				//Recuperamos la metadata
				getDoc(examRef)
					.then((examData) => {
						setSimulacrum({
							questions: exam.admissionExam,
							metadata: {
								dateNow: Timestamp.now().toDate(),
								startAt: examData.data().startAt,
								endAt: examData.data().endAt,
								examId: exam.examId,
							},
							totalMarkedAnswers: [],
							numberOfChecksPerCourse: {},
						});
						setLoading({ status: false, title: null });
					})
					.catch((err) => {
						console.warn(err);
						setLoading({ status: false, title: null });
						//actualizamos la pagina
						window.location.reload();
					});
			}
		})
		.catch((error) => {
			console.warn(error);
			setLoading({ status: false, title: null });
		});
};
