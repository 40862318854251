import styled from 'styled-components';

export const SocialMediaCardContainer = styled.button`
	display: flex;
	background: ${(props) => props.theme.color.neutral10};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	align-items: center;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	transition: all 0.2s;
	color: ${(props) => props.theme.color.neutral0};

	img {
		width: ${(props) => props.widthSvg || '30px'};
		fill: ${(props) => props.theme.color.neutral10};
		transition: all 0.2s;
	}

	p {
		text-align: initial;
		margin: 0 0 4px 0;
	}

	span {
		font-size: 0.813em;
		line-height: 0.938em;
		color: ${(props) => props.theme.color.neutral200};
		font-family: ${(props) => props.theme.font.textFont};
		text-align: initial;
		display: block;
	}

	.imageContainer {
		width: 55px;
		height: 55px;
		margin: 0 15px 0 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${(props) => (props.color.includes('#') ? props.color : '#fff')};
	}

	&:hover {
		background: ${(props) => props.theme.color.neutral350};
	}

	&:hover > .imageContainer > svg {
		transform: scale(1.1);
	}

	&:hover > .imageContainer > img {
		transform: scale(1.1);
	}
`;
