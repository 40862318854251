import React, { useContext } from 'react';
import { useFirestore } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Wrapper, RegisterAndLoginWrapper } from '../../styles/generalStyles';
import { Button } from '../../styles/buttonGeneral';
import { Text } from '../../styles/textGeneral';
import { ImageDesktopRegisterAndLogin, TransparentLR } from '../../styles/boxGeneral';
import { ReactComponent as GoogleSVG } from './../../icons/google-fill.svg';
import { MainRegisterContainer, DesktopRegisterContainer } from './styles/sMainRegister';
import loginImage from './../../images/aula-gauler.webp';

//Import ALGORITHMS
import { SignInWithGoogle } from './algorithms/SignInWithGoogle';

//Import Contexts
import { AppContext } from '../../App';

// Import Hooks

// import Logo from './../general/Logo';

const Mainlogin = () => {
	const db = useFirestore();
	const navigate = useNavigate();
	const { setCurrentUser, setLoading } = useContext(AppContext);
	const [t] = useTranslation('main');

	return (
		<main>
			<DesktopRegisterContainer>
				<ImageDesktopRegisterAndLogin>
					<TransparentLR />
					<img src={loginImage} alt="Entra en Ebbiner" />
					<p>Prepárate con una educación que transforma, prepárate con Ebbiner</p>
				</ImageDesktopRegisterAndLogin>
				<Wrapper>
					<RegisterAndLoginWrapper>
						<MainRegisterContainer>
							<div className="mainRegisterAndLogin">
								{/* <Logo height="50px" /> */}
								<div className="LogoDecideContainer">
									<img
										src="https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/logos%2FAcademiaDecide2.png?alt=media&token=355d1d5f-bbb8-40d2-98d1-0868e2fa83b0"
										alt="logo academia decide"
									/>
								</div>
								<Text className="initialLoginMessage">{t('initialLoginMessage')}</Text>
								{/* TODO: Arreglar el boton */}
								<Button
									type="button"
									className="googleButton"
									text={t('signInWithGoogle')}
									google
									icon={<GoogleSVG />}
									onClick={() => SignInWithGoogle(db, navigate, setCurrentUser, setLoading)}
								/>
							</div>
							<div className="footerRegisterAndLogin">
								<Text className="registerAndLoginFooter">"La persistencia hace la diferencia"</Text>
								{/* <Link to="/promotions"><Button tertiary>Matricularse</Button></Link> */}
							</div>
						</MainRegisterContainer>
					</RegisterAndLoginWrapper>
				</Wrapper>
			</DesktopRegisterContainer>
		</main>
	);
};

export default Mainlogin;
