export const nameFValidator = {
	maxLength: {
		value: 60,
		message: 'El nombre debe ser menor a 60 caracteres*',
	},
	pattern: {
		value: /^[a-záéíóúñ '-]+$/i,
		message: 'Nombre inválido*',
	},
	required: 'Campo obligatorio*',
};

export const phoneFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^[90]\d{8}$/,
		message: 'Número telefónico inválido*',
	},
};

//validaor de edades
export const ageFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^[0-9]{1,2}$/,
		message: 'Edad inválida*',
	},
};

export const selectorFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^(?!Seleccione)/,
		message: 'Selecciona una opción*',
	},
};
