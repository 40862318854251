import React from 'react';
import { TextQuestionStyled, InputLabelResult, InputQuestionModalStyled } from './../../exams/form/styles/sQuestionInput';
import { Latex } from '../latex/Latex';
import OldLatex from 'react-latex-next';

export default function ResolucionQuestion({ stateQuestion, question, UrlOfImage, trueKey, markedKey, isKatex }) {
	return (
		<InputQuestionModalStyled>
			<li>
				<TextQuestionStyled>
					{isKatex ? <Latex>{question}</Latex> : <OldLatex>{question}</OldLatex>}
					{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
				</TextQuestionStyled>
				<div className="questionContainer">
					<InputLabelResult stateQuestion={stateQuestion}>
						<div className={`question ${stateQuestion}`}>
							<p>{isKatex ? <Latex>{markedKey}</Latex> : <OldLatex>{markedKey}</OldLatex>}</p>
							<button onClick={(e) => e.preventDefault()}>Incorrecta</button>
						</div>
					</InputLabelResult>
				</div>
				{/* Solo si la pregunta fue incorrecta o no respondida */}
				<div className="questionContainer">
					<InputLabelResult stateQuestion={'correct'}>
						<div className={`question correct`}>
							<p>{isKatex ? <Latex>{trueKey}</Latex> : <OldLatex>{trueKey}</OldLatex>}</p>
							<button onClick={(e) => e.preventDefault()}>Correcta</button>
						</div>
					</InputLabelResult>
				</div>
			</li>
		</InputQuestionModalStyled>
	);
}
