import styled from 'styled-components';

export const BooksOfAcademyContainer = styled.ul`
	margin: 10px 0 0 0;

	li {
		margin: 0 0 15px 0;

		&:nth-child(2) {
			margin: 0;
		}
	}
`;
