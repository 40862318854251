import React from 'react';
import { BoxCard } from '../../../styles/boxGeneral';
import { WrapperHome } from '../../../styles/generalStyles';
import { Title4, Text, Title6 } from '../../../styles/textGeneral';
import { SocialMediaCardsUnlist } from '../../home/styles/sMainHome';
import SocialMediaCard from '../cards/SocialMediaCard';
import { generalGroupLink } from './socialMediaLinks/linksComunities';

export const SocialMediaContain = () => {
	// const [t] = useTranslation("main");

	return (
		<main>
			<WrapperHome>
				<div>
					<Title4>{'Nuestras comunidades'}</Title4>
					<Text centerD>{'¡Accede a nuestras distintas comunidades y sigue aprendiendo!'}</Text>
				</div>
				<BoxCard>
					<Title6>{'Grupos generales'}</Title6>
					<SocialMediaCardsUnlist>
						{generalGroupLink.map((item, index) => (
							<SocialMediaCard
								key={index}
								image={item.image}
								title={item.title}
								description={item.description}
								widthSvg={item.widthSvg}
								color={item.color}
								url={item.url}
							/>
						))}
					</SocialMediaCardsUnlist>
				</BoxCard>
			</WrapperHome>
		</main>
	);
};
