import { useState } from 'react';
import { Wrapper } from '../../styles/generalStyles';
import {
	InfoContent,
	ListItem,
	MainContainer,
	PrincipalContactContainer,
	TextBackground,
	Items,
	ContentImage,
	TextBrand,
	ContentTextBrand,
} from './styles/sMainContact';
import { ReactComponent as CheckCircleSVG } from './../../../src/icons/check-circle.svg';
import { Button } from '../../styles/buttonGeneral';
import RocketSpace from './iconComponent/RocketSpace';
import MainModal from '../modal/MainModal';
import ModalContact from './ModalContact';
import GradientCircle from '../../shared/GradientCircle/GradienCircle';
import MainCloud from '../../shared/Clouds/Cloud';
import { SecondaryTitle, TertiaryTitle, TextBody4, TextSubtitle2 } from '../../styles/textGeneral';

const listItems = [
	{ name: 'Mejorar tu rendimiento académico' },
	{ name: 'Aumentar tu confianza y motivación' },
	{ name: 'Identificar tus fortalezas y debilidades' },
	{ name: 'Desarrollar un plan de acción para alcanzar tus objetivos' },
];

const MainContact = () => {
	const [modalState, setModalState] = useState(false);

	const ContentBrand = (
		<ContentImage>
			<RocketSpace />
			<ContentTextBrand>
				<TextBrand>CÁPSULAS DE</TextBrand>
				<TextBrand>CONOCIMIENTO</TextBrand>
				<TextBrand>ILIMITADO</TextBrand>
			</ContentTextBrand>
		</ContentImage>
	);
	return (
		<PrincipalContactContainer>
			<Wrapper>
				<MainContainer>
					<InfoContent>
						<SecondaryTitle white mb10>
							Presentamos
						</SecondaryTitle>
						<TertiaryTitle white mb25>
							¡LA <TextBackground>RUTA</TextBackground> PARA SIMPLIFICAR TÚ INGRESO{' '}
							<TextBackground>A LA UNIVERSIDAD</TextBackground>!
						</TertiaryTitle>
						<TextBody4 white mb25>
							Mejora tus habilidades y destaca en el proceso de postulación a la universidad.
						</TextBody4>
						<TextBody4 white mb25>
							La Master Class para que puedas experimentar el método CCI, una técnica comprobada, para alcanzar tus objetivos
							académicos y profesionales.
						</TextBody4>
						<Items mb30>
							{listItems.map(({ name }) => (
								<ListItem key={name}>
									<CheckCircleSVG />
									<TextBody4>{name}</TextBody4>
								</ListItem>
							))}
						</Items>
						<TextSubtitle2 white>MASTER CLASS GRATUITA</TextSubtitle2>
						<Button primary text={'Inscríbete ahora'} type="button" onClick={() => setModalState(!modalState)} />
					</InfoContent>
					<GradientCircle>{ContentBrand}</GradientCircle>
				</MainContainer>
				{modalState && (
					<MainModal
						modalState={modalState}
						setModalState={setModalState}
						type={'classic'}
						isMoreLater={false}
						overlayStyles={{ background: 'rgba(6, 14, 24, 0.8)' }}>
						<ModalContact />
					</MainModal>
				)}
			</Wrapper>
			<MainCloud />
		</PrincipalContactContainer>
	);
};

export default MainContact;
