import axiosInstance from './config';

export const getLives = () => axiosInstance.get('/lives');
export const getOneLive = (live, route) =>
	axiosInstance.get(`/lives?filters[$or][0][route][$eq]=${route}&filters[$or][0][uid][$eq]=${live}`);

export const getLivesInfo = () => {
	return getLives().then((res) => {
		return res.data.data;
	});
};

export const getOneLivesInfo = (payload) => {
	const { live, route } = payload;
	return getOneLive(live, route).then((res) => {
		return res.data.data;
	});
};
