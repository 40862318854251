import styled from 'styled-components';

export const QuestionReportContainer = styled.form`
	display: flex;
	flex-direction: column;
	padding: 0 10px;
	max-width: 800px;
	font-family: ${(props) => props.theme.font.textFont};
	text-align: center;
	line-height: 1.5em;
	font-size: 0.938em;

	textarea {
		height: 100px;
		margin-bottom: 15px;
	}

	p {
		text-align: justify;
	}

	label {
		align-self: flex-start;
	}

	button {
		border-radius: 15px;
		height: 35px;
	}

	.buttons-Content {
		display: flex;
		justify-content: flex-end;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 0 0 auto;
		width: 100%;
		gap: 5px;

		button {
			border-radius: 15px;
			width: 45%;
		}
	}

	@media (min-width: 700px) {
		.buttons-Content {
			display: flex;
			width: 70%;
			flex-direction: row;
			flex-wrap: wrap;
			button {
				width: 45%;
			}
		}
	}
	@media (min-width: 1200px) {
		height: auto;
		width: 656px;
		padding: 0 30px;

		textarea {
			margin-bottom: 15px;
		}

		.buttons-Content {
			display: flex;
			justify-content: flex-end;
			width: 300px;
			margin: 0 0 0 auto;
			gap: 15px;
		}
	}
`;
