import { useContext } from 'react';
import { AppContext } from '../App';
import { useLocation, Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

export function ProtectedRoute({ children, redirectTo }) {
	const location = useLocation();
	const { currentUser } = getAuth();
	const { dataOfUser } = useContext(AppContext);
	if (location.pathname === '/' || location.pathname === '/login' || location.pathname === '/register') {
		return currentUser && dataOfUser ? <Navigate to={location.state?.from ?? '/home'} replace /> : children;
	}

	return currentUser && dataOfUser ? (
		children
	) : (
		<Navigate to={redirectTo || '/login'} state={{ from: location.pathname }} replace />
	);
}
