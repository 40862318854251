import styled from 'styled-components';

export const MainContainer = styled.div`
	margin-top: 132px;

	h1 {
		margin-bottom: 20px;
		text-align: center;
	}
`;

export const TextContacts = styled.p`
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 400;
	font-size: 1em;
	line-height: 21px;
	text-align: center;

	color: #c3cbd7;
`;
