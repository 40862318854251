import React, { createContext, useContext, useEffect, useState } from 'react';
import { recoversimulacrumStatistics } from '../profile/algorithms/recoverSimulacrumStatistics';
import { AppContext } from '../../App';
import { useFirestore } from 'reactfire';
import { useQuery } from '@tanstack/react-query';

const LayoutContext = createContext();
export const useStatesContext = () => {
	return useContext(LayoutContext);
};

export const LayoutStatesContextProvider = ({ children }) => {
	const { dataOfUser } = useContext(AppContext);
	const db = useFirestore();
	const [stats, setStats] = useState(false);
	const [localRecentCoursePath, setLocalRecentCoursePath] = useState(null);
	const { isPending: IsPendingStats, data: dataStats } = useQuery({
		queryKey: ['stats'],
		queryFn: () => recoversimulacrumStatistics(dataOfUser, db),
	});

	useEffect(() => {
		if (!stats && !IsPendingStats && dataStats) {
			setStats(dataStats);
		}
	}, [IsPendingStats]);

	useEffect(() => {
		if (dataOfUser && dataOfUser?.recentPath) {
			setLocalRecentCoursePath(dataOfUser.recentPath);
		}
	}, [dataOfUser]);

	const contextValues = {
		// Define tus valores de contexto aquí
		stats,
		localRecentCoursePath,
		setLocalRecentCoursePath,
	};

	return <LayoutContext.Provider value={contextValues}>{children}</LayoutContext.Provider>;
};
