import React from 'react';
import MainHelpContacts from '../components/helpContacts/MainHelpContacts';
import { Helmet } from 'react-helmet-async';

export default function HelpContacts() {
	return (
		<>
			<Helmet>
				<title>Contactos | Ebbiner</title>
				<meta name="description" content="Contactos de Ayuda" />
				<meta property="og:title" content="Contactos | Ebbiner" />
				<meta property="og:description" content="Contactos de Ayuda" />
				<meta property="twitter:title" content="Contactos | Ebbiner" />
				<meta property="twitter:description" content="Contactos de Ayuda" />
			</Helmet>
			<MainHelpContacts />
		</>
	);
}
