import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { ReactComponent as ChartSVG } from './../../icons/chart.svg';
import { ReactComponent as ExclamationSVG } from './../../icons/exclamation.svg';
import { StatisticGeneralDataContainer } from './styles/sStatisticGeneralData';

import { useStatesContext } from '../layouts/LayoutContextOfStates';

export default function StatisticGeneralData({ type }) {
	const [simulacrumScoresData, setSimulacrumScoresData] = useState([]);
	const { dataOfUser } = useContext(AppContext);
	const navigate = useNavigate();
	// const [setLoading] = useState({ status: false, title: null });
	const { stats } = useStatesContext();

	useEffect(() => {
		changeTypeStatistic(type);
	}, [stats]);

	useEffect(() => {
		// setLoading({ status: true, title: null });
		if (!dataOfUser) {
			navigate('/');
		}
		// setLoading({ status: false, title: null });
	}, [dataOfUser]);

	const changeTypeStatistic = (type) => {
		type === 'simulacrumExamnsRecord' &&
			setSimulacrumScoresData({
				icon: <ChartSVG />,
				color: 'skyblue',
				title: 'Promedio de exámenes simulacro',
				score: stats?.averageStatistics ? stats?.averageStatistics?.totalScoreAverage.toFixed(3) : 0,
				details: 'El promedio final se obtiene a partir del puntaje de todos los simulacros resueltos.',
			});
	};

	return (
		<StatisticGeneralDataContainer color={simulacrumScoresData.color}>
			<div className="generalDataContainer">
				<div className="iconContainer">{simulacrumScoresData.icon}</div>
				<div className="detailsData">
					<p>{simulacrumScoresData.title}</p>
					<span>{simulacrumScoresData.score}</span>
				</div>
			</div>
			<div className="detailsStatisticContainer">
				<ExclamationSVG />
				<p>{simulacrumScoresData.details}</p>
			</div>
		</StatisticGeneralDataContainer>
	);
}
