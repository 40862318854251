import React from 'react';
import MainMaterials from './../components/materials/MainMaterials';

const Materials = () => {
	return (
		<>
			<MainMaterials />
		</>
	);
};

export default Materials;
