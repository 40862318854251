import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
export const recoverLastExams = async (db, currentUser, solvedExams, setSolvedExams, setLoading) => {
	const listOfUnschExamCourses = [
		'verbalReasoning',
		'mathematicalReasoning',
		'algebra',
		'arithmetic',
		'trigonometry',
		'geometry',
		'physics',
		'chemistry',
		'literature',
		'language',
		'civic',
		'universalHistory',
		'historyOfPeru',
		'biology',
		'humanAnatomy',
		'geography',
		'economy',
		'topicality',
	];
	setLoading(true);

	try {
		const exams = collection(db, 'admissionExams', currentUser.uid, 'exams');
		//pedimos los ultimos 3 examenes medidos por endTime
		const q = await query(exams, orderBy('endTime', 'desc'), limit(3));
		// hacemos un getDocs de los examenes
		const recoveredQuestions = await getDocs(q);
		// recorremos los examenes
		await recoveredQuestions.docs.forEach(async (doc) => {
			// si el examen no esta en el estado de solvedExams
			if (!Object.keys(solvedExams).includes(doc.id)) {
				// agregamos el examen a la lista de examenes resueltos
				const exam = await getDocs(
					query(collection(db, 'admissionExams', currentUser.uid, 'exams', doc.id, 'questions'), orderBy('course', 'desc')),
				);
				const questions = exam.docs.map((doc) => {
					return doc.data();
				});
				//ordenamos questions conforme a la lista de cursos establecida en listOfUnschExamCourses
				const orderedQuestions = questions.sort((a, b) => {
					const indexA = listOfUnschExamCourses.indexOf(a.course);
					const indexB = listOfUnschExamCourses.indexOf(b.course);
					return indexA - indexB;
				});
				// creamos un array con arrays de clasificados por courses
				const categorizedQuestions = orderedQuestions.reduce((previousValue, currentValue) => {
					const index = listOfUnschExamCourses.indexOf(currentValue.course);
					//Si no existe el index en el array, lo creamos
					if (index === -1) {
						return previousValue;
					}
					//Si existe el index en el array, lo agregamos al array
					if (!previousValue[index]) {
						previousValue[index] = [];
					}
					//Agregamos el currentValue al array
					previousValue[index].push(currentValue);
					return previousValue;
				}, []);
				//agregamos el examen a la lista de examenes resueltos
				setSolvedExams({
					[doc.id]: {
						categorizedQuestions,
						metadata: doc.data(),
						id: doc.id,
						numberOfQuestions: questions.length,
					},
				});
			}
		});
		setLoading(false);
	} catch (err) {
		console.warn(err?.message);
		setLoading(false);
	}
};
