import React from 'react';
import { StatisticCardContainer } from './styles/sStatisticCard';
import { Button } from './../../../styles/buttonGeneral';
import { Link } from 'react-router-dom';

export default function StatisticCard({ icon, color, number, name, button, padding }) {
	return (
		<>
			<li>
				<StatisticCardContainer button={button} padding={padding} color={color}>
					<div className="iconContainer">{icon}</div>
					<p>{name}</p>
					<span>{number}</span>
					<Link to="/statistic/simulacrums/score">
						{/* TODO: Corregir el color del botón */}
						<Button primary text={button} />
					</Link>
				</StatisticCardContainer>
			</li>
		</>
	);
}
