import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useFirestore } from 'reactfire';
import { WrapperHome } from '../../styles/generalStyles';
import { Title4, Title5, Text } from '../../styles/textGeneral';
import { Button } from '../../styles/buttonGeneral';
import { useTranslation } from 'react-i18next';
import MainSpinner from '../spinner/MainSpinner';
import { ExamnsTypeUnlist } from './styles/sMainExams';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as MonoSTA } from './../../images/sta-monochromatic.svg';
// import { ReactComponent as MonoEP } from "./../../images/ep-monochromatic.svg";

import { ReactComponent as FavoriteSVG } from './../../icons/favorite.svg';
import { BoxCard } from '../../styles/boxGeneral';
import Preview from './../general/landingComponents/Preview';

//Import Contexts
import { AppContext } from '../../App';

//Import ALGORITHMS
import { createSimulacrum } from './algorithms/createSimulacrum';
import { loadSimulacrum } from './algorithms/loadSimulacrum';
import { loadWeekExam } from './algorithms/loadWeekExam';
import CardTypeExam from './CardTypeExam';
import CourseCard from '../general/cards/CourseCard';
import { CourseCardsUnlist } from '../home/styles/sMainHome';
const MainExams = () => {
	const db = useFirestore();
	const [t] = useTranslation('main');
	const { setSimulacrum, simulacrum, weekExam, setWeekExam, currentUser, subscriptionTime, week } = useContext(AppContext);
	const [loading, setLoading] = useState({ status: true, title: null });
	const [launchBanner, setLaunchBanner] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		let ac = null;
		setLoading({ status: true, title: null });
		async () => {
			if (subscriptionTime.timeRemaining > 0 && !simulacrum.questions) {
				ac = new AbortController();
				await loadSimulacrum(db, currentUser.uid, setSimulacrum);
			} else if (!weekExam.questions) {
				ac = new AbortController();
				await loadWeekExam(db, currentUser.uid, setWeekExam);
			}
		};

		//Quita la carga si existe o no un simulacro disponible
		setLoading({ status: false, title: null });
		return () => {
			ac && ac.abort();
		};
	}, [currentUser, db, simulacrum, weekExam]);

	useEffect(() => {}, [launchBanner]);

	if (loading.status) return <MainSpinner title={'Cada día mejoramos para ti...'} />;

	//Bloqueo de examen si no hay subscription
	if (launchBanner) {
		return (
			<Preview
				title="Disponible con Ebbiner Premium"
				image={require('./../../images/preview-2.webp')}
				background={require('./../../images/b-block-examns.webp')}
				description="Accede a los Simulacros tipo admisión con una membresia mensual."
				them="black"
				type="inPromo">
				<ul>
					<li>
						<Text>Simulacros ilimitados.</Text>
					</li>
					<li>
						<Text>Todas las preguntas son resueltas inmediatamente después de culminado el simulacro.</Text>
					</li>
					<li>
						<Text>Mide tu nivel de progreso con cada simulacro.</Text>
					</li>
				</ul>
				<Link to="/promotions">
					<Button primary text="Comprar ahora" />
				</Link>
			</Preview>
		);
	}

	return simulacrum?.questions?.length > 0 || weekExam?.questions?.length > 0 ? (
		<Navigate to="/exams/form" replace={true} />
	) : (
		<main>
			<Helmet>
				<title>{t('brand.exams.title')}</title>
				<meta name="description" content={t('brand.exams.description')} />
				<meta property="og:title" content={t('brand.exams.title')} />
				<meta property="og:description" content={t('brand.exams.description')} />
				<meta property="twitter:title" content={t('brand.exams.title')} />
				<meta property="twitter:description" content={t('brand.exams.description')} />
			</Helmet>
			<WrapperHome>
				<div>
					<Title4>{t('exams.examsTitle')}</Title4>
					<Text centerD>{t('exams.examsDescription')}</Text>
				</div>
				<div>
					<Title5>{t('exams.examsTypeAdmision')}</Title5>
					<BoxCard>
						<ExamnsTypeUnlist>
							<li
								onClick={() => {
									subscriptionTime.timeRemaining > 0
										? createSimulacrum(currentUser.uid, db, setLoading, setSimulacrum)
										: setLaunchBanner(true);
								}}>
								<CardTypeExam disabled={!week} image={<MonoSTA />} title="Simulacro tipo admisión" />
							</li>
							{/* <li
                onClick={() =>
                  week
                    ? createWeekExam(
                        currentUser.uid,
                        db,
                        setLoading,
                        setWeekExam,
                        week
                      )
                    : undefined
                }
              >
                <CardTypeExam
                  disabled={!!week}
                  image={<MonoEP />}
                  title={`Examen de posicionamiento semanal ${
                    !week ? "(No disponible)" : ""
                  }`}
                />
              </li> */}
						</ExamnsTypeUnlist>
					</BoxCard>
					<CourseCardsUnlist>
						<BoxCard>
							<Link to="/statistic/simulacrums/score">
								<CourseCard
									image={<FavoriteSVG />}
									title="Revisa tus notas"
									description="De todos los simulacros"
									color="yellow"
								/>
							</Link>
						</BoxCard>
					</CourseCardsUnlist>
				</div>
				{/*<div>*/}
				{/*	<Title5>{t('exams.examnsPerCourse')}</Title5>*/}
				{/*	<Text>{t('exams.examnsPerCourseDesc')}</Text>*/}
				{/*</div>*/}
				{/*<div>*/}
				{/*	<Title5>{t('exams.examsCompetition')}</Title5>*/}
				{/*	<Text>{t('exams.examsCompetitionDesc')}</Text>*/}
				{/*</div>*/}
			</WrapperHome>
		</main>
	);
};
export default MainExams;
