import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoSVG } from './../../icons/logo-ebbiner-comp.svg';
import { LogoExpandContainer } from './styles/sLogoExpand';
import { TextBody3 } from './../../styles/textGeneral';

export default function LogoExpand({ title }) {
	return (
		<Link to="/" aria-label="Ebbiner">
			<LogoExpandContainer>
				<LogoSVG />
				<div className="separator"></div>
				<TextBody3>{title}</TextBody3>
			</LogoExpandContainer>
		</Link>
	);
}
