import * as yup from 'yup';

const phoneFValidator = /^[90]\d{8}$/;

export const schemaContactValidator = yup.object().shape({
	name: yup.string().max(60, 'El nombre debe ser menor a 60 caracteres*').required('Campo obligatorio*'),
	phone: yup.string().matches(phoneFValidator, 'Número telefónico inválido*').required('Campo obligatorio*'),
	email: yup.string().email('Correo electrónico inválido*').required('Campo obligatorio*'),
	accept_call: yup.boolean(),
});
