import { FETCH_LIVE, SET_LIVE, FETCH_ONE_LIVES, SET_ONE_LIVE } from './type';

export const setLive = (payload) => ({
	type: SET_LIVE,
	payload,
});

export const fetchLive = () => ({
	type: FETCH_LIVE,
});

export const setOneLive = (payload) => ({
	type: SET_ONE_LIVE,
	payload,
});

export const fetchOneLive = (payload) => ({
	type: FETCH_ONE_LIVES,
	payload,
});
