import styled from 'styled-components';

export const SharedWithFriendsContainer = styled.aside`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	min-width: 75px;
	max-width: 75px;
	height: 75px;
	background-color: ${({ theme }) => theme.color.neutral10};
	text-align: center;
	font-size: 11px;
	cursor: pointer;

	p {
		color: ${({ theme }) => theme.color.neutral400};
	}

	:hover {
		background-color: ${({ theme }) => theme.color.neutral5};
		p {
			color: ${({ theme }) => theme.color.primary300};
		}
	}
`;
