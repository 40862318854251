import { doc, getDoc } from 'firebase/firestore';

export const recoverCourses = async (db) => {
	const coursesRef = doc(db, 'classes', 'listRecordedCourses');
	const coursesSnap = await getDoc(coursesRef);
	if (coursesSnap.exists()) {
		return Object.values(coursesSnap.data().listRecordedCourses).reduce((acc, course) => {
			if (!acc[course.generalTopic]) {
				acc[course.generalTopic] = [course];
			} else {
				acc[course.generalTopic].push(course);
			}
			return acc;
		}, {});
	}
};
