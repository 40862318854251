import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';
import { useReducedState } from '../../hooks/useReducedState';
import { ReactComponent as UserGauler } from './../../icons/user-gauler.svg';
import { WrapperHome } from './../../styles/generalStyles';
import { Button } from './../../styles/buttonGeneral';
import { Title5 } from './../../styles/textGeneral';
import { ErrorText } from './styles/sMainProfileEdit';

//Import firebase utils
import { useFirestore } from 'reactfire';

import {
	nameFValidator,
	phoneFValidator,
	DNIFValidator,
	nameFValidatorAttorney,
	phoneFValidatorAttorney,
} from './formValidators.js';

import { InputContainer, FormContainer } from './../../styles/inputGeneral';
import { ProfileEditContainer, ProfileEditInformation } from './styles/sMainProfileEdit';

//Import contexts
import { AppContext } from '../../App';

//Import ALGORITHMS
import { profileInformationUpdate } from './algorithms/profileInformationUpdate';

const Maineditprofile = () => {
	const db = useFirestore();
	const navigate = useNavigate();
	const { dataOfUser, universities, currentUser, setDataOfUser } = useContext(AppContext);
	const [universityState, setUniversityState] = useReducedState({
		universityName: dataOfUser.university?.length > 0 ? dataOfUser.university : null,
		universityAcronym: dataOfUser.universityAcronym?.length > 0 ? dataOfUser.universityAcronym : null,
		school: dataOfUser.school?.length > 0 ? dataOfUser.school : null,
	});

	//Recuperacion de datos de usuario
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			firstName: dataOfUser.name.firstName?.length > 0 ? dataOfUser.name.firstName : '',
			lastName: dataOfUser.name.lastName?.length > 0 ? dataOfUser.name.lastName : '',
			gender: dataOfUser.gender?.length > 0 ? dataOfUser.gender : '',
			idCard: dataOfUser.idCard?.length > 0 ? dataOfUser.idCard : '',
			university: dataOfUser.university?.length > 0 ? dataOfUser.university : '',
			school: dataOfUser.school?.length > 0 ? dataOfUser.school : '',
			nameAttorney: dataOfUser.nameAttorney?.firstName?.length > 0 ? dataOfUser.nameAttorney.firstName : '',
			lastNameAttorney: dataOfUser.nameAttorney?.lastName?.length > 0 ? dataOfUser.nameAttorney.lastNameAttorney : '',
			phoneNumberAttorney: dataOfUser.phoneNumberAttorney?.length > 0 ? dataOfUser.phoneNumberAttorney : '',
		},
	});

	//TODO: Uncomment if is necessary
	/*const dataBackup = {
		uid: dataOfUser.uid,
		gender: dataOfUser?.gender,
		idCard: dataOfUser?.idCard,
		name: {
			firstName: dataOfUser.name?.firstName,
			lastName: dataOfUser.name?.lastName,
		},
		phoneNumber: dataOfUser?.phoneNumber,
		nameAttorney: {
			firstName: dataOfUser?.nameAttorney?.firstName,
			lastName: dataOfUser?.nameAttorney?.lastName,
		},
		phoneNumberAttorney: dataOfUser?.phoneNumberAttorney,
		university: dataOfUser?.university,
		school: dataOfUser?.school,
		universityAcronym: dataOfUser?.universityAcronym,
		username: dataOfUser?.username,
		email: dataOfUser?.email,
		subscriptionData: {
			endTimestamp: dataOfUser?.subscriptionData?.endTimestamp,
		},
		profileEdited: {
			status: dataOfUser?.profileEdited?.status || false,
			sleepTime: dataOfUser?.profileEdited?.sleepTime ? 0 : 0,
		},
	};*/

	useEffect(() => {}, [dataOfUser, universities]);

	const finalSubmit = async (data) => {
		const {
			firstName,
			lastName,
			idCard,
			phoneNumber,
			university,
			school,
			nameAttorney,
			lastNameAttorney,
			phoneNumberAttorney,
			gender,
		} = data;

		const user = {
			name: {
				firstName,
				lastName,
			},
			idCard,
			gender,
			phoneNumber,
			university,
			school: school.length > 0 ? school : universityState.school,
			nameAttorney: {
				firstName: nameAttorney,
				lastName: lastNameAttorney,
			},
			phoneNumberAttorney,
			universityAcronym: universityState.universityAcronym,
			profileEdited: {
				status: true,
			},
		};
		await profileInformationUpdate(db, dataOfUser, user, navigate, setDataOfUser);
	};

	return (
		<main>
			<WrapperHome>
				<ProfileEditContainer>
					<div className="userProfileDescription">
						{dataOfUser?.photoURL ? (
							<img src={dataOfUser.photoURL} alt="Foto de perfil"></img>
						) : currentUser?.photoURL ? (
							<img src={currentUser.photoURL.replace('s96-c, s120-c')} alt="Foto de perfil"></img>
						) : (
							<UserGauler />
						)}
						<div>
							<h1>
								{dataOfUser.name?.firstName !== ''
									? `${dataOfUser.name?.firstName} ${dataOfUser.name?.lastName}`
									: dataOfUser.username}
							</h1>
							{/* <span>Ciclo verano 2022</span> */}
						</div>
					</div>
					<ProfileEditInformation>
						<div className="profileEditButtonContainer">{/* <Button quaternary>Subir foto de perfil</Button> */}</div>
						<FormContainer onSubmit={handleSubmit(finalSubmit)}>
							<div>
								<Title5>Datos personales</Title5>
								<div className="inputContainerDuplo">
									<InputContainer>
										<label>Nombres</label>
										<input defaultValue={dataOfUser?.name.firstName} type={'text'} {...register('firstName', nameFValidator)} />
										{errors.firstName && <ErrorText>{errors.firstName.message}</ErrorText>}
									</InputContainer>
									<InputContainer>
										<label>Apellidos</label>
										<input defaultValue={dataOfUser?.name.lastName} type={'text'} {...register('lastName', nameFValidator)} />
										{errors.lastName && <ErrorText>{errors.lastName.message}</ErrorText>}
									</InputContainer>
								</div>
								<div className="inputContainerDuplo">
									<InputContainer>
										<label>Sexo</label>
										<div className="inputsRadioContainer">
											<label className="inputRadioContainer inputType">
												<input
													defaultChecked={dataOfUser?.gender === 'female' ? true : false}
													type="radio"
													name="sexo"
													value="female"
													{...register('gender')}
												/>{' '}
												Mujer
											</label>
											<label className="inputRadioContainer inputType">
												<input
													defaultChecked={dataOfUser?.gender === 'male' ? true : false}
													type="radio"
													name="sexo"
													value="male"
													{...register('gender')}
												/>{' '}
												Varón
											</label>
											<label className="inputRadioContainer inputType">
												<input
													defaultChecked={dataOfUser?.gender === 'no binary' ? true : false}
													type="radio"
													name="sexo"
													value="no binary"
													{...register('gender')}
												/>
												No binario
											</label>
										</div>
									</InputContainer>
								</div>
								<div className="inputContainerDuplo">
									<InputContainer>
										<label>DNI</label>
										<input type={'text'} {...register('idCard', DNIFValidator)} />
										{errors.idCard && <ErrorText>{errors.idCard.message}</ErrorText>}
									</InputContainer>
									<InputContainer>
										<label>Celular</label>
										<input type={'text'} defaultValue={dataOfUser?.phoneNumber} {...register('phoneNumber', phoneFValidator)} />
										{errors.phoneNumber && <ErrorText>{errors.phoneNumber.message}</ErrorText>}
									</InputContainer>
								</div>
								<div className="inputContainerDuplo">
									<InputContainer>
										<label>Postulante a universidad</label>
										<div className="select">
											<select
												id="standard-select"
												value={universityState.universityName}
												{...register('university')}
												onChange={(e) => {
													// filtrando professions al seleccionar una universidad
													setUniversityState({
														universityName: e.target.value,
														universityAcronym: universities.find((u) => u.universityName === e.target.value)?.acronym,
														school: universities.find((u) => u.universityName === e.target.value)?.professions[0],
													});
												}}>
												{/* mapeando universities */}
												{universities &&
													universities.map((university, index) => (
														<option key={index} value={university.universityName}>
															{university.universityName}
														</option>
													))}
											</select>
											<span className="focus"></span>
										</div>
									</InputContainer>
									<InputContainer>
										<label>Escuela o facultad</label>
										<div className="select">
											<select
												id="standard-select"
												value={universityState.school}
												{...register('school')}
												onChange={
													//mostrar la opcion seleccionada de la escuela
													(e) => {
														setUniversityState({ school: e.target.value });
													}
												}>
												{universities &&
													universityState.universityName &&
													universities
														.find((u) => u.universityName === universityState.universityName)
														.professions.sort((a, b) => a.localeCompare(b))
														.map((school, index) => (
															<option key={index} value={school}>
																{school}
															</option>
														))}
											</select>
											<span className="focus"></span>
										</div>
									</InputContainer>
								</div>
							</div>
							<div>
								<Title5>Datos del apoderado</Title5>
								<div className="inputContainerDuplo">
									<InputContainer>
										<label>Nombres (apoderado)</label>
										<input
											type={'text'}
											defaultValue={dataOfUser?.nameAttorney?.firstName}
											// Placeholder={dataProfile.nameAttorney}
											{...register('nameAttorney', nameFValidatorAttorney)}
										/>
										{errors.nameAttorney && <ErrorText>{errors.nameAttorney.message}</ErrorText>}
									</InputContainer>
									<InputContainer>
										<label>Apellidos (apoderado)</label>
										<input
											type={'text'}
											defaultValue={dataOfUser?.nameAttorney?.lastName}
											// placeholder={dataProfile.lastNameAttorney}
											{...register('lastNameAttorney', nameFValidatorAttorney)}
										/>
										{errors.lastNameAttorney && <ErrorText>{errors.lastNameAttorney.message}</ErrorText>}
									</InputContainer>
								</div>
								<div className="inputContainerDuplo">
									<InputContainer>
										<label>Celular (apoderado)</label>
										<input
											type={'text'}
											defaultValue={dataOfUser.phoneNumberAttorney}
											// placeholder={dataProfile.phoneNumberAttorney}
											{...register('phoneNumberAttorney', phoneFValidatorAttorney)}
										/>
										{errors.phoneNumberAttorney && <ErrorText>{errors.phoneNumberAttorney.message}</ErrorText>}
									</InputContainer>
								</div>
							</div>
							<div className="inputContainerDuplo">
								<Button primary text="Guardar información" type={'submit'} />
							</div>
						</FormContainer>
					</ProfileEditInformation>
				</ProfileEditContainer>
			</WrapperHome>
		</main>
	);
};

export default Maineditprofile;
