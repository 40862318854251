import React from 'react';
import { TextBody3 } from '../../../styles/textGeneral';
import { SocialMediaCardContainer } from './styles/sSocialMediaCard';

export default function SocialMediaCard({ image, title, description, widthSvg, color, url }) {
	return (
		<li>
			<SocialMediaCardContainer
				onClick={() => {
					window.open(url, '_blank');
				}}
				widthSvg={widthSvg}
				color={color}>
				<div className="imageContainer">{typeof image == 'string' ? <img src={image} alt={title} /> : <>{image}</>}</div>
				<div>
					<TextBody3>{title}</TextBody3>
					<div>
						<span>{description}</span>
					</div>
				</div>
			</SocialMediaCardContainer>
		</li>
	);
}
