import React, { useLayoutEffect } from 'react';
import { Wrapper } from '../../styles/generalStyles';
import Coverpage from '../general/landingComponents/CoverPage';
import { Title2 } from '../../styles/textGeneral';
import Preview from '../general/landingComponents/Preview';
import {
	Advantage,
	ImageBox,
	BoxOne,
	BoxTeam,
	BoxTwo,
	ContainerAdvantage,
	MainContainer,
	ContainerTeam,
	TextSubtextCard,
	TextSubtext1,
	TextSubtext2,
} from './styles/sMainAboutUs';

import { ReactComponent as PlayPresentationSVG } from '../../icons/playPresentation.svg';
import { ReactComponent as NotesSVG } from '../../icons/notes.svg';
import { ReactComponent as BrainSVG } from '../../icons/brain.svg';
import { ReactComponent as CircleSVG } from './../../images/circle.svg';

export default function MainAboutUs() {
	useLayoutEffect(() => {
		document.documentElement.scrollTop = 0;
	}, []);

	return (
		<main>
			<Coverpage
				background={require('./../../images/background-aboutus.webp')}
				work="aboutUs"
				justifyContent="center"
				height="100vh"
				id="aboutUsCoverPage"
			/>
			<CircleSVG className="cicleSVGBackground" />
			<Wrapper>
				<MainContainer>
					<Title2 className="center">Nuestra misión</Title2>
					<TextSubtext1 className="center">
						Acelerar y personalizar el aprendizaje mediante data
						<br />e investigación.
					</TextSubtext1>
					<ContainerAdvantage>
						<Advantage>
							<PlayPresentationSVG />
							<TextSubtextCard className="center">+ 300 horas en video de clases</TextSubtextCard>
						</Advantage>
						<Advantage>
							<NotesSVG />
							<TextSubtextCard className="center">+ 1000 preguntas tipo admisión</TextSubtextCard>
						</Advantage>
						<Advantage>
							<BrainSVG />
							<TextSubtextCard className="center">80% de efectividad</TextSubtextCard>
						</Advantage>
					</ContainerAdvantage>
					<Title2 className="center">Nuestro equipo</Title2>
					<TextSubtext1 className="center">
						Comprometidos con la educación de la
						<br />
						nueva era
					</TextSubtext1>
					<ContainerTeam>
						<BoxTeam>
							<TextSubtext1>Coordinador General</TextSubtext1>
							<BoxTwo>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/academiaDecide/TíoDecide.webp')} />
									<TextSubtext2>Tío Decide</TextSubtext2>
								</BoxOne>
							</BoxTwo>
						</BoxTeam>
					</ContainerTeam>
					<ContainerTeam className="sectionBot">
						<BoxTeam>
							<TextSubtext1>Coordinadores</TextSubtext1>
							<BoxTwo>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/academiaDecide/Lizeth.webp')} />
									<TextSubtext2>Lizeth</TextSubtext2>
								</BoxOne>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/academiaDecide/Royer.webp')} />
									<TextSubtext2>Royer</TextSubtext2>
								</BoxOne>
							</BoxTwo>
						</BoxTeam>
						{/* <BoxTeam>
							<TextSubtext1>Marketing</TextSubtext1>
							<BoxTwo>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/Arnold.webp')} />
									<TextSubtext2>Arnold</TextSubtext2>
								</BoxOne>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/Rafael.webp')} />
									<TextSubtext2>Rafael</TextSubtext2>
								</BoxOne>
							</BoxTwo>
						</BoxTeam>
						<BoxTeam>
							<TextSubtext1>Soporte</TextSubtext1>
							<BoxTwo>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/Katy.webp')} />
									<TextSubtext2>Katy</TextSubtext2>
								</BoxOne>
								<BoxOne>
									<ImageBox image={require('./../../images/staff/Khevin.webp')} />
									<TextSubtext2>Christian</TextSubtext2>
								</BoxOne>
							</BoxTwo>
						</BoxTeam> */}
					</ContainerTeam>
				</MainContainer>
			</Wrapper>
			<Preview
				active={true}
				title={`Hacemos de la educación una verdadera \n educación virtual`}
				background={require('./../../images/background-aboutus2.webp')}
				allText="center"
				margin="0"
				type="simple"
			/>
		</main>
	);
}
