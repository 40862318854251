import styled from 'styled-components';

export const NavbarInHomeMenuContainer = styled.nav`
	position: fixed;
	width: ${(props) => props.navbarWith};
	transition: all 0.5s ease-in-out;
	height: 100%;
	background: ${(props) => props.theme.color.neutral2};
	display: none;
	z-index: 4000;
	padding: ${(props) => props.theme.space.spacing6X} ${(props) => props.theme.space.spacing4X};
	top: 0;
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	background-attachment: fixed;

	.carretDoubleLeft {
		cursor: pointer;
	}

	.carretDoubleRigth {
		cursor: pointer;
		rotate: 180deg;
	}

	.logoMin {
		padding-left: ${(props) => props.theme.space.spacing3X5};
		width: 35px;
		height: 35px;
	}
	.logoAcademia-Container {
		width: 35px;
		height: 35px;

		img {
			width: 100%;
		}
	}

	@media (min-width: 1200px) {
		display: block;

		.logoNavbarInHomeMenuContainer {
			margin: 0 0 30px 0;
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: ${(props) => props.theme.space.spacing3X5};
		}
	}
`;

export const UnlistNavbarInHomeMenu = styled.ul`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.space.spacing8X5};
	margin-bottom: ${(props) => props.theme.space.spacing8X5};
`;
