import React, { useLayoutEffect } from 'react';
import { Wrapper } from '../../styles/generalStyles';
import { Title5 } from '../../styles/textGeneral';
import { CardAssistance, MainContainer, TextCard, TitleCard, CardBox, HelloTitle, SubTitle } from './styles/sMainHelpCenter';
// import { ReactComponent as CheckSVG } from './../../../src/icons/check.svg';
import { ReactComponent as HeadsetSVG } from './../../../src/icons/headset-1.svg';
// import { ReactComponent as DocumentSVG } from './../../../src/icons/document-folder.svg';
import { ReactComponent as CopyrightSVG } from './../../../src/icons/copyright.svg';
// import { ReactComponent as HelpSVG } from './../../../src/icons/help.svg';
import { useNavigate } from 'react-router';

export default function MainHelpCenter() {
	useLayoutEffect(() => {
		document.documentElement.scrollTop = 0;
	}, []);
	const navigate = useNavigate();
	return (
		<main>
			<Wrapper>
				<MainContainer>
					<div className="center addTop-container">
						<HelloTitle>Centro de ayuda y soporte</HelloTitle>
					</div>
					<div className="title-container">
						<SubTitle>¿En qué podemos ayudarte?</SubTitle>
						<Title5>Servicios</Title5>
					</div>
					<CardBox>
						{/*TODO: Uncomment the following line if the info about this pages exists */}
						{/*<CardAssistance>*/}
						{/*	<CheckSVG />*/}
						{/*	<TitleCard>Guía rápida para iniciar</TitleCard>*/}
						{/*	<TextCard>Mira una guía de todo lo que te ofrece Ebbiner y cómo aprovecharlo.</TextCard>*/}
						{/*</CardAssistance>*/}
						<CardAssistance onClick={() => navigate('/help-contacts')}>
							<HeadsetSVG />
							<TitleCard>Contacta con un asistente</TitleCard>
							<TextCard>Resuelve tus dudas con una persona encargada.</TextCard>
						</CardAssistance>
						{/*<CardAssistance>*/}
						{/*	<DocumentSVG />*/}
						{/*	<TitleCard>Términos y condiciones</TitleCard>*/}
						{/*	<TextCard>Accede a los términos del servicio y privacidad.</TextCard>*/}
						{/*</CardAssistance>*/}
						{/*<CardAssistance>*/}
						{/*	<HelpSVG />*/}
						{/*	<TitleCard>Preguntas frecuentes</TitleCard>*/}
						{/*	<TextCard>Resuelve algunas de tus dudas más frecuentes.</TextCard>*/}
						{/*</CardAssistance>*/}
						<CardAssistance onClick={() => navigate('/terms-conditions')}>
							<CopyrightSVG />
							<TitleCard>Copyright</TitleCard>
							<TextCard>Derechos de propiedad intelectual de Ebbiner y sus usuarios.</TextCard>
						</CardAssistance>
					</CardBox>
				</MainContainer>
			</Wrapper>
		</main>
	);
}
