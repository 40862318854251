import styled from 'styled-components';

export const ItemNavbarMenuContainer = styled.li`
	/* margin: 0 0 12px 0; */
	margin-left: ${(props) => props.theme.space.spacing3X};

	p {
		white-space: nowrap;
		opacity: 0;
		animation: fadeIn 0.5s ease-in-out forwards;
		animation-delay: 0.1s; /* Retraso de 0.5 segundos para que la animación comience después */
	}

	@keyframes fadeIn {
		to {
			opacity: 1;
		}
	}

	a {
		color: ${(props) => props.theme.color.neutral600};
		display: flex;
		align-items: center;
		gap: ${(props) => props.theme.space.spacing2X};

		p,
		svg {
			position: relative;
			z-index: 10;
		}

		p {
			z-index: -1;
			width: 100%;
			font-weight: ${(props) => props.theme.font.weight.medium};
			text-transform: capitalize;
			font-family: ${(props) => props.theme.font.brandFont};
		}

		svg {
			fill: ${(props) => props.theme.color.neutral600};
			min-height: 24px;
			min-width: 24px;
		}

		img {
			width: 24px;
			height: 24px;
			border-radius: 50px;
			border: 1px solid ${(props) => props.theme.color.neutral600};
			position: relative;
			z-index: 10;
		}

		&:hover {
			/* background-color: ${(props) => props.theme.color.primary304}; */

			p {
				color: ${(props) => props.theme.color.primary300};
			}

			svg {
				fill: ${(props) => props.theme.color.primary300};
			}

			img {
				border: 1px solid ${(props) => props.theme.color.primary300};
			}
		}

		&:hover {
			&::before {
				content: '';
				position: absolute;
				width: ${(props) => props.navbarMinWith ?? '90%'};
				height: 40px;
				border-radius: 14px;
				margin-left: -${(props) => props.theme.space.spacing3X};
				background-color: ${(props) => props.theme.color.primary304};
				z-index: -1;
			}
		}

		.userGaulerMenu {
			fill: ${(props) => props.theme.color.neutral10};
			width: 24px;
			height: 24px;
		}

		.isActive {
			&::before {
				content: '';
				position: absolute;
				width: ${(props) => props.navbarMinWith ?? '90%'};
				height: 40px;
				border-radius: 14px;
				margin-left: -${(props) => props.theme.space.spacing3X};
				background-color: ${(props) => props.theme.color.primary304};
				z-index: -1;
			}

			p {
				color: ${(props) => props.theme.color.primary300};
			}

			svg {
				fill: ${(props) => props.theme.color.primary300};
			}

			img {
				border: 1px solid ${(props) => props.theme.color.primary300};
			}
		}
	}
`;
