import React, { useState } from 'react';
import { TertiaryTitle, Text } from '../../styles/textGeneral';
import { ContactsContainer, ContentEvent, FormContainer } from './styles/sModalContact';
import { InputContainer } from '../../styles/inputGeneral';
import { ErrorText } from '../profile/styles/sMainProfileEdit';
import { Button } from '../../styles/buttonGeneral';
import AstroHearth from '../../images/astro_hearth.png';
import { useFirestore } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaContactValidator } from './validation.schema';
import { SendContactInfo } from './algorithms/SendContactInfo';

const ModalContact = () => {
	const db = useFirestore();
	const [isSuccess, setSuccess] = useState(false);
	const [isError, setError] = useState(false);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(schemaContactValidator),
		defaultValues: {
			accept_call: true,
		},
	});
	const handleSubmitData = async (info) => {
		await SendContactInfo(db, info, setSuccess, setError);
		reset();
	};
	return (
		<ContactsContainer>
			{!isSuccess && !isError && (
				<>
					<TertiaryTitle white center>
						Clase gratuita
					</TertiaryTitle>
					<Text center margin={'0 0 49px 0'}>
						Llena los campos para que podamos comunicarnos contigo:
					</Text>
					<FormContainer onSubmit={handleSubmit(handleSubmitData)}>
						<InputContainer margin20B>
							<label>Nombre</label>
							<input type={'text'} name="name" {...register('name')} required />
							{errors?.name && <ErrorText>{errors.name.message}</ErrorText>}
						</InputContainer>
						<InputContainer margin20B>
							<label>WhatsApp (Número)</label>
							<input type={'text'} name="phone" {...register('phone')} required />
							{errors?.phone && <ErrorText>{errors.phone.message}</ErrorText>}
						</InputContainer>
						<InputContainer margin20B>
							<label>Correo</label>
							<input type={'email'} name="email" {...register('email')} required />
							{errors?.email && <ErrorText>{errors.email.message}</ErrorText>}
						</InputContainer>
						<InputContainer margin20B>
							<label className="inputType">
								<input type={'checkbox'} name="accept_call" {...register('accept_call')} /> Estoy de acuerdo con que me llamen
								para mi clase gratuita
							</label>
							{errors?.accept_call && <ErrorText>{errors.accept_call.message}</ErrorText>}
						</InputContainer>
						<Button primary type={'submit'} text="Inscribirme ahora" />
					</FormContainer>
				</>
			)}

			{isSuccess && (
				<ContentEvent>
					<img src={AstroHearth} alt={'Imagen de despegue'} />
					<TertiaryTitle white center>
						¡Gracias por responder!
					</TertiaryTitle>
					<Text center> La información ha sido enviada con éxito</Text>
					{/* TODO: verificar el button */}
					<Button primary onClick={() => navigate('/')} text="Continuar" />
				</ContentEvent>
			)}

			{isError && (
				<ContentEvent>
					<TertiaryTitle white center>
						¡Ups! Ocurrió un error
					</TertiaryTitle>
					{/* TODO: verificar el button */}
					<Button primary onClick={() => navigate(0)} text="Inténtalo nuevamente" />
				</ContentEvent>
			)}
		</ContactsContainer>
	);
};

export default ModalContact;
