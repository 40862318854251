import React, { useContext, useEffect, useState, useRef } from 'react';
import { useReducedState } from '../../../hooks/useReducedState';
import { useNavigate } from 'react-router';
import { WrapperExpand } from './../../../styles/generalStyles';
import { MainExamFormContainer, DetailsPageExamn, SubHeaderExamn } from './styles/sMainExamForm';
import { Title4, Title5 } from './../../../styles/textGeneral';
import { Button } from './../../../styles/buttonGeneral';
import CourseCategoryForm from './CourseCategoryForm';
import MainModal from './../../modal/MainModal';
import ConfirmationSendExamModal from './ConfirmationSendExamModal';
import MainSpinner from '../../spinner/MainSpinner';
import { ReactComponent as ClockSVG } from './../../../icons/clock.svg';
import ModalReportQuestion from '../ModalReportQuestion';

//import Contexts
import { AppContext } from '../../../App';

//import ALGORITHMS
import { RemainingTimer } from './RemainingTimer';
import { questionsOrganizer } from './algorithms/questionsOrganizer';
import { useScroll } from './../../../hooks/useScroll';

export default function MainExamForm() {
	const [modalState, setModalState] = useState(false);
	const [modalStateReport, setModalStateReport] = useState(false);
	const [dataQuestionReport, setDataQuestionReport] = useState(null);
	const navigate = useNavigate();
	const { simulacrum, weekExam } = useContext(AppContext);
	const [detailsOpened, setDetailsOpened] = useReducedState({
		actual: null,
		previous: null,
	});
	const [simulacrumFirstCharge, setSimulacrumFirstCharge] = useState(true);
	const [weekExamFirstCharge, setWeekExamFirstCharge] = useState(true);
	const [ordenatedSimulacrum, setOrdenatedSimulacrum] = useState([]);
	const [loading, setLoading] = useState({ status: false, title: null });
	const subHeaderRef = useRef();
	const { scroll } = useScroll(subHeaderRef);

	useEffect(() => {
		if (!simulacrum.questions && !weekExam.questions) {
			navigate('/exams');
		}
		if (simulacrum.questions && simulacrumFirstCharge) {
			setSimulacrumFirstCharge(false);
			setOrdenatedSimulacrum(questionsOrganizer(simulacrum.questions));
		} else if (weekExam.questions && weekExamFirstCharge) {
			setWeekExamFirstCharge(false);
			setOrdenatedSimulacrum(questionsOrganizer(weekExam.questions));
		}
		// console.log(scroll)
	}, []);

	useEffect(() => {
		if (ordenatedSimulacrum.length > 0 && detailsOpened.previous) {
			const openedElement = document.getElementById(detailsOpened.previous);
			openedElement.removeAttribute('open');
		}
	}, [detailsOpened]);

	if (loading.status) return <MainSpinner title={'Estamos enviando tu examen...'} />;

	return (
		<main ref={subHeaderRef}>
			<WrapperExpand>
				<DetailsPageExamn>
					<Title4>{simulacrum?.questions?.length > 0 ? 'Simulacro tipo admisión' : 'Examen de posicionamiento semanal'}</Title4>
				</DetailsPageExamn>
			</WrapperExpand>
			<SubHeaderExamn scroll={scroll}>
				<WrapperExpand>
					{simulacrum?.questions?.length > 0 && (
						<div className="subHeaderExamnContainer">
							<Title5 className="curses">Cursos</Title5>
							<div className="totalQuestionAndClockDetails">
								<p>
									Tienes {simulacrum?.totalMarkedAnswers.length}{' '}
									{simulacrum?.totalMarkedAnswers.length === 1 ? 'respuesta marcada' : 'respuestas marcadas'} de{' '}
									{simulacrum?.questions.length} preguntas
								</p>
								{simulacrum && simulacrum.metadata && (
									<div className="clockContent">
										<ClockSVG />
										<p>
											{' '}
											<RemainingTimer />
										</p>
									</div>
								)}
							</div>
						</div>
					)}
				</WrapperExpand>
			</SubHeaderExamn>
			<WrapperExpand>
				<MainExamFormContainer>
					<div>
						<form className="formExamn">
							<ul className="courseCategoryFormContainer" id="listOfQuestionsContainter">
								{ordenatedSimulacrum.length > 0 &&
									ordenatedSimulacrum.map((questions) => (
										<CourseCategoryForm
											key={questions[0].course}
											setDetailsOpened={setDetailsOpened}
											detailsOpened={detailsOpened}
											isReview={false}
											questions={questions}
											setModalStateReport={setModalStateReport}
											modalStateReport={modalStateReport}
											setDataQuestionReport={setDataQuestionReport}
										/>
									))}
							</ul>
							<div className="buttonExamFormContainer">
								<Button
									secondary
									text={'Enviar'}
									onClick={(e) => {
										e.preventDefault();
										setModalState(!modalState);
									}}
								/>
							</div>
						</form>
						<MainModal
							modalState={modalStateReport}
							setModalState={setModalStateReport}
							title="¿Encontraste un problema?"
							type="classic">
							<ModalReportQuestion setModalStateReport={setModalStateReport} dataQuestionReport={dataQuestionReport} />
						</MainModal>
						<MainModal modalState={modalState} setModalState={setModalState} title="¿Terminaste el simulacro?" type="classic">
							<ConfirmationSendExamModal setModalState={setModalState} setLoading={setLoading} />
						</MainModal>
					</div>
				</MainExamFormContainer>
			</WrapperExpand>
		</main>
	);
}
