import React from 'react';
import ExpandHeader from '../general/internalComponents/ExpandHeader/ExpandHeader';

const VideoLayout = ({ title, children }) => {
	return (
		<>
			{/* <Headerinhome/> */}
			<ExpandHeader title={title} />
			{children}
		</>
	);
};

export default VideoLayout;
