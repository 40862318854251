'use client';
import styled from 'styled-components';
import { Body3Mixin, Body4Mixin, Body7Mixin, Details2Mixin } from '../../../../../styles/textGeneral';

export const Section2Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	h3 {
		${Body4Mixin};
	}

	.link {
		display: flex;
		align-items: center;
		gap: ${({ theme }) => theme.space.spacing2X};

		span {
			${Details2Mixin};
			color: ${({ theme }) => theme.color.secondary300};
		}

		svg {
			width: 16px;
			height: 16px;
			fill: ${({ theme }) => theme.color.secondary300};
		}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		h3 {
			${Body3Mixin};
		}

		.link {
			span {
				${Body7Mixin};
			}
		}
	}
`;
