import styled from 'styled-components';

export const QuestionResultsContainer = styled.ul`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	flex-direction: column;
	margin: 20px 0 0 0;

	li {
		display: flex;
		align-items: center;
	}

	.circle {
		width: 10px;
		height: 10px;
		border-radius: 50px;
		margin: 0 10px 0 0;
	}

	.correct {
		background: ${(props) => props.theme.color.success800};
	}

	.incorrect {
		background: ${(props) => props.theme.color.error400};
	}

	.unanswered {
		background: ${(props) => props.theme.color.warning800};
	}

	@media (min-width: 768px) {
		flex-direction: row;
	}

	@media (min-width: 1200px) {
		margin: 0;
		gap: 25px;
	}
`;
