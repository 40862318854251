// @ts-nocheck
import HeaderInHome from '../general/internalComponents/HeaderInHome';
import NavbarInHome from '../general/internalComponents/navbarInHome/NavbarInHome';
import NavbarInHomeMenu from './../general/internalComponents/navbarInHomeMenu/NavbarInHomeMenu';
import { GridLayoutInHome } from './../../styles/generalStyles';
import { useNavbarStore } from '../../store/navbarStore';

const Inhomelayout = ({ children }) => {
	const { navbarDeployed } = useNavbarStore();
	return (
		<>
			<HeaderInHome />
			<GridLayoutInHome widthfr={navbarDeployed ? '270px' : '108px'}>
				<NavbarInHomeMenu />
				<div></div>
				{children}
			</GridLayoutInHome>
			<NavbarInHome />
		</>
	);
};

export default Inhomelayout;
