import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../styles/buttonGeneral';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../../styles/generalStyles';
import { Title1A, Title3, Text, TertiaryTitle } from '../../styles/textGeneral';
import { FormContainer, InputContainer } from '../../styles/inputGeneral';
import { CardContactContainer, DataFormContainer, ProgressBarContainer, ContentEvent } from './styles/sMainContactAdviser';
import { ageFValidator, nameFValidator, phoneFValidator, selectorFValidator } from './validators';
import { useFirestore } from 'reactfire';
import { ErrorText } from '../profile/styles/sMainProfileEdit';
import { SendContactAdviser } from './algorithms/SendContactAdviser';
import AstroHearth from './../../images/astro_hearth.png';

export const MainContactAdviser = () => {
	const [t] = useTranslation('main');
	const db = useFirestore();
	const [counter, setCounter] = useState(0);
	const [isSuccess, setSuccess] = useState(false);
	const [isError, setError] = useState(false);
	const navigate = useNavigate();
	const [progressBar, setProgressBar] = useState([
		{ id: 1, step: true, statusBar: true },
		{ id: 2, step: false, statusBar: false },
		{ id: 3, step: false, statusBar: false },
		{ id: 4, step: false, statusBar: false },
	]);
	const [dataOfVisitor, setDataOfVisitor] = useState({
		name: '',
		lastName: '',
		levelOfStudy: '',
		yearsOld: '',
		phoneNumber: '',
		nameAttorney: '',
		lastNameAttorney: '',
		phoneNumberAttorney: '',
	});
	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = useForm({
		mode: 'all',
		defaultValues: {
			name: '',
			lastName: '',
			yearsOld: '',
			phoneNumber: '',
			nameAttorney: '',
			lastNameAttorney: '',
			phoneNumberAttorney: '',
		},
	});
	const valueYearOld = getValues('yearsOld');
	const levelOfStudyItems = [
		{ id: 1, name: 'Primaria' },
		{ id: 2, name: 'Secundaria' },
		{ id: 3, name: 'Preuniversitario' },
	];

	const prevStep = () => {
		setCounter(counter - 1);
		setProgressBar(
			progressBar.map((item, index) => {
				if (index === counter) {
					return {
						...item,
						step: false,
						statusBar: false,
					};
				}
				if (index === counter - 1) {
					return {
						...item,
						step: true,
					};
				}
				return item;
			}),
		);
	};

	const nextStep = () => {
		setCounter(counter + 1);
		setProgressBar(
			progressBar.map((item, index) => {
				if (index === counter) {
					return {
						...item,
						step: false,
					};
				}
				if (index === counter + 1) {
					return {
						...item,
						step: true,
						statusBar: true,
					};
				}
				return item;
			}),
		);
	};

	const onSubmit = async (info) => {
		await SendContactAdviser(db, info, setSuccess, setError);
		reset();
	};
	const onError = (errors) => {
		console.error(errors);
	};

	return (
		<main>
			<Wrapper>
				<CardContactContainer>
					{!isSuccess && !isError && (
						<>
							<ProgressBarContainer>
								<span className={`circle ${progressBar[0].statusBar ? 'circle-active' : ''}`}>1</span>
								<span className={`line ${progressBar[1].statusBar ? 'line-active' : ''}`}></span>
								<span className={`circle ${progressBar[1].statusBar ? 'circle-active' : ''}`}>2</span>
								<span className={`line ${progressBar[2].statusBar ? 'line-active' : ''}`}></span>
								<span className={`circle ${progressBar[2].statusBar ? 'circle-active' : ''}`}>3</span>
								<span className={`line ${progressBar[3].statusBar ? 'line-active' : ''}`}></span>
								<span className={`circle ${progressBar[3].statusBar ? 'circle-active' : ''}`}>4</span>
							</ProgressBarContainer>
							<DataFormContainer>
								<div className="container-title">
									{/* {Object.values(errors).length > 0 ? <ErrorText>Corrige tus datos</ErrorText> : null} */}
									{progressBar[0].step ? (
										<>
											<Title1A>Habla con un asesor académico</Title1A>
											<Title3>
												Especialista en metodologías de aprendizaje rápido y asesoramiento en cursos de colegios y
												preuniverstarios
											</Title3>
										</>
									) : null}
								</div>
								<div className="container-image">
									<img
										src="https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/images%2Frocket_crunch.png?alt=media&token=8dfb7fa9-5c08-4c1d-9c5f-733d19d49d3b"
										alt="Imagen de despegue"
									/>
								</div>
								<div className="container-questions">
									{progressBar[0].step ? <Title3>Antes de iniciar, ¿Cuál es tu nombre?</Title3> : null}
									{progressBar[1].step ? <Title3>Seguimos, ¿En que etapa te encuentras?</Title3> : null}
									{progressBar[2].step ? <Title3>¿Cuántos años tienes?</Title3> : null}
									{progressBar[3].step && valueYearOld > 16 ? <Title3>Por último, ¿Cuál es tu número de celular?</Title3> : null}
									{progressBar[3].step && valueYearOld < 16 ? (
										<Title3>Por último, ¿Cuál es el nombre y número de celular de tu apoderado? Nos contaremos.</Title3>
									) : null}
								</div>
								{progressBar[0].step && (
									<FormContainer onSubmit={handleSubmit(onError)}>
										<div className="inputContainerInRow">
											<InputContainer>
												<label>Nombre</label>
												<input
													defaultValue={dataOfVisitor.name}
													placeholder="Ecribe tu nombre"
													{...register('name', nameFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, name: e.target.value });
													}}
												/>
												{errors.name && <ErrorText>{errors.name.message}</ErrorText>}
											</InputContainer>
											<InputContainer>
												<label>Apellidos</label>
												<input
													defaultValue={dataOfVisitor.lastName}
													placeholder="Escribe tus apellidos"
													{...register('lastName', nameFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, lastName: e.target.value });
													}}
												/>
												{errors.lastName && <ErrorText>{errors.lastName.message}</ErrorText>}
											</InputContainer>
										</div>
										<div className="buttonsContainer container-button">
											<Button
												primary
												text={'Continuar'}
												type={dataOfVisitor.name.length > 3 && dataOfVisitor.lastName.length > 3 ? 'button' : 'submit'}
												onClick={dataOfVisitor.name.length > 3 && dataOfVisitor.lastName.length > 3 ? () => nextStep() : null}
											/>
										</div>
									</FormContainer>
								)}
								{progressBar[1].step && (
									<FormContainer onSubmit={handleSubmit(onError)}>
										<div className="inputContainerInRowOne">
											<InputContainer>
												<label>Etapa de estudios</label>
												<div className="select">
													<select
														defaultValue={dataOfVisitor.levelOfStudy}
														{...register('levelOfStudy', selectorFValidator)}
														onChange={(e) => {
															setDataOfVisitor({ ...dataOfVisitor, levelOfStudy: e.target.value });
														}}>
														<option>{t('selectedOptionText')}</option>
														{levelOfStudyItems.map((item, index) => (
															<option key={index} value={item.name}>
																{item.name}
															</option>
														))}
													</select>
													<span className="focus"></span>
												</div>
												{errors.levelOfStudy && <ErrorText>{errors.levelOfStudy.message}</ErrorText>}
											</InputContainer>
										</div>
										<div className="buttonsContainer container-button">
											<Button text={'Atras'} primary type={'button'} onClick={() => prevStep()} />
											<Button
												primary
												type={
													dataOfVisitor.levelOfStudy.length !== 0 && dataOfVisitor.levelOfStudy !== t('selectedOptionText')
														? 'button'
														: 'submit'
												}
												onClick={
													dataOfVisitor.levelOfStudy.length !== 0 && dataOfVisitor.levelOfStudy !== t('selectedOptionText')
														? () => nextStep()
														: null
												}
												text={'Continuar'}
											/>
										</div>
									</FormContainer>
								)}
								{progressBar[2].step && (
									<FormContainer onSubmit={handleSubmit(onError)}>
										<div className="inputContainerInRowOne">
											<InputContainer>
												<label>Edad</label>
												<input
													placeholder="Ingresa tu edad"
													{...register('yearsOld', ageFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, yearsOld: e.target.value });
													}}
												/>
												{errors.yearsOld && <ErrorText>{errors.yearsOld.message}</ErrorText>}
											</InputContainer>
										</div>
										<div className="buttonsContainer container-button">
											<Button primary text="Atras" type={'button'} onClick={() => prevStep()} />
											<Button
												primary
												type={dataOfVisitor.yearsOld.length !== 0 && Object.values(errors).length === 0 ? 'button' : 'submit'}
												onClick={
													dataOfVisitor.yearsOld.length !== 0 && Object.values(errors).length === 0 ? () => nextStep() : null
												}
												text={'Continuar'}
											/>
										</div>
									</FormContainer>
								)}
								{progressBar[3].step && valueYearOld > 16 && (
									<FormContainer onSubmit={handleSubmit(onSubmit, onError)}>
										<div className="inputContainerInRowOne">
											<InputContainer>
												<label>Número de celular</label>
												<input
													placeholder="Ingresa tu numero de celular"
													{...register('phoneNumber', phoneFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, phoneNumber: e.target.value });
													}}
												/>
												{errors.phoneNumber && <ErrorText>{errors.phoneNumber.message}</ErrorText>}
											</InputContainer>
										</div>
										<div className="buttonsContainer container-button">
											<Button primary text={'Atras'} type={'button'} onClick={() => prevStep()} />
											<Button primary text={'Finalizar'} type={'submit'} />
										</div>
									</FormContainer>
								)}
								{progressBar[3].step && valueYearOld < 16 && (
									<FormContainer onSubmit={handleSubmit(onSubmit, onError)}>
										<div className="inputContainerInRow">
											<InputContainer>
												<label>Nombres</label>
												<input
													placeholder="Ingrese su nombre"
													{...register('nameAttorney', nameFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, nameAttorney: e.target.value });
													}}
												/>
												{errors.nameAttorney && <ErrorText>{errors.nameAttorney.message}</ErrorText>}
											</InputContainer>
											<InputContainer>
												<label>Apellidos</label>
												<input
													placeholder="Ingrese sus apellidos"
													{...register('lastNameAttorney', nameFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, lastNameAttorney: e.target.value });
													}}
												/>
												{errors.lastNameAttorney && <ErrorText>{errors.lastNameAttorney.message}</ErrorText>}
											</InputContainer>
											<InputContainer>
												<label>Número de celular</label>
												<input
													placeholder="Ingresa tu numero de celular"
													{...register('phoneNumberAttorney', phoneFValidator)}
													onChange={(e) => {
														setDataOfVisitor({ ...dataOfVisitor, phoneNumberAttorney: e.target.value });
													}}
												/>
												{errors.phoneNumberAttorney && <ErrorText>{errors.phoneNumberAttorney.message}</ErrorText>}
											</InputContainer>
										</div>
										<div className="buttonsContainer container-button">
											<Button primary text={'Atras'} type={'button'} onClick={() => prevStep()} />
											<Button primary text="Finalizar" type={'submit'} />
										</div>
									</FormContainer>
								)}
							</DataFormContainer>
						</>
					)}
					{isSuccess && (
						<ContentEvent marginTop={100}>
							<img src={AstroHearth} alt={'Imagen de despegue'} />
							<TertiaryTitle white center>
								¡Gracias por responder!
							</TertiaryTitle>
							<Text center> La información ha sido enviada con éxito</Text>
							{/* TODO: verificar el button */}
							<Button primary text="Continuar" onClick={() => navigate('/')} />
						</ContentEvent>
					)}

					{isError && (
						<ContentEvent marginTop={100}>
							<TertiaryTitle white center>
								¡Ups! Ocurrió un error
							</TertiaryTitle>
							{/* TODO: verificar el button */}
							<Button primary text="Inténtalo nuevamente" onClick={() => navigate(0)} />
						</ContentEvent>
					)}
				</CardContactContainer>
			</Wrapper>
		</main>
	);
};
