import React from 'react';
import MainLogin from '../components/registerAndLogin/MainLogin';
import { Helmet } from 'react-helmet-async';

const Login = () => {
	return (
		<>
			<Helmet>
				<title>Inicia sesión | Ebbiner</title>
				<meta name="description" content="Ebbiner - Educación virtual" />
				<meta property="og:title" content="Inicia sesión | Ebbiner" />
				<meta property="og:description" content="Ebbiner - Educación virtual" />
				<meta property="twitter:title" content="Inicia sesión | Ebbiner" />
				<meta property="twitter:description" content="Ebbiner - Educación virtual" />
			</Helmet>
			<MainLogin />
		</>
	);
};

export default Login;
