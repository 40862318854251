import React from 'react';
import MainRegister from '../components/registerAndLogin/MainRegister';
import { Helmet } from 'react-helmet-async';

const Register = () => {
	return (
		<>
			<Helmet>
				<title>Registro | Ebbiner</title>
				<meta name="description" content="Ebbiner - Educación virtual" />
				<meta property="og:title" content="Registro | Ebbiner" />
				<meta property="og:description" content="Ebbiner - Educación virtual" />
				<meta property="twitter:title" content="Registro | Ebbiner" />
				<meta property="twitter:description" content="Ebbiner - Educación virtual" />
			</Helmet>
			<MainRegister />
		</>
	);
};

export default Register;
