import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { CourseCardsUnlist } from './../home/styles/sMainHome';
import { BoxCard } from '../../styles/boxGeneral';
import { WrapperOthers } from './../../styles/generalStyles';
import { Title5, Title6 } from './../../styles/textGeneral';
import ProfileGeneralData from './../profile/ProfileGeneralData';
import { UserProfileContainer } from './../profile/styles/sMainProfile';
import StatisticCard from './../general/cards/StatisticCard';
import SimulacrumRecord from './../general/cardsModal/SimulacrumRecord';
import { ReactComponent as FavoriteSVG } from './../../icons/favorite.svg';

import { probabilityCalculator } from './../profile/algorithms/probabilityCalculator';

import { useStatesContext } from '../layouts/LayoutContextOfStates';

export default function MainStatisticAdmission() {
	const { dataOfUser } = useContext(AppContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState({ status: false, title: null });
	const { stats } = useStatesContext();

	useEffect(() => {
		setLoading({ status: true, title: null });
		if (!dataOfUser) {
			navigate('/');
		}

		setLoading({ status: false, title: null });
	}, [dataOfUser, stats]);
	// console.log('stats.averageStatistics', stats.averageStatistics);

	const statisticsCardList = [
		{
			loading: loading,
			icon: <FavoriteSVG />,
			color: 'yellow',
			number: stats?.averageStatistics ? probabilityCalculator(stats?.averageStatistics) : 0,
			name: 'Probabilidad de ingreso a la universidad',
			// button: "Ver como mejorar",
			titleModal: 'Consejos para mejorar',
			content: <SimulacrumRecord stats={stats ? stats : null} />,
		},
	];

	return (
		<main>
			<UserProfileContainer>
				<ProfileGeneralData />
				<WrapperOthers>
					<Title5>Estadísticas de ingreso</Title5>
					<Title6>Probabilidades de ingreso</Title6>
					<BoxCard>
						<CourseCardsUnlist>
							{statisticsCardList?.map((card) => {
								if (card.number !== 0) {
									return <StatisticCard key={card.name} {...card} />;
								}
							})}
						</CourseCardsUnlist>
					</BoxCard>
				</WrapperOthers>
			</UserProfileContainer>
		</main>
	);
}
