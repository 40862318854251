import React from 'react';
import { Helmet } from 'react-helmet-async';
import { OffersMain } from '../components/offers/OffersMain';

export default function Offers() {
	return (
		<>
			<Helmet>
				<title>Ofertas | Ebbiner</title>
				<link rel="canonical" href="https://ebbiner.com" />
				<meta name="description" content="" />
				<meta property="og:title" content="" />
				<meta property="og:description" content="Tenemos lo mejor para ti" />
				<meta property="twitter:title" content="Ebbiner" />
				<meta property="twitter:description" content="Tenemos lo mejor para ti" />
				<meta name="twitter:card" content="summary" />
			</Helmet>
			<OffersMain />
		</>
	);
}
