import styled from 'styled-components';
import { mixinHoverAndFocus } from '../../../styles/inputGeneral';

export const ChatRoomContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		max-width: 390px;
		flex-direction: column-reverse;
		margin: 20px 0 0;
	}

	@media (min-width: 1200px) {
		margin: 0;
	}
`;

export const ChatMessage = styled.ul`
	padding: 20px;
	overflow-y: scroll;
	flex: 1 0 0;

	&::-webkit-scrollbar-track {
		background-color: ${(props) => props.theme.color.neutral10};
		border-radius: 4px;
	}

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background-color: ${(props) => props.theme.color.neutral10};
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: ${(props) => props.theme.color.neutral400};
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: ${(props) => props.theme.color.neutral400};
		box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
	}
	&::-webkit-scrollbar-thumb:active {
		background-color: ${(props) => props.theme.color.neutral600};
	}

	@media (min-width: 1024px) {
		display: flex;
		flex-direction: column-reverse;
		align-items: start;
		margin: 0;
		padding: 0;
	}

	/* display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 280px;
  padding: 20px 26px;
  margin: 6px 0 0;

   */
`;

export const ChatForm = styled.form`
	flex: 0 0 60px;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	background: ${(props) => props.theme.color.neutral900};
	padding: 14px 20px;

	input {
		width: 294px;
		height: 32px;
		padding: 7px 10px;
		margin: 0 0 0 10px;
		border: 1px solid ${(props) => props.theme.color.neutral500};
		border-radius: 14px;
		color: ${(props) => props.theme.color.neutral300};
		background: ${(props) => props.theme.color.neutral2};
		font-family: ${(props) => props.theme.font.textFont};
		font-style: normal;
		font-weight: ${(props) => props.theme.font.weight.light};
		font-size: 14px;
		line-height: 17px;
		text-align: start;
		${mixinHoverAndFocus};
	}

	label {
		display: none;
	}

	button {
		background-color: transparent;
		width: 33px;
		height: 33px;
		border-radius: 50%;
		transition: background-color 0.3s ease-out;

		svg {
			width: 24px;
			height: 24px;
			fill: ${(props) => props.theme.color.neutral0};
		}

		&:active {
			background-color: ${(props) => props.theme.color.primary100};
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	@media (min-width: 1024px) {
		margin: 0 0 20px 0;
		padding: 0;
		background-color: transparent;
		flex-direction: column;
		align-items: start;

		label {
			display: block;
			font-size: 0.938em;
			font-family: ${(props) => props.theme.font.textFont};
			font-weight: ${(props) => props.theme.font.weight.medium};
			margin: 0 0 5px 0;
		}

		button {
			display: none;
		}

		input {
			width: 100%;
			margin: 0;
			padding: 17px 22px;
			background-color: ${(props) => props.theme.color.neutral2};
			font-weight: ${(props) => props.theme.font.weight.medium};
		}
	}
`;

export const CircleExplosion = styled.span`
	&::after {
		content: ' ';
		width: ${(props) => props.size}px;
		height: ${(props) => props.size}px;
		position: absolute;
		left: ${(props) => props.left}px;
		bottom: ${(props) => props.bottom}px;
		right: ${(props) => props.right}px;
		top: ${(props) => props.top}px;
		background-color: ${(props) => props.color};
		border-radius: 50%;
		z-index: -10;

		@media (min-width: 1024px) {
			display: none;
		}
	}
`;
