import styled, { css } from 'styled-components';

export const CardContactContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 0 30px;
	height: 750px;
	margin-top: 50px;

	@media (max-width: 768px) {
		height: 850px;
		margin-top: 30px;
	}
`;

export const ProgressBarContainer = styled.div`
	display: grid;
	grid-template-columns: 30px 1fr 30px 1fr 30px 1fr 30px;
	justify-items: center;
	align-items: center;
	width: 70%;
	height: 50px;
	margin-top: 40px;

	.circle {
		background-color: ${(props) => props.theme.color.neutral400};
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${(props) => props.theme.color.neutral200};

		@media (max-width: 678px) {
			width: 20px;
			height: 20px;
			font-size: 10px;
		}
	}

	.circle-active {
		background-color: ${(props) => props.theme.color.primary200};
		color: ${(props) => props.theme.color.neutral100};
	}

	.line {
		width: 100%;
		height: 3px;
		background-color: ${(props) => props.theme.color.neutral400};

		@media (max-width: 678px) {
			height: 2px;
		}
	}
	.line-active {
		background-color: ${(props) => props.theme.color.primary200};
	}

	@media (max-width: 678px) {
		grid-template-columns: 20px 1fr 20px 1fr 20px 1fr 20px;
	}
`;

export const DataFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70%;
	height: 50px;
	margin-top: 40px;

	h2 {
		opacity: 0.5;
	}
	form {
		width: 100%;
	}

	.container-title {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		text-align: center;
	}
	.container-image {
		margin-top: 20px;
		img {
			width: 80px;
			height: 80px;
			border-radius: 50%;
		}
	}

	.container-questions {
		text-align: center;
	}

	.container-form {
	}

	.container-button {
		display: flex;
		justify-content: center;
		margin-top: 30px;
		width: 100%;
		gap: 10px;

		//TODO arreglar el tama;o de botones en pantallas grandes centrandolos

		@media (max-width: 420px) {
			flex-direction: column;
			gap: 10px;
		}
	}

	@media (max-width: 1024px) {
		h1 {
			font-size: 2em;
		}
	}
`;

export const ContentEvent = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	${(props) =>
		props?.marginTop &&
		css`
			margin-top: ${props?.marginTop}px;
		`}
	> button {
		width: 90%;
	}

	@media (min-width: 480px) {
		> button {
			width: 380px;
		}
	}
	img {
		width: 200px;
		margin-bottom: 20px;
	}
`;
