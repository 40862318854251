//Import components for the routes
import Classes from './pages/Classes';
import ClassVideo from './pages/ClassVideo';
import ClassVideoPrev from './pages/ClassVideoPrev';
import Materials from './pages/Materials';
import Exams from './pages/Exams';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import MainClassLive from './pages/MainClassLive';
import RecordedCourse from './pages/RecordedCourse';
import StatisticSimulacrums from './pages/statistic/Simulacrums';
import StatisticAdmission from './pages/statistic/Admission';
import StatisticSimulacrumScores from './pages/statistic/SimulacrumScores';
import SocialNetworks from './pages/SocialNetworks';
import Offers from './pages/Offers';
import HelpCenter from './pages/HelpCenter';

// Protected Routes
import { ProtectedLivesRoute } from './routes/ProtectedLivesRoute';

export const internalRoutes = [
	{
		path: '/materials',
		element: <Materials />,
	},
	{
		path: '/exams',
		element: <Exams />,
	},
	{
		path: '/profile',
		element: <Profile />,
	},
	{
		path: '/courses/:course',
		element: <RecordedCourse />,
	},
	{
		path: '/statistic/simulacrums',
		element: <StatisticSimulacrums />,
	},
	{
		path: '/statistic/simulacrums/score',
		element: <StatisticSimulacrumScores />,
	},
	{
		path: '/statistic/admission',
		element: <StatisticAdmission />,
	},
	{
		path: '/profile/edit',
		element: <EditProfile />,
	},
	{
		path: '/courses',
		element: <Classes />,
	},
	{
		path: '/courses/:course/classes/:week',
		element: <ClassVideo />,
	},
	{
		path: '/courses/:course/classes',
		element: <RecordedCourse />,
	},
	{
		path: '/courses/:course/classes-prev/:week',
		element: <ClassVideoPrev />,
	},
	{
		path: '/courses/:course/classes-prev',
		element: <RecordedCourse />,
	},
	{
		path: '/social-networks',
		element: <SocialNetworks />,
	},
	{
		path: '/offers',
		element: <Offers />,
	},
	{
		path: '/support-help',
		element: <HelpCenter />,
	},
];

export const internalRoutesVideo = [
	{
		path: '/class/:live/:route',
		element: (
			<ProtectedLivesRoute>
				<MainClassLive />
			</ProtectedLivesRoute>
		),
	},
];
