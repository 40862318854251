import styled from 'styled-components';
import { Body4Mixin } from '../../../../../styles/textGeneral';

export const CourseContentContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (width >= ${({ theme }) => theme.media.mediaXL.width}) {
		// el width deberia ser 359 pero inspeccionando en el navegador se aplica un width menor
		/* width: 359px; */
		width: ${({ menu }) => (menu ? '100%' : '550px')};
	}
`;

export const Header = styled.div`
	background-color: ${({ theme }) => theme.color.neutral1000};
	border-radius: 12px 12px 0 0;
	width: 100%;
	padding-top: ${({ theme }) => theme.space.spacing5X};
	padding-right: ${({ theme }) => theme.space.spacing4X};
	padding-bottom: ${({ theme }) => theme.space.spacing3X};
	padding-left: ${({ theme }) => theme.space.spacing4X};

	p {
		${Body4Mixin}
		color: ${({ theme }) => theme.color.primary300};
	}
`;

export const Footer = styled.div`
	background-color: ${({ theme }) => theme.color.neutral1000};
	border-radius: 0 0 12px 12px;
	width: 100%;
	height: ${({ theme }) => theme.space.spacing5X};
`;
export const WeeklyClasses = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: ${({ theme }) => theme.color.neutral1000};
	gap: ${({ theme }) => theme.space.spacing1X};

	a {
		text-decoration: none;
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing2X};
	}
`;

export const CourseInformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing8X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing9X};
	}
`;

export const WrapperCourse = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing6X};

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		display: grid;
		grid-template-columns: 1fr minmax(359px, 10%);
		gap: ${({ theme }) => theme.space.spacing7X};
	}
`;

export const WrapperFullCourse = styled.div`
	display: flex;
	width: 100%;
	padding-top: 50px;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing8X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing9X};
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		padding-top: 10px;
		gap: ${({ theme }) => theme.space.spacing5X};
	}
	@media (width >= ${({ theme }) => theme.media.mediaXL.width}) {
		padding: 0 5%;
	}
`;
