import { getAuth } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../App';

export function ProtectedLivesRoute({ children }) {
	const { currentUser } = getAuth();
	const { dataOfUser } = useContext(AppContext);

	return currentUser && dataOfUser ? children : <Navigate to={'/login'} state={{ from: location.pathname }} replace />;
	// return currentUser && subscriptionTime.timeRemaining > 0 ? (
	//   children
	// ) : (
	//   <Navigate to={"/"} replace/>
	// )
}
