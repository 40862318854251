import { doc, updateDoc } from 'firebase/firestore';

export const sendRecentPahOfUser = async ({ db, recentPath, userId }) => {
	const data = {
		recentPath,
	};
	const referencia = doc(db, 'users', userId);
	try {
		await updateDoc(referencia, data);
	} catch (error) {
		console.log('error', error);
	}
};
