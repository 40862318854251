import styled from 'styled-components';

export const RecordCardContainer = styled.div`
	background: ${(props) => props.theme.color.neutral10};
	border-radius: 14px;
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	display: flex;
	padding: 20px;
	align-items: center;
	flex-direction: column;
	font-weight: ${(props) => props.theme.font.weight.medium};
	margin: 0 0 20px 0;
	font-family: ${(props) => props.theme.font.textFont};

	.title {
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	.detailsContent {
		width: 100%;
	}

	.recordDetails {
		display: flex;
		flex-direction: column-reverse;
	}

	.dateDetailsContent {
		display: flex;
		justify-content: space-around;
	}

	.dateDetails {
		display: flex;
		align-items: center;
	}

	.clock {
		fill: ${(props) => props.theme.color.success800};
	}

	.calender {
		fill: ${(props) => props.theme.color.warning800};
	}

	svg {
		width: 20px;
		margin: 0 10px 0 0;
	}

	p {
		font-weight: ${(props) => props.theme.font.weight.semiBold};
		text-align: center;
		margin: 20px 0;
	}

	button {
		margin: 20px 0 0 0;
	}

	@media (min-width: 768px) {
		.title {
			margin: 0 0 20px 0;
		}

		.recordDetails {
			flex-direction: row;
			justify-content: space-between;
		}

		.dateDetailsContent {
			justify-content: flex-start;
		}

		.dateDetails {
			margin: 0 30px 0 0;
		}

		p {
			margin: 0;
		}
	}

	@media (min-width: 1200px) {
		flex-direction: column;
		padding: 20px;
		align-items: start;
		position: relative;

		.title {
			margin: 0 0 10px 0;
		}

		.detailsContent {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.recordDetails {
			margin: 0 20px 0 0;
		}

		p {
			font-weight: ${(props) => props.theme.font.weight.medium};
		}

		a {
			position: absolute;
			top: 28px;
			right: 20px;
		}

		button {
			width: max-content;
			margin: 0;
		}
	}
`;
