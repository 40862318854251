import styled from 'styled-components';

export const ResExamContainer = styled.div`
	background: ${(props) => props.theme.color.neutral400};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
	font-family: ${(props) => props.theme.font.brandFont};
	margin: 20px 0 0 0;

	h4 {
		margin: 0 0 10px 0;
		font-size: 1.12em;
	}

	p {
		font-size: 0.875em;
	}
`;

export const ExamnsTypeUnlist = styled.ul`
	display: grid;
	gap: 20px;

	li:first-child {
		cursor: pointer;
	}

	li:last-child {
		cursor: pointer;
	}

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
`;
