import React from 'react';
import { CardBookOfAcademyContainer } from './styles/sCardBookOfAcademy';

const Cardbookofacademy = ({ name }) => {
	return (
		<li>
			<CardBookOfAcademyContainer>
				<p>{name}</p>
			</CardBookOfAcademyContainer>
		</li>
	);
};

export default Cardbookofacademy;
