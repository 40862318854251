import { collection, setDoc, serverTimestamp, doc } from 'firebase/firestore';

export const createNewMessage = async (db, live, route, message, name, userUid, username, photoURL) => {
	try {
		const messageRef = doc(collection(db, 'chatRoom', `${live}-${route}`, 'messages'));

		await setDoc(messageRef, {
			message: message,
			createdAt: serverTimestamp(),
			name: name,
			userUid: userUid,
			username: username,
			photoURL: photoURL,
		});
	} catch (err) {
		console.warn(err);
	}
};
