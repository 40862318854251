import styled from 'styled-components';

export const CardTypeExamContainer = styled.div`
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	border-radius: 14px;
	background: ${(props) => (props.disabled ? props.theme.color.neutral10 : props.theme.color.neutral400)};
	transition: all 0.2s;

	svg {
		width: 100%;
	}

	p {
		padding: 14px 20px;
		color: ${(props) => (props.disabled ? props.theme.color.neutral0 : props.theme.color.neutral400)};
	}

	&:hover {
		background: ${(props) => props.theme.color.neutral350};
	}

	&:hover > .cardImageContainer > .cardTransparent {
		background: ${(props) => props.theme.color.neutral10};
	}

	.cardImageContainer {
		position: relative;
	}

	.cardTransparent {
		transition: all 0.2s;
		width: 100%;
		height: calc(100% - 3px);
		position: absolute;
		border-radius: 8px 8px 0 0;
	}
`;
