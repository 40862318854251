import React, { useState } from 'react';
import LogoExpand from '../../LogoExpand';
import { ExpandHeaderContainer, ExpandHeaderContent } from './styles/sExpandHeader';
import { ReactComponent as MenuSVG } from './../../../../icons/menu.svg';
import { ReactComponent as CloseMenuSVG } from './../../../../icons/close.svg';
import InHomeNavbarModal from './../../internalComponents/InHomeNavbarModal';
import { WrapperExpand } from '../../../../styles/generalStyles';
import NavExpandHeader from './NavExpandHeader';

export default function ExpandHeader({ title, scroll }) {
	const [hamburger, setHamburger] = useState(false);

	return (
		<header className="expandHeader">
			<ExpandHeaderContainer scroll={scroll}>
				<WrapperExpand width="auto" margin="0 40px" top="0">
					<ExpandHeaderContent>
						<LogoExpand title={title} />
						<NavExpandHeader />
						<div className="onlyMobile">
							{!hamburger ? (
								<MenuSVG className="menu" onClick={() => setHamburger(!hamburger)} />
							) : (
								<CloseMenuSVG className="close" onClick={() => setHamburger(!hamburger)} />
							)}
							{hamburger && <InHomeNavbarModal />}
						</div>
					</ExpandHeaderContent>
				</WrapperExpand>
			</ExpandHeaderContainer>
		</header>
	);
}
