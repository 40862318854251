import styled from 'styled-components';

export const AnswerComponetsContainer = styled.div`
	display: grid;
	grid-template-columns: 100%;

	.button-report {
		height: 40px !important;

		&:hover {
			color: #9ea6ad;
		}
	}

	@media (min-width: 1200px) {
		grid-template-columns: 68% 1fr;
		gap: 20px;
	}
`;

export const BoxComments = styled.div`
	background: ${(props) => props.theme.color.neutral800};
	border-radius: 14px;
	color: ${(props) => props.theme.color.neutral400};
	width: 100%;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;

	.descriptionComments {
		font-size: 0.813em;
		line-height: 1.25em;
		font-family: ${(props) => props.theme.font.textFont};
		margin: 20px 0 0 0;
		text-align: center;
	}

	@media (min-width: 1200px) {
		height: 500px;
	}
`;
