import styled from 'styled-components';

export const OtherBookContainer = styled.div`
	display: flex;
	font-family: ${(props) => props.theme.font.brandFont};

	p {
		font-weight: ${(props) => props.theme.font.weight.light};
	}

	a {
		font-weight: ${(props) => props.theme.font.weight.medium};
		color: ${(props) => props.theme.color.primary300};
		text-decoration: underline;
	}
`;
