import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainKat from '../components/katPremium/MainKat';
import HeaderLanding from '../components/general/landingComponents/HeaderLanding';

const KatPremium = () => {
	return (
		<>
			<HeaderLanding backgroundColor={'transparent'} hiddeNavBar />
			<Helmet>
				<title>Kat | Ebbiner</title>
				<meta name="description" content="Kat - Asistente Virtual basada en Inteligencia Artificial" />
				<meta property="og:title" content="Kat | Ebbiner" />
				<meta property="og:description" content="Kat - Asistente Virtual basada en Inteligencia Artificial" />
				<meta property="twitter:title" content="Kat | Ebbiner" />
				<meta property="twitter:description" content="Kat - Asistente Virtual basada en Inteligencia Artificial" />
			</Helmet>
			<MainKat />
		</>
	);
};

export default KatPremium;
