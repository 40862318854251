import styled from 'styled-components';
import { Body4Mixin } from '../../../../../../styles/textGeneral';

export const Preview3Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	width: 100%;
	height: 250px;
	border-radius: ${({ theme }) => theme.border.border12};
	padding: 0 0 20px 20px;
	background-image: url(${(props) => props.backgroundImage});
	background-size: cover;
	background-position: center;

	gap: ${({ theme }) => theme.space.spacing6X};

	section {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.space.spacing1X};
	}

	button {
		width: 162px;
		padding: ${({ theme }) => theme.space.spacing3X5};
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		button {
			width: 174px;
			height: 40px;
		}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		height: 300px;
		button {
			width: 198px;
			height: 44px;
		}
	}
`;
export const Title = styled.p`
	${Body4Mixin}
	color: ${({ theme }) => theme.color.neutral300};
`;

export const TitleClass = styled.p`
	${Body4Mixin}
	font-weight: 600;
`;
