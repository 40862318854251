import React from 'react';
import { Button } from './../../../styles/buttonGeneral';
import { ReactComponent as ClockSVG } from './../../../icons/clock-2.svg';
import { ReactComponent as CalenderSVG } from './../../../icons/calender.svg';
import { RecordCardContainer } from './styles/sRecordCard';
import { Link } from 'react-router-dom';

export default function RecordCard({ exam }) {
	return (
		<li>
			<RecordCardContainer>
				<span className="title">Simulacro</span>
				<div className="detailsContent">
					<div className="recordDetails">
						<div className="dateDetailsContent">
							<div className="dateDetails">
								<ClockSVG className="clock" />
								<span>{exam.metadata?.endTime.toDate().toLocaleTimeString('es-PE')}</span>
							</div>
							<div className="dateDetails">
								<CalenderSVG className="calender" />
								<span>{exam.metadata?.endTime.toDate().toLocaleDateString('es-PE')}</span>
							</div>
						</div>
						<p>Puntaje: {exam.metadata?.statistics?.totalScore} puntos</p>
					</div>
					<Link to={'/exams/results'} state={exam}>
						<Button primary text="Ver respuestas" />
					</Link>
				</div>
			</RecordCardContainer>
		</li>
	);
}
