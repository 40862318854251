import styled from 'styled-components';

export const CourseCardContainer = styled.button`
	display: flex;
	background: ${(props) => props.color + '1a'};
	background: ${(props) => props.color === 'yellow' && '#FBFF291a'};
	background: ${(props) => props.color === 'green' && '#11A83C1a'};
	background: ${(props) => props.color === 'bluesky' && '#86F8FF1a'};
	background: ${(props) => props.color === 'brand' && props.theme.color.primary900 + '1a'};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	align-items: center;
	border-radius: 14px;
	overflow: hidden;
	width: 100%;
	height: 70px;
	transition: all 0.2s;

	img {
		width: ${(props) => props.widthSvg || '30px'};
		fill: ${(props) => props.theme.color.neutral10};
		transition: all 0.2s;
	}

	svg {
		fill: ${(props) => props.classN && props.theme.color.neutral900};
	}

	p {
		text-align: initial;
		font-size: 0.75em !important;
		color: ${(props) => (props.color ? props.color : props.theme.color.neutral0)};

		/* color: ${(props) => props.theme.color.neutral0}; */
	}

	span {
		font-size: 0.938em;
		line-height: 1.313em;
		color: #f1f6ff;
		font-weight: 600;
		font-family: ${(props) => props.theme.font.textFont};
		text-align: initial;
		display: block;
		margin: 3px 0 0 0;
	}

	.titleContainerCard {
		color: ${(props) => props.theme.color.primary500};
	}

	.imageContainer {
		width: 70px;
		height: 70px;
		margin: 0 15px 0 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${(props) => (props.color.includes('#') ? props.color : '#fff')};
		background: ${(props) => props.color === 'brand' && props.theme.color.primary900};
		background: ${(props) => props.color === 'yellow' && '#FBFF29'};
		background: ${(props) => props.color === 'green' && '#11A83C'};
		background: ${(props) => props.color === 'bluesky' && '#86F8FF'};
	}

	&:hover {
		background: ${(props) => props.color + '33'};
		background: ${(props) => props.color === 'yellow' && '#FBFF2933'};
		background: ${(props) => props.color === 'brand' && props.theme.color.primary900 + '33'};
		background: ${(props) => props.color === 'green' && '#11A83C33'};
		background: ${(props) => props.color === 'bluesky' && '#86F8FF33'};
	}

	&:hover > .imageContainer > svg {
		transform: scale(1.1);
	}
`;
