export const generalGroupLink = [
	{
		image:
			'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/Icons%2FsocialMedia%2FFacebook-logo.svg?alt=media&token=c96d489a-3d8c-4570-bb06-4f7fd0e82776',
		title: 'Facebook',
		description: 'Únete a nuestra página de Facebook',
		widthSvg: '80%',
		color: '#ffffff',
		url: 'https://www.facebook.com/TeamDecide',
	},
	// {
	// 	image:
	// 		'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/Icons%2FsocialMedia%2Fwhatsapp-logo.svg?alt=media&token=3612f712-a5a4-4e3c-bd4d-2dbd3e9eebd2',
	// 	title: 'WhatssApp',
	// 	description: 'Únete a nuestro grupo de WhatssApp',
	// 	widthSvg: '80%',
	// 	color: '#25D366',
	// 	url: 'https://chat.whatsapp.com/IDrWWQzjc8tLxkc0wP93bk',
	// },
];
