import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as CheckCircleSVG } from './../../../../icons/check-circle.svg';

const sparkles = keyframes`
  0% {
    background-position: 0% 200%;
  }
  50% {
    background-position: 200% 0%;
  }
  100% {
    background-position: 0% 330%;
  }
`;

export const UnlistFeatures = styled(({ features, ...rest }) => (
	<ul {...rest}>
		{features.map((element) => {
			return (
				<li>
					<CheckCircleSVG className="checkCircle" />
					{element}
				</li>
			);
		})}
	</ul>
))`
	padding: 30px 20px 40px 20px;

	li {
		display: flex;
		margin: 0 0 20px 0;

		&:last-child {
			margin: 0;
		}

		span {
			font-weight: ${(props) => props.theme.font.weight.semiBold};
		}
	}

	.checkCircle {
		width: 20px;
		margin: 0 10px 0 0;
		flex: 0 0 auto;
	}
`;

export const PriceCardContainer = styled.article`
	background: ${(props) => props.theme.color.neutral0};
	position: relative;
	margin: 0 0 20px 0;
	border-radius: 14px;
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	font-family: ${(props) => props.theme.font.textFont};
	cursor: default;
	color: ${(props) => props.theme.color.neutral900};

	${(props) =>
		props.discount &&
		css`
			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				margin: -4px;
				border-radius: inherit;
				background-image: linear-gradient(120deg, #eba102, #f10271, #00c298);
				animation: background-image ${sparkles} 15s linear infinite;
			}
		`}

	.backgroundPriceCard {
		width: 100%;
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${(props) => props.theme.color.neutral0};

		img {
			width: 100%;
			position: absolute;
			height: inherit;
			z-index: 100;
			border-radius: 12px 12px 0 0;
		}
	}

	.backgroundDetails {
		position: absolute;
		z-index: 100;
		text-align: center;

		h2 {
			font-size: 1em;
			font-weight: ${(props) => props.theme.font.weight.bold};
		}

		h3 {
			font-size: 2.375em;
			font-weight: ${(props) => props.theme.font.weight.semiBold};
			margin: 10px 0 1px 0;
		}

		span {
			font-size: 0.813em;
			font-weight: ${(props) => props.theme.font.weight.semiBold};
			color: ${(props) => props.theme.color.neutral200};
			display: block;
		}
	}

	.buttonsContainer {
		font-family: ${(props) => props.theme.font.brandFont};
		padding: 0 20px 30px 20px;

		p {
			text-align: center;
			font-weight: ${(props) => props.theme.font.weight.semiBold};
		}
		button {
			margin-top: 5px;
		}

		.regularPrice {
			color: ${(props) => props.theme.color.neutral400};
			text-decoration: line-through;
		}
	}

	.temporalOffer {
		position: absolute;
		padding: 8px 20px;
		background: linear-gradient(120deg, #eba102, #f10271, #00c298);
		background-size: 400% 300%;
		z-index: 101;
		top: -17px;
		border-radius: 8px;
		color: ${(props) => props.theme.color.neutral0};
		margin: 0 auto;
		left: 0;
		right: 0;
		width: max-content;
		animation: ${sparkles} 15s linear infinite;
		text-transform: uppercase;
		font-weight: ${(props) => props.theme.font.weight.bold};
		font-size: 0.75em;
	}
`;
