import styled from 'styled-components';

export const ContentGradient = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 237px;
	justify-content: center;
	align-items: center;

	@media (min-width: 480px) {
		height: 320px;
	}

	@media (min-width: 768px) {
		height: 400px;
	}

	@media (min-width: 1200px) {
		height: 337px;
	}
`;

export const Gradient = styled.div`
	width: 100%;
	position: relative;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	background: -o-radial-gradient(50% 50%, 50% 50%, rgba(128, 76, 236, 0.15) 0%, rgba(209, 30, 225, 0) 100%);
	background: radial-gradient(50% 50% at 50% 50%, rgba(128, 76, 236, 0.15) 0%, rgba(209, 30, 225, 0) 100%);
	-webkit-filter: blur(145px);
	filter: blur(145px);
	z-index: 1;
`;

export const ChildContainer = styled.div`
	position: absolute;
	z-index: 10;
`;
