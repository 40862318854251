import React from 'react';
import MainEditProfile from '../components/profile/MainEditProfile';

const Editprofile = () => {
	return (
		<>
			<MainEditProfile />
		</>
	);
};

export default Editprofile;
