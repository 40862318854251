import { useState, useEffect } from 'react';

export const useInnerWidth = () => {
	const [innerWidth, setInnerWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
	useEffect(() => {
		const handleResize = () => {
			setInnerWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return innerWidth;
};
