import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeftSVG } from '../../../../icons/ArrowLeft.svg';
import { Section1Container } from './styles/sSection1';

export const Section1 = ({ path, icon, iconShow, children }) => {
	return (
		<Section1Container>
			{!!iconShow && path && <Link href={path}>{icon ? icon : <ArrowLeftSVG />}</Link>}
			<h2>{children}</h2>
		</Section1Container>
	);
};
