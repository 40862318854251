import React from 'react';
import { PreviewContainer, Flex, Gradient, ImageComponent, ContentGradient } from './styles/sPreview';
import { TertiaryTitle, TextSubtext1 } from './../../../styles/textGeneral';
import { Wrapper } from './../../../styles/generalStyles';

export default function Preview({
	background,
	backgroundColor,
	title,
	active,
	description,
	image,
	uniqueImage,
	imageWidth,
	reverse,
	children,
	allText,
	displayD,
	margin,
	type,
	padding,
	height,
	them,
	clase,
}) {
	return (
		<PreviewContainer
			active={active}
			background={background}
			backgroundColor={backgroundColor}
			imageWidth={imageWidth}
			allText={allText}
			margin={margin}
			type={type}
			padding={padding}
			height={height}
			them={them}>
			<Wrapper>
				<div className="landingPage">
					<Flex displayD={displayD} reverse={reverse} imageWidth={imageWidth} type={type}>
						<div className={`textContent center ${clase}`}>
							<TertiaryTitle className="titlePreview">{title}</TertiaryTitle>
							<TextSubtext1 className="textPreview">{description}</TextSubtext1>
							{children}
						</div>
						{image && (
							<ContentGradient>
								<ImageComponent src={image} alt={`Imagen de ${title}`} className="previewImage" />
								<Gradient />
							</ContentGradient>
						)}
						{uniqueImage && <img src={uniqueImage} alt={`Imagen de ${title}`} className="previewImage" />}
					</Flex>
				</div>
			</Wrapper>
		</PreviewContainer>
	);
}
