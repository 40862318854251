import styled, { css } from 'styled-components';

//TODO: Update this styles to new styles
/////////////// OLD STYLES ///////////////
const colorMixin = css`
	${(props) =>
		props.white &&
		css`
			color: ${(props) => props.theme.color.neutral0};
		`}

	${(props) =>
		props.black &&
		css`
			color: ${(props) => props.theme.color.neutral900};
		`}
`;

export const PrincipalTitle = styled.h1`
	font-family: ${(props) => props.theme.font.brandFont};
	color: ${(props) => props.theme.color.neutral900};
	font-weight: ${(props) => props.theme.font.weight.bold};
	font-size: 2.563em;
	cursor: default;
	${colorMixin};

	strong {
		font-size: 1em;
		font-weight: ${(props) => props.theme.font.weight.bold};
	}

	@media (orientation: landscape) {
		font-size: 2.55em;
	}

	@media (min-width: 768px) {
		font-size: 3.25em;
	}

	@media (min-width: 1200px) {
		font-size: 3.375em;
	}
`;

export const SecondaryTitle = styled.h2`
	font-family: ${(props) => props.theme.font.textFont};
	color: ${(props) => props.theme.color.neutral900};
	font-size: 1em;
	line-height: 1.5em;
	cursor: default;
	font-weight: ${(props) => props.theme.font.weight.regular};
	${colorMixin};

	${(props) =>
		props.center &&
		css`
			text-align: center;
		`};

	${(props) =>
		props.mb25 &&
		css`
			margin-bottom: 25px;
		`};

	@media (min-width: 768px) {
		font-size: 1.85em;
		line-height: 1.45em;
	}

	@media (min-width: 1200px) {
		font-size: 1.25em;
		line-height: 1.563em;
	}
`;

export const TertiaryTitle = styled.h3`
	font-family: ${(props) => props.theme.font.brandFont};
	color: ${(props) => props.theme.color.neutral900};
	font-weight: ${(props) => props.theme.font.weight.bold};
	font-size: 1.85em;
	cursor: default;
	margin: ${(props) => props.margin || '0 0 20px 0'};
	line-height: 1.25em;
	${colorMixin};

	@media (min-width: 768px) {
		font-size: 2em;
	}

	@media (min-width: 1200px) {
		font-size: 2.125em;
	}

	${(props) =>
		props.mb25 &&
		css`
			margin-bottom: 25px;
		`}

	${(props) =>
		props.mb10 &&
		css`
			margin-bottom: 10px;
		`}
	
	${(props) =>
		props.center &&
		css`
			text-align: center;
		`}

	${(props) =>
		props.noMargin &&
		css`
			margin: 0;
		`}
`;

export const Title2 = styled.h2`
	font-size: 1.563em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.bold};
	padding: 46px 0 10px 0;
	line-height: 1.25em;
	cursor: default;

	@media (min-width: 375px) {
		padding: 58px 0 12px 0;
	}

	@media (min-width: 768px) {
		font-size: 1.763em;
		line-height: 1.55em;
	}

	@media (min-width: 1200px) {
		font-size: 2.47em;
		padding: 85px 0 20px 0;
	}
`;

export const Title3 = styled.h2`
	font-size: 0.938em;
	font-weight: ${(props) => props.theme.font.weight.semiBold};
	font-family: ${(props) => props.theme.font.brandFont};
	color: ${(props) => props.theme.iris500};
	padding: 15px 0 10px 0;
	cursor: default;
	line-height: 1.5em;

	@media (min-width: 375px) {
		padding: 20px 0 12px 0;
	}

	@media (min-width: 768px) {
		font-size: 0.948em;
	}

	@media (min-width: 1200px) {
		font-size: 1.27em;
		line-height: 1.5em;
	}
`;

export const Title4 = styled.h2`
	font-size: 1.375em;
	font-weight: ${(props) => props.theme.font.weight.bold};
	font-family: ${(props) => props.theme.font.brandFont};
	padding: 35px 0 15px 0;
	cursor: default;
	line-height: 1.5em;
	text-align: center;

	@media (min-width: 1200px) {
		font-size: 1.563em;
	}

	${(props) =>
		props.left &&
		css`
			text-align: left;
		`}
`;

export const Title5 = styled.h3`
	font-size: 1.375em;
	font-weight: ${(props) => props.theme.font.weight.semiBold};
	font-family: ${(props) => props.theme.font.brandFont};
	color: ${(props) => props.theme.color.primary300};
	padding: 30px 0 15px 0;
	cursor: default;
	line-height: 1.5em;

	${(props) =>
		props.textBrand &&
		css`
			color: ${(props) => props.theme.color.primary900};
			font-size: 0.95em;
			font-weight: ${(props) => props.theme.font.weight.bold};
		`}
`;

export const Title6 = styled.h3`
	font-size: 1.125em;
	font-weight: ${(props) => props.theme.font.weight.medium};
	font-family: ${(props) => props.theme.font.brandFont};
	color: ${(props) => props.theme.color.primary300};
	padding: 10px 0;
	cursor: default;
	line-height: 1.5em;
`;

export const Text = styled.p`
	font-size: 1em;
	font-family: ${(props) => props.theme.font.textFont};
	line-height: 1.5em;
	font-weight: ${(props) => props.theme.font.weight.regular};
	color: ${(props) => props.theme.color.neutral200};
	cursor: default;
	margin: ${(props) => props.margin};

	${(props) =>
		props.center &&
		css`
			text-align: center;
		`}

	${(props) =>
		props.centerD &&
		css`
			@media (min-width: 1200px) {
				text-align: center;
			}
		`}

  ${(props) =>
		props.boldText &&
		css`
			font-weight: ${(props) => props.theme.font.weight.bold};
			font-size: 1.938em;
		`};

	@media (min-width: 768px) {
		font-size: 0.948em;
		line-height: 1.6em;

		${(props) =>
			props.boldText &&
			css`
				font-weight: ${(props) => props.theme.font.weight.bold};
				font-size: 2.563em;
			`}
	}

	@media (min-width: 1200px) {
		font-size: 1em;
		line-height: 1.5em;
		font-weight: ${(props) => props.theme.font.weight.regular};

		${(props) =>
			props.boldText &&
			css`
				font-weight: ${(props) => props.theme.font.weight.bold};
				font-size: 2.563em;
			`}
	}
`;

export const TextBody1 = styled.p`
	font-size: 1em;
	line-height: 1.313em;
	font-family: ${(props) => props.theme.font.textFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const TextBody3 = styled.p`
	font-size: 0.938em;
	line-height: 1.313em;
	font-family: ${(props) => props.theme.font.textFont};
	font-weight: ${(props) => props.theme.font.weight.semiBold};
`;

export const TextBody4 = styled.p`
	font-size: 0.938em;
	line-height: 1.313em;
	font-family: ${(props) => props.theme.font.textFont};
	font-weight: ${(props) => props.theme.font.weight.regular};

	${(props) =>
		props.mb25 &&
		css`
			margin-bottom: 25px;
		`}
`;

export const TextSubtitle2 = styled.p`
	font-size: 0.938em;
	line-height: 1.5em;
	font-family: ${(props) => props.theme.font.textFont};
	font-weight: ${(props) => props.theme.font.weight.semiBold};

	${(props) =>
		props.mb25 &&
		css`
			margin-bottom: 25px;
		`}
`;

export const TextSubtext1 = styled.p`
	font-size: 0.938em;
	line-height: 1.5em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
	margin: ${(props) => props.margin || '0 0 30px 0'};
	${colorMixin};

	${(props) =>
		props.center &&
		css`
			text-align: center;
		`};

	@media (min-width: 768px) {
		font-size: 0.978em;
	}
	.padingBot {
		margin-top: 30px;
	}
`;

export const TextSubtext2 = styled.p`
	font-size: 0.875em;
	line-height: 1.125em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const TextSubtext4 = styled.p`
	font-size: 0.813em;
	line-height: 1.125em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Title1A = styled.h1`
	font-size: 2.813em;
	font-family: ${(props) => props.theme.font.brandFont};
	font-weight: ${(props) => props.theme.font.weight.bold};
	cursor: default;
`;

/////////////// NEW STYLES ///////////////
// import Link from 'next/link';

const mixinGeneral = css`
	${(props) =>
		props.center &&
		css`
			text-align: center;
		`}

	${(props) =>
		props.right &&
		css`
			text-align: right;
		`}

  ${(props) =>
		props.neutral900 &&
		css`
			color: ${(props) => props.theme.color.neutral900};

			&:hover {
				color: ${(props) => props.theme.color.neutral700};
			}
		`}
  
  ${(props) =>
		props.error300 &&
		css`
			color: ${(props) => props.theme.error300};
		`}

	${(props) =>
		props.neutral0 &&
		css`
			color: ${(props) => props.theme.color.neutral0};

			&:hover {
				color: ${(props) => props.theme.color.neutral200};
			}
		`}

  ${(props) =>
		props.primary200 &&
		css`
			color: ${(props) => props.theme.color.primary200};

			&:hover {
				color: ${(props) => props.theme.color.primary200};
			}
		`}
`;

const mixinTitle = css`
	${mixinGeneral};
	font-family: ${(props) => props.theme.font.brandFont}, sans-serif;
	line-height: 120%;
`;

const mixinText = css`
	${mixinGeneral};
	font-family: ${(props) => props.theme.font?.textFont}, sans-serif;
	line-height: 140%;
`;

// export const LinkStyled = styled(Link)`
// 	${mixinGeneral};

// 	${(props) =>
// 		props.underline &&
// 		css`
// 			&:hover {
// 				text-decoration: underline;
// 			}
// 		`}
// `;

export const Headline1Mixin = css`
	${mixinTitle};
	font-size: 4.8rem;
	font-weight: ${(props) => props.theme.font.weight.semiBold};
`;

export const Headline2Mixin = css`
	${mixinTitle};
	font-size: 3.8rem;
	font-weight: ${(props) => props.theme.font.weight.semiBold};
`;

export const Headline3Mixin = css`
	${mixinTitle};
	font-size: 3.067rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Headline4Mixin = css`
	${mixinTitle};
	font-size: 2.467rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Headline5Mixin = css`
	${mixinTitle};
	font-size: 2.467rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Headline6Mixin = css`
	${mixinTitle};
	font-size: 1.933rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Body1Mixin = css`
	${mixinText};
	font-size: 1.533rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Body2Mixin = css`
	${mixinText};
	font-size: 1.533rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
`;

export const Body3Mixin = css`
	${mixinText};
	font-size: 1.267rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Body4Mixin = css`
	${mixinText};
	font-size: 1rem;
	font-weight: ${(props) => props.theme.font?.weight.semibold};
`;

export const Body5Mixin = css`
	${mixinText};
	font-size: 1rem;
	font-weight: ${(props) => props.theme.font?.weight.medium};
`;

export const Body6Mixin = css`
	${mixinText};
	font-size: 0.933rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Body7Mixin = css`
	${mixinText};
	font-size: 0.933rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Body8Mixin = css`
	${mixinText};
	font-size: 0.867rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
`;

export const Body9Mixin = css`
	${mixinText};
	font-size: 0.867rem;
	font-weight: ${(props) => props.theme.font?.weight.medium};
`;

export const Body10Mixin = css`
	${mixinText};
	font-size: 0.867rem;
	font-weight: ${(props) => props.theme.font?.weight.regular};
`;

export const Details1Mixin = css`
	${mixinText};
	font-size: 0.8rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
`;

export const Details2Mixin = css`
	${mixinText};
	font-size: 0.8rem;
	font-weight: ${(props) => props.theme.font.weight.semiBold};
`;

export const Details3Mixin = css`
	${mixinText};
	font-size: 0.8rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
`;

export const Details4Mixin = css`
	${mixinText};
	font-size: 0.667rem;
	font-weight: ${(props) => props.theme.font?.weight.regular};
`;

export const Details5Mixin = css`
	${mixinText};
	font-size: 0.667rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`;

export const Details6Mixin = css`
	${mixinText};
	font-size: 0.533rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
`;

export const Details7Mixin = css`
	${mixinText};
	font-size: 0.533rem;
	font-weight: ${(props) => props.theme.font.weight.semiBold};
`;

export const Button1Mixin = css`
	${mixinText};
	font-size: 0.933rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	letter-spacing: 0.037rem;
`;

export const Headline1 = styled.h1`
	${Headline1Mixin}
`;

export const Headline2 = styled.h2`
	${Headline2Mixin}
`;

export const Headline3 = styled.h3`
	${Headline3Mixin}
`;

export const Headline4 = styled.h4`
	${Headline4Mixin}
`;

export const Headline5 = styled.h5`
	${Headline5Mixin}
`;

export const Headline6 = styled.h6`
	${Headline6Mixin}
`;

export const Body1 = styled.p`
	${Body1Mixin}
`;

export const Body2 = styled.p`
	${Body2Mixin}
`;

export const Body3 = styled.p`
	${Body3Mixin}
`;

export const Body4 = styled.p`
	${Body4Mixin}
`;

export const Body5 = styled.p`
	${Body5Mixin}
`;

export const Body6 = styled.p`
	${Body6Mixin}
`;

export const Body7 = styled.p`
	${Body7Mixin}
`;

export const Body8 = styled.p`
	${Body7Mixin}
`;

export const Body9 = styled.p`
	${Body7Mixin}
`;

export const Body10 = styled.p`
	${Body7Mixin}
`;

export const Details1 = styled.p`
	${Details1Mixin}
`;

export const Details2 = styled.p`
	${Details2Mixin}
`;

export const Details3 = styled.p`
	${Details3Mixin}
`;

export const Details4 = styled.p`
	${Details4Mixin}
`;

export const Details5 = styled.p`
	${Details5Mixin};
`;

export const Details6 = styled.p`
	${Details6Mixin}
`;

export const Details7 = styled.p`
	${Details7Mixin};
`;

export const ErrorText = styled.p`
	${Body10Mixin};
	text-align: end;
	margin-top: 4px;
	color: ${({ theme }) => theme.color?.error400};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		display: none;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		display: block;
	}
`;
