import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../App';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaReportValidator } from './validation.schema';

import { Button } from '../../styles/buttonGeneral';
import { InputContainer } from '../../styles/inputGeneral';
import { Text } from '../../styles/textGeneral';
import { QuestionReportContainer } from './styles/sModalReportQuestion';
import { ErrorText } from '../profile/styles/sMainProfileEdit';

//Import ALGORITHMS
import { sendReportQuestion } from './algorithms/sendReportQuestion';
import { useFirestore } from 'reactfire';
import LocalSpinner from '../spinner/LocalSpinner';

export default function ModalReportQuestion({ setModalStateReport, dataQuestionReport }) {
	const db = useFirestore();
	const { dataOfUser } = useContext(AppContext);
	const { uid, username = '' } = dataOfUser;
	const [localLoading, setLocalLoading] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		mode: 'all',
		resolver: yupResolver(schemaReportValidator),
	});

	const handleSubmitData = async (data) => {
		setLocalLoading(true);
		await sendReportQuestion({ db, data, dataQuestionReport, uid, username });
		reset();
		setModalStateReport(false);
		setLocalLoading(false);
	};

	return (
		<>
			<QuestionReportContainer onSubmit={handleSubmit(handleSubmitData)}>
				<Text center>
					Por favor, comparte detalles sobre la pregunta incorrecta (confusa o respuesta incorrecta) y proporciona una
					justificación lo más clara y precisa posible. Tu informe ayudará a mejorar la calidad del examen. ¡Gracias por tu ayuda
					y colaboración! ¡Valoraremos tu esfuerzo!
				</Text>
				{localLoading ? (
					<LocalSpinner title={'Enviando...'} />
				) : (
					<>
						<InputContainer>
							<label htmlFor="report">Escribe un comentario</label>
							<textarea
								type="text"
								placeholder="Escribe aquí tu comentario"
								name="commentOfReport"
								{...register('commentOfReport')}
								required
							/>
							{errors?.commentOfReport && <ErrorText>{errors.commentOfReport.message}</ErrorText>}
						</InputContainer>

						<div className="buttons-Content">
							<Button
								secondary
								text="Cancelar"
								onClick={() => {
									setModalStateReport(false);
								}}
							/>
							<Button primary text="Enviar" type="submit" />
						</div>
					</>
				)}
			</QuestionReportContainer>
		</>
	);
}
