import styled from 'styled-components';
import { Body3Mixin } from '../../../../../styles/textGeneral';

export const TeacherInformationContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing4X};

	div {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.space.spacing3X};
	}

	section {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${({ theme }) => theme.space.spacing3X};
	}

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid ${({ theme }) => theme.color.primary300};
	}
`;

export const Title = styled.p`
	${Body3Mixin}
	font-size: 15px;
	font-weight: ${({ theme }) => theme.font.weight.semiBold};

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		${Body3Mixin}
	}
`;
