import styled from 'styled-components';

export const UserProfileContainer = styled.div`
	font-family: ${(props) => props.theme.font.textFont};
	padding: 0 0 70px 0;

	@media (min-width: 768px) {
		padding: 0 0 30px 0;
	}
`;
