import React from 'react';
import { ComentContainer, Reactions, UserInformation } from './styles/sComments';
import { Body7, Body8, Details3 } from '../../../../styles/textGeneral';
import { ReactComponent as HeartSVG } from '../../../../icons/Heart.svg';

export const Comment = ({ userImageLink, userName, userComent, numberCommentReactions = 0 }) => {
	return (
		<ComentContainer>
			<UserInformation>
				<img src={userImageLink} />
				<Body7>{userName}</Body7>
			</UserInformation>
			<Body8>{userComent}</Body8>
			<Reactions>
				<HeartSVG />
				<Details3>{numberCommentReactions}</Details3>
			</Reactions>
		</ComentContainer>
	);
};
