import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ItemFooterLanding from './ItemFooterLanding';
import { Wrapper } from './../../../styles/generalStyles';
import { ReactComponent as HomeFooterSVG } from './../../../icons/home-footer.svg';
import { ReactComponent as CellphoneSVG } from './../../../icons/cellphone.svg';
// import { ReactComponent as PhoneSVG } from './../../../icons/phone.svg';
import { ReactComponent as EmailSVG } from './../../../icons/email.svg';
import {
	FooterLandingContainer,
	AuthorsFooterLanding,
	LinkColumn,
	SocialMediaContainer,
	SocialMediaColumn,
	LinkContainer,
} from './styles/sFooterLanding';
import { ReactComponent as FacebookSVG } from './../../../icons/facebook.svg';
// import { ReactComponent as InstagramSVG } from './../../../icons/instagram.svg';
import { ReactComponent as YoutubeSVG } from './../../../icons/youtube.svg';
import { ReactComponent as TiktokSVG } from './../../../icons/tiktok.svg';

const Footerlanding = () => {
	const [t] = useTranslation('main');
	const listItemsFooter = [
		{
			key: 'space',
			icon: <HomeFooterSVG />,
			text: 'Av. Ramon Castilla N° 509',
			link: 'https://www.google.com/maps/place/Av.+Ramon+Castilla+509,+Ayacucho+05001/@-13.1647622,-74.2186976,18.75z/data=!4m5!3m4!1s0x91127d8aeaab9771:0xe634c490cafde9f9!8m2!3d-13.164224!4d-74.2191116?authuser=0&entry=ttu',
			type: 'direction',
		},
		{
			key: 'cellphone',
			icon: <CellphoneSVG />,
			text: '(+51) 959 187 087',
			link: 'https://api.whatsapp.com/send?phone=+51959187087&text=%C2%A1Hola!%20quiero%20más%20información%20sobre%20la%20Academia%20Decide.',
			type: 'whatsapp',
		},
		// { key: 'phone', icon: <PhoneSVG />, text: '(066) 635859', type: 'phone' },
		{ key: 'email', icon: <EmailSVG />, text: 'soporteebbiner@gmail.com', type: 'email' },
	];

	useEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, []);

	return (
		<footer>
			<FooterLandingContainer>
				<Wrapper>
					<LinkContainer>
						<LinkColumn className="linkColumn">
							<Link className="sectionTitle" to="/about-us">
								Acerca de nosotros
							</Link>
							<Link to="/">Qué es Ebbiner </Link>
							{/* <Link to="/">Empleos y pasantías </Link> */}
						</LinkColumn>
						<LinkColumn className="linkColumn">
							<Link className="sectionTitle" to="/terms-conditions">
								Ebinner SAC
							</Link>
							<Link to="/terms-conditions">Términos y condiciones </Link>
							{/* <Link to="/">Privacidad </Link>
							<Link to="/">Copyrigth </Link> */}
						</LinkColumn>
						<LinkColumn className="linkColumn">
							<Link className="sectionTitle" to="/help-center">
								Ayuda
							</Link>
							<Link to="/help-center">Centro de ayuda </Link>
							{/* <Link to="/">Preguntas frecuentes </Link> */}
							<Link to="/help-contacts">Contáctanos </Link>
						</LinkColumn>
					</LinkContainer>
					<ul className="unlistedListItemsFooter">
						{listItemsFooter.map((item) => {
							return <ItemFooterLanding key={item.key} {...item} />;
						})}
					</ul>
					<SocialMediaContainer>
						<SocialMediaColumn>
							<a href="https://www.facebook.com/TeamDecide" target="_blank" rel="noopener noreferrer">
								<FacebookSVG />
							</a>
						</SocialMediaColumn>
						{/* <SocialMediaColumn>
							<a href="https://www.instagram.com/ebbineroficial/" target="_blank" rel="noopener noreferrer">
								<InstagramSVG />
							</a>
						</SocialMediaColumn> */}
						<SocialMediaColumn>
							<a href="https://www.youtube.com/@AcademiaDecide" target="_blank" rel="noopener noreferrer">
								<YoutubeSVG />
							</a>
						</SocialMediaColumn>
						<SocialMediaColumn>
							<a href="https://www.tiktok.com/@academiadecide" target="_blank" rel="noopener noreferrer">
								<TiktokSVG />
							</a>
						</SocialMediaColumn>
					</SocialMediaContainer>
				</Wrapper>
			</FooterLandingContainer>
			<AuthorsFooterLanding>
				<p>{t('landing.details.copyright')}</p>
			</AuthorsFooterLanding>
		</footer>
	);
};

export default Footerlanding;
