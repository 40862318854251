import React from 'react';
import { SharedWithFriendsContainer } from './styles/sShareWithFriends';
import { Details3 } from '../../styles/textGeneral';

export const ShareWithFriends = ({ text, setModalState }) => {
	return (
		<SharedWithFriendsContainer onClick={() => setModalState((prev) => !prev)}>
			<Details3>{text}</Details3>
		</SharedWithFriendsContainer>
	);
};
