import React, { useState } from 'react';
import { InputLabelResult, TextQuestionStyled, QuestionInputContainer } from './../../exams/form/styles/sQuestionInput';
import MainModal from './../../modal/MainModal';
import ResolucionQuestion from './../cardsModal/ResolucionQuestion';
import { Latex } from '../../general/latex/Latex';
import OldLatex from 'react-latex-next';

export default function QuestionInputResult({ stateQuestion, question, keys, UrlOfImage, trueKey, markedKey, isKatex }) {
	const [modalState, setModalState] = useState(false);
	const keysList = 'abcde';

	return (
		<>
			<QuestionInputContainer>
				<li>
					<TextQuestionStyled>
						{isKatex ? <Latex>{question}</Latex> : <OldLatex>{question}</OldLatex>}
						{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
					</TextQuestionStyled>
					<div className="questionContainer">
						<InputLabelResult stateQuestion={stateQuestion}>
							<span className="keyLetterQuestion">{keysList[keys.indexOf(trueKey)]}</span>
							<div className="question">
								<p>
									{isKatex ? (
										<Latex>{markedKey ? markedKey : trueKey}</Latex>
									) : (
										<OldLatex>{markedKey ? markedKey : trueKey}</OldLatex>
									)}
								</p>
								{markedKey && markedKey !== trueKey && (
									<button
										onClick={(e) => {
											e.preventDefault();
											setModalState(!modalState);
										}}>
										Ver respuesta
									</button>
								)}
							</div>
						</InputLabelResult>
					</div>
				</li>
			</QuestionInputContainer>
			<MainModal modalState={modalState} setModalState={setModalState} title="Solución de la pregunta" type="classic">
				<ResolucionQuestion
					isKatex={isKatex}
					UrlOfImage={UrlOfImage}
					question={question}
					stateQuestion={stateQuestion}
					markedKey={markedKey}
					trueKey={trueKey}
				/>
			</MainModal>
		</>
	);
}
