import React from 'react';
import MainAboutUs from '../components/aboutUs/MainAboutUs';
import { Helmet } from 'react-helmet-async';

export default function AboutUs() {
	return (
		<>
			<Helmet>
				<title>Nosotros | Ebbiner</title>
				<meta name="description" content="Acerca de nosotros" />
				<meta property="og:title" content="Nosotros | Ebbiner" />
				<meta property="og:description" content="Acerca de nosotros" />
				<meta property="twitter:title" content="Nosotros | Ebbiner" />
				<meta property="twitter:description" content="Acerca de nosotros" />
			</Helmet>
			<MainAboutUs />
		</>
	);
}
