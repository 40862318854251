import { doc, getDoc } from 'firebase/firestore';

export const recoversimulacrumStatistics = async (dataOfUser, db) => {
	const { uid } = dataOfUser;
	const statsRef = doc(db, 'admissionExams', uid);
	const statsSnap = await getDoc(statsRef);

	if (statsSnap.exists()) {
		return statsSnap.data();
	}

	return null;
};
