import styled from 'styled-components';

export const MainExamFormContainer = styled.div`
	position: relative;
	padding: 0 0 70px 0;

	.buttonExamFormContainer {
		position: fixed;
		padding: 15px 25px;
		bottom: 50px;
		right: 0;
		left: 0;
		background: ${(props) => props.theme.color.info410};

		button {
			position: relative;
			background: ${(props) => props.theme.color.neutral2};
			color: ${(props) => props.theme.color.neutral0};
			border: 1px solid ${(props) => props.theme.color.neutral0};
			backdrop-filter: blur(20px);
			z-index: 1000;

			&:hover {
				background: ${(props) => props.theme.color.neutral0};
				color: ${(props) => props.theme.color.primary900};
			}
		}
	}

	.courseCategoryFormContainer {
		margin-bottom: 80px;
	}

	form {
		padding: 0;
	}

	.scoreExamn {
		font-size: 1.125em;
		font-weight: ${(props) => props.theme.font.weight.medium};
		margin: 10px 0 20px 0;
		font-family: ${(props) => props.theme.font.brandFont};
	}

	@media (min-width: 768px) {
		.buttonExamFormContainer {
			padding: 15px 18%;
		}
	}

	@media (min-width: 1024px) {
		.buttonExamFormContainer {
			padding: 15px 15%;
		}
	}

	@media (min-width: 1200px) {
		padding: 10px 0 0 0;

		form {
			height: 100%;
		}

		.courseCategoryFormContainer {
			width: 300px;
			position: relative;
		}

		.buttonExamFormContainer {
			position: fixed;
			bottom: 0;
			margin: 0 5vw 0 auto;
			width: 250px;
			box-shadow: ${(props) => props.theme.shadow.shadow1};
			background: transparent;
			box-shadow: none;
			padding: 15px 0;
		}

		.scoreExamn {
			margin: 10px 0;
		}
	}
`;

export const FormTitleContent = styled.div`
	color: ${(props) => props.theme.color.neutral300};
	font-weight: ${(props) => props.theme.font.weight.medium};
	font-family: ${(props) => props.theme.font.brandFont};
	display: flex;
	flex-direction: column-reverse;
	position: relative;

	@media (min-width: 1200px) {
		display: flex;
		flex-direction: row;
		margin: 15px 0 10px 0;
		position: sticky;
		top: 40px;
		z-index: 4000;
	}
`;

export const DetailsPageExamn = styled.div`
	margin: 0 0 30px 0;

	@media (min-width: 1200px) {
		margin: 50px 0 30px 0;
	}
`;

export const SubHeaderExamn = styled.div`
	font-family: ${(props) => props.theme.font.textFont};

	.subHeaderExamnContainer {
		display: flex;
		flex-direction: column-reverse;
	}

	.totalQuestionAndClockDetails {
		p {
			line-height: 1.35em;
		}
	}

	.clockContent {
		display: flex;
		margin: 7px 0 0 0;

		svg {
			margin: 0 5px 0 0;
			width: 24px;
			fill: ${(props) => props.theme.color.neutral0};
		}
	}

	@media (min-width: 1200px) {
		position: sticky;
		top: 49px;
		z-index: 4000;
		background: ${(props) => props.scroll < -49 && '#2b364a'};
		transition: 0.2s all;

		.subHeaderExamnContainer {
			flex-direction: row;
			justify-content: space-between;
		}

		.curses {
			padding: 15px 0;
		}

		.totalQuestionAndClockDetails {
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(1200px - 380px);
			padding: 15px 0;
		}

		.clockContent {
			display: flex;
			margin: 0;

			svg {
				margin: 0 10px 0 0;
			}
		}
	}
`;
