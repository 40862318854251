import { collection, query, where, getDocs } from 'firebase/firestore';

export const recoverUserForEmail = async ({ db, emailForSearch, nameDb = 'usersRegistered' }) => {
	try {
		const userQuery = query(collection(db, nameDb), where('email', '==', emailForSearch));
		const querySnapshot = await getDocs(userQuery);

		// if (querySnapshot.size === 0) {
		// 	return null;
		// }

		const recoverUser = querySnapshot?.docs?.[0]?.data();
		return recoverUser ?? null;
	} catch (error) {
		console.error('Error al recuperar usuario por correo electrónico:', error);
		throw error;
	}
};
