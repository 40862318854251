import styled from 'styled-components';

export const MainCloud = styled.div`
	display: none;
	@media (min-width: 1024px) and (max-width: 2048px) {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 0;
		min-width: 320px;
		img {
			overflow: hidden;
			width: 100%;
			position: absolute;
			&:first-child {
				left: 70px;
				bottom: 30px;
			}
			&:last-child {
				bottom: 0;
			}
		}
	}
`;

export const MainContent = styled.div`
	width: 100vw;
	position: relative;
`;
