import React, { useContext, useState, useEffect } from 'react';
import { Wrapper } from '../../styles/generalStyles';
import { Title4, Text, TextSubtitle2 } from '../../styles/textGeneral';
import {
	IconQuiz,
	InfoQuiz,
	MainContainer,
	PrincipalQuizContainer,
	QuizPuntuation,
	SequenceContent,
	SmallCircle,
} from './styles/sMainQuiz';
import ProgressBar from '../../shared/ProgressBar/ProgressBar';
import {
	QuestionInputContainer,
	QuestionInputResultContainer /*QuestionInputResultContainer*/,
} from '../exams/form/styles/sCourseCategoryForm';
import QuestionInputResultQuiz from './QuestionInputResultQuiz';
import QuestionInput from './QuestionInputQuiz';
import { useTranslation } from 'react-i18next';
import { Button } from '../../styles/buttonGeneral';
import Alternative from './Alternative';
import { AppContext } from '../../App';
import { recoverQuestionsPerWeek } from '../../algorithms/recoverQuestionsPerWeek';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import SimpleSpinner from '../../shared/Content-loaders/SimpleSpinner';

const MainQuiz = () => {
	const db = useFirestore();
	const [t] = useTranslation('main');
	const { course, week } = useParams();
	const navigate = useNavigate();
	const { quiz, setQuiz } = useContext(AppContext);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [markedKey] = useState(false);
	const [questions, setQuestions] = useState();
	const [correctQuiz, setCorrectQuiz] = useState();
	const [error, setError] = useState(false);

	useEffect(() => {
		if (week && course) {
			setQuiz({
				key: null,
				uqidSelected: null,
				selectAlternative: null,
				score: 0,
				incorrectAnswers: 0,
				skipAnswers: 0,
				totalMarkedAnswers: {},
				markedKeyIncorrect: {},
				markedKeySkip: {},
			});
			recoverQuestionsPerWeek({ db, week, course, setError })
				.then((resQ) => {
					setQuestions(resQ.error === 404 ? resQ : resQ?.newQuestions);
					setCorrectQuiz(resQ?.questions);
					setError(false);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [week, course]);

	const nextQuestionOrReview = () => {
		setQuiz({ selectAlternative: null });
		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions?.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
	};

	const handleAnswerButtonClick = (question) => {
		let uqid = questions[currentQuestion].uqid;
		if (question === quiz.key) {
			setQuiz({
				score: quiz.score + 1,
				totalMarkedAnswers: {
					...quiz.totalMarkedAnswers,
					[uqid]: { markedKey: quiz.key, uqid: uqid, option: 'correct', trueKey: correctQuiz[currentQuestion].keys[0] },
				},
			});
		} else {
			setQuiz({
				incorrectAnswers: quiz.incorrectAnswers + 1,
				totalMarkedAnswers: {
					...quiz.totalMarkedAnswers,
					[uqid]: { markedKey: quiz.key, uqid: uqid, option: 'incorrect', trueKey: correctQuiz[currentQuestion].keys[0] },
				},
			});
		}
		nextQuestionOrReview();
	};

	const handleSkipQuestion = () => {
		let uqid = questions[currentQuestion].uqid;
		setQuiz({
			skipAnswers: quiz.skipAnswers + 1,
			totalMarkedAnswers: {
				...quiz.totalMarkedAnswers,
				[uqid]: { markedKey: quiz.key, uqid: uqid, option: 'skip', trueKey: correctQuiz[currentQuestion].keys[0] },
			},
		});
		nextQuestionOrReview();
	};

	if (!questions) {
		return <SimpleSpinner />;
	}

	const SequenceView = (
		<SequenceContent>
			{/* TODO: Arreglar botones */}
			<Button primary secondary text="Saltar pregunta" style={{ margin: 'initial' }} onClick={handleSkipQuestion} />
			<Button
				sixth
				disabled={quiz.selectAlternative === null}
				text={currentQuestion === questions?.length - 1 ? 'Finalizar preguntas' : 'Siguiente pregunta'}
				style={{ margin: 'initial' }}
				onClick={() => handleAnswerButtonClick(correctQuiz[currentQuestion].keys[0])}
			/>
		</SequenceContent>
	);

	return (
		<PrincipalQuizContainer>
			<Wrapper>
				<MainContainer>
					{questions?.error === 404 || error ? (
						<>
							<Title4>{questions?.message} ❌</Title4>
							{/* TODO: Arreglar el boton */}
							<Button purple onClick={() => course && week && navigate(`/courses/${course}/classes/${week}`)}>
								Volver atrás
							</Button>
						</>
					) : (
						<>
							<Title4>
								{!showScore
									? 'Cuestionario ' + t(`courses.${questions?.[0]?.course}`)
									: 'Resolución del cuestionario: ' + t(`courses.${questions?.[0]?.course}`)}
							</Title4>
							<Text center margin="0 0 40px">
								El cuestionario consta de {questions?.length} preguntas
							</Text>
							<InfoQuiz>
								<div className="info-description">
									<IconQuiz iconBackground={'#007aff'}>
										<img
											src="https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/Icons%2Fcourses%2FFilosof%C3%ADa%20ICON.svg?alt=media&token=569a80b6-39e6-4275-a457-cecf98d4acc3"
											alt="Imágen del curso"
										/>
									</IconQuiz>
									<TextSubtitle2 white>
										{t(`courses.${questions?.[0]?.course}`)} | {t(`weeks.${week}`)}
									</TextSubtitle2>
								</div>
								{!showScore ? (
									<TextSubtitle2 white>
										Pregunta {currentQuestion + 1} de {questions?.length}
									</TextSubtitle2>
								) : (
									questions?.length == 5 && (
										<QuizPuntuation>
											<TextSubtitle2>
												<SmallCircle green />
												Correctas: {quiz.score}
											</TextSubtitle2>
											<TextSubtitle2>
												<SmallCircle red />
												Incorrectas: {quiz.incorrectAnswers}
											</TextSubtitle2>
											<TextSubtitle2>
												<SmallCircle yellow />
												No respondidas: {quiz.skipAnswers}
											</TextSubtitle2>
										</QuizPuntuation>
									)
								)}
							</InfoQuiz>
							{!showScore && (
								<ProgressBar
									completed={!showScore ? (currentQuestion / questions?.length) * 100 : 100}
									heightSize={8}
									borderRadius={8}
									margin={'auto auto 30px'}
								/>
							)}
							{!showScore ? (
								<>
									{questions?.map((question, index) => {
										if (index === currentQuestion) {
											return (
												<QuestionInputContainer key={question.uqid}>
													<QuestionInput
														isKatex={question?.isKatex}
														trueKey={question?.trueKey}
														markedKey={markedKey}
														renderInput={(value) => <Alternative {...value} questionItem={question} />}
														{...question}
													/>
													<br />
												</QuestionInputContainer>
											);
										}
									})}
									{SequenceView}
								</>
							) : (
								<>
									{questions?.map((question) => {
										return (
											<QuestionInputResultContainer key={question.uqid}>
												<QuestionInputResultQuiz
													isKatex={question?.isKatex}
													key={question?.uqid}
													stateQuestion={
														(quiz.totalMarkedAnswers[question?.uqid].option === 'correct' && 'correct') ||
														(quiz.totalMarkedAnswers[question?.uqid].option === 'incorrect' && 'incorrect') ||
														(quiz.totalMarkedAnswers[question?.uqid].option === 'skip' && 'unaswered')
													}
													markedKey={quiz.totalMarkedAnswers[question?.uqid].markedKey}
													trueKey={quiz.totalMarkedAnswers[question?.uqid].trueKey}
													{...question}
												/>
												<br />
											</QuestionInputResultContainer>
										);
									})}
								</>
							)}
						</>
					)}
				</MainContainer>
			</Wrapper>
		</PrincipalQuizContainer>
	);
};

export default MainQuiz;
