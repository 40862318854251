import React from 'react';
import { LocalSpinnerContainer } from './styles/sLocalSpinner';

export default function LocalSpinner({ title }) {
	return (
		<LocalSpinnerContainer>
			<div className="sk-chase">
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
			</div>
			{title && <p>{title}</p>}
		</LocalSpinnerContainer>
	);
}
