import { writeBatch, doc, collection, runTransaction, Timestamp } from 'firebase/firestore';

export const sendToken = async ({ db, data, setLoading, setSubscriptionTime, dataOfUser, planData, navigate }) => {
	setLoading({ status: true, title: 'Procesando pago...' });
	const chargeData = {
		amount: planData.amount,
		currency_code: planData.currency,
		email: data.email,
		source_id: data.id,
		capture: true,
		description: planData.nameOfPlan,
		antifraud_details: {
			email: dataOfUser?.email,
			phone: dataOfUser?.phoneNumber,
			first_name: dataOfUser?.name.length > 0 ? dataOfUser?.name.firstName : 'No name',
			last_name: dataOfUser?.name.length > 0 ? dataOfUser?.name.lastName : 'No name',
		},
	};
	const batch = writeBatch(db);
	const userDocRef = doc(db, 'users', dataOfUser.uid);
	const userLogDocRef = doc(collection(db, 'usersLogs', dataOfUser.uid, 'payments'));
	await fetch(process.env.REACT_APP_CULQI_CHARGES_URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + process.env.REACT_APP_CULQI_PRIVATE_KEY,
		},
		body: JSON.stringify(chargeData),
	})
		.then((response) => response.json())
		.then(async (res) => {
			if (res.object === 'charge') {
				//PANTALLA DE NOTIFICATION CUANDO LA TARJETA PASA.
				try {
					await runTransaction(db, async (transaction) => {
						const userDoc = await transaction.get(userDocRef);
						if (!userDoc.exists()) throw 'Document does not exist!';
						let subscriptionUpdate = null;
						if (userDoc.data().subscriptionData?.endTimestamp) {
							subscriptionUpdate = Timestamp.fromDate(
								new Date(
									new Date(userDoc.data().subscriptionData?.endTimestamp.toDate()).setDate(
										new Date(userDoc.data().subscriptionData?.endTimestamp.toDate()).getDate() +
											Math.ceil(30.5 * parseInt(planData.intervalCount)) +
											(planData.trialDays ?? 0),
									),
								),
							);
							setSubscriptionTime((prev) => ({
								...prev,
								endTimestamp: subscriptionUpdate.subscriptionData.endTimestamp,
								timeRemaining:
									prev.timeRemaining + Math.ceil(30.5 * parseInt(planData.intervalCount)) + (planData.trialDays ?? 0),
							}));
						} else {
							subscriptionUpdate = {
								subscriptionData: {
									endTimestamp: Timestamp.fromDate(
										new Date(
											new Date().setDate(
												new Date().getDate() + Math.ceil(30.5 * parseInt(planData.intervalCount)) + (planData.trialDays ?? 0),
											),
										),
									),
								},
							};
						}
						setSubscriptionTime((prev) => ({
							...prev,
							endTimestamp: subscriptionUpdate.subscriptionData.endTimestamp,
							timeRemaining: prev.timeRemaining + Math.ceil(30.5 * parseInt(planData.intervalCount)) + (planData.trialDays ?? 0),
						}));
						transaction.update(userDocRef, subscriptionUpdate);
					});
				} catch (err) {
					console.error(err);
				}
				batch.set(userLogDocRef, res, { merge: true });
				await batch.commit();
				setLoading({ status: false, title: null });
				navigate('/profile');
			} else {
				//PANTALLA DE NOTIFICATION CUANDO LA TARJETA NO PASA.
				alert('Error en la tarjeta');
				setLoading({ status: false, title: null });
			}
		})
		.catch((error) => {
			console.error(error);
			setLoading({ status: false, title: null });
		});
};
