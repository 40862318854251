import React, { useState, useEffect } from 'react';
import { Wrapper } from '../../../styles/generalStyles';
import { ReactComponent as MenuSVG } from './../../../icons/menu.svg';
import { ReactComponent as CloseMenuSVG } from './../../../icons/close.svg';
import { HeaderInHomeContainer, HeaderInHomeItems } from './../internalComponents/styles/sHeaderInHome';
import InHomeNavbarModal from './../internalComponents/InHomeNavbarModal';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './../../../icons/logo-ebbiner-comp.svg';
import { ReactComponent as LogoWhite } from './../../../icons/logo-ebbiner-white.svg';

const Headerinhome = () => {
	const [hamburger, setHamburger] = useState(false);
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const setWindowWidth = () => setWidth(window.innerWidth);

		return () => {
			window.removeEventListener('resize', setWindowWidth);
		};
	});

	return (
		<header>
			<HeaderInHomeContainer>
				<Wrapper>
					<HeaderInHomeItems>
						<Link to="/">{width > 1200 ? <LogoWhite className="logo" /> : <Logo className="logo" />}</Link>
						<div>
							{!hamburger ? (
								<MenuSVG className="menu" onClick={() => setHamburger(!hamburger)} />
							) : (
								<CloseMenuSVG className="close" onClick={() => setHamburger(!hamburger)} />
							)}
							{hamburger && <InHomeNavbarModal setHamburger={setHamburger} hamburger={hamburger} />}
						</div>
					</HeaderInHomeItems>
				</Wrapper>
			</HeaderInHomeContainer>
		</header>
	);
};

export default Headerinhome;
