import { writeBatch, doc } from 'firebase/firestore';

export const CollectInfo = async (db, phone, dataOfUser = false) => {
	const batch = writeBatch(db);

	if (phone && phone.startsWith('51')) {
		const logDocRef = doc(db, 'whatsAppLogs', phone);
		if (dataOfUser?.uid) {
			batch.set(
				logDocRef,
				{
					userUid: dataOfUser?.uid,
				},
				{ merge: true },
			);
		}
		batch.set(
			logDocRef,
			{
				hasInternet: true,
			},
			{ merge: true },
		);
	}
	batch
		.commit()
		.then(() => {
			console.info('Success');
		})
		.catch((err) => {
			console.error(err);
		});
};
