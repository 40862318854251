import styled from 'styled-components';

export const RecordsCardUnlist = styled.ul`
	display: grid;
	gap: 20px;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
`;
