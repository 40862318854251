'use client';
import styled from 'styled-components';

export const Preview1Container = styled.div`
	display: flex;
	flex-direction: column;
	align-self: stretch;
	width: 250px;
	max-width: 400px;
	/* height: 380px; */
	border-radius: ${({ theme }) => theme.border.border12};
	cursor: pointer;
`;

export const PicturePreview1Container = styled.picture`
	display: flex;
	position: relative;
	span {
		right: 12px;
		top: 12px;
		position: absolute;
	}

	aside {
		position: absolute;
		top: 12px;
		left: 12px;
	}
	img {
		width: 250px;
		height: 290px;
		border-radius: ${({ theme }) => theme.border.border12} ${({ theme }) => theme.border.border12} 0 0;
	}
`;

export const DescriptionPreview1Container = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	padding: ${({ theme }) => theme.space.spacing4X};
	gap: ${({ theme }) => theme.space.spacing1X};
	background-color: ${({ theme }) => theme.color.neutral5};
	border-radius: 0 0 ${({ theme }) => theme.border.border12} ${({ theme }) => theme.border.border12};
	font-size: 14px;
	height: auto;

	& > *:first-child {
		font-size: 12px;
		color: ${({ theme }) => theme.color.neutral400};
	}

	& > *:last-child {
		color: ${({ theme }) => theme.color.neutral0};
	}
`;
