import { Helmet } from 'react-helmet-async';
import MainAnswer from './../answer/MainAnswer';

export default function Answer() {
	return (
		<>
			<Helmet>
				<title>Resolución de pregunta | Ebbiner</title>
				<meta name="description" content="Resolución de preguntas" />
				<meta property="og:title" content="Resolución de pregunta | Ebbiner" />
				<meta property="og:description" content="Resolución de preguntas" />
				<meta property="twitter:title" content="Resolución de pregunta | Ebbiner" />
				<meta property="twitter:description" content="Resolución de preguntas" />
			</Helmet>
			<MainAnswer />
		</>
	);
}
