import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../App';
import { useTranslation } from 'react-i18next';
import QuestionInput from './QuestionInput';
import { examns } from '../../../constants/exams';
import { CourseCategoryFormContainer, QuestionInputContainer, QuestionInputResultContainer } from './styles/sCourseCategoryForm';
import { Timestamp } from 'firebase/firestore';
import QuestionInputResult from './../../general/cOthers/QuestionInputResult';
import { ReactComponent as CheckSVG } from '../../../icons/check.svg';
import { ReactComponent as ChatReportSVG } from '../../../icons/report/chat-report.svg';
import { ReactComponent as ChatReportResultSVG } from '../../../icons/report/circle-information.svg';

export default function CourseCategoryForm({
	modalStateReport,
	setModalStateReport,
	setDataQuestionReport,
	questions,
	setDetailsOpened,
	detailsOpened,
	isReview,
}) {
	const [t] = useTranslation('main');
	const { setSimulacrum, simulacrum } = useContext(AppContext);

	//crea el timer de la primera pregunta que aparece cuando carga el examen (sin esto no se envia la respuesta de la pregunta)
	useEffect(() => {
		if (!detailsOpened?.actual && !simulacrum.timers && questions[0]?.course === examns.firstCourseVisible) {
			setSimulacrum({
				timers: {
					//Muy importante porque si falta no se envian las preguntas
					//Relacionado con el useEffect del QuestionInput
					questionStartTime: Timestamp.now().toDate(),
				},
			});
		}
	}, []);

	return (
		<CourseCategoryFormContainer>
			<details
				//En la primera carga se abre el primer details (curso de razonamiento verbal)
				open={!detailsOpened?.actual && questions[0]?.course === examns.firstCourseVisible}
				className="detailsQuestionInput"
				id={questions[0].course}
				onClick={(e) => {
					//cambio de summary a open, no poner prevevetDefault
					if (e.target.parentNode.tagName === 'DETAILS') {
						setDetailsOpened({
							actual: e.target.parentNode.id,
							previous: detailsOpened?.actual === e.target.parentNode.id ? null : detailsOpened.actual,
						});
						//Inicia y reinicia timer para cada pregunta
						setSimulacrum({
							timers: {
								//Muy importante porque si falta no se envian las preguntas
								//Relacionado con el useEffect del QuestionInput
								questionStartTime: Timestamp.now().toDate(),
							},
						});
					}
				}}>
				<summary
					className={
						!isReview &&
						(simulacrum?.numberOfChecksPerCourse?.[questions[0].course] === questions.length
							? 'courseQuestionCompleted'
							: 'courseQuestionIcompleted')
					}>
					{t(`courses.${questions[0].course}`)}
					{!isReview &&
						(simulacrum?.numberOfChecksPerCourse?.[questions[0].course] === questions.length ? (
							<CheckSVG />
						) : (
							<span>
								{simulacrum?.numberOfChecksPerCourse[questions[0]?.course]
									? simulacrum?.numberOfChecksPerCourse[questions[0]?.course]
									: 0}
								/{questions?.length}
							</span>
						))}
				</summary>
				<ol className="questionInputContainer">
					<p className="titleCourseDesktop">
						{t(`courses.${questions[0]?.course}`)} <span>{questions?.length} preguntas</span>
					</p>
					{questions?.map((question) => {
						return isReview ? (
							<QuestionInputResultContainer key={question.uqid}>
								<ChatReportResultSVG
									onClick={() => {
										setModalStateReport(!modalStateReport);
										setDataQuestionReport({ uidQuestion: question?.uqid, subTopicId: question?.subTopicId ?? '' });
									}}
									title="Click para reportar pregunta"
								/>
								<QuestionInputResult
									isKatex={question?.isKatex}
									key={question.uqid}
									stateQuestion={
										question.markedKey ? (question.markedKey === question.trueKey ? 'correct' : 'incorrect') : 'unaswered'
									}
									{...question}
								/>
								<br />
							</QuestionInputResultContainer>
						) : (
							<QuestionInputContainer key={question.uqid}>
								<ChatReportSVG
									onClick={() => {
										setModalStateReport(!modalStateReport);
										setDataQuestionReport({ uidQuestion: question?.uqid, subTopicId: question?.subTopicId ?? '' });
									}}
									title="Click para reportar pregunta"
								/>
								<QuestionInput
									key={question.uqid}
									isKatex={question?.isKatex}
									trueKey={question.trueKey}
									markedKey={question.markedKey}
									course={question.course}
									{...question}
								/>
								<br />
							</QuestionInputContainer>
						);
					})}
				</ol>
			</details>
		</CourseCategoryFormContainer>
	);
}
