import styled from 'styled-components';

export const ExpandHeaderUnlist = styled.ul`
	display: flex;

	li {
		margin: 0 15px;
	}

	a {
		transition: all 0.2s;
		color: ${(props) => props.theme.color.neutral0};

		&:hover {
			color: ${(props) => props.theme.color.info400};
		}
	}
`;

export const ProfileContainer = styled.div`
	display: flex;
	position: relative;
	transition: all 0.2s;

	svg {
		fill: ${(props) => props.theme.color.neutral900};
		width: 20px;
		height: 20px;
		border-radius: 50%;
	}

	img {
		width: 34px;
		height: 34px;
		border-radius: 50%;
	}

	a {
		color: ${(props) => props.theme.color.neutral900};
	}

	.userCercle {
		border-radius: 50px;
		background: ${(props) => props.theme.color.neutral100};
		width: 34px;
		height: 34px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: -17px;
	}

	.userName {
		background: ${(props) => props.theme.color.neutral2};
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 15px 0 32px;
		border-radius: 0 50px 50px 0;
		height: 34px;
		transition: all 0.2s;
		color: ${(props) => props.theme.color.neutral0};
	}

	&:hover {
		.userName {
			background: ${(props) => props.theme.color.neutral350};
		}
	}
`;
