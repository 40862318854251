import React from 'react';
import MainHelpCenter from '../components/helpCenter/MainHelpCenter';
import { Helmet } from 'react-helmet-async';

export default function HelpCenter() {
	return (
		<>
			<Helmet>
				<title>Ayuda | Ebbiner</title>
				<meta name="description" content="Centro de Ayuda" />
				<meta property="og:title" content="Ayuda | Ebbiner" />
				<meta property="og:description" content="Centro de Ayuda" />
				<meta property="twitter:title" content="Ayuda | Ebbiner" />
				<meta property="twitter:description" content="Centro de Ayuda" />
			</Helmet>
			<MainHelpCenter />
		</>
	);
}
