export const colors = {
	primary: {
		p0: '#DEF7F4',
		p100: '#AEEBE2',
		p200: '#72DED0',
		p300: '#00D0BB',
		p304: 'rgba(0, 208, 187, 0.04)',
		p305: 'rgba(0, 208, 187, 0.05)',
		p310: 'rgba(0, 208, 187, 0.10)',
		p335: 'rgba(0, 208, 187, 0.35)',
		p350: 'rgba(126, 225, 247, 0.5)',
		p400: '#00C4AB',
		p500: '#00B69A',
		p600: '#00A88C',
		p605: 'rgba(0, 168, 140, 0.05)',
		p610: 'rgba(0, 168, 140, 0.10)',
		p700: '#00977A',
		p800: '#00866B',
		p900: '#00684D',
	},
	secondary: {
		sec0: '#FEE5EF',
		sec5: 'rgba(246, 154, 204, 0.05)',
		sec100: '#FCBDD6',
		sec200: '#FB93BB',
		sec300: '#FB669F',
		sec400: '#FA4289',
		sec500: '#FA1B72',
		sec600: '#E8186F',
		sec700: '#D11669',
		sec800: '#BC1265',
		sec900: '#960E5D',
	},
	error: {
		e0: '#FFE9EE',
		e100: '#FFC8D0',
		e200: '#FA9094',
		e300: '#F4616A',
		e400: '#FF2F44',
		e405: 'rgba(255, 47, 68, 0.05)',
		e410: 'rgba(255, 47, 68, 0.10)',
		e435: 'rgba(255, 47, 68, 0.35)',
		e500: '#FF0024',
		e600: '#F60025',
		e700: '#E4001F',
		e800: '#D70017',
		e900: '#C80009',
	},
	info: {
		i0: '#E3F3FE',
		i100: '#BAE1FE',
		i200: '#8DCEFE',
		i300: '#5CBBFE',
		i400: '#2FACFF',
		i405: 'rgba(47, 172, 255, 0.05)',
		i410: 'rgba(47, 172, 255, 0.10)',
		i500: '#009EFF',
		i600: '#008FF1',
		i700: '#007DDE',
		i800: '#006CCC',
		i900: '#004DAD',
	},
	success: {
		suc0: '#E4FFEB',
		suc100: '#BDFFCD',
		suc200: '#8AFFAB',
		suc300: '#2FFF82',
		suc400: '#00FB5E',
		suc405: 'rgba(0, 251, 94, 0.05)',
		suc410: 'rgba(0, 251, 94, 0.10)',
		suc500: '#00F444',
		suc600: '#00E338',
		suc700: '#00CE28',
		suc800: '#00BB1A',
		suc900: '#009900',
	},
	warning: {
		w0: '#FFFDE8',
		w5: 'rgba(255, 244, 134, 0.05)',
		w100: '#FFF9C5',
		w200: '#FFF49F',
		w300: '#FFEF79',
		w400: '#FFE95B',
		w405: 'rgba(255, 233, 91, 0.05)',
		w410: 'rgba(255, 233, 91, 0.10)',
		w500: '#FFE43E',
		w600: '#FFDA44',
		w700: '#FFC33F',
		w800: '#FFAB39',
		w900: '#FF822F',
	},
	neutral: {
		n0: '#F1F6FF',
		n2: 'rgba(241, 246, 255, 0.02)',
		n5: 'rgba(241, 246, 255, 0.05)',
		n10: 'rgba(241, 246, 255, 0.10)',
		n35: 'rgba(241, 246, 255, 0.35)',
		n100: '#E5EBF4',
		n105: 'rgba(229, 235, 244, 0.05)',
		n200: '#D7DDE6',
		n300: '#C3C9D2',
		n400: '#9DA3AB',
		n405: 'rgba(157, 163, 171, 0.05)',
		n500: '#7C8189',
		n600: '#555B62',
		n605: 'rgba(229, 235, 244, 0.05)',
		n700: '#434850',
		n735: 'rgba(67, 72, 80, 0.35)',
		n800: '#262B32',
		n835: 'rgba(38, 43, 50, 0.35)',
		n900: '#010812',
		n910: 'rgba(1, 8, 18, 0.1)',
		n980: 'rgba(1, 8, 18, 0.8)',
		n1000: '#030D1B',
	},
};

export const spacing = {
	spacing0X: '0px',
	spacing0X5: '2px',
	spacing1X: '4px',
	spacing2X: '8px',
	spacing2X5: '10px',
	spacing3X: '12px',
	spacing3X5: '14px',
	spacing4X: '16px',
	spacing4X5: '18px',
	spacing5X: '20px',
	spacing5X5: '22px',
	spacing6X: '24px',
	spacing7X: '28px',
	spacing8X: '32px',
	spacing8X5: '34px',
	spacing9X: '36px',
	spacing9X5: '38px',
	spacing10X: '40px',
	spacing11X: '44px',
	spacing12X: '48px',
	spacing14X: '56px',
	spacing16X: '64px',
	spacing20X: '80px',
	spacing28X: '112px',
	spacing39X: '156px',
};

export const media = {
	xs: {
		width: '360px',
		height: '640px',
		columns: 6,
		margin: '16px',
		gutter: '16px',
	},
	md: {
		width: '768px',
		height: '1024px',
		columns: 6,
		margin: '24px',
		gutter: '16px',
	},
	lg: {
		width: '1200px',
		height: '768px',
		columns: 12,
		margin: '24px',
		gutter: '24px',
	},
	xl: {
		width: '1440px',
		height: '900px',
		columns: 12,
		margin: '32px',
		gutter: '16px',
	},
};

export const borders = {
	border12: '12px',
};

export const shadows = {
	shadow1: '0px 0px 8px 1px rgba(1, 8, 18, 0.15)',
	shadow2: '0px -4px 4px rgba(0, 0, 0, 0.25)',
	shadow3: '0px 0px 8px 1px rgba(1, 8, 18, 0.40)',
};

export const blurs = {
	blur1: 'blur(1px)', // backdrop-filter <== styles
	blur2: 'blur(2px)', // backdrop-filter <== styles
	blur3: 'blur(3px)', // backdrop-filter <== styles
	blur4: 'blur(4px)', // backdrop-filter <== styles
	blur5: 'blur(5px)', // backdrop-filter <== styles
	blur6: 'blur(6px)', // backdrop-filter <== styles
	blur7: 'blur(7px)', // backdrop-filter <== styles
	blur8: 'blur(8px)', // backdrop-filter <== styles
};

export const fonts = {
	brandFont: 'var(--font-quicksand)',
	textFont: 'var(--font-nunito)',
	weight: {
		light: 300,
		regular: 400,
		medium: 500,
		semiBold: 600,
		bold: 700,
	},
};
