import styled from 'styled-components';

export const ContactsContainer = styled.div`
	margin: 50px 0 0 0;

	.contactItem {
		&:first-child {
			margin: 0 0 20px 0;
		}
	}

	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;

		.contactItem {
			&:first-child {
				margin: 0;
			}
		}
	}

	@media (min-width: 1200px) {
		margin: 50px 80px 0 80px;
		gap: 40px;
	}
`;
