import React, { useState } from 'react';
import { ClassBoardContainer, ClassBoardContent, ClassResourceContainer } from './styles/sClassResource';
import { Body3, Body8 } from '../../../styles/textGeneral';
import { Preview2 } from '../../../packages/shared/components/cards/previews/Preview2';
import CustomPreviewModal from '../../../packages/shared/components/cards/previews/CustomPreviewModal';

export const ClassResource = ({ textResource, arrayOfBoards }) => {
	const [selectedIndex, setSelectedIndex] = useState(null);

	const openModal = (index) => {
		setSelectedIndex(index);
	};

	const closeModal = () => {
		setSelectedIndex(null);
	};

	const goToNext = () => {
		setSelectedIndex((oldIndex) => oldIndex + 1);
	};

	const goToPrev = () => {
		setSelectedIndex((oldIndex) => oldIndex - 1);
	};

	return (
		<ClassResourceContainer>
			<Body8>{textResource}</Body8>
			<ClassBoardContainer>
				<Body3>Pizarras de la clase</Body3>
				<ClassBoardContent>
					{arrayOfBoards?.map((board, index) => (
						<div onClick={() => openModal(index)}>
							<Preview2
								isBoard
								urlImage={board.boardImage}
								courseName={board.titleOfBoard}
								totalClass={board.descriptionOfBoard}
								key={board.titleOfBoard}
							/>
						</div>
					))}
				</ClassBoardContent>
			</ClassBoardContainer>
			{selectedIndex !== null && (
				<CustomPreviewModal
					urlImage={arrayOfBoards[selectedIndex].boardImage}
					courseName={arrayOfBoards[selectedIndex].titleOfBoard}
					totalClass={arrayOfBoards[selectedIndex].descriptionOfBoard}
					onClose={closeModal}
					onNext={goToNext}
					onPrev={goToPrev}
					selectedIndex={selectedIndex}
					totalElements={arrayOfBoards.length}
				/>
			)}
		</ClassResourceContainer>
	);
};
