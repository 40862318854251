// @ts-nocheck
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ItemNavbarMenuContainer } from './styles/sItemNavbarInHomeMenu';
import { NavLink } from 'react-router-dom';

//Import ALGORITHMS
import { closeSession } from './algorithms/closeSession';

//Import Contexts
import { AppContext } from '../../../../App';
import { Body5 } from '../../../../styles/textGeneral';
import { useNavbarStore } from '../../../../store/navbarStore';

const ItemNavbarInHomeMenu = ({
	nameItem,
	icon,
	link,
	// navbarMin
}) => {
	const navigate = useNavigate();
	const { navbarDeployed } = useNavbarStore();
	const { simulacrum, setDataOfUser, setCurrentUser, setSubscriptionTime, setSolvedExams, setLoading } = useContext(AppContext);
	return (
		<ItemNavbarMenuContainer navbarMinWith={navbarDeployed ? '90%' : '45%'}>
			<NavLink
				exact="true"
				to={nameItem === 'simulacros' ? (Array.isArray(simulacrum) ? '/exams/form' : link) : link}
				className={
					({ isActive }) => (isActive ? 'isActiveItem' : '')
					// (nameItem === 'clases' && window.location.pathname.startsWith('/classes')) || isActive ? 'navbarMenu' : ''
				}
				onClick={() =>
					nameItem === 'Cerrar sesión' &&
					closeSession(navigate, setDataOfUser, setCurrentUser, setSubscriptionTime, setSolvedExams, setLoading)
				}>
				{icon}
				{navbarDeployed && <Body5>{nameItem}</Body5>}
			</NavLink>
		</ItemNavbarMenuContainer>
	);
};

export default ItemNavbarInHomeMenu;
