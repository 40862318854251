import React from 'react';
import { Link } from 'react-router-dom';
import { Wrapper } from './../../../styles/generalStyles';
import { CoverPageContainer, Flex, LandingPageContent, LandingPromotionsContent, PromotionContent } from './styles/sCoverPage';
import { PrincipalTitle, SecondaryTitle } from './../../../styles/textGeneral';
import { Button } from './../../../styles/buttonGeneral';
import students from './../../../images/students.webp';
import imageLandingPage from './../../../images/image-landingpage.webp';
// import Arrow from '../../../icons/arrow-right.svg';
import { ReactComponent as ArrowSVG } from '../../../icons/arrow-right.svg';

const Coverpage = ({ background, work, justifyContent, height }) => {
	return (
		<CoverPageContainer background={background}>
			<Wrapper>
				<Flex justifyContent={justifyContent} height={height}>
					{work === 'landingPage' && (
						<LandingPageContent>
							<div>
								<PrincipalTitle>
									<strong className="pink">Aprende</strong> de manera <strong className="blue">inteligente</strong> con una{' '}
									<strong className="underline">educación</strong> que transforma
								</PrincipalTitle>
								<SecondaryTitle className="coverPage">
									<em>Y acelera tu ingreso</em> a cualquier <mark>universidad del Perú</mark>
								</SecondaryTitle>
							</div>
							<div>
								<Link to="/register">
									<Button primary text="Empezar hoy" />
								</Link>

								<Link to="/help-contacts" className="linkAsesor">
									Hablar con un asesor académico <ArrowSVG />
								</Link>
							</div>
							<img src={imageLandingPage} className="astronout" alt="astronauta Ebbiner" />
						</LandingPageContent>
					)}
					{work === 'promotion' && (
						<LandingPromotionsContent>
							<PromotionContent>
								<h2>
									Matricúlate ahora <em>y obtén</em>
								</h2>
								<PrincipalTitle>
									<strong>Hasta el 20% de dto. en tu suscripción mensual</strong>
								</PrincipalTitle>
								<p>Precio normal: s/147 mes</p>
								<h3>
									<strong>Por 3 meses: s/127 mes</strong>
								</h3>
							</PromotionContent>
							<img src={students} className="students" alt="Estudiantes" />
						</LandingPromotionsContent>
					)}
					{work === 'free' && (
						<>
							<PromotionContent>
								<h2>Es hora te completar tu preparación a la universidad</h2>
								{/* <PrincipalTitle><strong>Obtén 14 días gratis en Ebbiner Premium</strong></PrincipalTitle> */}
								<p>Simulacros tipo admisión | Mide tu progreso | Clases grabadas</p>
								<Link to="/login">
									<Button primary text="Quiero mis 14 días gratis" />
								</Link>
							</PromotionContent>
							<img src={students} alt="Estudiantes" />
						</>
					)}
					{work === 'aboutUs' && (
						<>
							<PromotionContent className="nosotros">
								<h1 className="bigText">Reduciendo el tiempo de ingreso a la universidad</h1>
								<p className="bigText2">
									Hacemos el uso de data para otorgarte una
									<br />
									educación personalizada.
								</p>
							</PromotionContent>
						</>
					)}
				</Flex>
			</Wrapper>
		</CoverPageContainer>
	);
};

export default Coverpage;
