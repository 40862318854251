import styled, { css } from 'styled-components';

export const PrincipalQuizContainer = styled.main`
	min-width: 320px;
`;

export const MainContainer = styled.div`
	position: relative;
	margin-top: 60px;
	margin-bottom: 50px;
	z-index: 1000;

	@media (min-width: 1024px) {
		margin-top: 10%;
	}

	@media (min-width: 1920px) {
		margin-top: 60px;
	}
`;

export const InfoQuiz = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	gap: 10px;

	.info-description {
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 10px;
	}

	@media (min-width: 574px) {
		flex-direction: row;
		gap: initial;
		.info-description {
			flex-direction: row;
			gap: initial;
		}
	}
`;

export const QuizPuntuation = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	column-gap: 10px;

	@media (min-width: 574px) {
		flex-direction: row;
	}
`;

export const SmallCircle = styled.span`
	display: inline-flex;
	vertical-align: middle;
	&:before {
		content: ' ';
		width: 10px;
		height: 10px;
		border: none;
		border-radius: 50%;
		margin-right: 10px;
		${(props) =>
			props.green &&
			css`
				background-color: #11a83c;
			`}
		${(props) =>
			props.red &&
			css`
				background-color: ${(props) => props.theme.color.error400};
			`}
    ${(props) =>
			props.yellow &&
			css`
				background-color: ${(props) => props.theme.color.warning800};
			`}
	}
`;

export const IconQuiz = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: 27px;
	background: ${(props) => props?.iconBackground};
	width: 30px;
	height: 30px;
	border-radius: 50px;
	margin: 0 15px 0 0;

	img {
		width: 50%;
	}
`;

export const SequenceContent = styled.div`
	display: flex;
	flex-direction: column-reverse;
	row-gap: 20px;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 375px) {
		flex-direction: initial;
		row-gap: initial;
	}
`;
