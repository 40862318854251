import React from 'react';
import { TextSubtitle2 } from './../../styles/textGeneral';
import { CardTypeExamContainer } from './styles/sCardTypeExamn';

export default function CardTypeExam({ image, title, disabled }) {
	return (
		<div>
			<CardTypeExamContainer disabled={disabled}>
				<div className="cardImageContainer">
					<div className="cardTransparent"></div>
					{image}
				</div>
				<TextSubtitle2>{title}</TextSubtitle2>
			</CardTypeExamContainer>
		</div>
	);
}
