import styled, { css } from 'styled-components';

export const PromotionsContainer = styled.div`
	button {
		border: 1px solid ${(props) => props.theme.color.neutral0};
		color: ${(props) => props.theme.color.neutral0};

		&:hover {
			background: ${(props) => props.theme.color.neutral2};
		}

		@media (min-width: 1024px) {
			width: 200px;
			margin: 0 auto;
			display: block;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		border-radius: 14px;
		overflow: hidden;
		margin: 40px 0 30px 0;

		@media (min-width: 1024px) {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}

		@media (min-width: 1200px) {
			width: 80%;
			margin: 40px auto 30px auto;
		}
	}
`;

export const Promotion = styled.div`
	display: flex;
	padding: 35px;
	font-family: ${(props) => props.theme.font.textFont};
	justify-content: space-between;

	${(props) =>
		props.background === 'gradient' &&
		css`
			background: linear-gradient(153.62deg, #609ae7 0%, #dc6be7 100.51%);
			color: ${(props) => props.theme.color.neutral900};
		`}

	${(props) =>
		props.background === 'black' &&
		css`
			background: rgba(30, 38, 42, 0.55);
		`}
    
    h3 {
		font-size: 1em;
		font-weight: ${(props) => props.theme.font.weight.semiBold};
		width: 48%;
		line-height: 1.25;
	}

	.price {
		font-size: 1.25em;
		font-weight: ${(props) => props.theme.font.weight.bold};
		margin: 0 0 3px 0;
		display: block;
	}

	@media (min-width: 1024px) {
		h3 {
			font-size: 1em;
			font-weight: ${(props) => props.theme.font.weight.semiBold};
			width: 58%;
		}

		button {
			width: 200px !important;
		}
	}
`;
