import styled from 'styled-components';

export const ItemFooterContainer = styled.li`
	display: flex;
	align-items: center;

	svg {
		margin: 0 20px 0 0;
	}

	p {
		font-size: 0.938em;
		line-height: 1.35em;
		font-family: ${(props) => props.theme.font.textFont};
	}
`;
