import React from 'react';
import MainExamForm from './../components/exams/form/MainExamForm';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export default function ExamForm() {
	const [t] = useTranslation('main');
	return (
		<>
			<Helmet>
				<title>{t('brand.exam-form.title')}</title>
				<meta name="description" content={t('brand.exam-form.description')} />
				<meta property="og:title" content={t('brand.exam-form.title')} />
				<meta property="og:description" content={t('brand.exam-form.description')} />
				<meta property="twitter:title" content={t('brand.exam-form.title')} />
				<meta property="twitter:description" content={t('brand.exam-form.description')} />
			</Helmet>
			<MainExamForm />
		</>
	);
}
