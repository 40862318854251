import { call, put, takeEvery } from 'redux-saga/effects';
import { getOneLivesInfo } from '../api/getLives';
import { FETCH_ONE_LIVES } from '../actions/type';
import { setOneLive } from '../actions/index';

function* fetchOneLive(action) {
	try {
		const liveData = yield call(getOneLivesInfo, action.payload);
		yield put(setOneLive(liveData));
	} catch (error) {
		console.warn(error);
	}
}

function* liveOneSaga() {
	yield takeEvery(FETCH_ONE_LIVES, fetchOneLive);
}

export default liveOneSaga;
