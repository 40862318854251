import styled from 'styled-components';

export const LogoContainer = styled('div').withConfig({
	shouldForwardProp: (prop) => !['color'].includes(prop),
})`
	svg {
		height: ${(props) => props.height || '30px'};
		width: auto;
		fill: ${(props) => props.color || props.theme.color.neutral0};
	}

	@media (min-width: 360px) {
		svg {
			height: ${(props) => props.height || '32px'};
		}
	}
`;
