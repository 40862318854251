export const listOfCourses = {
	algebra: 'algebra',
	aritmetica: 'arithmetic',
	biologia: 'biology',
	civica: 'civic',
	quimica: 'chemistry',
	economia: 'economy',
	geografia: 'geography',
	geometria: 'geometry',
	peru: 'historyOfPeru',
	anatomia: 'humanAnatomy',
	lenguaje: 'language',
	literatura: 'literature',
	rm: 'mathematicalReasoning',
	fisica: 'physics',
	actualidad: 'topicality',
	trigonometria: 'trigonometry',
	universal: 'universalHistory',
	verbal: 'verbalReasoning',
};
