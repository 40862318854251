import { ContentModal, ModalPay } from './styles/sModalKat';
import Interbank from '../../images/interbank.webp';
import { Text } from '../../styles/textGeneral';
import BCP from '../../images/bcp.webp';
import React from 'react';

const ModalDeposit = () => {
	return (
		<ModalPay>
			<h3>
				Pago de <strong>s/&nbsp;33</strong>&nbsp;por depósito bancario
			</h3>
			<ContentModal style={{ rowGap: '49px' }}>
				<div>
					<img className="bcp mb20" src={BCP} alt="Imágen del yape" />
					<Text>Cuenta BCP: 22073536210000</Text>
					<Text>Cuenta Interbancaria BCP: 00222017353621000020</Text>
				</div>
				<div>
					<img className="interbank mb20" src={Interbank} alt="Imágen del yape del código qr" />
					<Text>Cuenta Interbank: 4403282597120</Text>
					<Text>Cuenta Interbancaria: 00344001328259712054</Text>
				</div>
				<Text center>Para validar tu pago, envía una foto de tu baucher al 984&nbsp;966&nbsp;356</Text>
			</ContentModal>
			<Text center style={{ color: '#7D8187' }}>
				Pagos seguros con Ebbiner Security
			</Text>
		</ModalPay>
	);
};

export default ModalDeposit;
