import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainContact from '../components/contact/MainContact';
import HeaderLanding from '../components/general/landingComponents/HeaderLanding';

const Contact = () => {
	return (
		<>
			<HeaderLanding backgroundColor={'#121a21'} />
			<Helmet>
				<title>Contacto | Ebbiner</title>
				<meta name="description" content="Contacto" />
				<meta property="og:title" content="Contacto | Ebbiner" />
				<meta property="og:description" content="Contacto" />
				<meta property="twitter:title" content="Contacto | Ebbiner" />
				<meta property="twitter:description" content="Contacto" />
			</Helmet>
			<MainContact />
		</>
	);
};

export default Contact;
