import { getAuth, signOut } from 'firebase/auth';

export const closeSession = (navigate, setDataOfUser, setCurrentUser, setSubscriptionTime, setSolvedExams, setLoading) => {
	const auth = getAuth();
	if (auth) {
		setLoading({ status: true, title: null });
		signOut(auth).then(() => {
			setDataOfUser(null);
			setCurrentUser(null);
			setSubscriptionTime({
				serverDateNow: null,
				endTimestamp: null,
				timeRemaining: 0,
			});
			setSolvedExams({});
			navigate('/');
			setLoading({ status: false, title: null });
		});
	}
};
