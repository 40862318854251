import styled from 'styled-components';

export const HeaderLandingContainer = styled.div`
	height: 50px;
	width: 100%;
	position: absolute;
	z-index: 10000;
	font-weight: ${(props) => props.theme.font.weight.bold};
	font-family: ${(props) => props.theme.font.brandFont};
	box-shadow: ${(props) => props.theme.shadow.shadow1};
	background: ${(props) => props.backgroundColor};

	.menu {
		fill: ${(props) => props.theme.color.neutral0};
	}

	@media (min-width: 912px) {
		.container-hamburger {
			display: none;
		}
	}

	@media (max-width: 912px) {
		button {
			font-size: 0.8rem;
			padding: 0.5rem 1rem;
		}
	}
`;

export const HeaderLandingItems = styled.nav`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: ${(props) => props.theme.font.brandFont};
	height: 50px;

	a {
		text-decoration: none;
		color: ${(props) => props.theme.color.neutral0};
		line-height: 20px;
	}

	ul {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.container-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
	}

	.logo {
		height: 25px;
		width: auto;
		fill: ${(props) => props.theme.color.neutral0};
	}

	.close {
		width: 20px;
		fill: ${(props) => props.theme.color.neutral0};
	}

	@media (min-width: 912px) {
		display: flex;
		gap: 1rem;

		ul {
			display: flex;
			gap: 2rem; //cabiar para adecuar items al menu
			width: 100%;
		}

		li {
			list-style: none;
			cursor: pointer;
			justify-content: space-between;

			&:hover {
				color: ${(props) => props.theme.color.info500};
				transform: scale(1.1);
			}
		}

		.container-btn {
			gap: 0.5rem;
			white-space: nowrap;
		}
	}

	@media (min-width: 1024px) {
		gap: 2rem;
		padding: 0;
		.container-btn {
			justify-content: flex-end;
		}
	}

	@media (max-width: 912px) {
		ul {
			display: none;
		}
		li {
			font-size: 0.8rem;
			padding: 0 0.5rem;
		}
	}

	@media (max-width: 280px) {
		.container-btn {
			font-size: 0.8rem;
			padding: 0 0.3rem;
			gap: 0.2rem;
		}
	}
`;
