import React, { useContext, useEffect, useState, useRef } from 'react';
import Message from './Message';
import { ChatRoomContainer, ChatForm, CircleExplosion, ChatMessage } from './styles/sChatRoom';
import { useForm } from 'react-hook-form';
import { useFirestore } from 'reactfire';
import { createNewMessage } from './algorithm/createNewMessage';
import { readMessages } from './algorithm/readMessages';
import { AppContext } from '../../App';
import { ReactComponent as SendIcon } from '../../icons/send-icon.svg';

const ChatRoom = ({ params: { live, route } }) => {
	const db = useFirestore();
	const lastMsgRef = useRef(null);
	const [messagesData, setDataMessages] = useState([]);
	const {
		dataOfUser: { name, uid, username },
		currentUser,
	} = useContext(AppContext);
	const { register, handleSubmit, reset } = useForm();
	useEffect(() => {
		let unsubscribe;
		let getChatSubscription = async () => {
			unsubscribe = await readMessages(db, live, route, setDataMessages);
		};
		getChatSubscription();
		return () => {
			unsubscribe();
		};
	}, []);

	const handleSubmitData = async (data) => {
		reset();
		await createNewMessage(db, live, route, data.message, name, uid, username, currentUser?.photoURL);
	};

	const scrollIntoLastMessage = () => {
		lastMsgRef?.current?.scrollIntoView({
			behavior: 'auto',
			block: 'nearest',
			inline: 'end',
		});
	};

	useEffect(() => {
		const handler = scrollIntoLastMessage();
		window.addEventListener('scroll', handler);
		return () => window.removeEventListener('scroll', handler);
	}, [messagesData]);

	return (
		<ChatRoomContainer>
			<CircleExplosion size={300} left={-107} bottom={-50} color="#eb882d33" />
			<CircleExplosion size={200} right={-90} bottom={150} color="#69c0b433" />
			<CircleExplosion size={150} top={-50} left={60} bottom={150} color="#91364533" />

			<ChatMessage>
				{messagesData.map((message, index) => (
					<Message key={index} message={message} uid={uid} />
				))}
				<span ref={lastMsgRef}></span>
			</ChatMessage>
			<ChatForm onSubmit={handleSubmit(handleSubmitData)}>
				<label>Escribe un comentario</label>
				<input
					autoComplete="off"
					type={'text'}
					{...register('message', {
						max: 180,
						min: 3,
						maxLength: 180,
						required: true,
					})}
					placeholder="Escribe un comentario..."
				/>
				<button type="submit">
					<SendIcon />
				</button>
			</ChatForm>
		</ChatRoomContainer>
	);
};

export default ChatRoom;
