import styled from 'styled-components';

export const ClassResourceContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 ${({ theme }) => theme.space.spacing4X};
	padding-top: ${({ theme }) => theme.space.spacing3X};
	gap: ${({ theme }) => theme.space.spacing6X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		padding: 0 ${({ theme }) => theme.space.spacing5X};
		padding-top: ${({ theme }) => theme.space.spacing3X};

		gap: ${({ theme }) => theme.space.spacing9X};
	}
`;

export const ClassBoardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing4X};
`;

export const ClassBoardContent = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.space.spacing4X};
	overflow: auto;

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		overflow: initial;
		flex-flow: wrap;
	}

	@media (width >= ${({ theme }) => theme.media.mediaXL.width}) {
		gap: ${({ theme }) => theme.space.spacing5X};
	}
`;
