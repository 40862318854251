import styled from 'styled-components';

export const ProfileItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: auto;
	width: 70px;
	gap: ${({ theme }) => theme.space.spacing1X};
	font-size: 12px;
	text-align: center;
	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		font-size: 13px;
		gap: ${({ theme }) => theme.space.spacing2X};
	}
`;

export const ProfileItemAvatar = styled.picture`
	border-radius: 50%;
	display: flex;
	justify-content: center;

	img {
		border-radius: 50%;
		width: 32px;
		height: 32px;
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		img {
			width: 50px;
			height: 50px;
		}
	}
`;
