// import { TeacherInformation } from '../../../packages/shared/components/items/TeacherInformation';

import { Button } from '../../../styles/buttonGeneral';
import { Body7 } from '../../../styles/textGeneral';
import {
	CardClassVideoInformationContainer,
	CourseProgress,
	InformationClassVideo,
	TeacherInformation,
	TitleClassVideos,
	TitleCourse,
} from './styles/sCardClassVideoInformation';

export const CardClassVideoInformation = ({ titleClass, courseProgress, courseName, professorName, urlProfessorImage }) => {
	return (
		<CardClassVideoInformationContainer>
			<section>
				<article>
					<InformationClassVideo>
						<TitleClassVideos>{titleClass}</TitleClassVideos>
						<div className="courseProgressContainer">
							<CourseProgress>
								<p>{courseProgress}</p>
							</CourseProgress>
						</div>
					</InformationClassVideo>
					<TitleCourse>{courseName}</TitleCourse>
				</article>
				<TeacherInformation>
					<img
						src={
							urlProfessorImage ??
							'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d'
						}
					/>
					<Body7>{professorName}</Body7>
				</TeacherInformation>
			</section>
			<section className="sectionButton">
				<Button secondary disabled text={'Reportar un problema'} />
			</section>
		</CardClassVideoInformationContainer>
	);
};
