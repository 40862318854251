import styled, { css } from 'styled-components';

const mixinWrapperHome = css`
	width: 100%;
	padding: 45px 20px 18% 20px;

	@media (min-width: 360px) {
		padding: 45px 20px 20% 20px;
	}

	@media (min-width: 375px) {
		padding: 45px 25px 22% 25px;
	}

	@media (min-width: 768px) {
		padding: 60px 18% 75px 18%;
	}

	@media (min-width: 1024px) {
		padding: 60px 5% 75px 5%;
	}
`;

const mixinWrapper = css`
	@media (min-width: 360px) {
		padding: 0 20px;
	}

	@media (min-width: 375px) {
		padding: 0 25px;
	}

	@media (min-width: 768px) {
		padding: 0 18%;
	}

	@media (min-width: 1024px) {
		padding: 0 15%;
	}
`;

export const Wrapper = styled.div`
	${mixinWrapper};

	@media (min-width: 574px) {
		.titleLandingPage {
			width: 70%;
			margin: auto;
		}
	}
	@media (min-width: 1200px) {
		padding: 0;
		margin: ${(props) => props.margin || '0 auto'};
		width: ${(props) => props.width || '1100px'};

		.titleLandingPage {
			width: 50%;
		}
	}
	.center {
		text-align: center;
	}
	.button-container {
		margin-top: 85px;
		display: flex;
		flex-direction: column;

		button {
			margin: 10px 0;
		}
	}
`;

export const RegisterAndLoginWrapper = styled.div`
	margin: 50px 0 70px 0;

	.registerAndLoginFooter {
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	.mainRegisterAndLogin {
		flex: 1 0 auto;
	}

	.footerRegisterAndLogin {
		flex: 0 0 auto;
	}

	@media (min-width: 340px) {
		.mainRegisterAndLogin {
			margin: 0 0 200px 0;
		}
	}

	@media (min-width: 410px) {
		.mainRegisterAndLogin {
			margin: 0 0 300px 0;
		}
	}

	@media (min-width: 1024px) {
		.mainRegisterAndLogin {
			margin: 0 0 150px 0;
		}
	}

	@media (min-width: 1200px) {
		margin: 35px 110px 25px 110px;

		.mainRegisterAndLogin {
			margin: 0 0 250px 0;
		}
	}
`;

//Styles for home login

export const WrapperHome = styled.div`
	${mixinWrapperHome};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing7X};

	@media (min-width: 1200px) {
		padding: 30px 0 4% 0;
		width: 97%;
		max-width: 1400px;
		margin: 0 auto;
	}
`;
export const WrapperHomeHamburger = styled.div`
	padding: 0 10px 8% 10px;
	display: flex;
	z-index: 1500;
	flex-direction: column;

	button {
		margin-top: 15px;
	}

	a {
		display: flex;
		justify-content: flex-start;
		margin: 5px 0;
		padding: 10px 0;
		hr {
		}
	}
	svg {
		width: 20px;
		height: 20px;
	}
	span {
		font-family: ${(props) => props.theme.fontFamily};
	}

	@media (min-width: 1024px) {
		padding: 0 0 4% 0;
		width: 670px;
		margin: 0 auto;
	}
	@media (min-width: 820px) {
		padding: 0 0 4% 0;
		width: 570px;
		margin: 0 auto;
	}
	@media (min-width: 768px) {
		padding: 1% 0 4% 0;
		width: 500px;
		margin: 0 auto;
	}
`;

export const WrapperHomeInternal = styled.div`
	${mixinWrapperHome};

	@media (min-width: 768px) {
		padding: 0 18%;
	}

	@media (min-width: 1024px) {
		padding: 0 15%;
	}

	@media (min-width: 1200px) {
		padding: 0 0 4% 0;
		width: 970px;
		margin: 0 auto;
	}
`;

export const WrapperAdmin = styled.div`
	@media (min-width: 1200px) {
		padding: 0 0 4% 0;
		width: 990px;
		margin: 0 auto;
	}
`;

export const WrapperCompleteM = styled.div`
	padding: 0;
	width: 100%;

	@media (min-width: 360px) {
		padding: 0;
	}

	@media (min-width: 375px) {
		padding: 0;
	}

	@media (min-width: 768px) {
		padding: 0;
	}

	@media (min-width: 1024px) {
		padding: 0;
	}

	@media (min-width: 1200px) {
		padding: 0;
		width: 970px;
		margin: 0 auto;
	}
`;

export const ModalNavbarContainer = styled.div`
	background: #161e24;
	position: absolute;
	left: 0;
	right: 0;
	top: 50px;
	height: 100vh;
`;
export const ContainerButtonInModal = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		width: 50% !important;
	}
`;

export const ModalNavbarHeaderContainer = styled.div`
	background: #161e24;
	position: absolute;
	left: 0;
	right: 0;
	top: 50px;
	height: auto;
`;

export const GridLayoutInHome = styled.div`
	@media (min-width: 1200px) {
		display: grid;
		grid-template-columns: ${(props) => props.widthfr} 1fr;
		transition: all 0.5s ease-in-out;
	}
`;

export const WrapperExpand = styled.div`
	${mixinWrapper};

	@media (min-width: 360px) {
		position: relative;
		top: ${(props) => props.top || '50px'};
	}

	@media (min-width: 375px) {
		padding: 0 25px;
	}

	@media (min-width: 768px) {
		padding: 0 18%;
	}

	@media (min-width: 1024px) {
		padding: 0 15%;
	}

	@media (min-width: 1200px) {
		position: static;
		padding: 0;
		margin: ${(props) => props.margin || '0 auto'};
		width: ${(props) => props.width || '1200px'};
	}
`;

export const WrapperOthers = styled.div`
	padding: 0 15px;
	width: 100%;

	@media (min-width: 360px) {
		padding: 0 20px 50px 20px;
	}

	@media (min-width: 375px) {
		padding: 0 25px 50px 25px;
	}

	@media (min-width: 768px) {
		padding: 0 18% 50px 18%;
	}

	@media (min-width: 1024px) {
		padding: 3% 15% 50px 15%;
	}

	@media (min-width: 1200px) {
		padding: 0;
		width: auto;
		max-width: 1200px;
		margin: 3% 2% 30px 2%;
	}
`;
