import styled from 'styled-components';

export const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000;
`;

export const ModalContent = styled.div`
	background-color: ${({ theme }) => theme.color.neutral835};
	max-width: 80%;
	height: auto;
	max-height: 100vh;
	overflow: auto;
	padding: 0 0 5px 0;
	border-radius: 12px;
	position: relative;

	img {
		border-radius: 12px 12px 0 0;
		max-width: 100%;
		max-height: 100%;
	}

	p {
		margin: 10px 0 10px 10px;
	}

	.nextButton {
		top: 50%;
		right: 10px;
		transform: translateY(-50%);

		svg {
			width: 32px;
			height: 32px;
		}
		path {
			fill: ${({ theme }) => theme.color.neutral0};
		}
		&:hover {
			transform: translateY(-50%) scale(1.1);
		}
	}

	.backButton {
		top: 50%;
		left: 10px;
		right: unset;
		transform: translateY(-50%);
		svg {
			width: 32px;
			height: 32px;
		}
		path {
			fill: ${({ theme }) => theme.color.neutral0};
		}
		&:hover {
			transform: translateY(-50%) scale(1.1);
		}
	}
`;

export const Button = styled.button`
	padding: 4px;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	border-radius: 50%;
	display: flex;
	transition: all 0.2s linear;
	background-color: transparent;

	svg {
		width: 24px;
		height: 24px;
		fill: ${({ theme }) => theme.color.neutral0};
	}

	&:hover {
		transform: scale(1.1);
		background-color: ${({ theme }) => theme.color.neutral835};
	}
	&:active {
		transform: scale(1);
	}
`;
