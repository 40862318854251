import { Body7, Body8 } from '../../../../styles/textGeneral';
import { TeacherInformationContainer, Title } from './style/sTeacherInformation';

export const TeacherInformation = ({ nameTeacher, urlImage, descriptionTeacher }) => {
	return (
		<TeacherInformationContainer>
			<Title>Sobre el Profesor</Title>
			<div>
				<section>
					<img
						src={
							urlImage ??
							'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d'
						}
					/>
					<Body7>{nameTeacher ?? 'Cargando nombre'}</Body7>
				</section>
				{descriptionTeacher && <Body8>{descriptionTeacher}</Body8>}
			</div>
		</TeacherInformationContainer>
	);
};
