import { collection, query, limit, where, getDocs } from 'firebase/firestore';

export const getUsersOfPlatform = async (db) => {
	try {
		const usersQuery = query(collection(db, 'users'), where('photoURL', '!=', null), limit(20));

		const snapshot = await getDocs(usersQuery);
		const users = [];

		snapshot.forEach((doc) => {
			const userData = doc.data();

			users.push({
				urlPhoto: userData.photoURL,
				username: userData.username.split(' ', 2).join(' '),
			});
		});
		const usersSorted = users.sort(() => Math.random() - 0.5).slice(0, 10);
		return usersSorted;
	} catch (error) {
		console.log(error);
		throw new Error(error);
	}
};
