import { Body10 } from '../../../../styles/textGeneral';
import { CourseHeaderContainer, Information, InformationCard, InformationCardContainer } from './style/sCourseHeader';
import { ReactComponent as Clock2SVG } from '../../../../icons/Clock2.svg';
import { ReactComponent as FilmStripSVG } from '../../../../icons/FilmStrip.svg';

export const CourseHeader = ({ urlImageCourse, titleCourse, totalQuantityClass, totalTime, iconQuantityClass, iconTime }) => {
	return (
		<>
			<CourseHeaderContainer>
				<img src={urlImageCourse ?? 'https://picsum.photos/200/300'} alt="" />
				<InformationCardContainer>
					<p>{titleCourse}</p>
					<InformationCard>
						<Information>
							{iconQuantityClass ?? <FilmStripSVG />}
							<Body10>{totalQuantityClass}</Body10>
						</Information>
						<Information>
							{iconTime ?? <Clock2SVG />}
							{totalTime && <Body10>{totalTime}</Body10>}
						</Information>
					</InformationCard>
				</InformationCardContainer>
			</CourseHeaderContainer>
		</>
	);
};
