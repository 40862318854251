'use client';
import { createGlobalStyle } from 'styled-components';

const globalStyles = createGlobalStyle`
  *, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 15px;
  }
  
  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    height: 100%;
    position: relative;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${(props) => props?.theme?.font?.brandFont}, sans-serif;
    color: ${(props) => props?.theme?.color?.neutral0};
    display: flex;
    flex-wrap: wrap;
    background: ${(props) => props?.theme?.color?.neutral900};
    scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
    max-width: 100%;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }

  :root {
    --font-quicksand: 'Quicksand', sans-serif;
    --font-nunito: 'Nunito', sans-serif;
    --toastify-color-light: ${(props) => props?.theme?.color?.neutral0};
    --toastify-color-dark: ${(props) => props?.theme?.color?.neutral800};
    --toastify-color-info: ${(props) => props?.theme?.color?.info400};
    --toastify-color-success: ${(props) => props?.theme?.color?.success400};
    --toastify-color-warning: ${(props) => props?.theme?.color?.warning400};
    --toastify-color-error: ${(props) => props?.theme?.color?.error400};

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-text-color-light: ${(props) => props?.theme?.color?.neutral900};
    --toastify-text-color-dark: ${(props) => props?.theme?.color?.neutral0};
  }

  header {
    flex: 0 0 auto;
    width: 100%;
  }

  main {
    flex: 1 0 auto;
  }

  footer {
    flex: 0 0 auto;
  }

  h1, h2, h3, h4, h5, h6, span {
    font-style: normal;
    font-family: ${(props) => props.theme?.font?.brandFont}, sans-serif;
  }
  
  p {
    font-family: ${(props) => props.theme?.font?.textFont}, sans-serif;
  }

  fieldset {
    border: none;
  }
  
  ol,
  ul {
    list-style: none;
  }

  main {
    display: block;
  }

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }

  img {
    border-style: none;
    display: block;
    line-height:0;
    object-fit: cover;
  }

  em, strong, address {
    font-style: normal;
  }

  strong {
    color: inherit;
  }

  mark {
    background: transparent;
    color: inherit;
  }

  button,
  select { /* 1 */
    text-transform: none;
  }

  button {
    border: none;
    font-size: 15px;
    cursor: pointer;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearence: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  [hidden] {
    display: none;
  }

  *::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  *::-webkit-scrollbar:vertical {
    width:10px;
    background: ${(props) => props?.theme?.color?.neutral900};
  }

  *::-webkit-scrollbar-button:increment,
  *::-webkit-scrollbar-button {
    display: none;
  } 

  *::-webkit-scrollbar:horizontal {
    height: 10px;
    background: ${(props) => props?.theme?.color?.neutral900};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props?.theme?.color.primary300};
    border-radius: 20px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  svg {
    height: auto;
  }

  form {
    width: 100%;
  }

  .cicleSVGBackground{
    position: absolute;
    width: 100vw;
    z-index: -1;
    opacity: 0.3;
  }
  .hide {
		display: none;
	}

  .Toastify__toast {
    box-shadow: 0px 5px 16px 1px rgba(1, 8, 18, 0.40);
    border-radius: ${({ theme }) => theme.border.border12};
    padding: ${({ theme }) => theme.space.spacing3X};

    @media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
      padding: ${({ theme }) => theme.space.spacing4X};
    }
  }

  .Toastify__toast-container {
    width: calc(100% - 32px) !important;
    display: flex !important;
    flex-direction: column !important;
    gap: ${({ theme }) => theme.space.spacing3X} !important;
    
    @media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
      width: var(--toastify-toast-width) !important;
      gap:0 !important;
    }
  }

  .Toastify__toast-container--bottom-left {
    bottom: calc(16px + 56.8px);
    left: 16px;
    z-index: 10000;

    @media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
      bottom: calc(24px + 56.8px);
      left: 24px;
    }

    @media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
      bottom: 16px;
    }
  }

  .Toastify__toast--success {
    .icon {
      fill: ${(props) => props?.theme?.color?.success400};
    }
  }

  .Toastify__toast--error {
    .icon {
      fill: ${(props) => props?.theme?.color?.error400};
    }
  }

  .Toastify__toast--warning {
    .icon {
      fill: ${(props) => props?.theme?.color?.warning400};
    }
  }

  .Toastify__toast--info {
    .icon {
      fill: ${(props) => props?.theme?.color?.info400};
    }
  }

  .isActiveItem{

    &::before {
				content: '';
				position: absolute;
				width: ${(props) => props.navbarMinWith ?? '90%'};
				height: 40px;
				border-radius: 14px;
				margin-left: -${(props) => props.theme.space.spacing3X};
				background-color: ${(props) => props.theme.color.primary304};
				z-index: -1;
			}
    p{
      color: ${(props) => props.theme.color.primary300} !important;
    }

    img {
      border: 1px solid ${(props) => props.theme.color.primary300} !important;
    }

    svg{
      fill: ${(props) => props.theme.color.primary300} !important;
    }
  }
`;

export default globalStyles;
