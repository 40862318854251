// @ts-nocheck
import React, { useState, useEffect, createContext } from 'react';
import { useReducedState } from './hooks/useReducedState';
import { Routes, Route, Navigate } from 'react-router-dom';
import { FirestoreProvider, useFirebaseApp } from 'reactfire';
import { doc, getFirestore, Timestamp, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './App.css';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './theme/GlobalStyles';
import LandingLayout from './components/layouts/LandingLayout';
import InHomeLayout from './components/layouts/InHomeLayout';
import theme from './theme/Theme';

//import ALGORITHMS
import { recoverDataOfUser } from './algorithms/recoverDataOfUser';
import { recoverUniversities } from './algorithms/recoverUniversities';
import { recoverCourses } from './algorithms/recoverCourses';

//import Routes
import { internalRoutes, internalRoutesVideo } from './routes';

import Welcome from './pages/Welcome';
import NotFound from './pages/NotFound';
import { Terms } from './pages/Terms';
// import Landing from './pages/Landing';
import Home from './pages/Home';
// import ClassVideo from "./pages/ClassVideo";
import Promotions from './pages/Promotions';
import Free from './pages/Free';
import Register from './pages/Register';
import ContactAdviser from './pages/ContactAdviser';
import Login from './pages/Login';
import ExamForm from './pages/ExamForm';
import ResultsExamn from './pages/ResultsExamn';
import AboutUs from './pages/AboutUs';
import HelpCenter from './pages/HelpCenter';
import HelpContacts from './pages/HelpContacts';
import MainSpinner from './components/spinner/MainSpinner';
import ExpandLayout from './components/layouts/ExpandLayout';
import VideoLayout from './components/layouts/VideoLayout';

//Import route guards
import { LocationRouteGuard } from './components/routeGuards/LocationRouteGuard';
import Contact from './pages/Contact';
import KatPremium from './pages/KatPremium';
import { ProtectedRoute } from './routes/ProtectedRoute';
import Quiz from './pages/Quiz';
import Answer from './pages/Answer';
import { LayoutStatesContextProvider } from './components/layouts/LayoutContextOfStates';
import { useNavbarStore } from './store/navbarStore';

const AppContext = createContext();
const { Provider: AppProvider, Consumer } = AppContext;

function App() {
	const firestoreInstance = getFirestore(useFirebaseApp());
	const auth = getAuth();
	const { navbarDeployed } = useNavbarStore();
	const [currentUser, setCurrentUser] = useState(auth.currentUser || null);
	const [dataOfUser, setDataOfUser] = useState(null);
	const [weekExam, setWeekExam] = useReducedState({
		questions: null,
		metadata: null,
		timers: null,
		totalMarkedAnswers: [],
	});
	const [simulacrum, setSimulacrum] = useReducedState({
		questions: null,
		metadata: null,
		timers: null,
		totalMarkedAnswers: [],
	});
	const [quiz, setQuiz] = useReducedState({
		key: null,
		uqidSelected: null,
		selectAlternative: null,
		score: 0,
		incorrectAnswers: 0,
		skipAnswers: 0,
		totalMarkedAnswers: {},
		markedKeyIncorrect: {},
		markedKeySkip: {},
	});
	const [week] = useState(null); //Temporal state
	const [dataOfClasses, setDataOfClasses] = useState(null);
	const [dataPrevOfClasses, setDataPrevOfClasses] = useState(null);
	const [listOfCoursesPerTopics, setListOfCoursesPerTopics] = useState(null);
	const [weekSelected, setWeekSelected] = useState();
	const [universities, setUniversities] = useState(null);
	const [loading, setLoading] = useState({ status: true, title: null });
	const [subscriptionTime, setSubscriptionTime] = useState({
		serverDateNow: null,
		endTimestamp: null,
		timeRemaining: 0,
	});
	//Lista de examenes simulacro resueltos para no ejecutar doble carga
	const [solvedExams, setSolvedExams] = useReducedState({});

	useEffect(() => {
		!universities && recoverUniversities(firestoreInstance, setUniversities);
		!currentUser &&
			onAuthStateChanged(auth, async (user) => {
				setLoading({ status: true, title: 'Recopilando datos de usuario' });
				user ? setCurrentUser(user) : setCurrentUser(null);
				if (user) {
					setListOfCoursesPerTopics(await recoverCourses(firestoreInstance));
					if (!dataOfClasses || !dataPrevOfClasses) {
						const classesRef = doc(firestoreInstance, 'classes', 'listOfNewLongClasses');

						const classesRefBack = doc(firestoreInstance, 'classes', 'listOfClasses');

						const classesSnap = await getDoc(classesRef);
						setDataOfClasses(classesSnap.exists() && classesSnap.data());

						const classesSnapPrev = await getDoc(classesRefBack);
						setDataPrevOfClasses(classesSnapPrev.exists() && classesSnapPrev.data());

						//Subir preguntas
						// questions.map(async (q) => {
						//   const docRef = doc(firestoreInstance, "questionsWeekBank", q.uqid);
						//   await setDoc(docRef, q, { merge: true });
						// });
						// Subir clases a la base de datos
						//   const listOfClassesRef = doc(
						//     firestoreInstance,
						//     "classes",
						//     "listOfClasses"
						//   );
						//   setDoc(listOfClassesRef, schedule, { merge: true });
						//   console.log("se envió");
					}
					const recoverUser = await recoverDataOfUser(firestoreInstance, user);
					recoverUser.forEach((doc) => {
						setDataOfUser(doc.data());
						let serverTimestamp = Timestamp.now().toDate();
						if (doc.data()?.subscriptionData?.endTimestamp) {
							let time = Math.ceil(
								(doc.data().subscriptionData.endTimestamp.toDate().getTime() - serverTimestamp.getTime()) / (1000 * 60 * 60 * 24),
							);
							setSubscriptionTime({
								endTimestamp: doc.data().subscriptionData.endTimestamp.toDate(),
								serverDateNow: serverTimestamp,
								timeRemaining: time > 0 ? time : 0,
							});
						}
					});
				}
				setLoading({ status: false, title: null });
			});
		return () => {};
	}, [auth, dataOfUser, currentUser, subscriptionTime]);

	const appValue = {
		setCurrentUser,
		currentUser,
		quiz,
		setQuiz,
		setSimulacrum,
		simulacrum,
		setDataOfUser,
		dataOfUser,
		week,
		dataPrevOfClasses,
		dataOfClasses,
		listOfCoursesPerTopics,
		weekSelected,
		setWeekSelected,
		subscriptionTime,
		setSubscriptionTime,
		solvedExams,
		setSolvedExams,
		universities,
		weekExam,
		setWeekExam,
		setLoading,
	};

	if ((loading.status && !currentUser) || loading.status) {
		return <MainSpinner title={loading.title || 'Calentando motores...'} />;
	}

	return (
		<FirestoreProvider sdk={firestoreInstance}>
			<AppProvider value={appValue}>
				<LayoutStatesContextProvider>
					<ThemeProvider theme={theme}>
						<GlobalStyles navbarMinWith={navbarDeployed ? '90%' : '45%'} />
						<Routes>
							<Route
								path="/"
								element={
									<Navigate to="/login" />
									// <ProtectedRoute>
									// 	<LandingLayout children={<Landing />} />
									// </ProtectedRoute>
								}
							/>
							<Route
								path="/home"
								element={
									<ProtectedRoute redirectTo="/">
										<InHomeLayout children={<Home />} />
									</ProtectedRoute>
								}
							/>
							{/* <Route path="/classes-prev/algebra" element={<InHomeLayout children={<ClassVideo />} />} /> */}
							<Route path="/promotions" element={<LandingLayout children={<Promotions />} />} />
							<Route path="/about-us" exact={true} element={<LandingLayout children={<AboutUs />} />} />
							<Route path="/contact-us" element={<Contact />} />
							<Route path="/kat-pay" element={<KatPremium />} />
							<Route path="/free" element={<LandingLayout children={<Free />} />} />
							<Route path="/answer/:uqid" element={<LandingLayout children={<Answer />} />} />
							<Route path="/courses/:course/classes/:week/quiz" element={<Quiz />} />
							<Route
								path="/register"
								element={
									<ProtectedRoute>
										<Register />
									</ProtectedRoute>
								}
							/>
							<Route path="/contact-adviser" element={<LandingLayout children={<ContactAdviser />} />} />
							<Route
								path="/login"
								element={
									<ProtectedRoute>
										<Login />
									</ProtectedRoute>
								}
							/>
							<Route path="/welcome" element={<Welcome />} />
							<Route path="/terms-conditions" element={<LandingLayout children={<Terms />} />} />
							<Route path="/help-center" element={<LandingLayout children={<HelpCenter />} />} />
							<Route path="/help-contacts" element={<LandingLayout children={<HelpContacts />} />} />
							{internalRoutes.map((route) => (
								<Route
									path={route.path}
									key={route.path}
									element={
										<ProtectedRoute redirectTo={'/'}>
											<InHomeLayout children={route.element} />
										</ProtectedRoute>
									}
								/>
							))}
							{currentUser &&
								internalRoutesVideo.map((route) => (
									<Route
										path={route.path}
										key={route.path}
										element={<VideoLayout children={route.element} title="Clase en vivo" />}
									/>
								))}

							<Route path="*" element={<NotFound />} />
							<Route
								path="/exams/form"
								element={
									<ProtectedRoute redirectTo="/contact-us">
										<ExpandLayout children={<ExamForm />} title={'Examen'} />
									</ProtectedRoute>
								}
							/>
							<Route
								path="/exams/results"
								element={
									<ProtectedRoute redirectTo="/contact-us">
										<LocationRouteGuard
											children={<ExpandLayout children={<ResultsExamn />} title={'Resultados'} />}
											exitPath="/exams"
										/>
									</ProtectedRoute>
								}
							/>
							<Route path="*" element={<Navigate to="/404" />} />
						</Routes>
					</ThemeProvider>
				</LayoutStatesContextProvider>
			</AppProvider>
		</FirestoreProvider>
	);
}

export { App, Consumer as AppConsumer, AppContext };
