import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseSVG } from './../../icons/close.svg';
import { ReactComponent as RightSVG } from './../../icons/right-arrow.svg';
import { ReactComponent as LeftSVG } from './../../icons/left-arrow.svg';
import { moreLater } from './cards/algorithms/moreLater';
import { Overlay, ModalContainer, ModalContainerBoard, ModalBoard } from './styles/sMainModal';

//Import firebase utils
import { useFirestore } from 'reactfire';

export default function MainModal({
	children,
	modalState,
	setModalState,
	title,
	currentUser,
	setDataOfUser,
	type,
	boards,
	index,
	overlayStyles,
	isMoreLater = true,
}) {
	const db = useFirestore();
	const [localIndex, setLocalIndex] = useState(index);

	const handleArrowRightClick = (e) => {
		e.preventDefault();
		if (localIndex < boards.length - 1) {
			setLocalIndex(localIndex + 1);
		} else {
			setLocalIndex(0);
		}
	};
	const handleArrowLeftClick = (e) => {
		e.preventDefault();
		if (localIndex > 0) {
			setLocalIndex(localIndex - 1);
		} else {
			setLocalIndex(boards.length - 1);
		}
	};

	useEffect(() => {}, []);

	return (
		<>
			{type === 'board' && modalState && (
				<ModalBoard>
					<Overlay
						style={overlayStyles}
						onClick={() => {
							setModalState(false);
						}}></Overlay>
					<ModalContainerBoard>
						<img
							src={boards[localIndex]?.boardImage}
							alt={title}
							onClick={() => window.open(boards[localIndex]?.boardImage, '_blank')}
						/>
						<CloseSVG
							className="closeModal"
							onClick={() => {
								setModalState(false);
							}}
						/>
						<LeftSVG className="rightArrow" onClick={(e) => handleArrowLeftClick(e)} onDoubleClick={(e) => e.stopPropagation()} />
						<RightSVG
							className="leftArrow"
							onClick={(e) => handleArrowRightClick(e)}
							onDoubleClick={(e) => e.stopPropagation()}
						/>
					</ModalContainerBoard>
					<div className="boardDescription">
						<h1>{title}</h1>
						<p>{`${boards[localIndex]?.titleOfBoard}: ${boards[localIndex]?.descriptionOfBoard}`}</p>
					</div>
				</ModalBoard>
			)}
			{type === 'classic' && modalState && (
				<>
					<Overlay
						style={overlayStyles}
						onClick={() => {
							setModalState && setModalState(true);
						}}></Overlay>
					<ModalContainer>
						<div>
							{title && <h1>{title}</h1>}
							<CloseSVG
								className="closeModal"
								onClick={() => {
									setModalState(false);
									isMoreLater && moreLater({ db, currentUser, setDataOfUser });
								}}
							/>
						</div>
						{children}
					</ModalContainer>
				</>
			)}
		</>
	);
}
