export const nameFValidator = {
	required: 'Campo obligatorio*',
	maxLength: {
		value: 60,
		message: 'El nombre debe ser menor a 60 caracteres*',
	},
	pattern: {
		value: /^[a-záéíóúñ '-]+$/i,
		message: 'Nombre inválido*',
	},
};

export const nameFValidatorAttorney = {
	maxLength: {
		value: 60,
		message: 'El nombre debe ser menor a 60 caracteres*',
	},
	pattern: {
		value: /^[a-záéíóúñ '-]+$/i,
		message: 'Nombre inválido*',
	},
};

export const phoneFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^[90]\d{8}$/,
		message: 'Número telefónico inválido*',
	},
};

export const phoneFValidatorAttorney = {
	pattern: {
		value: /^[90]\d{8}$/,
		message: 'Número telefónico inválido*',
	},
};

export const DNIFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^\d{8}$/,
		message: 'Documento de identidad inválido*',
	},
};

export const DNIOptionalFValidator = {
	pattern: {
		value: /^\d{8}$/,
		message: 'Documento de identidad inválido*',
	},
};

export const passwordFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^(?=.*[A-Za-z])[A-Za-z@ $!%*#?&\d]{8,}$/,
		message: 'Tu contraseña es insegura, prueba a usar mayúsculas, minúsculas y números*',
	},
};

export const addressFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
		message: 'Ingrese una dirección correcta*',
	},
};

export const referenceFValidator = {
	required: 'Campo obligatorio*',
	pattern: {
		value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
		message: 'Ingrese una referencia válida*',
	},
};
