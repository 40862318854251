import React from 'react';
import Sky2 from '../../images/sky_2.png';
import Sky from '../../images/sky_1.png';
import { MainCloud, MainContent } from './styles/sCloud';

const Cloud = () => {
	return (
		<MainCloud>
			<MainContent>
				<img src={Sky} alt="Nubes" />
				<img src={Sky2} alt="Nubes nuevas" />
			</MainContent>
		</MainCloud>
	);
};

export default Cloud;
