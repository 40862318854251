import styled from 'styled-components';

export const DesktopRegisterContainer = styled.div`
	display: block;

	@media (min-width: 1200px) {
		display: flex;
	}
`;

export const MainRegisterContainer = styled.div`
	display: flex;
	flex-direction: column;

	p {
		text-align: center;
	}

	.googleButton {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;

		p {
			margin-bottom: 0;
		}
	}

	.mainRegisterAndLogin {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin: 50px 0 0 0;

		a {
			display: block;
			margin: 0 auto 40px auto;
		}

		.initialLoginMessage {
			margin: 0 0 10px 0;
		}
	}

	.registerAndLoginFooter {
		margin: 30px 0;
	}

	.LogoDecideContainer {
		height: 120px;
		margin-bottom: 20px;
		img {
			height: 100%;
		}
	}

	.logoFooterLanding {
		fill: ${(props) => props.theme.color.neutral900};
		margin: 0 0 50px 0;
		width: -webkit-fill-available;

		@media (min-width: 768px) {
			height: 58px;
		}

		@media (min-width: 1200px) {
			margin: 0 0 25px 0;
		}
	}
`;
