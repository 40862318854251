import styled from 'styled-components';
import { Title4 } from './../../styles/textGeneral';

export const TitleContainer = styled.div`
	margin-top: 2rem;

	${Title4} {
		margin: 0 0 -30px 0;
	}

	@media (min-width: 1200px) {
		margin: 0 0 30px 0;
	}
`;

export const ContentAnswers = styled.div`
	margin-top: 80px;
`;

export const ChatKat = styled.section`
	line-height: 1.5;
	margin: 10px 0 100px 0;
	a {
		color: ${(props) => props.theme.color.info400};
		display: inline-block;
		margin: 0 0 0 4px;

		&:hover {
			text-decoration: underline;
		}
	}

	.title {
		font-weight: ${(props) => props.theme.font.weight.bold};
		margin: 0 0 15px 0;
		font-family: ${(props) => props.theme.font.brandFont};
	}

	.chatContainer {
		display: flex;
	}

	.profile {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		margin: 0 10px 0 0;
	}

	.chat {
		background: rgba(17, 168, 60, 0.1);
		border-radius: 10px;
		padding: 15px;
		width: fit-content;
		font-family: ${(props) => props.theme.font.textFont};
	}

	.chat-first {
		margin: 0 0 10px 0;
	}
`;

export const SectionPromotions = styled.section`
	background: ${(props) => props.theme.color.neutral1000};
	padding: 65px 0 100px 0;
`;
