import { NavLinkLandingStyled } from './styles/sItemNavbarInLanding';

const ItemNavbar = ({ icon, path, nameItem, setHamburger, hamburger }) => {
	return (
		<li
			onClick={() => {
				setHamburger(!hamburger);
			}}>
			<NavLinkLandingStyled to={path} end>
				<div className="container-icon-text">
					{icon}
					<span>{nameItem}</span>
				</div>
				<span className="line"></span>
			</NavLinkLandingStyled>
		</li>
	);
};

export default ItemNavbar;
