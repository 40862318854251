import { QuestionInputContainer, TextQuestionStyled } from '../exams/form/styles/sQuestionInput';
import { Latex } from '../general/latex/Latex';
import OldLatex from 'react-latex-next';

export default function QuestionInput({ question, keys, UrlOfImage, uqid, markedKey, isKatex, renderInput }) {
	return (
		<QuestionInputContainer>
			<TextQuestionStyled>{isKatex ? <Latex>{question}</Latex> : <OldLatex>{question}</OldLatex>}</TextQuestionStyled>
			{UrlOfImage && <img className="questionImage" src={UrlOfImage} alt={question} />}
			{keys.map((item, index) => {
				let keyLetter = null;
				switch (index) {
					case 0:
						keyLetter = 'a';
						break;
					case 1:
						keyLetter = 'b';
						break;
					case 2:
						keyLetter = 'c';
						break;
					case 3:
						keyLetter = 'd';
						break;
					case 4:
						keyLetter = 'e';
						break;
					default:
						keyLetter = null;
				}
				if (keyLetter !== null) {
					return (
						<div className="questionContainer" key={uqid.concat(item.concat(index))}>
							{renderInput({ uqid, item, question, markedKey, index })}
							<label htmlFor={uqid.concat(item)}>
								<span className="keyLetterQuestion">{keyLetter}</span>
								<div className="question">
									{item.startsWith('https') ? (
										<img src={item} alt={item} />
									) : (
										<p>{isKatex ? <Latex>{item}</Latex> : <OldLatex>{item}</OldLatex>}</p>
									)}
								</div>
							</label>
						</div>
					);
				}
				return false;
			})}
		</QuestionInputContainer>
	);
}
