import { formatRelative } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatDate = (date) => {
	let formattedDate = '';
	if (date) {
		// Convert the date in words relative to the current date
		formattedDate = formatRelative(date, new Date(), { locale: es });
		// Uppercase the first letter
		formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
	}
	return formattedDate;
};
