import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { WrapperExpand } from './../../../styles/generalStyles';
import { MainExamFormContainer, SubHeaderExamn, DetailsPageExamn } from './../form/styles/sMainExamForm';
import { Title4, Title5, Text } from './../../../styles/textGeneral';
import QuestionResults from './../../general/cOthers/QuestionResults';
import ResultsPerCourse from './ResultsPerCourse';
import { useScroll } from './../../../hooks/useScroll';
import MainModal from '../../modal/MainModal';
import ModalReportQuestion from '../ModalReportQuestion';

//import Contexts

export default function MainResultsExamn() {
	const [modalStateReport, setModalStateReport] = useState(false);
	const [dataQuestionReport, setDataQuestionReport] = useState(null);
	const location = useLocation();
	const statistics = location.state.metadata.statistics;
	const subHeaderRef = useRef();
	const { scroll } = useScroll(subHeaderRef);

	return (
		<main ref={subHeaderRef}>
			<WrapperExpand>
				<DetailsPageExamn>
					<Title4>Simulacro tipo admisión</Title4>
					<Text center className="scoreExamn">
						Puntaje obtenido: {statistics?.totalScore} puntos
					</Text>
				</DetailsPageExamn>
			</WrapperExpand>
			<SubHeaderExamn scroll={scroll}>
				<WrapperExpand>
					<div className="subHeaderExamnContainer">
						<Title5 className="curses">Cursos</Title5>
						<div className="totalQuestionAndClockDetails">
							<p>{statistics?.totalMarkedAnswers} preguntas respondidas</p>
							<QuestionResults state={location.state} />
						</div>
					</div>
				</WrapperExpand>
			</SubHeaderExamn>
			<WrapperExpand>
				<MainExamFormContainer>
					<div>
						<form className="formExamn">
							<ul className="courseCategoryFormContainer" id="listOfQuestionsContainter">
								<ResultsPerCourse
									setModalStateReport={setModalStateReport}
									modalStateReport={modalStateReport}
									setDataQuestionReport={setDataQuestionReport}
									state={location.state}
								/>
							</ul>
						</form>
						<MainModal
							modalState={modalStateReport}
							setModalState={setModalStateReport}
							title="¿Encontraste un problema?"
							type="classic">
							<ModalReportQuestion setModalStateReport={setModalStateReport} dataQuestionReport={dataQuestionReport} />
						</MainModal>
					</div>
				</MainExamFormContainer>
			</WrapperExpand>
		</main>
	);
}
