import { useState, useEffect } from 'react';

export const useScroll = (elmentRef) => {
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			if (elmentRef?.current) {
				const element = elmentRef.current;
				const { y } = element.getBoundingClientRect();
				setScroll(y);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return { scroll };
};
