import styled from 'styled-components';
import { Body4Mixin, Body9Mixin, Details3 } from '../../../../styles/textGeneral';

export const CardClassVideoInformationContainer = styled.div`
	display: flex;
	padding: 2%;
	flex-direction: row;
	gap: ${({ theme }) => theme.space.spacing3X};
	justify-content: space-between;

	section {
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: ${({ theme }) => theme.space.spacing3X};
	}

	.sectionButton {
		display: none;
		@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
			display: flex;
		}
	}

	article {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.space.spacing1X};

		.courseProgressContainer {
			display: flex;
			flex-direction: row;
		}
	}
`;

export const InformationClassVideo = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.space.spacing1X};

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing5X};
		flex-direction: row;
	}
`;

export const TitleClassVideos = styled.p`
	${Body4Mixin}
`;

export const CourseProgress = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 100px;
	flex-direction: column;
	border-radius: ${({ theme }) => theme.border.border12};
	background-color: ${({ theme }) => theme.color.neutral835};
	padding: ${({ theme }) => theme.space.spacing1X} ${({ theme }) => theme.space.spacing2X};
	p {
		${Details3}
	}
`;

export const TitleCourse = styled.p`
	${Body9Mixin}
	color: ${({ theme }) => theme.color.neutral400};
`;

export const TeacherInformation = styled.div`
	align-items: center;
	gap: ${({ theme }) => theme.space.spacing3X};
	display: none;

	img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid ${({ theme }) => theme.color.primary300};
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		display: flex;
		flex-direction: row;
	}
`;
