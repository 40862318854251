import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../App';
import { ClassVideoContainer, VideoBloquedContainer } from '../styles/sMainClassVideo';
import MainSpinner from '../../spinner/MainSpinner';
import ReactPlayer from 'react-player';
import { MainClassVideoContainer } from './styles/sMainClassVideo';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { WrapperHome } from '../../../styles/generalStyles';
import premiunClasses from './../../../images/premium-classes.webp';
import { Button } from '../../../styles/buttonGeneral';
import { Link, useParams } from 'react-router-dom';

import { CardClassVideoInformation } from '../recordedCourse/CardClassVideoInformation';
import {
	ClassVideoMenuContainer,
	ContentContainer,
	LineButtonActive,
	MainClassContent,
	MenuContainer,
} from './styles/sClassVideoMenu';
import { CourseContent } from '../../../packages/shared/components/items/CourseContent';
import { CommentList } from '../../../packages/shared/components/cards/CommentList';
import { ClassResource } from '../recordedCourse/ClassResource';
// import { useEffect } from 'react';
import { useInnerWidth } from '../algorithms/useInnerWidth';
import { classesOrganizer } from '../recordedCourse/algorithms/classesOrganizer';

const commentsArreglo = [
	{
		userImageLink:
			'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d',
		userName: 'Usuario',
		userComent: 'Lorem few ewfwe  fwe we',
		numberCommentReactions: 10,
	},
	{
		userImageLink:
			'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d',
		userName: 'Usuario',
		userComent: 'Lorem few ewfwe  fwe we',
		numberCommentReactions: 10,
	},
	{
		userImageLink:
			'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d',
		userName: 'Usuario',
		userComent: 'Lorem few ewfwe  fwe we',
		numberCommentReactions: 10,
	},
	{
		userImageLink:
			'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/newsBackground%2F%C3%81lgebra.webp?alt=media&token=6e782a5e-fda4-4ef3-99d5-6d98b40d844d',
		userName: 'Usuario',
		userComent: 'Lorem few ewfwe  fwe we',
		numberCommentReactions: 10,
	},
];

const MainClassvideo = ({ listClassesMorning }) => {
	const actualClass = listClassesMorning.flat();

	const [t] = useTranslation('main');
	let params = useParams();
	const [activeTab, setActiveTab] = useState('a');
	const [activeMenu, setActiveMenu] = useState('active');
	const [course, setCourse] = useState(null);
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const innerWidth = useInnerWidth();
	const {
		dataOfClasses,
		subscriptionTime,
		listOfCoursesPerTopics,
		//  dataPrevOfClasses
	} = useContext(AppContext);

	// const { localRecentCoursePath } = useStatesContext();
	const [latestClasses, setLatestClasses] = useState(null);
	// const [oldClasses, setOldClasses] = useState(null);
	const [
		viewerClass,
		//  setViewerClass
	] = useState({
		top: false,
		bot: false,
	});

	useEffect(() => {
		setCourse(
			Object.values(listOfCoursesPerTopics).reduce((acc, value) => {
				let result = value.filter((course) => course.route === params.course);
				if (result.length > 0) {
					acc = result;
				}
				return acc;
			}, []),
		);
	}, []);

	useEffect(() => {
		if (course) {
			setLatestClasses(
				classesOrganizer({
					classes: dataOfClasses,
					course: course,
					latestClasses: true,
				}),
			);
		}
		// setOldClasses(
		// 	classesOrganizer({
		// 		classes: dataPrevOfClasses,
		// 		course: course,
		// 		latestClasses: false,
		// 	}),
		// );
	}, [viewerClass, course]);
	// console.log('course', course);

	useEffect(() => {
		if (innerWidth > 1199) {
			setActiveMenu('inactive');
			setActiveTab('a');
		} else {
			setActiveMenu('active');
		}
	}, [innerWidth]);

	if (!listClassesMorning) {
		return <MainSpinner title={'Obteniendo lista de clases disponibles...'} />;
	}

	return (
		<MainClassVideoContainer>
			<div className="mainClassVideoBloqued"></div>
			{listClassesMorning.length > 0
				? listClassesMorning[0].map((item) => (
						<>
							{subscriptionTime.timeRemaining > 0 ? (
								<>
									<MainClassContent>
										<div
										//  className="ClassDescriptionContainer"
										>
											<ClassVideoContainer key={item.urlOfVideo}>
												<Helmet>
													<title>{item.titleOfClass ? item.titleOfClass : `courses.${item.course}`} - Ebbiner</title>
													<meta
														name="description"
														content={`Clase del curso de ${t(`courses.${item.course}`)}. Tema: ${
															item.titleOfClass ? item.titleOfClass : `courses.${item.course}`
														}.`}
													/>
													<meta
														property="og:title"
														content={`${item.titleOfClass ? item.titleOfClass : `courses.${item.course}`} - Ebbiner`}
													/>
													<meta
														property="og:description"
														content={`Clase del curso de ${t(`courses.${item.course}`)}. Tema: ${
															item.titleOfClass ? item.titleOfClass : `courses.${item.course}`
														}.`}
													/>
													<meta
														property="twitter:title"
														content={`${item.titleOfClass ? item.titleOfClass : `courses.${item.course}`} - Ebbiner`}
													/>
													<meta
														property="twitter:description"
														content={`Clase del curso de ${t(`courses.${item.course}`)}. Tema: ${
															item.titleOfClass ? item.titleOfClass : `courses.${item.course}`
														}.`}
													/>
												</Helmet>
												<ReactPlayer
													url={item.urlOfVideo}
													playing={true}
													loop={false}
													muted={false}
													controls={true}
													className="react-player"
													fallback={<h1>CARGANDO...</h1>}
													style={null}
													config={{
														youtube: {
															playerVars: {
																showinfo: 1,
																rel: 0,
															},
														},
													}}
													light={true}
												/>
											</ClassVideoContainer>
											<div>
												<CardClassVideoInformation
													titleClass={actualClass?.[0]?.titleOfClass}
													courseProgress={`Clase ${params.week.slice(4, 5)} / ${latestClasses?.length}`}
													courseName={t(`courses.${actualClass?.[0].course}`)}
													professorName={actualClass?.[0].professor}
													urlProfessorImage={course?.[0].imageProfessor}
												/>
												{activeMenu === 'inactive' && (
													<ClassResource
														arrayOfBoards={actualClass?.[0]?.urlOfBoard}
														textResource={'Docente altamente calificado con amplia experiencia en la enseñanza en el área.'}
													/>
												)}
											</div>
										</div>
										<WrapperHome>
											<ClassVideoMenuContainer>
												<MenuContainer>
													<div className="buttonContainer">
														<button className={`option ${activeTab === 'a' ? 'active' : ''}`} onClick={() => handleTabClick('a')}>
															<p>Clases</p>
														</button>
														<LineButtonActive className={`option ${activeTab === 'a' ? 'active' : ''}`} />
													</div>
													{activeMenu === 'active' && (
														<div className="buttonContainer">
															<button
																className={`option ${activeTab === 'b' ? 'active' : ''}`}
																onClick={() => handleTabClick('b')}>
																<p>Recursos</p>
															</button>
															<LineButtonActive className={`option ${activeTab === 'b' ? 'active' : ''}`} />
														</div>
													)}
													<div className="buttonContainer">
														<button className={`option ${activeTab === 'c' ? 'active' : ''}`} onClick={() => handleTabClick('c')}>
															<p>Comentarios</p>
														</button>
														<LineButtonActive className={`option ${activeTab === 'c' ? 'active' : ''}`} />
													</div>
												</MenuContainer>
												<ContentContainer>
													{activeTab === 'a' && (
														<div className="tab">
															{<CourseContent clasesInformacion={latestClasses} headerActive={false} footerActive={false} menu />}
														</div>
													)}
													{activeTab === 'b' && (
														<div className="tab">
															{
																<ClassResource
																	textResource={
																		''
																		// 'La región axial del cuerpo humano es la parte central que incluye el tronco y la cabeza. Esta región se extiende desde la cabeza hasta la pelvis e incluye las siguientes subregiones:'
																	}
																	arrayOfBoards={actualClass?.[0]?.urlOfBoard}
																/>
															}
														</div>
													)}

													{activeTab === 'c' && <div className="tab">{<CommentList commentsArreglo={commentsArreglo} />}</div>}
												</ContentContainer>
											</ClassVideoMenuContainer>

											{/* <DescriptionClassVideo>
												<BaseContent>
													<h2>{t(`courses.${item.course}`)}</h2>
													<Link to="quiz">
														<Button primary secondary text="Resolver cuestionario" />
													</Link>
												</BaseContent>
												{item.titleOfClass && <Text>{item.titleOfClass}</Text>}
												{Array.isArray(item?.urlOfBoard) && item?.urlOfBoard?.length > 0 && <Title6>Pizarras de la clase</Title6>}
												<CourseCardsUnlist>
													{Array.isArray(item?.urlOfBoard) &&
														item?.urlOfBoard?.length > 0 &&
														item.urlOfBoard.map((board, index) => (
															<BoardCard
																key={board.boardImage}
																course={t(`courses.${item.course}`)}
																boards={item.urlOfBoard}
																index={index}
															/>
														))}
												</CourseCardsUnlist>
											</DescriptionClassVideo> */}
										</WrapperHome>
									</MainClassContent>
								</>
							) : (
								<>
									<VideoBloquedContainer image={premiunClasses}>
										<p>Contenido Premium</p>
										<h2>Recibe este y más contenido para complementar tu preparación</h2>
										<p className="suscribeOnly">Suscríbete hasta por</p>
										<span>S/. 127 mensual</span>
										<Link to="/promotions">
											<Button primary text="Comprar ahora" />
										</Link>
									</VideoBloquedContainer>
								</>
							)}
						</>
				  ))
				: 'No hay ningún video'}
		</MainClassVideoContainer>
		// <MainClassVideoContainer>
		// 	<div className="mainClassVideoBloqued"></div>
		// 	{listClassesMorning.length > 0
		// 		? listClassesMorning[0].map((item) => (
		// 				<>
		// 					{subscriptionTime.timeRemaining > 0 ? (
		// 						<>
		// 							<ClassVideoContainer key={item.urlOfVideo}>
		// 								<Helmet>
		// 									<title>{item.titleOfClass ? item.titleOfClass : `courses.${item.course}`} - Ebbiner</title>
		// 									<meta
		// 										name="description"
		// 										content={`Clase del curso de ${t(`courses.${item.course}`)}. Tema: ${
		// 											item.titleOfClass ? item.titleOfClass : `courses.${item.course}`
		// 										}.`}
		// 									/>
		// 									<meta
		// 										property="og:title"
		// 										content={`${item.titleOfClass ? item.titleOfClass : `courses.${item.course}`} - Ebbiner`}
		// 									/>
		// 									<meta
		// 										property="og:description"
		// 										content={`Clase del curso de ${t(`courses.${item.course}`)}. Tema: ${
		// 											item.titleOfClass ? item.titleOfClass : `courses.${item.course}`
		// 										}.`}
		// 									/>
		// 									<meta
		// 										property="twitter:title"
		// 										content={`${item.titleOfClass ? item.titleOfClass : `courses.${item.course}`} - Ebbiner`}
		// 									/>
		// 									<meta
		// 										property="twitter:description"
		// 										content={`Clase del curso de ${t(`courses.${item.course}`)}. Tema: ${
		// 											item.titleOfClass ? item.titleOfClass : `courses.${item.course}`
		// 										}.`}
		// 									/>
		// 								</Helmet>
		// 								<ReactPlayer
		// 									url={item.urlOfVideo}
		// 									playing={true}
		// 									loop={false}
		// 									muted={false}
		// 									controls={true}
		// 									className="react-player"
		// 									fallback={<h1>CARGANDO...</h1>}
		// 									style={null}
		// 								/>
		// 							</ClassVideoContainer>
		// 							<WrapperHome>
		// 								<DescriptionClassVideo>
		// 									<BaseContent>
		// 										<h2>{t(`courses.${item.course}`)}</h2>
		// 										<Link to="quiz">
		// 											{/* TODO: Arreglar el boton */}
		// 											<Button primary secondary text="Resolver cuestionario" />
		// 										</Link>
		// 									</BaseContent>
		// 									{item.titleOfClass && <Text>{item.titleOfClass}</Text>}
		// 									{Array.isArray(item?.urlOfBoard) && item?.urlOfBoard?.length > 0 && <Title6>Pizarras de la clase</Title6>}
		// 									<CourseCardsUnlist>
		// 										{Array.isArray(item?.urlOfBoard) &&
		// 											item?.urlOfBoard?.length > 0 &&
		// 											item.urlOfBoard.map((board, index) => (
		// 												<BoardCard
		// 													key={board.boardImage}
		// 													course={t(`courses.${item.course}`)}
		// 													boards={item.urlOfBoard}
		// 													index={index}
		// 												/>
		// 											))}
		// 									</CourseCardsUnlist>
		// 								</DescriptionClassVideo>
		// 							</WrapperHome>
		// 						</>
		// 					) : (
		// 						<>
		// 							<VideoBloquedContainer image={premiunClasses}>
		// 								<p>Contenido Premium</p>
		// 								<h2>Recibe este y más contenido para complementar tu preparación</h2>
		// 								<p className="suscribeOnly">Suscríbete hasta por</p>
		// 								<span>S/. 127 mensual</span>
		// 								<Link to="/promotions">
		// 									<Button primary text="Comprar ahora" />
		// 								</Link>
		// 							</VideoBloquedContainer>
		// 						</>
		// 					)}
		// 				</>
		// 		  ))
		// 		: 'No hay ningún video'}
		// </MainClassVideoContainer>
	);
};

export default MainClassvideo;
