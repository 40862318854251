import styled from 'styled-components';

export const ShareWithFriendsContainer = styled.aside`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: ${({ theme }) => theme.space.spacing2X};
	align-items: center;
	justify-content: center;
	margin-top: ${({ theme }) => theme.space.spacing10X};

	button {
		position: relative;
		height: 80px;
		svg {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		p {
			:hover {
				color: ${({ theme }) => theme.color.primary300};
			}
		}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: ${({ theme }) => theme.space.spacing4X};
	}
`;
