import React from 'react';
import { Helmet } from 'react-helmet-async';
import MainStatisticAdmission from './../../components/statistics/MainStatisticAdmission';

export default function Admission() {
	return (
		<>
			<Helmet>
				<title>Ebbiner | Probabilidad de ingreso</title>
				<meta name="description" content="Mis probabilidades de ingreso" />
				<meta property="og:title" content="Probabilidad de ingreso" />
				<meta property="og:description" content="Mis probabilidades de ingreso" />
				<meta property="twitter:title" content="Probabilidad de ingreso" />
				<meta property="twitter:description" content="Mis probabilidades de ingreso" />
			</Helmet>
			<MainStatisticAdmission />
		</>
	);
}
