import styled from 'styled-components';
import { Body10Mixin, Body1Mixin, Body3Mixin } from '../../../../../styles/textGeneral';

export const CourseHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: ${({ theme }) => theme.space.spacing3X};

	img {
		width: 74px;
		height: 74px;
		border-radius: ${({ theme }) => theme.border.border12};
	}

	p {
		${Body3Mixin}
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing4X};
		img {
			width: 84px;
			height: 84px;
		}
	}

	@media (width >= ${({ theme }) => theme.media.mediaLG.width}) {
		gap: ${({ theme }) => theme.space.spacing5X};
		p {
			${Body1Mixin}
		}

		img {
			width: 100px;
			height: 100px;
		}
	}
`;

export const InformationCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${({ theme }) => theme.space.spacing2X};
`;

export const InformationCard = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.space.spacing4X};
`;

export const Information = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.space.spacing2X};

	p {
		${Body10Mixin}
		color: ${({ theme }) => theme.color.neutral500};
	}

	svg {
		width: 20px;
		height: 20px;
	}
`;
