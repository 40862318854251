import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './../../../styles/buttonGeneral';
import { UnlistFeatures, PriceCardContainer } from './styles/sPriceCard';
const Culqi = window.Culqi;

export default function PriceCard({
	background,
	type,
	price,
	features,
	button,
	discount,
	uniquePrice,
	nameOfPlan,
	intervalCount,
	trialDays,
	dataOfUser,
}) {
	const navigate = useNavigate();

	const cardHandleClick = (e) => {
		e.preventDefault();
		Culqi.publicKey = process.env.REACT_APP_CULQI_PUBLIC_KEY;
		Culqi.settings({
			title: type,
			currency: 'PEN', // Este parámetro es requerido para realizar pagos yape
			amount: uniquePrice * 100, // Este parámetro es requerido para realizar pagos yape
			// order: "ord_live_0CjjdWhFpEAZlxlz", // Este parámetro es requerido para realizar pagos con pagoEfectivo, billeteras y Cuotéalo
			nameOfPlan,
			intervalCount,
			trialDays,
		});
		Culqi.options({
			lang: 'auto',
			installments: false, // Habilitar o deshabilitar el campo de cuotas
			paymentMethods: {
				tarjeta: true,
				bancaMovil: false,
				agente: false,
				billetera: false,
				yape: false,
				cuotealo: false,
			},
			style: {
				logo: 'https://firebasestorage.googleapis.com/v0/b/gauler-665ba.appspot.com/o/logos%2Febbiner-icon.svg?alt=media&token=cbe205c5-d713-4c10-8544-4910079e06fc',
				bannerColor: '#1D2C40', // hexadecimal
				buttonBackground: 'green', // hexadecimal
				menuColor: '', // hexadecimal
				linksColor: '', // hexadecimal
				buttonText: '', // texto que tomará el botón
				buttonTextColor: '', // hexadecimal
				priceColor: '', // hexadecimal
			},
		});
		const plan = {
			name: nameOfPlan,
			amount: uniquePrice * 100,
			currency_code: 'PEN',
			interval: 'meses',
			interval_count: intervalCount,
			limit: 12,
		};
		if (trialDays) plan.trial_days = trialDays;
		Culqi.open();
	};

	return (
		<li>
			<PriceCardContainer discount={discount}>
				{discount && <div className="temporalOffer">Oferta temporal</div>}
				<div className="backgroundPriceCard">
					<img src={background} />
					<div className="backgroundDetails">
						<h2>{type}</h2>
						<h3>s/ {price}</h3>
						<span>por mes</span>
					</div>
				</div>
				<UnlistFeatures features={features} />
				<div className="buttonsContainer">
					{discount && <p className="regularPrice">Precio regular de s/ 441</p>}
					<p>Pago recurrente de s/ {uniquePrice}</p>
					<Button primary text={button} onClick={(e) => (dataOfUser ? cardHandleClick(e) : navigate('/register'))} />
				</div>
			</PriceCardContainer>
		</li>
	);
}
