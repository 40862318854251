import styled from 'styled-components';

export const ClassVideoContainer = styled.div`
	width: 100%;
	height: 400px;

	& iframe,
	& object,
	& embed {
		position: absolute;
		top: 50px;
		left: 0;
	}

	.react-player {
		position: absolute;
		/* top: 0;
		left: 0; */
		width: 100% !important;
		height: auto;
	}
	@media (min-width: 768px) {
		height: 430px;
	}

	@media (min-width: 1200px) {
		height: calc(60vh + 30px);

		& iframe,
		& object,
		& embed {
			top: 30px;
		}

		.react-player {
			width: calc(100% - 359px) !important;
			height: 60vh !important;
		}
	}
`;

export const VideoBloquedContainer = styled.div`
	background-image: url(${(props) => props.image});
	width: 100%;
	color: ${(props) => props.theme.color.neutral0};
	position: relative;
	top: 50px;
	background-size: cover;
	padding: 40px 20px;
	position: absolute;
	left: 0;
	height: auto;

	h2,
	p,
	span {
		text-align: center;
		font-family: ${(props) => props.theme.font.textFont};
	}

	p {
		font-size: 1.25em;
	}

	h2 {
		font-size: 1.75em;
		margin: 15px 0 35px 0;
		line-height: 1.5em;
		text-transform: initial;
	}

	.suscribeOnly {
		font-size: 1em;
		margin: 0 0 5px 0;
	}

	span {
		font-size: 2.5em;
		font-weight: ${(props) => props.theme.font.weight.bold};
		color: ${(props) => props.theme.color.primary900};
		display: block;
	}

	button {
		margin: 40px 0 0 0;
	}

	@media (min-width: 768px) {
		padding: 40px 20%;
	}

	@media (min-width: 1200px) {
		top: 30px;
		position: absolute;
		height: calc(80vh + 50px);
		width: calc(100% - 320px);
		left: 300px;
		padding: 0 20%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h2 {
			margin: 15px 0 45px 0;
		}

		a {
			width: 250px;
			margin: 40px auto;
		}
	}
`;

export const WrapperChat = styled.div`
	position: relative;
	top: 50px;
	height: calc(100vh - 250px);
	overflow: hidden;

	@media (min-width: 768px) {
		height: calc(100vh - 450px);
	}

	@media (min-width: 1024px) {
		height: calc(100vh - 50px);
	}

	@media (min-width: 1200px) {
		padding: 20px;
		background: ${(props) => props.theme.color.neutral10};
	}
`;

export const DescriptionClassVideo = styled.div`
	font-family: ${(props) => props.theme.font.textFont};

	p {
		line-height: 1.5em;
	}
`;

export const BaseContent = styled.div`
	display: flex;
	flex-direction: column-reverse;
	row-gap: 20px;
	justify-content: space-between;

	@media (min-width: 575px) {
		flex-direction: row;
		row-gap: initial;
	}

	@media (min-width: 768px) {
		margin-bottom: initial;
	}
`;
