import React from 'react';
import { ProfileItemAvatar, ProfileItemContainer } from './style/sProfile';
import { Body10 } from '../../../../styles/textGeneral';

export const Profile = ({ name, urlImage }) => {
	return (
		<ProfileItemContainer>
			<ProfileItemAvatar>
				<img src={urlImage ?? 'https://i.pravatar.cc/300'} alt="avatar" />
			</ProfileItemAvatar>
			<Body10>{name ?? 'Howard H.'}</Body10>
		</ProfileItemContainer>
	);
};
