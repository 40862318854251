import styled from 'styled-components';

export const ContactsContainer = styled.div`
	.contactItem {
		margin: 0 0 20px 0;
		box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.75);
		&:hover {
			box-shadow: 10px 10px 2px 0px rgba(0, 0, 0, 0.75);
			transform: scale(1.01);
		}
		&:active {
			box-shadow: 5px 6px 7px -1px rgba(0, 0, 0, 0.75);
			transform: scale(1);
		}
	}

	.center-items {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		text-align: start;
	}

	svg {
		fill: ${(props) => props.theme.color.primary200};
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;

		.contactItem {
			margin: 0;
		}
	}

	@media (min-width: 1200px) {
		margin: 0;
		gap: 20px;
	}
`;
