import { useState, useEffect, useContext } from 'react';
import { Wrapper } from '../../styles/generalStyles';
import {
	InfoContent,
	ListItem,
	MainContainer,
	PrincipalContainer,
	TextBackground,
	Items,
	ContentImage,
	TextBrand,
	ContentTextBrand,
} from './styles/sMainKat';
import { ReactComponent as CheckCircleSVG } from './../../../src/icons/check-circle.svg';
import { Button } from '../../styles/buttonGeneral';
import Kat from './iconComponent/Kat';
import MobileKat from '../../images/phone-kat.webp';
import MainModal from '../modal/MainModal';
import ModalKatPayment from './ModalKatPayment';
import GradientCircle from '../../shared/GradientCircle/GradienCircle';
import MainCloud from '../../shared/Clouds/Cloud';
import { SecondaryTitle, TertiaryTitle, TextBody4, TextSubtext2 } from '../../styles/textGeneral';
import { useSearchParams } from 'react-router-dom';
import { CollectInfo } from './algorithms/collectInfo';
import { useFirestore } from 'reactfire';
import { AppContext } from '../../App';

const listItems = [
	{ name: 'Recordatorios para estudiar' },
	{ name: 'Apoyo emocional y asesoría psicológica' },
	{ name: 'Kat te ayuda a descubrir nueva información' },
	{ name: 'Secretos de Kat para aprender y estudiar mejor' },
	{ name: 'Preguntas y resolución de todos los cursos' },
	{ name: 'Prioridad alta de respuesta' },
];

const MainKat = () => {
	const db = useFirestore();
	const { dataOfUser } = useContext(AppContext);
	const [modalState, setModalState] = useState(false);
	const [searchParams] = useSearchParams();
	const query = Object.fromEntries([...searchParams]);

	useEffect(() => {
		if (query?.nu) {
			CollectInfo(db, query?.nu, dataOfUser);
		}
	}, []);
	const ContentBrand = (
		<ContentImage>
			<figure>
				<img src={MobileKat} alt="Imágen del bot de Kat en mobile" />
			</figure>
			<div className="flex-content-kat">
				<Kat />
				<ContentTextBrand>
					<TextBrand>KAT</TextBrand>
					<TextBrand>PREMIUM</TextBrand>
				</ContentTextBrand>
			</div>
		</ContentImage>
	);
	return (
		<PrincipalContainer>
			<Wrapper>
				<MainContainer>
					<InfoContent>
						<SecondaryTitle white mb10>
							Presentamos
						</SecondaryTitle>
						<TertiaryTitle white mb25>
							¡LA FORMA DE <TextBackground>ESTUDIAR</TextBackground> EN LINEA <TextBackground>SIN INTERNET</TextBackground>!
						</TertiaryTitle>
						<TextBody4 white mb25>
							Acelera tu aprendizaje y tu ingreso a la universidad con la información más confiable, rápida y de calidad.
						</TextBody4>
						<TextBody4 white mb25>
							Si piensas que ya descubriste todo lo que puede hacer Kat, aún no viste nada.
						</TextBody4>
						<Items mb30>
							{listItems.map(({ name }) => (
								<ListItem key={name}>
									<CheckCircleSVG />
									<TextBody4>{name}</TextBody4>
								</ListItem>
							))}
						</Items>
						<TextSubtext2 white mb25>
							KAT TE APOYARÁ 24/7 DEL DÍA
						</TextSubtext2>
						<TextBody4 white>por solo</TextBody4>
						<p className="regularPrice">s/&nbsp;39.90</p>
						<h3>
							<strong>s/&nbsp;32.90</strong>&nbsp;al mes
						</h3>
						<Button payment text="Comprar Ahora" type="button" onClick={() => setModalState(!modalState)} />
					</InfoContent>
					<GradientCircle
						customStyle={{
							background: 'radial-gradient(50% 50% at 50% 50%, rgba(41, 125, 237, 0.25) 0%, rgba(41, 125, 237, 0) 100%)',
							'-webkit-filter': 'blur(145px)',
							filter: 'blur(145px)',
						}}>
						{ContentBrand}
					</GradientCircle>
				</MainContainer>

				{modalState && (
					<MainModal
						modalState={modalState}
						setModalState={setModalState}
						type={'classic'}
						overlayStyles={{ background: 'rgba(6, 14, 24, 0.8)' }}>
						<ModalKatPayment />
					</MainModal>
				)}
			</Wrapper>
			<MainCloud />
		</PrincipalContainer>
	);
};

export default MainKat;
