import React, { useContext } from 'react';
import { AppContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import { ContainerButtonInModal, ModalNavbarContainer, WrapperHomeHamburger } from './../../../styles/generalStyles';
import { Button } from './../../../styles/buttonGeneral';
// import { WrapperHome } from './../../../styles/generalStyles';
import { InHomeNavbarModalContainer } from './styles/sInHomeNavbarModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VeryUserSVG } from './../../../icons/navbarLanding/very-user.svg';
// import { ReactComponent as MoneySimbolSVG } from './../../../icons/navbarLanding/money-symbol.svg';
import { ReactComponent as HelpSVG } from './../../../icons/navbarHome/repair-tool.svg';

//Import ALGORITHMS
import { closeSession } from './navbarInHomeMenu/algorithms/closeSession';
import ItemNavbar from '../landingComponents/ItemNavbar';

const listOfItems = [
	{
		nameItem: 'helpAndSupport',
		icon: <HelpSVG />,
		path: '/support-help',
	},
	{
		nameItem: 'Communities',
		icon: <VeryUserSVG />,
		path: '/social-networks',
	},
	// {
	// 	nameItem: 'promotions',
	// 	icon: <MoneySimbolSVG />,
	// 	path: '/offers',
	// },
];

const Inhomenavbarmodal = ({ setHamburger, hamburger }) => {
	const navigate = useNavigate();
	const { setDataOfUser, setCurrentUser, setSubscriptionTime, setSolvedExams, setLoading } = useContext(AppContext);
	const [t] = useTranslation('main');

	return (
		<>
			<ModalNavbarContainer>
				<InHomeNavbarModalContainer>
					<WrapperHomeHamburger>
						{listOfItems.map((item, index) => (
							<ItemNavbar
								key={index}
								icon={item.icon}
								path={item.path}
								nameItem={t(item.nameItem)}
								setHamburger={setHamburger}
								hamburger={hamburger}
							/>
						))}
					</WrapperHomeHamburger>
					<ContainerButtonInModal>
						{/* TODO: Agregar el boton de cerrar sesion */}
						<Button
							secondary
							text={t('signOut')}
							onClick={() =>
								closeSession(navigate, setDataOfUser, setCurrentUser, setSubscriptionTime, setSolvedExams, setLoading)
							}
						/>
					</ContainerButtonInModal>
				</InHomeNavbarModalContainer>
			</ModalNavbarContainer>
		</>
	);
};

export default Inhomenavbarmodal;
