import { ContentGradient, Gradient } from '../../components/general/landingComponents/styles/sPreview';
import { ChildContainer } from './styles/sGradientCircle';

const GradientCircle = ({ children, customStyle }) => {
	return (
		<ContentGradient>
			<ChildContainer>{children}</ChildContainer>
			<Gradient style={customStyle} />
		</ContentGradient>
	);
};

export default GradientCircle;
