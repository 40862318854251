import React from 'react';
import { DescriptionPreview2Container, PicturePreview2Container, Preview2Container } from './styles/sPreview2';
import { Body8, Details3 } from '../../../../../styles/textGeneral';

export const Preview2 = ({ urlImage, courseName, totalClass = '0', isBoard }) => {
	return (
		<Preview2Container isBoard={isBoard}>
			<PicturePreview2Container>
				<img src={urlImage ?? 'https://picsum.photos/200/300'} alt="Preview2" />
			</PicturePreview2Container>
			<DescriptionPreview2Container>
				<Body8>{courseName ?? 'Actualizando nombre del curso'}</Body8>
				<Details3>{`${totalClass} clases`}</Details3>
			</DescriptionPreview2Container>
		</Preview2Container>
	);
};
