import styled, { css } from 'styled-components';
import { TertiaryTitle, Text } from '../../../../styles/textGeneral';
import check from './../../../../images/check-black.png';

const phone = css`
	.previewImage {
		width: 64%;
	}

	@media (min-width: 768px) {
		.previewImage {
			width: 48%;
		}
	}

	@media (min-width: 1024px) {
		.previewImage {
			width: 35%;
		}
	}
`;

export const Gradient = styled.div`
	width: 100%;
	position: relative;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	background: -o-radial-gradient(50% 50%, 50% 50%, rgba(128, 76, 236, 0.15) 0%, rgba(209, 30, 225, 0) 100%);
	background: radial-gradient(50% 50% at 50% 50%, rgba(128, 76, 236, 0.15) 0%, rgba(209, 30, 225, 0) 100%);
	-webkit-filter: blur(145px);
	filter: blur(145px);
	z-index: 1;
`;

export const ImageComponent = styled.img`
	position: absolute;
	z-index: 10;
`;

export const ContentGradient = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 237px;
	justify-content: center;
	align-items: center;

	@media (min-width: 480px) {
		height: 320px;
	}

	@media (min-width: 768px) {
		height: 400px;
	}

	@media (min-width: 1200px) {
		height: 337px;
	}
`;

export const PreviewContainer = styled.section`
	background-color: ${(props) => props?.backgroundColor};
	background-image: url(${(props) => props.background});
	background-size: cover;
	padding: ${(props) => props.padding || '30px 0'};
	height: ${(props) => props.height || '100vh'};

	.textContent {
		z-index: 20;
	}
	.center {
		margin: auto;
	}

	.titlePreview,
	.textPreview {
		text-align: center;
	}

	.normal {
		padding: 0;
		height: 0;
	}

	.titlePreview {
		margin: ${(props) => props.margin};
	}

	.textPreview {
		font-size: 1em;
	}

	.previewImage {
		width: 100%;
		margin: 40px auto 0 auto;
	}

	ul {
		margin: 30px 0 0 0;
	}

	li {
		margin: 0 0 20px 0;

		&:last-child {
			margin: 0;
		}
	}

	li p {
		text-align: left;
		position: relative;
		left: 30px;
		width: calc(100% - 30px);

		&::before {
			content: '';
			background-image: url(${check});
			background-size: cover;
			width: 20px;
			height: 20px;
			position: absolute;
			left: -30px;
			top: 2px;
		}
	}

	button {
		margin: 30px 0 0 0;
	}

	${(props) =>
		props.imageWidth === 'phone' &&
		css`
			${phone};

			@media (min-width: 1200px) {
				margin: 0;

				.previewImage {
					width: 220px !important;
				}
			}
		`}

	${(props) =>
		props.imageWidth === 'otherPhone' &&
		css`
			${phone};

			@media (min-width: 1200px) {
				margin: 0 auto;

				.previewImage {
					width: 220px !important;
				}
			}
		`}

  ${(props) =>
		props.allText === 'center' &&
		css`
			.titlePreview,
			.textPreview {
				text-align: center !important;
			}
		`}

  ${(props) =>
		props.type === 'simple' &&
		css`
			margin: 0 0 120px 0;

			@media (min-width: 1200px) {
			}
		`}

   ${(props) =>
		props.active &&
		css`
			padding: 80px 0;
			height: auto;
		`}

  ${(props) =>
		props.them === 'black' &&
		css`
			${TertiaryTitle},
			${Text} {
				color: ${(props) => props.theme.color.neutral0};
			}
		`};

	@media (min-width: 1200px) {
		.titlePreview,
		.textPreview {
			text-align: left;
		}

		.textPreview {
			font-size: 1.125em;
		}

		.textContent {
			flex-basis: 805px;
		}

		.cardsList {
			display: flex;
			flex-direction: column;
			gap: 28px;
			margin: 18px 0 0 0;
			align-self: normal;
		}

		.previewImage {
			margin: 0;
			width: 556px;
		}

		button {
			margin: 50px 0 0 0;
			width: 303px;
		}
	}
`;

export const Flex = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1200px) {
		flex-direction: row;
		align-items: center;
		gap: 50px;
	}

	${(props) =>
		props?.reverse &&
		css`
			@media (min-width: 1200px) {
				flex-direction: row-reverse;
			}
		`}

	${(props) =>
		props.displayD === 'block' &&
		css`
			@media (min-width: 1200px) {
				display: block;
			}
		`}

  ${(props) =>
		props.imageWidth === 'phone' &&
		css`
			@media (min-width: 1200px) {
				margin: 0 110px;
			}
		`}

  ${(props) =>
		props.type === 'simple' &&
		css`
			@media (min-width: 1200px) {
				div {
					width: 100%;
				}
			}
		`}

  ${(props) =>
		props.type === 'inPromo' &&
		css`
			padding: 0 0 70px 0;

			@media (min-width: 1200px) {
				margin: auto 25px;
			}
		`}

  ${(props) =>
		props.imageWidth === 'otherPhone' &&
		css`
			@media (min-width: 1200px) {
				margin: 0 auto;
				justify-content: center;
			}
		`}
`;
