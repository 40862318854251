import styled from 'styled-components';

export const CoverPageContainer = styled.section`
	width: 100%;
	overflow: hidden;
	background-image: url(${(props) => props.background});
	background-size: cover;
	height: 100vh;

	h1,
	h2 {
		color: ${(props) => props.theme.color.neutral0};
	}

	h1 {
		padding: 50px 0 30px 0;
		position: relative;
	}

	@media (min-width: 768px) {
		button {
			margin: 70px 0;
			width: 350px;
		}
	}

	@media (min-width: 1000px) {
		button {
			margin: 85px 0 20px 10px;
		}
	}

	@media (min-width: 1200px) {
		height: calc(100vh);
		max-height: 800px;

		h1 {
			padding: 0 0 40px 0;
			width: 70%;
		}

		h2 {
			width: 50%;
		}

		a {
			width: fit-content;
		}
	}
`;
export const LandingPromotionsContent = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1000px) {
		img {
			display: block;
			position: relative;
			bottom: -50px;
			width: 50%;
		}
		.students {
			width: 60%;
			margin: auto auto;
		}
	}

	@media (min-width: 1200px) {
		img {
			display: block;
			position: absolute;
			bottom: 0;
			right: 25%;
			width: 50%;
		}

		.students {
			width: 50%;
			right: -150px;
			margin: auto auto 0 auto;
		}
	}

	@media (max-width: 1000px) {
		.students {
			width: 80%;
			margin: auto auto 0 auto;
			@media (min-height: 900px) {
				width: 90%;
			}
		}

		img {
			width: 100%;
			height: 100%;
			bottom: 0;
			object-fit: cover;
			@media (min-height: 900px) {
				padding-top: 50px;
			}
			@media (max-height: 900px) {
				display: none;
			}
		}
	}

	@media (max-width: 790px) {
		img {
			display: flex;
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media (min-height: 800px) {
				padding-top: 50px;
			}
			@media (max-height: 720px) {
				display: none;
			}
		}

		.students {
			width: 70%;
			margin: auto;
			margin-bottom: 0 !important;
			@media (min-height: 800px) {
				width: 90% !important;
			}
			@media (min-height: 900px) {
				width: 90%;
			}
		}
	}

	@media (max-width: 500px) {
		img {
			@media (min-height: 850px) {
				padding-top: 50px;
			}
		}

		.students {
			width: 70%;
			margin: auto;
			margin-bottom: 0 !important;
			@media (min-height: 900px) {
				width: 90%;
			}
		}
	}
	@media (max-width: 350px) {
		.students {
			display: none;
		}
	}
`;

export const LandingPageContent = styled.div`
	display: flex;
	flex-direction: column;

	button {
		margin: 40px 0;
		position: relative;
		z-index: 100;
	}

	div .linkAsesor {
		margin: 0;
		color: ${(props) => props.theme.color.neutral0};
		font-family: ${(props) => props.theme.font.brandFont};
		font-style: normal;
		font-weight: 700;
		font-size: 1em;
		line-height: 20px;
		display: inline-flex;
	}

	.linkAsesor {
		&:hover {
			color: ${(props) => props.theme.color.secondary800};
			scale: 1.1;
		}
	}

	@media (min-width: 1024px) {
		img {
			display: none;
		}

		div .linkAsesor {
			margin: 0 0 0 40px;
		}
	}

	@media (min-width: 1200px) {
		img {
			display: block;
			position: absolute;
			bottom: 0;
			right: 25%;
			width: 50%;
		}

		.astronout {
			width: 61%;
			right: -290px;
			bottom: 0;
			top: 0;
			margin: auto;
		}
		.coverPage {
			width: 55%;
		}
		div .linkAsesor {
			margin: 0 0 0 40px;
		}
	}

	@media (max-width: 1000px) {
		.astronout {
			width: 70%;
			bottom: 0;
			top: 0;
			margin: auto;
			@media (min-height: 900px) {
				width: 60%;
			}
			@media (min-height: 1180px) {
				width: 90%;
			}
		}

		img {
			display: flex;
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media (min-height: 900px) {
				padding-top: 50px;
			}
			@media (max-height: 900px) {
				display: none;
			}
		}
	}

	@media (max-width: 790px) {
		img {
			display: flex;
			position: relative;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@media (min-height: 850px) {
				padding-top: 50px;
			}
			@media (max-height: 720px) {
				display: none;
			}
		}
		.astronout {
			width: 70%;
			bottom: 0;
			top: 0;
			margin: auto;
			@media (min-height: 850px) {
				width: 80%;
			}
		}
	}

	@media (max-width: 350px) {
		.astronout {
			display: none;
		}
	}
`;

export const Flex = styled.div`
	margin: 43px 0 0 0;
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => props.justifyContent || 'space-between'};
	height: ${(props) => props.height || '35vh - 48px'};

	.pink {
		color: ${(props) => props.theme.color.secondary800};
	}

	.blue {
		color: ${(props) => props.theme.color.primary500};
	}

	@media (min-width: 1024px) {
		justify-content: center;
		height: calc(100vh - 43px);
		margin: 0;

		.underline {
			text-decoration: underline;
		}
	}

	@media (min-width: 1200px) {
		position: relative;
		height: 100vh;
		max-height: 800px;
	}
`;

export const PromotionContent = styled.div`
	color: ${(props) => props.theme.color.neutral0};
	justify-content: center;

	align-content: center;

	h2 {
		margin: 10vh 0 15px 0;
		font-size: 1.125em;
		line-height: 1.25em;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	h1 {
		padding: 0;
		margin: 0 0 40px 0;
	}
	h1.bigText {
		font-size: 52px;
		width: 70%;
		text-align: center;
		margin: 50px auto;
		margin-top: 0;
		@media (max-width: 720px) {
			font-size: 40px;
		}
	}
	p.bigText2 {
		text-align: center;
	}
	.About {
		display: flex;
		justify-content: center;
		text-align: center;
	}

	p {
		font-family: ${(props) => props.theme.font.brandFont};
		margin: 0 0 10px 0;
	}

	h3 {
		color: ${(props) => props.theme.color.error400};
		font-size: 1.875em;
	}

	@media (min-width: 1200px) {
		h2 {
			margin: 0 0 15px 0;
			font-size: 1.375em;
		}

		h1 {
			margin: 0 0 50px 0;
			width: 50%;
		}

		p {
			margin: 0 0 10px 0;
		}

		h3 {
			font-size: 2.375em;
		}
	}
`;
