import styled, { css } from 'styled-components';
import { Details3Mixin } from '../../../../../styles/textGeneral';
import { Link } from 'react-router-dom';

export const WeekClassContainer = styled(Link)`
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: ${({ theme }) => theme.color.neutral980};
	gap: ${({ theme }) => theme.space.spacing3X};
	padding: ${({ theme }) => theme.space.spacing4X};

	${(props) =>
		props.isActive &&
		css`
			background-color: ${({ theme }) => theme.color.neutral735};
		`}

	p {
		color: ${({ theme }) => theme.color.neutral0};
	}
	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		padding: ${({ theme }) => theme.space.spacing5X};
	}
`;

export const Point = styled.div`
	margin-top: ${({ theme }) => theme.space.spacing1X};
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.color.primary300};
	flex: 0 0 auto;
`;

export const Informationn = styled.p`
	${Details3Mixin};
	color: ${({ theme }) => theme.color.neutral400} !important;
`;
