import React from 'react';
import Coverpage from './../general/landingComponents/CoverPage';
import Preview from './../general/landingComponents/Preview';
import Contacts from './../general/cOthers/Contacts';
import { previewsList } from './../landing/previewsList';

export default function MainFree() {
	return (
		<main>
			<Coverpage background={require('./../../images/free-promo.webp')} work="free" />
			{previewsList.map((preview) => (
				<Preview key={preview.title} {...preview} />
			))}
			<Contacts />
			<Preview
				title={`¡No esperes más! \n La universidad a un click de ti`}
				background={require('./../../images/b-last-1.webp')}
				allText="center"
				margin="0"
				type="simple"
			/>
		</main>
	);
}
