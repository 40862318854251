import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../App';
import RecordCard from './../cards/RecordCard';
import { RecordsCardUnlist } from './styles/sSimulacrumRecord';
import LocalSpinner from './../../spinner/LocalSpinner';
import { Text } from '../../../styles/textGeneral';
// import StatisticCard from './..//**/cards/StatisticCard';

//Import firebase utils
import { useFirestore } from 'reactfire';

//Import ALGORITHMS
import { recoverLastExams } from './algorithms/recoverLastExams';

export default function SimulacrumRecord({ stats, type }) {
	const db = useFirestore();
	const [, setStatisticsCardList] = useState([]);
	const { currentUser, solvedExams, setSolvedExams } = useContext(AppContext);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (stats) {
			if (type === 'simulacrum') {
				setStatisticsCardList([
					{
						number: stats?.averageStatistics?.examsSolved,
						name: 'Simulacros tipo admisión resueltos',
					},
				]);
				if (Object.keys(solvedExams).length === 0) {
					recoverLastExams(db, currentUser, solvedExams, setSolvedExams, setLoading);
				}
			}
		}
	}, [stats]);

	if (loading) {
		return <LocalSpinner title="Cargando..." />;
	}

	return (
		<div>
			<RecordsCardUnlist>
				{/* {statisticsCardList.map((card) => (
          <StatisticCard padding="30px 0" key={card.name} {...card} />
        ))} */}
			</RecordsCardUnlist>
			<ul>
				{Object.keys(solvedExams).length > 0 ? (
					Object.keys(solvedExams).map((key) => <RecordCard key={key} exam={solvedExams[key]} />)
				) : (
					<Text>Aún no cuentas con simulacros realizados</Text>
				)}
			</ul>
		</div>
	);
}
