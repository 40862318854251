import React from 'react';
import { Link } from 'react-router-dom';
import { ItemFooterContainer } from './styles/sItemFooterLanding';
// import Link from 'react-dom';
const Itemfooterlanding = ({ icon, text, link, type }) => {
	const mailTo = (e) => {
		e.preventDefault();
		window.location.href = 'mailto:zbeizaga@gmail.com?Subject= Quiero%20más%20información%20de%20Servicio%20Puntual';
	};

	return (
		<>
			{type === 'email' ? (
				<Link target="_blank" to="/" rel="noopener noreferrer" onClick={mailTo}>
					<ItemFooterContainer className="itemFooterLanding">
						<div>{icon}</div>
						<p>{text}</p>
					</ItemFooterContainer>
				</Link>
			) : (
				<a href={link} target="_blank" rel="noopener noreferrer">
					<ItemFooterContainer className="itemFooterLanding">
						<div>{icon}</div>
						<p>{text}</p>
					</ItemFooterContainer>
				</a>
			)}
		</>
	);
};

export default Itemfooterlanding;
