import styled from 'styled-components';

export const MainVideoContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100% - 50px);

	@media (min-width: 1024px) {
		display: grid;
		width: 100%;
		grid-template-columns: auto 410px;
		grid-template-rows: auto;
	}
`;
