import styled from 'styled-components';

export const CommentListContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: transparent;
	gap: ${({ theme }) => theme.space.spacing1X};

	.weDontComments {
		padding: 20px;
		text-align: center;
	}

	@media (width >= ${({ theme }) => theme.media.mediaMD.width}) {
		gap: ${({ theme }) => theme.space.spacing2X};
	}
`;
