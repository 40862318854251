import styled from 'styled-components';

export const MainClassVideoContainer = styled.div`
	position: relative;
	.mainClassVideoBloqued {
		width: 80%;
		height: 18vh;
		position: absolute;
		top: 50px;
		z-index: 4000;
	}

	h2,
	h3 {
		font-family: ${(props) => props.theme.font.textFont};
	}

	h2 {
		text-transform: capitalize;
		margin: 0 0 10px 0;
	}

	h3 {
		font-size: 1em;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	@media (min-width: 1200px) {
		.mainClassVideoBloqued {
			top: 30px;
			width: calc(92% - 270px);
		}
	}
`;
